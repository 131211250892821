import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function isWishlistDataRequest(
  state = initialState.isWishListData,
  action
) {
  switch (action.type) {
    case types.LOAD_WISHLIST_DATA_SUCCESS:
      return action.isWishListData;
    default:
      return state;
  }
}
