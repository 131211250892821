export const LOGIN = "login";
export const LOGOUT = "user/logout";
export const SOCIAL_SINGUP = "socialSignUp";
export const SINGUP = "signUp";
export const SINGUP_AS_GUEST = "signUpAsGuest";
export const OTP_CHECK = "checkOtp";
export const RESEND_OTP = "resendOtp";
export const FORGOT_PASSWORD = "forgotPassword";
export const BANNER = "banner";
export const HOME_CATEGORY = "home/furniture";
export const CMS_PAGE = "pages/";
export const CATEGORY = "home/category";
export const PRODUCT = "catalogue/products";
export const FILTERS = "catalogue/filters";
export const PRODUCT_DETAILS = "catalogue/products/details";
export const PRODUCT_ATTRIBUTE = "catalogue/products/details/attribute";
export const WISH_LIST_ADD = "wishList/add";
export const ADD_TO_CART = "cart/add";
export const RECENTIY_VIEW_PRODUCT = "catalogue/products/recentiyView";
export const PRODUCT_REVIEW_LIKE = "review/likeReview";
export const LOAD_CART_ITEM = "cart/list";
export const UPDATE_CART_ITEM = "cart/update";
export const REMOVE_CART_ITEM = "cart/remove";
export const CHECK_COUPON_CODE = "check/coupon";
export const CHECK_COUPON_CODE_REMOVE = "check/couponRemove";
export const LOAD_COUPON_CODE_DATA = "coupon";
export const PLACE_ORDER = "checkout/create";
export const MY_ORDER_LIST = "order/list";
export const MY_WISH_LIST = "wishList/my_wishlist";
export const UPDATE_PROFILE = "user/profile";
export const GET_PROFILE = "user/myProfile";
export const UPDATE_PASSWORD = "user/changePassword";
export const UPDATE_PROFILE_IMAGE = "user/profile/image";
export const LOAD_FOOTER_PRODUCT = "catalogue/footer/product";
export const WHAT_HOT_PRODUCT = "home/whatHot";
export const CHECK_AVAILABILITY = "checkAvailablity";
export const LIKE_SELLER_REVIEW = "review/seller/like";
export const USER_ADDRESS_DATA = "address/list";
export const ADD_USER_ADDRESS_DATA = "address/add";
export const UPDATE_USER_ADDRESS_DATA = "address/update";
export const REMOVE_USER_ADDRESS_DATA = "address/remove";
export const SETTING = "setting";
export const DUMMY_ORDER = "checkout/create/dummy";
export const UPDATE_USER_INFO = "checkout/update_user_info";
export const CATALOGUE_SEARCH = "catalogue/search";
export const ADD_PRODUCT_REVIEW = "review/add";
export const LOAD_PRODUCT_REVIEW = "catalogue/products/review";
export const ADD_SELLER_REVIEW = "review/seller/add";
export const MY_ORDER_CANCEL = "order/cancel";
export const LOAD_MY_REVIEW = "review/list";
export const DELETE_MY_REVIEW = "review/delete";
export const LOAD_FOOTER_CATEGORY = "category";
export const FAQ_PAGE = "faq";
export const CONTACT_US_PAGE = "contactUs";
export const SPECIAL_REQUEST_PAGE = "specialRequest";
export const UPLOAD_INSTA_IMAGE = "instaPhoto";
export const GET_REAL_SPACES = "home/realSpaces";
export const GET_HOME_PAGE_DESIGEN = "desigen";
export const MEETING_REQUEST = "requestForMeeting";
export const SELLER_DATA_LOAD = "sellerName";
export const CHECK_CART_ITEM = "cart/itemCheck";
export const PAGE_LIST_DATA_SUCCESS = "pages/list";
export const CMS_PAGE_SUBSCRIBE = "pages/Subscribe";
export const LOAD_NOTIFICATION = "notification";
export const LOAD_FOOTER_SUPPLIER = "supplier";
export const PACKAGE = "package";
export const GET_PACKAGE_ROOM_CLASS = "package/getPackageRoomClass";
export const GET_SUPPLIER_DETAILS = "supplier/details";
export const SAVE_SWADESH_COLLECTION_DATA = "save_swadesh_collection_data";
