import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function dummyProductModelReducer(
  state = initialState.dummyProductModal,
  action
) {
  switch (action.type) {
    case types.LOAD_DUMMY_PRODUCT_MODEL_DATA:
      return action.dummyProductModal;
    default:
      return state;
  }
}
