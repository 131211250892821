import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import { showToaster } from "../utils/helpers";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import * as productActions from "./productActions";
import * as userActions from "./userActions";
import {
  loadCartCountDataSuccess,
  loadDataSuccess,
  loadCartItemDataSuccess,
  loadUserAddressData,
} from "./userActions";

export function loadItemRemoveCart(cartItem) {
  return { type: types.REMOVE_PRODUCT_FROM_CART, cartItem };
}

export function loadItemIncrementCart(cartItem) {
  return { type: types.INCREMENT_CART_ITEM_QUANTITY, cartItem };
}

export function loadItemDecrementCart(cartItem) {
  return { type: types.DECREMENT_CART_ITEM_QUANTITY, cartItem };
}

export function loadCheckCouponData(cartItem) {
  return { type: types.LOAD_CHECK_COUPON_DATA, cartItem };
}

export function loadCouponModelData(couponModel) {
  return { type: types.LOAD_COUPON_MODEL_DATA, couponModel };
}

export function loadCouponCodeData(couponCode) {
  return { type: types.LOAD_COUPON_CODE_DATA, couponCode };
}

export function userLoginDataSuccess(userLogin) {
  return { type: types.LOADED_USER_LOGIN_SUCCESS, userLogin };
}

export function availabilityDataSuccess(isAvailability) {
  return { type: types.CHECK_AVAILABILITY, isAvailability };
}

export function dummyOrderDataSuccess(dummyOrder) {
  return { type: types.ORDER_REQUEST_STATUS, dummyOrder };
}

export function addToCart(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.ADD_TO_CART, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          showToaster(response.data.message);
          if (response.data.status === 200) {
            dispatch(
              loadCartCountDataSuccess(response.data.data.cartItemCount)
            );
            dispatch(loadDataSuccess(false));
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function loadCartItem(params) {
  return async function (dispatch) {
    productActions.loadRecentiyView(params)(dispatch);
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.LOAD_CART_ITEM, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
            dispatch(
              loadCartCountDataSuccess(response.data.data.cartItemCount)
            );
            dispatch(loadUserAddressData());
            if (response.data.data.cartItemCount === 0) {
              dispatch(loadDataSuccess(true));
            } else {
              dispatch(loadDataSuccess(false));
            }
            dispatch(loadCartItemDataSuccess(response.data.data));
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function removeCartItem(params) {
  return async function (dispatch) {
    dispatch(loadItemRemoveCart(params));
    await agent
      .post(API.REMOVE_CART_ITEM, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 200) {
            dispatch(
              loadCartCountDataSuccess(response.data.data.cartItemCount)
            );
            dispatch(loadCartItemDataSuccess(response.data.data));
            if (response.data.data.cartItemCount === 0) {
              dispatch(toggleNetworkRequestStatus(false));
              showToaster(response.data.message);
              dispatch(loadCartCountDataSuccess(0));
              dispatch(loadDataSuccess(true));
              dispatch(loadCartItemDataSuccess({}));
            }
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function incrementOrDecrement(params) {
  return async function (dispatch) {
    if (params.type === "inc") {
      dispatch(loadItemIncrementCart(params.id));
    } else {
      dispatch(loadItemDecrementCart(params.id));
    }
    if (params.qty !== 0) {
      await agent
        .post(API.UPDATE_CART_ITEM, params)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(toggleNetworkRequestStatus(false));
            if (response.data.status === 200) {
              dispatch(
                loadCartCountDataSuccess(response.data.data.cartItemCount)
              );
              dispatch(loadCartItemDataSuccess(response.data.data));
            }
          }
          if (response.status === 201) {
            if (response.data.status === 401) {
              userActions.userLogoutAction()(dispatch);
              showToaster("Your session has been expired please login again.");
            }
          }
        })
        .catch((error) => {
          dispatch(toggleNetworkRequestStatus(false));
          throw error;
        });
    }
  };
}

export function checkCouponCode(params, handleclickCouponCode) {
  return async function (dispatch) {
    if (params.coupon_code) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    await agent
      .post(API.CHECK_COUPON_CODE, params)
      .then((response) => {
        if (params.coupon_code) {
          showToaster(response.data.message);
        }
        dispatch(toggleNetworkRequestStatus(false));

        if (response.status === 200) {
          handleclickCouponCode(params.coupon_code);
          dispatch(loadCheckCouponData(response.data.data));
        } else {
          handleclickCouponCode(false);
        }
        dispatch(loadCouponModelData(false));

        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function handleclickCouponModel(params) {
  return async function (dispatch) {
    dispatch(loadCouponModelData(params));
  };
}

export function LoadCouponCodeData() {
  return async function (dispatch) {
    await agent
      .get(API.LOAD_COUPON_CODE_DATA)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(loadCouponCodeData(response.data.data));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function handlePlaceOrder(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.PLACE_ORDER, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          showToaster(response.data.message);
          dispatch(loadCartCountDataSuccess(0));
          dispatch(loadDataSuccess(true));
          dispatch(loadCartItemDataSuccess({}));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function checkAvailability(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.CHECK_AVAILABILITY, params)
      .then((response) => {
        showToaster(response.data.message);
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.status === 200) {
            dispatch(availabilityDataSuccess(true));
            dispatch(locationRequestPincode(params.pincode));
            dispatch(locationAction(false));
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function handlePlacePaymentOrder(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.DUMMY_ORDER, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        if (response.status === 200) {
          dispatch(dummyOrderDataSuccess(true));
        } else {
          dispatch(submittingRequestStatus(false));
          dispatch(toggleNetworkRequestStatus(false));
          dispatch(dummyModelRequestStatus(true));
          dispatch(dummyProductRequestStatus(response.data.data));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function locationRequestStatus(locationPop) {
  return { type: types.LOADED_LOCATION_SUCCESS, locationPop };
}
export function locationRequestPincode(pinCode) {
  return { type: types.LOADED_PINCODE_SUCCESS, pinCode };
}

export function loadSellerDataSuccess(sellerOrderData) {
  return { type: types.LOAD_SELLER_ORDER_DATA_SUCCESS, sellerOrderData };
}

export function locationAction(params) {
  return async function (dispatch) {
    dispatch(locationRequestStatus(params));
  };
}

export function loadSellerData(params) {
  return async function (dispatch) {
    await agent
      .get(API.SELLER_DATA_LOAD, { params })
      .then((response) => {
        dispatch(loadSellerDataSuccess(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function handleclickCouponCode(params) {
  return async function (dispatch) {
    if (params.coupon_code) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    await agent
      .post(API.CHECK_COUPON_CODE, params)
      .then((response) => {
        if (params.coupon_code) {
          showToaster(response.data.message);
        }
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          dispatch(loadCouponModelData(false));
          dispatch(loadCheckCouponData(response.data.data));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function checkCouponRemoveCode(params) {
  return async function (dispatch) {
    if (params.coupon_code) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    await agent
      .post(API.CHECK_COUPON_CODE_REMOVE, params)
      .then((response) => {
        if (params.coupon_code) {
          showToaster(response.data.message);
        }
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          dispatch(loadCouponModelData(false));
          dispatch(loadCheckCouponData(response.data.data));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadRecentlyProductCartSuccess(recentlyView) {
  return { type: types.LOAD_RECENT_VIEW_DATA_SUCCESS, recentlyView };
}

export const loadRecentiyViewCart = (params) => async (dispatch) => {
  try {
    dispatch(loadRecentlyProductCartSuccess([]));
    const response = await agent.post(API.RECENTIY_VIEW_PRODUCT, params);
    if (response.status === 200 || response.status === 201) {
      dispatch(loadRecentlyProductCartSuccess(response.data.data));
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    throw error;
  }
};

export function dummyModelRequestStatus(dummyProductModal) {
  return { type: types.LOAD_DUMMY_PRODUCT_MODEL_DATA, dummyProductModal };
}

export function dummyProductRequestStatus(dummyProduct) {
  return { type: types.LOAD_DUMMY_PRODUCT_DATA, dummyProduct };
}

export function dummyProductAction(params) {
  return async function (dispatch) {
    dispatch(dummyModelRequestStatus(params));
  };
}

export function updateUserInfo(params) { 
  return async function (dispatch) {
    
    await agent
      .post(API.UPDATE_USER_INFO, params)
      .then((response) => {
        
      })
      .catch((error) => { 
        throw error;
      });
  };
}
