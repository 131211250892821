import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { renderTitle } from "../../utils/helpers";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import * as wishlistActions from "../../actions/wishlistActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Slider from "react-slick";

const List = (props) => {
  const { userInfo, actions, wishCount } = props;

  const [product, setProduct] = useState(props.product);
  const [mainProduct, setMainProduct] = useState(true);

  // useEffect(() => {
  //   setProduct(product);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [product]);

  function showSilder(e) {
    if (product.imageWeb) {
      setMainProduct(false);
    }
  }

  function hideSilder(e) {
    if (product.imageWeb) {
      setMainProduct(true);
    }
  }

  function handleclickWishlist() {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      let params = {};
      if (product.productId) {
        params.productId = product.productId;
      } else {
        params.productId = product.id;
      }
      params.count = wishCount;
      actions.addWishList(params);
      setProduct({ ...props.product, ...{ isWishlist: !product.isWishlist } });
    }
  }

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
  };

  return (
    <>
      {mainProduct ? (
        <div className="product">
		<div className="product-wrap">
          {product && (
            <>
            
              <div className="pro_img" onMouseEnter={showSilder}>
                

                <NavLink
                    to={"/product/details/" + product.id}
                    title={product.title}
                  >
                  <LazyLoad
                    images={product.image}
                    key={product.id}
                    imageClass={"productBlur"}
                  />
                  <div className="tab_overlay">
                    <NavLink
                      to={"/product/details/" + product.id}
                      title={product.title}
                      className="btn"
                    >
                      <i className="fas fa-eye"></i>
                    </NavLink>
                  </div>
                </NavLink>
              </div>
              
              <NavLink
                    className="w-100"
                    to={"/product/details/" + product.id}
                    title={product.title}
                  >
                <div className="title py-2">
                  <h5 className="p-0">
                    <NavLink
                      to={"/product/details/" + product.id}
                      title={product.title}
                    >
                      {renderTitle(product.title)}
                    </NavLink>
                  </h5>


          <div className="text-center">
            <NavLink
              to={
                "/product/details/" +
                product.id
              }
              title={product.title}
              exact
              className="btn view-product"
              >View Product </NavLink>
            </div>
                  
                </div>
              </NavLink>
            </>
          )}
		   </div>
		   
			  <div className="dealer_bio d-flex align-items-center mt-2 py-2 border-bottom">
                  {/* <div className="dealer_name">
					  <span className="mr-2">
						<img src={product.seller.logo} alt="seller" />
					  </span>
					  {product.seller.name}
					</div>
					<div className="seller_rate ml-auto">
					  <img className="ml-auto" src={product.seller.star} alt="star" />
					</div> */}
                </div>
        </div>
      ) : (
        <div className="product">
          {product && (
            <>
              <div className="pro_img" onMouseLeave={hideSilder}>
                <Slider {...settings}>
                  {product.imageWeb.map((item, index) => (
                    <div className="sliderproduct" key={index}>
                      <LazyLoad
                        images={item.thumbnail}
                        key={index}
                        imageClass={"productBlur"}
                      />
                    </div>
                  ))}
                </Slider>
                <span
                  className="like"
                  onClick={() => handleclickWishlist(product)}
                >
                  {product.isWishlist ? (
                    <i className="fas fa-heart isWishlist"></i>
                  ) : (
                    <i className="far fa-heart"></i>
                  )}
                </span>
                <div className="tab_overlay">
                  <NavLink
                    to={"/product/details/" + product.id}
                    title={product.title}
                    className="btn"
                  >
                    <i className="fas fa-eye"></i>
                  </NavLink>
                </div>
              </div>
              <div className="title py-2">
                <h5 className="p-0">
                  <NavLink
                    to={"/product/details/" + product.id}
                    title={product.title}
                  >
                    {renderTitle(product.title)}
                  </NavLink>
                </h5>
                <div className="discount mt-2">
                  <span>
                    {product.currency}
                    {product.discounted_price}
                  </span>
                  {product.discount !== 0 && (
                    <>
                      <del className="mx-2">
                        {product.currency}
                        {product.price}
                      </del>
                      <span>{product.discount}% off</span>
                    </>
                  )}
                </div>
                <div className="rating mt-2">
                  <div className="star d-inline-flex align-items-center">
                    <StarRatingComponent
                      name="rate"
                      starCount={5}
                      value={product.rating}
                      renderStarIcon={() => <i className="fas fa-star"></i>}
                    />
                    <span className="ml-1 mb-1">{product.rating} Rating</span>
                  </div>
                  {product.stock ? (
                    <div className="text-success">&nbsp;In Stock</div>
                  ) : (
                    <div className="text-danger">&nbsp;Out of Stock</div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(wishlistActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
