import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function brandReducers(state = initialState.brandData, action) {
  switch (action.type) {
    case types.LOAD_BRAND_DATA_SUCCESS:
      return action.brandData
    default:
      return state
  } 
}
