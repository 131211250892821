import React, { useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import "./HomeBanner.css"
import { NavLink } from "react-router-dom"

var banner_slider = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const HomeBanner = (props) => {
	
  const [urlLink, setUrlLink] = useState("asdasd");
	
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" }
    }
    return { __html: "" }
  }
  
  const handleBannerClick = () => {  
   const ele= document.querySelectorAll(".slick-current .shopNowBtn");
    ele.forEach(el=>el.click());
  }
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight, // Adjust this value as needed
      behavior: 'smooth', // for smooth scrolling
    });
  };
  return (
    <>
      <section className="banner_sec container">
        <Slider {...banner_slider}>
          {props.bannerData.map((item, index) => (
            // <div key={index} className="cursor-link" onClick={() => handleBannerClick()}>
            <div key={index}>
              <div
                className="banner_slide"
                style={{ backgroundImage: "url(" + item.image + ")" }}
              >
                <Container fluid className="banner_caption px-5 pb-5 pt-0">
                  <div className="banner_btn" >
                    <NavLink
					  
                      to="how_it_work"
                      title="How It Works"
                      exact
                      className="btn"
                    >
                      How It Works
                    </NavLink>
                    {/* <NavLink
					            style={{ display: "none" }}
                      to={item.hyperlink}
                      title="Shop Now"
                      exact
                      className="btn ml-2"
                    >
                      Shop Now
                    </NavLink> */}
					          {/* <a title="Shop Now" className="btn ml-2 float--right shopNowBtn" href="#ShopNow">Shop Now</a> */}
                  </div>
                  <h1 className="mb-4">{item.title}&nbsp;</h1>
                  <p>{item.description}&nbsp;</p> 
                </Container>
              </div>
            </div>
          ))}
        </Slider>
      </section> 
    </>
  )
}

export default HomeBanner
