import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function productAttributeReducers(
  state = initialState.productAttribute,
  action
) {
  switch (action.type) {
    case types.PRODUCT_ATTRIBUTE_DATA_SUCCESS:
      return [action.productAttribute];
    default:
      return state;
  }
}
