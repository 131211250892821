import React, { useEffect } from "react";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import Newsletter from "../../component/Newsletter/Newsletter";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";

import "./ContactUs.css";

const Index = (props) => {
  const { title, actions, setting } = props;
  const { register, errors, handleSubmit } = useForm();

  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [actions, title]);

  const onSubmit = (data) => {
    actions.sendContactUs(data);
  };
  return (
    <>
      <section className="contact_sec py-5 pt-5">
        <Container>
          <Row>
            <Col md={6}>
              <h1 className="page_title mb-4 pt-5">Contact Us</h1>
              {setting.setting && (
                <div className="contact_info">
                  <div className="locate">
                    <img src="../assets/img/location.png" alt="location" />
                    <h4>
                      Location: <span>{setting.setting.address}</span>
                    </h4>
                  </div>

                  <div className="locate">
                    <img src="../assets/img/email.png" alt="email" />
                    <h4>
                      Email: <span>{setting.setting.email}</span>
                    </h4>
                  </div>

                  <div className="locate">
                    <img src="../assets/img/call.png" alt="number" />
                    <h4>
                      Phone: <span>{setting.setting.number}</span>
                    </h4>
                  </div>
                </div>
              )}
              <div className="formOuter">
			   
			   <h4 className="mb-3">Drop in your query and we'll get in touch with you soon!</h4>
			   
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
				 
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Group controlId="formBasicFirstName">
                          <FormControl
                            type="text"
                            placeholder="Name*"
                            className={errors.name ? "formError" : ""}
                            name="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 3,
                              maxLength: 20,
                            })}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span role="alert">Please enter your name.</span>
                          )}
                          {errors.name && errors.name.type === "minLength" && (
                            <span role="alert">
                              Your name should contain atleast 3 characters.
                            </span>
                          )}
                          {errors.name && errors.name.type === "maxLength" && (
                            <span role="alert">
                              Your name should should not 20 characters.
                            </span>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="email"
                            placeholder="Email Address*"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <span role="alert">
                              Please enter your email address.
                            </span>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <span role="alert">Invalid email address.</span>
                          )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should not exceed 50 characters.
                              </span>
                            )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Group controlId="formBasicNumber">
                          <FormControl
                            type="tel"
                            placeholder="Mobile Number*"
                            name="number"
                            className={errors.number ? "formError" : ""}
                            aria-invalid={errors.number ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                              },
                            })}
                          />
                          {errors.number &&
                            errors.number.type === "required" && (
                              <span role="alert">
                                Please enter your mobile number.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "maxLength" && (
                              <span role="alert">
                                Your mobile number should not exceed 10 digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "minLength" && (
                              <span role="alert">
                                Your mobile number should contain atleast 10
                                digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "pattern" && (
                              <span role="alert">
                                Please enter correct mobile number. It should be
                                entered without country code.( Example
                                xxxxxxxxxx)
                              </span>
                            )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Group controlId="formBasicMessage">
                          <FormControl
                            as="textarea"
                            rows={3}
                            type="text"
                            placeholder="Message*"
                            className={errors.message ? "formError" : ""}
                            name="message"
                            aria-invalid={errors.message ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 5,
                            })}
                          />
                          {errors.message &&
                            errors.message.type === "required" && (
                              <span role="alert">
                                Please enter your message.
                              </span>
                            )}
                          {errors.message &&
                            errors.message.type === "minLength" && (
                              <span role="alert">
                                Your message should contain atleast 5
                                characters.
                              </span>
                            )}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="btnOuter">
                        <CustomButton title={"SEND MESSAGE"} />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>

            <Col md={6}>
              <div className="mapbox">
                <Iframe
                  url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.102814893646!2d77.15731141508111!3d28.566675182443838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0:0x0!2zMjjCsDM0JzAwLjAiTiA3N8KwMDknMzQuMiJF!5e0!3m2!1sen!2sin!4v1612437698495!5m2!1sen!2sin"
                  width="100%"
                  height="650px"
                  id="myId"
                  className="myClassname"
                  display="initial"
                  position="relative"
                  frameBorder="0"
                />
              </div>
            </Col>
            <Col md={12} className="mb-4 mt-5"></Col>
          </Row>
        </Container>
        <Newsletter />
      </section>
    </>
  );
};

Index.propTypes = {
  setting: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
