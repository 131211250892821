import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

import LazyLoad from "../../component/LazyLoad/LazyLoad";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as hotProductActions from "../../actions/hotProductActions";
import "./TrandingFooter.css";
import Instagram from "./Instagram";
import PropTypes from "prop-types";
import {Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay,Pagination } from 'swiper';
 

const TrandingFooter = (props) => {
  const { actionStaus, actions } = props;
  const [modalShow, setModalShow] = useState(false);

  const handleLoadModal = (faq) => {
    if (!props.userInfo.user_id) {
      props.handleClickLogin(true);
    } else {
      actions.loadActionStatus();
      setModalShow(true);
    }
  };
  useEffect(() => {
    if (actionStaus) {
      setModalShow(false);
    }
  }, [actionStaus]);

  const history = useHistory();

  const openDetails = (ide) => {
    history.push({
      pathname: "/product/details/" + ide,
    });
  };

  return (
    <>
      <section className="tranding_footer mt-4">
        <Container> 
          <Row>
            <Col lg={6} className="trending_list">
              <div className="tab_heading d-flex align-items-center">
                <h2>Trending</h2>
                <NavLink
                  to="/marketplace?type=trending"
                  title="Product"
                  exact
                  className="link ml-auto"
                >
                  See All
                </NavLink>
              </div>
              <ul className="mt-4">
                {props.trandingProductData.map((product, index) => (
                  <li
                    key={index}
                    onClick={() => openDetails(product.id)}
                    className="linkStyle"
                  >
                    <div className="image">
                      <LazyLoad
                        images={product.image}
                        key={product.id}
                        imageClass={"trendingBlur"}
                      />
                    </div>
                    <div className="text pl-3">
                      <p>{product.title}</p>

					  
                    </div>
                  </li>
                ))}
              </ul>
            </Col>
            <Col lg={6} className="insta_feed">
              <div className="tab_heading d-flex align-items-center">
                <h2>Follow us on Instagram</h2>
              </div>
              <Row className="no-gutters mt-4">
                <Col sm={6}>
                  <div className="insta_text">
                    <div className="text-center">
                      <h6># MY Designiche</h6>
                      <h3>Real Spaces Style</h3>
                      <p>
                        We Love Seeing Our Products in your Place! Keep adding
                        images below and Follow us on Designiche
                      </p>
                      <NavLink
                        to="#!"
                        title="Product"
                        exact
                        className="btn-transparent mt-2"
                        onClick={() => handleLoadModal()}
                      >
                        Submit Your Photo’s
                      </NavLink>
                    </div>
                  </div>
                </Col>
                
                  <Col sm={6} >
				  <Swiper
                        pagination={{
                          clickable: true,
                        }}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                      >
					  {props.realspaces.map((item, index) => (
                      <SwiperSlide key={index}>
						<LazyLoad
						  images={item.image}
						  key={1}
						  imageClass={"productBlur"}
						/>
					  </SwiperSlide>
					  ))}
				</Swiper>
                  </Col>
                
              </Row>
            </Col>
          </Row>
        </Container>
        {modalShow && (
          <Instagram show={modalShow} onHide={() => setModalShow(false)} />
        )}
      </section>
    </>
  );
};

TrandingFooter.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    actionStaus: state.actionStaus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(hotProductActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrandingFooter);
