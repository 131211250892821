import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";

const LocationModal = (props) => {
  const { actions, locationPop } = props;

  const handleclickPop = (action) => {
    actions.locationAction(action);
  };

  const { register, errors, handleSubmit } = useForm();
  const onSubmit = (data) => {
    actions.checkAvailability(data);
  };
  // show={locationPop}
  return (
    <>
      <Modal
        show={false}
        size="lg"
        onHide={() => handleclickPop(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton></Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center">
              <img
                alt="Product"
                src={"../../../assets/img/location.svg"}
                width={"100px"}
              />
            </div>
            <Form.Group className="sidebar_filter position-relative search_locate pt-5">
              <div>
                <p>Check for Availability in your Area?</p>
              </div>
              <Form.Control
                type="text"
                className={errors.pincode ? "formError" : ""}
                name="pincode"
                placeholder="Enter your pincode"
                ref={register({
                  required: true,
                  minLength: 6,
                  pattern: {
                    value: /^[0-9]+$/i,
                  },
                })}
              />
            </Form.Group>
            {errors.pincode && errors.pincode.type === "required" && (
              <span role="alert" className="spanError pb-2">
                Please enter your pincode
              </span>
            )}
            {errors.pincode && errors.pincode.type === "minLength" && (
              <span role="alert" className="spanError pb-2">
                Your pincode should contain atleast 6 digits
              </span>
            )}
            {errors.pincode && errors.pincode.type === "pattern" && (
              <span role="alert" className="spanError">
                Pincode should be only integer value
              </span>
            )}
            <CustomButton title={"Submit"} />
          </Form>
        </Modal.Body>
      </Modal>
      {/* <Location onClick={handleClickZipCode} /> */}
    </>
  );
};

function mapStateToProps(state) {
  return {
    locationPop: state.locationPop,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(cartActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);
