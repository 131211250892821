import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function wishlistItemReducer(
  state = initialState.wishlistItem,
  action
) {
  let updatedList;
  let updatedItemIndex;
  switch (action.type) {
    case types.LOAD_WISHLIST_ITEM_DATA:
      return action.wishlistItem;
    case types.UPDATE_WISHLIST_ITEM_DATA:
      updatedList = state.item;
      updatedItemIndex = updatedList.findIndex(
        (item) => item.key_id === action.wishlistItem.key_id
      );
      updatedList.splice(updatedItemIndex, 1);
      return { ...state, wishlistItem: updatedList };
    default:
      return state;
  }
}
