import axios from "axios";
import config from "../config";
import { showToaster } from "../utils/helpers";

const agent = axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

agent.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (!token) {
    return config;
  }

  if (process.browser) {
    config = {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  }

  return config;
});

agent.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      showToaster(error.response.statusText);
    }
    return error;
  }
);

export { agent };
