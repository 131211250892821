import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Marquee from "react-double-marquee";
import "./Footer.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import config from "../../config";

const Footer = (props) => {
  const { footerProduct, userInfo, footerCategory, actions, pageList,footerSupplier } = props;
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();
	
	// Convert the array to a string
	const arrayCategory = JSON.stringify(footerCategory);

	// Save the string to localStorage under a specific key
	localStorage.setItem('localStorageFooterCategory', arrayCategory);
	
 	// console.log("-------------------Footer props :  strat cat----------------"); 
	// console.log(props);
	// console.log("-------------------Footer props :  end cat----------------");

  const handleClickLogin = (action) => {
    actions.userLoginDataSuccess(action);
  };

  const handleClickSeller = () => {
    window.open(config.SELLER_URL, "_blank");
  };
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("forMobile")) {
      setShowHeader(false);
    }
  }, [location]);

  const handleClick = (type) => {
    if (type === "APP_STORE") {
      window.open(
        "https://apps.apple.com/in/app/designiche/id1603056348",
        "_blank"
      );
    }
    if (type === "GOOGLE_STORE") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.octal.designiche",
        "_blank"
      );
    }
    return false;
  };

  return (
    <>
      {showHeader && (
        <footer>
          <Container className="py-3">
            <Row>
              <Col sm={6} md={6} lg={4}>
                <h5>Service</h5>
                <ul>
                  {pageList.includes(1) && (
                    <li>
                      <NavLink
                        to="/about-us"
                        title="About us"
                        exact
                        activeClassName="active"
                      >
                        About us
                      </NavLink>
                    </li>
                  )}

                  <li>
                    <NavLink
                      to="/contact-us"
                      title="Contact us"
                      exact
                      activeClassName="active"
                    >
                      Contact us
                    </NavLink>
                  </li>

                  {pageList.includes(5) && (
                    <li>
                      <NavLink
                        to="/refunds-policy"
                        title="Refunds and cancellation policy"
                        exact
                        activeClassName="active"
                      >
                        Refunds and cancellation policy
                      </NavLink>
                    </li>
                  )}
                  {pageList.includes(4) && (
                    <li>
                      <NavLink
                        to="/delivery-policy"
                        title="Shipping policy"
                        exact
                        activeClassName="active"
                      >
                        Shipping policy
                      </NavLink>
                    </li>
                  )}
                  {pageList.includes(2) && (
                    <li>
                      <NavLink
                        to="/privacy-policies"
                        title="Privacy Policies"
                        exact
                        activeClassName="active"
                      >
                        Privacy policy
                      </NavLink>
                    </li>
                  )}
                  {pageList.includes(6) && (
                    <li>
                      <NavLink
                        to="/terms-of-us"
                        title="Terms and conditions"
                        exact
                        activeClassName="active"
                      >
                        Terms and conditions
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      to="/faq"
                      title="FAQ"
                      exact
                      activeClassName="active"
                    >
                      FAQ
                    </NavLink>
                  </li>
                  {pageList.includes(7) && (
                    <li>
                      <NavLink
                        to="/help"
                        title="Help"
                        exact
                        activeClassName="active"
                      >
                        Help
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      to="#"
                      title="Blog"
                      exact
                      activeClassName="active"
                      onClick={() =>
                        window.open(
                          "https://www.designiche.com/blog/",
                          "_blank"
                        )
                      }
                    >
                      Blog
                    </NavLink>
                  </li>
                  
				   
				  <li className="d-none">
                      <NavLink
                        to="/design-services"
                        title="Design Services"
                        exact
                        activeClassName="active"
                      >
                        Design Services
                      </NavLink>
                    </li>
					<li>
                    <NavLink
                      to="/packages"
                      title="Packages"
                      exact
                      activeClassName="active"
                      
                    >
                      Packages
                    </NavLink>
                  </li>
				  
				          <li>
                    <NavLink
                      to="/designiche_design_services"
                      title="Designiche Design Services"
                      exact
                      activeClassName="active"
                      
                    >
                      Designiche Design Services
                    </NavLink>
                  </li>
				          <li>
                    <NavLink
                      to="/kids-room-design-services"
                      title="Kids Room Design Services"
                      exact
                      activeClassName="active"
                      
                    >
                      Kids Room Design Services
                    </NavLink>
                  </li>
					
                </ul>
              </Col>
              <Col sm={6} md={6} lg={4}>
                <h5>Account</h5>

                {!userInfo.user_id ? (
                  <ul>
                    <li>
                      <Nav.Item>
                        <Nav.Link className="px-0 pb-1 pt-0"
                          title="Login"
                          onClick={() => handleClickLogin(true)}
                        >
                          Customer Login
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    <li>
                      <Nav.Item>
                        <Nav.Link className="px-0 pb-1 pt-0"
                          title="Login as supplier"
                          onClick={() => handleClickSeller()}
                        >
                          Supplier Login
                        </Nav.Link>
                      </Nav.Item>
                    </li>
					
					
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <NavLink
                        to="/user/profile"
                        title="App"
                        exact
                        activeClassName="active"
                      >
                        Account Overview
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/user/order"
                        title="Order"
                        exact
                        activeClassName="active"
                      >
                        Order
                      </NavLink>
                    </li>
                  </ul>
                )}
				
				 <>
				  
				 <ul>
                     <li>
                      <NavLink
                        to="/anrad-gallery"
                        title="Swades Collective"
                        exact
                        activeClassName="active"
                      >
                        Swades Collective
                      </NavLink>
                    </li>
				</ul>
				</>
              </Col>
              <Col sm={6} md={6} lg={4}>
                <div className="d-none align-items-center mb-4">
                  <div className="d-none align-items-center mb-4">
					<h5>Download App</h5>
                    <NavLink
                      to="#!"
                      title="App"
                      exact
                      activeClassName="active"
                      onClick={() => handleClick("APP_STORE")}
                    >
                      <img src="/assets/img/appstore.jpg" alt="appstore" />
                    </NavLink>
                    <NavLink
                      to="#!"
                      title="App"
                      exact
                      activeClassName="active"
                      className="ml-3"
                      onClick={() => handleClick("GOOGLE_STORE")}
                    >
                      <img src="/assets/img/play_store.jpg" alt="play_store" />
                    </NavLink>
                  </div>
                </div>
                <div className="social">
                  <h5>Keep in Touch</h5>
                  <NavLink
                    to="#"
                    title="Designiche on Facebook"
                    exact
                    activeClassName="active"
                    onClick={() =>
                      window.open("https://www.facebook.com/DesignicheIndia/", "_blank")
                    }
                  >
                    <img src="/assets/img/facebook.svg" alt="facebook" />
                  </NavLink>
                  {/* <NavLink to="#" title="App" exact activeClassName="active">
                  <img src="/assets/img/twitter.svg" alt="twitter" />
                </NavLink>
                <NavLink to="#" title="App" exact activeClassName="active">
                  <img src="/assets/img/pintrest.svg" alt="pintrest" />
                </NavLink> */}
                  <NavLink
                    to="#"
                    title="Designiche on Youtube"
                    exact
                    activeClassName="active"
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/watch?v=t6MxsTLtIPw",
                        "_blank"
                      )
                    }
                  >
                    <img src="/assets/img/youtube.svg" alt="youtube" />
                  </NavLink>
                  <NavLink
                    to="#"
                    title="Designiche on Instagram"
                    exact
                    activeClassName="active"
                    onClick={() =>
                      window.open("https://www.instagram.com/designiche.ind/?igshid=YmMyMTA2M2Y=", "_blank")
                    }
                  >
                    <img src="/assets/img/instagram.svg" alt="instagram" />
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="copyright text-center">
            <p className="m-0">
              © {new Date().getFullYear()} Designiche.com. All rights reserved.
            </p>
          </div>
          <div
            className="marquee"
            style={{
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
 
          </div>
          {isVisible && (
            <div className="scroll-to-top">
              <span onClick={scrollToTop}>
                <i className="fas fa-chevron-up"></i>
              </span>
            </div>
          )}
        </footer>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    footerProduct: state.footerProduct,
    pageList: state.pageList,
    userInfo: state.userInfo,
    footerCategory: state.footerCategory,
	footerSupplier: state.footerSupplier,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
