import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { Container } from "react-bootstrap";
import Newsletter from "../../component/Newsletter/Newsletter";
import { useParams } from "react-router-dom";
import "./About.css";

const About = (props) => {
  const { actions, homePageData } = props;
  const { slug } = useParams();

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    function fetchData() {
      actions.loadHomePageData(slug);
    }
    fetchData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [actions, slug]);

  useEffect(() => {
    document.title = homePageData.title;
  });

  return (
    <section className="about_sec py-5">
      <Container>
        {/* <h1 className="page_title mb-4">{homePageData.title}</h1> */}

        {/* <Row>
          <Col lg="4">
            <div className="about-up-image text-center">
            <img src="" alt="" className=img-fluid>
              <h2>Preneet Malhotra</h2>
              <h3>Designation</h3>
            </div>
          </Col>
          <Col lg="4">
            <div className="about-up-image text-center">
            <img src="" alt="" className=img-fluid>
              <h2>Bharat Malhotra</h2>
              <h3>Designation</h3>
            </div>
          </Col>
          <Col lg="4">
            <div className="about-up-image text-center">
            <img src="" alt="" className=img-fluid>
              <h2>Lead Design Partner</h2>
              <h3>Designation</h3>
            </div>
          </Col>
        </Row> */}

        <div
          className="page_content"
          dangerouslySetInnerHTML={prepareHtml(homePageData.description)}
        />
      </Container>
      <Newsletter />
    </section>
  );
};

About.propTypes = {
  homePageData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    homePageData: state.homePageData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
