import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function packageRoomClassReducers(state = initialState.packageRoomClassData, action) {
  switch (action.type) {
    case types.LOAD_PACKAGE_ROOM_CLASS_DATA_SUCCESS:
      return action.packageRoomClassData
    default:
      return state
  }
}
