import React, { useState } from "react";
import { Modal, Form, FormControl } from "react-bootstrap";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import CustomButton from "../../component/Button/Button";
import { useForm } from "react-hook-form";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../actions/productActions";

const AddReviewModal = (props) => {
  const { details, actions, deviceToken } = props;
  const { register, errors, handleSubmit } = useForm();

  const [star, setStar] = useState(0);

  const onSubmit = (data) => {
    data.productId = details.id;
    data.rating = star;
    data.deviceToken = deviceToken;
    data.type = "web";
    actions.addProductReview(data);
  };

  const ratingChanged = (newRating) => {
    setStar(newRating);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="login_sign_popup_sec addReviewmodal"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rating and Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <LazyLoad images={details.defultyImage} imageClass={"productBlur"} />
        <h5 className="p-0">{details.title}</h5>
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={50}
          activeColor="#ffd700"
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formBasicEmail">
            <FormControl
              as="textarea"
              rows={5}
              className={errors.description ? "formError" : ""}
              name="description"
              placeholder="Write your review"
              aria-invalid={errors.description ? "true" : "false"}
              ref={register({
                required: true,
              })}
            />
            {errors.description && errors.description.type === "required" && (
              <span role="alert">Please enter your message</span>
            )}
          </Form.Group>
          <CustomButton title={"Submit"} />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(productActions), dispatch),
  };
}

function mapStateToProps(state) {
  return {
    deviceToken: state.deviceToken,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReviewModal);
