import React from "react";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import NumberFormat from "react-number-format";

const DetailsModal = (props) => {
  const { details } = props;

  var RecentlyProductSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1439, settings: { slidesToShow: 1 } },
      { breakpoint: 1365, settings: { slidesToShow: 1 } },
      { breakpoint: 1199, settings: { slidesToShow: 1 } },
      { breakpoint: 991, settings: { slidesToShow: 1 } },
      { breakpoint: 767, settings: { slidesToShow: 1 } },
      { breakpoint: 575, settings: { slidesToShow: 1 } },
      { breakpoint: 479, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      className="login_sign_popup_sec"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Order Details ({details.booking_id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <div className="order_box d-flex justify-content-between px-0 py-4">
          <div className="product_info product_address">
            <strong className="d-block pb-2 font_bold">Shipping Address</strong>
            {/* <p className="m-0 address">
              <b>{details.shipping.name}</b>
            </p> */}
            <p className="m-0 address">
              {details.shipping.address},{details.shipping.landmark},
              {details.shipping.city},{details.shipping.state},
              {details.shipping.pincode}
            </p>
            <p className="m-0 address">
              mobile number - {details.shipping.number}
            </p>
          </div>
          <div className="product_info product_address">
            <strong className="d-block pb-2 font_bold">Billing Address</strong>
            {/* <p className="m-0 address">
              <b>{details.billing.name}</b>
            </p> */}
            <p className="m-0 address">
              {details.billing.address},{details.billing.landmark},
              {details.billing.city},{details.billing.state},
              {details.billing.pincode}
            </p>
            <p className="m-0 address">
              mobile number - {details.billing.number}
            </p>
          </div>
        </div>
        <div className="order_box d-flex justify-content-between border-bottom   mb-5">
          <div className="product_info product_address">
            <span className="d-block">
              <b>Order Date:&nbsp;</b>
              {details.date}
            </span>
            <span className="d-block">
              <b>Order Status:&nbsp;</b>
              {details.order_status}
            </span>
            <span className="d-block">
              <b>Payment Type:&nbsp;</b> {details.orderType}
            </span>
          </div>
          <div className="product_info product_address">
            {details.delivery_time && (
              <p className="m-0 address">
                <b>Expected Delivery Date</b> - {details.delivery_time}
              </p>
            )}
            {details.delivery_info && (
              <p className="m-0 address">
                <b>Delivery Info</b> - {details.delivery_info}
              </p>
            )}
          </div>
        </div>

        {details.item.map((item, key) => (
          <div
            className="order_box d-flex justify-content-between border p-3 mb-2"
            key={key}
          >
            <div className="product_img">
              <img src={item.image} alt="Designiche" />
            </div>
            <div className="w-100 product_info_sec">
              <div className="product_info">
                <NavLink
                  to={"/product/details/" + item.productId}
                  title={item.product_name}
                >
                  <p className="m-0 product_name pb-1">{item.product_name}</p>
                  <span className="d-block product_color">
                    {item.attribute_name}
                  </span>
                </NavLink>
              </div>
              <div className="product_price">
                <small>Unit Cost </small> <br />
                {details.currency}&nbsp;{item.discounted_amount}
                {item.discount !== 0 && (
                  <del className="pl-2">
                    {details.currency}&nbsp;
                    {item.amount}
                  </del>
                )}
              </div>
              <div className="product_price">
                <small>Quantity</small> <br />
                {item.qty}
              </div>
              <div className="product_price">
                <small>Total</small> <br />
                {details.currency}&nbsp;{item.subTotal}
              </div>
            </div>
          </div>
        ))}

        <Row>
          <Col md={6} lg={6}></Col>
          <Col md={6} lg={6} className="text-right pt-3">
            <p>
              <b>Sub Total: </b>
              {details.currency}&nbsp;{details.amount}
            </p>

            {details.coupon_discount && (
              <>
                <p>
                  <b>Coupon Discount: </b>-{details.currency}&nbsp;
                  {details.coupon_discount}
                </p>
                <p>
                  <b>Sub Total After Discount: </b>
                  {details.currency}&nbsp;{details.discount_amount}
                </p>
              </>
            )}
            <p>
              <b>Shipping & Processing: </b>
              {details.currency}&nbsp;{details.delivery_charges}
            </p>
            <p>
              <b>GST: </b>
              {details.currency}&nbsp;{details.tax}
            </p>
            <h3 className="m-b-0">
              {details.currency}&nbsp;{details.totalAmount}
            </h3>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
