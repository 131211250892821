import React, { useState } from "react";
import { checkConform } from "../../utils/helpers";

const Item = (props) => {
  const [notification] = useState(props.notification);
  const removeCartItem = () => {
    const afterCallback = () => {
      props.removeItem(notification.id);
    };
    checkConform(
      afterCallback,
      "Are you sure, you want to remove this notification?"
    );
  };
  return (
    <li className="notification_li">
      <div className="d-flex notification_flex">
        <p className="mr-2">
          {notification.message}
          <small className="d-block"> {notification.date}</small>
        </p>

        <div className="link helful_count ml-auto" onClick={removeCartItem}>
          <i className="fas fa-trash-alt"></i>
        </div>
      </div>
    </li>
  );
};

export default Item;
