import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function dummyProductReducers(
  state = initialState.dummyProduct,
  action
) {
  switch (action.type) {
    case types.LOAD_DUMMY_PRODUCT_DATA:
      return action.dummyProduct;
    default:
      return state;
  }
}
