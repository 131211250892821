import React from "react";
import { geolocated } from "react-geolocated";
import Geocode from "react-geocode";
import config from "../../config";

Geocode.setApiKey(config.Geocode_KEY);
Geocode.setLanguage(config.LANGUAGE);
Geocode.setRegion(config.REGION);

const Location = (props) => {
  const { isGeolocationAvailable, coords, onClick } = props;
  const getLocation = () => {
    if (isGeolocationAvailable && coords) {
      Geocode.fromLatLng(coords.latitude, coords.longitude).then(
        (response) => {
          const address = response.results[0].address_components;
          var i;
          for (i = 0; i < address.length; i++) {
            if (address[i].types[0] === "postal_code") {
              onClick(address[i].long_name);
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  return <>{getLocation()}</>;
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Location);
