import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "../../component/Button/Button";
import { Row, Col, Form } from "react-bootstrap";
import * as orderActions from "../../actions/orderActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const CancelModal = (props) => {
  const { details, actions } = props;
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data) => {
    data.id = details.id;
    actions.cancelOrder(data);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      className="login_sign_popup_sec"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Order Details ({details.booking_id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <div className="order_box d-flex justify-content-between px-0 py-4">
          <div className="product_info product_address">
            <strong className="d-block pb-2 font_bold">Shipping Address</strong>
            <p className="m-0 address">
              <b>{details.shipping.name}</b>
            </p>
            <p className="m-0 address">
              {details.shipping.address},{details.shipping.landmark},
              {details.shipping.city},{details.shipping.state},
              {details.shipping.pincode}
            </p>
            <p className="m-0 address">
              mobile number - {details.shipping.number}
            </p>
          </div>
          <div className="product_info product_address">
            <strong className="d-block pb-2 font_bold">Billing Address</strong>
            <p className="m-0 address">
              <b>{details.billing.name}</b>
            </p>
            <p className="m-0 address">
              {details.billing.address},{details.billing.landmark},
              {details.billing.city},{details.billing.state},
              {details.billing.pincode}
            </p>
            <p className="m-0 address">
              mobile number - {details.billing.number}
            </p>
          </div>
        </div>
        <div className="order_box d-flex justify-content-between border-bottom   mb-5">
          <div className="">
            <span className="d-block">
              <b>Order Date:&nbsp;</b>
              {details.date}
            </span>
            <span className="d-block">
              <b>Order Status:&nbsp;</b>
              {details.order_status}
            </span>
            <span className="d-block">
              <b>Payment Type:&nbsp;</b> {details.orderType}
            </span>
          </div>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formBasicAddress">
                <Form.Label>Cancel Reason*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className={
                    errors.reason ? "form-control is-invalid" : "form-control"
                  }
                  name="reason"
                  aria-invalid={errors.reason ? "true" : "false"}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.reason && errors.reason.type === "required" && (
                  <label className="invalid-feedback text-left">
                    Please enter your reason
                  </label>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Button
                title={"Submit"}
                className={"btn btn-primary shadow-2 mb-4 w-30"}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(orderActions), dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CancelModal);
