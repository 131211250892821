import React, { useEffect } from "react"
import HomeBanner from "./HomeBanner"
import HomeTabPanel from "./HomeTabPanel"
import TrandingFooter from "./TrandingFooter"
import Newsletter from "../../component/Newsletter/Newsletter"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as bannerActions from "../../actions/bannerActions"
import * as parentCategoryActions from "../../actions/parentCategoryActions"
import * as categoryProductActions from "../../actions/categoryProductActions"
import * as hotProductActions from "../../actions/hotProductActions"
import * as cartActions from "../../actions/cartActions"
import PropTypes from "prop-types"

const Home = (props) => {
  const { title, userInfo, realSpaces, actions, homeDesigen } = props

  const handleClick = (k) => {
    if('d3121f41-09d9-483e-9462-52845a3d3ce2' == k) {
        localStorage.setItem("ActiveCategoryTab", k);
    } else {
       localStorage.removeItem("ActiveCategoryTab");
    }
    var param = {}
    param.categoryId = k
    param.type = "web"
    props.actions.loadcategoryProductData(param)

  }

  const handleClickLogin = (k) => {
    actions.userLoginDataSuccess(true)
  }

  useEffect(() => {
    document.title = title
    const fetchData = () => {
      actions.loadBannerData()
      actions.loadParentCategoryData()
      actions.loadhotProductData()
      actions.loadRealSpaces()
      actions.loadHomeDesign()
    }
    fetchData()
    window.scrollTo(0, 0)
    setTimeout(() => {
      try{
        var el = document.querySelector(".inner_tab_sec .ic-tabs");
        console.log(el)
        el?.insertAdjacentHTML( 'beforeend', '<a class="nav-item nav-link" href="/packages">Room Packages</a>' )
      }catch(e){}
	;}, 1000);	
  
  }, [title, actions])

  useEffect(() => {
    document.title = title
    setTimeout(() => {
      try{
        var el = document.querySelector(".inner_tab_sec .d-tabs");
        console.log(el)
        el?.insertAdjacentHTML( 'beforeend', '<a class="nav-item nav-link designGpt" href="/designgpt">Design GPT <span>AI Powered</span></a> <a class="nav-item nav-link" href="#" onclick=\'document.getElementById("room_planner").click()\'>Room Planner</a> <a class="nav-item nav-link" href="/packages">Room Packages</a>' )
      }catch(e){}
	;});	
  
  }, [title])

  return (
    <>
      <HomeBanner bannerData={props.bannerData} />
      <HomeTabPanel
        parentCategoryData={props.parentCategoryData}
        onClick={handleClick}
        categoryProductData={props.categoryProductData}
        hotProductData={props.hotProductData}
        homeDesigen={homeDesigen}
      />
      <TrandingFooter
        trandingProductData={props.trandingProductData}
        userInfo={userInfo}
        handleClickLogin={handleClickLogin}
        realspaces={realSpaces}
      />
      <Newsletter />
    </>
  )
}
Home.propTypes = {
  bannerData: PropTypes.any.isRequired,
  parentCategoryData: PropTypes.any.isRequired,
  categoryProductData: PropTypes.any.isRequired,
  trandingProductData: PropTypes.any.isRequired,
  hotProductData: PropTypes.any.isRequired,
  realSpaces: PropTypes.any.isRequired,
  homeDesigen: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    bannerData: state.bannerData,
    parentCategoryData: state.parentCategoryData,
    categoryProductData: state.categoryProductData,
    trandingProductData: state.trandingProductData,
    hotProductData: state.hotProductData,
    userInfo: state.userInfo,
    realSpaces: state.realSpaces,
    homeDesigen: state.homeDesigen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        bannerActions,
        parentCategoryActions,
        categoryProductActions,
        hotProductActions,
        cartActions
      ),
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
