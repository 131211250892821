import React, { useState } from "react";

const Item = (props) => {
  const [item] = useState(props.details);

  const handleViewClick = (item) => {
    props.onClick(item);
  };

  const handleCancelClick = () => {
    props.onChange(item);
  };

  const handleDownloadClick = (invoice) => {
    window.open(invoice, "_blank");
  };
  return (
    <div key={item.id}>
      <div
        className="order_box d-flex justify-content-between border p-3 mb-2"
        key={item.id}
      >
        <div className="w-100 product_info_sec">
          <div className="product_info">
            <p className="m-0 product_name pb-1">
              <small className="d-block product-retail">Order Id: </small>
              {item.booking_id}
            </p>
            <span className="d-block product-retail">
              Order Date & Time: {item.date}
            </span>
            <span className="d-block product-retail">
              Transaction Id: {item.transaction_id}
            </span>
            <span className="d-block product-retail">
              Number of items: {item.totalItem}
            </span>
            {/* <div className="dealer_bio d-flex align-items-center mt-2 py-2 border-top">
              <div className="dealer_name">
                <span className="mr-2">
                  <img src={item.seller.logo} alt="seller" />
                </span>
                {item.seller.name}
              </div>
              <div className="seller_rate ml-auto">
                <img className="ml-auto" src={item.seller.star} alt="star" />
              </div>
            </div> */}
          </div>
          <div className="product_price">
            {item.currency}&nbsp;{item.totalAmount}
            <span
              className={
                item.status === 5
                  ? "d-block text-danger mt-4"
                  : "d-block green_text mt-4"
              }
            >
              {item.order_status}
            </span>
          </div>
          <div className="product_record">
            <span
              className="btn btn_record"
              onClick={() => handleViewClick(item)}
            >
              View Details
            </span>

            {(item.status === 1 || item.status === 2) && (
              <div className="product_record pt-2">
                <span
                  className="btn btn_record"
                  onClick={() => handleCancelClick()}
                >
                  Cancel
                </span>
              </div>
            )}
            {item.status === 4 && (
              <div className="product_record pt-2">
                <span
                  className="btn btn_record"
                  onClick={() => handleDownloadClick(item.invoice)}
                >
                  Download Invoice
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {item.status !== 5 && (
        <ul className="d-flex order_status  border-bottom panding_gray">
          <li className="clear_steps">
            <span className="d-block text_green">Ordered</span>
            <span className="d-block">{item.date}</span>
          </li>
          {item.orderLog &&
            item.orderLog.length > 0 &&
            item.orderLog.map((log, logKey) => (
              <li
                key={logKey}
                className={log.date ? "d-block clear_steps" : "d-block"}
              >
                <span className={log.date ? "d-block up_label" : "d-block"}>
                  {log.status}
                </span>
                <span className="d-block">{log.date}</span>
              </li>
            ))}
        </ul>
      )}
      {item.status === 5 && (
        <ul className="d-flex order_status  border-bottom">
          <li className="clear_steps">
            <span className="d-block text_green">Ordered</span>
            <span className="d-block">{item.date}</span>
          </li>
          <li className="clear_steps">
            <span className="d-block text-danger">Cancelled</span>
            <span className="d-block">{item.updateDate}</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Item;
