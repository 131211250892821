import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function productReducers(state = initialState.productData, action) {
  switch (action.type) {
    case types.LOAD_PRODUCT_DATA_SUCCESS:
      return action.productData
    default:
      return state
  }
}
