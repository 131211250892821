import * as types from "../actions/actionsTypes"
import initialState from "./initialState"

export default function cancleModalReducer(
  state = initialState.cancleModal,
  action
) {
  switch (action.type) {
    case types.LOADED_ORDER_CANCLE_MODAL:
      return action.cancleModal
    default:
      return state
  }
}
