import React from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import List from "../../page/marketplace/List";

const RecentlyViewed = (props) => {
  const { recentlyView, wishCount } = props;

  var RecentlyProductSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1439, settings: { slidesToShow: 4 } },
      { breakpoint: 1365, settings: { slidesToShow: 4 } },
      { breakpoint: 1199, settings: { slidesToShow: 3 } },
      { breakpoint: 991, settings: { slidesToShow: 2 } },
      { breakpoint: 767, settings: { slidesToShow: 2 } },
      { breakpoint: 575, settings: { slidesToShow: 1 } },
      { breakpoint: 479, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      {recentlyView && recentlyView.length > 0 && (
        <>
          <div className="border-top-0 tab_heading d-flex align-items-center pt-3">
            <h2>Recently Viewed</h2>
          </div>
          {recentlyView.length > 4 ? (
            <Slider {...RecentlyProductSlider} className="testimonials_slider">
              {recentlyView.map((product, key) => (
                <div key={key}>
                  <List product={product} wishCount={wishCount} />
                </div>
              ))}
            </Slider>
          ) : (
            <Row>
              {recentlyView.map((product, key) => (
                <Col xs="12" md="4" lg="3" key={key}>
                  <List product={product} wishCount={wishCount} />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default RecentlyViewed;
