import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import curve from './images/curve.png';
import userprofile from './images/user-profile.jpg';
import gallery1 from './images/gallery-1.jpg';
import gallery2 from './images/gallery-2.jpg';
import gallery3 from './images/gallery-3.jpg';
import gallery4 from './images/gallery-4.jpg';
import gallery5 from './images/gallery-5.jpg';
import gallery6 from './images/gallery-6.jpg';

import "./kitchen-packages.css";


import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
//import "swiper/css/pagination";
import { Pagination } from "swiper";

const KitchenPackages = (props) => {
  const { actions, homePageData, isSubmitting } = props;
  const { slug } = useParams();

  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();
  
  const onSubmit = (data) => {
    console.log(JSON.stringify(data),'Prem');
	data.request_for = 'Kitchen Packages';
    //return;
    actions.sendSpecialRequest(data);
  };

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" }
  };

  useEffect(() => {
    function fetchData() {
      actions.loadHomePageData(slug);
    }
    fetchData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [actions, slug]);


  return (
    <div>
      <section className="about-supplier">
        <div className="container">
          <h3 className="common-heading text-uppercase">Qualita</h3>
          <p>Qualita has a full spectrum of product portfolio in modular Kitchens, Wardrobes, Bath Vanities, Built-in Kitchen Appliances from Whirlpool, Siemens, Bosch and other 12 Brands from Europe, and small Appliances like Kitchenaid. The price points starts from the economy range with Indian laminates and goes to the top of the line Luxury offerings that the best Global OEMs can offer anywhere across the world. Hence if a client is doing interiors for their own living or for giving on rent, we are a perfect solution provider for their all requirements, while keeping the key working philosophy intact. We have centralized design at our head office at Gurgaon. Customized Kitchens, wardrobes and Bath vanities are delivered and installed by our team of installers across India. We have two company owned experience centers at Gurgaon, which allow the clients to get a perfect feel of the space dynamics, materials library and our vast experience before the specifications are frozen. A boxed, pre-determined sized Bath vanity range is available across India soon.</p>
          <p className="mb-0">After a long research and product development cycle, we recently launched a complete range of Bath vanities in 3 price ranges and specifications including completely waterproof materials to luxury ranges. These are over the counter products, in predetermined sizes suitable for Indian Bathrooms. These “vanity in a box" vanities are available to Dealers and consumers all across India.</p>
        </div>
      </section>
      <img src={curve} alt="curve" className="img-fluid w-100" />


    
      <section className="sample-work-section bg-white pt-0 mt-0">
          <div className="container">
            {/* <h3 className="common-heading text-uppercase mb-4">Sample Work</h3> */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 mb-3 pr-2">
                        <img src={gallery1} className="img-fluid w-100" alt="" />
                      </div>
                      <div className="col-md-6 mb-3 pr-2">
                        <img src={gallery2} className="img-fluid w-100" alt="" />
                      </div>
                      <div className="col-md-12 mb-3 pr-2">
                        <img src={gallery3} className="img-fluid w-100" alt="" />
                      </div>
                      <div className="col-md-12 mb-3 pr-2">
                        <img src={gallery4} className="img-fluid w-100" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <img src={gallery5} className="img-fluid w-100" alt="" />
                  </div>
                  <div className="col-md-12 mb-3">
                    <img src={gallery6} className="img-fluid w-100" style={{height: '298px', objectFit: 'cover'}} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



      <section className="kitchen-package-section">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-4">Kitchen Packages</h3>
            <div className="row mx-0 align-items-center">
              <div className="col-md-4 px-0 mb-md-0 mb-4">
                <div className="kps--boxes">
                  <h3 className="kps--heading">Pret</h3>
                  <div className="kps--wrap">
                    <h5 className="kps--title">MATERIAL SPECIFICATIONS</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li>
                        <span>Carcasse Material:</span> MR Ply with both sides lamination with ABS Edge banding.
                      </li>
                      <li><span>Carcasse Color Choices:</span> 2</li>
                      <li><span>Sink Carcasse Material:</span> BWP Ply</li>
                      <li><span>Drawer System:</span> 6 Nos.</li>
                      <li><span>Carcasse area considered:</span> 100 Sq.ft Upto.</li>
                    </ul>
                  </div>
                  <div className="kps--wrap">
                    <h5 className="kps--title">HARDWARE / GERMAN MADE / EQUIVALENT</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li><span>Hinges:</span> Soft closing Hettich</li>
                      <li><span>Handle:</span> External mounted handle for Base and Tall unit's shutter.</li>
                      <li><span>Plinth leveller:</span> Yes</li>
                      <li><span>Skirting Pvc Silver / Black:</span> Yes</li>
                      <li><span>Carcasse edge profile for wall units:</span> Yes</li>
                      <li><span>Hdyraulic Lift up dual door opening fititng:</span> No</li>
                    </ul>
                  </div>
                  <div className="kps--wrap">
                    <h5 className="kps--title">ACCESSORIES INCLUDED IN THE PACKAGES.</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li><span>Dishrack:</span> Yes</li>
                      <li><span>Autolid Wastebin:</span> Yes </li>
                      <li><span>Pull out Wastebin:</span> No</li>
                      <li><span>Pvc cutlery:</span> Yes</li>
                      <li><span>Sink; Double bowl with one side drain board, Stainless steel finish:</span> Upto 4 feet length</li>
                      <li><span>Faucet Sink Mounted:</span> Yes, Fixed Spout</li>
                      <li><span>Light undet the wall units:</span> No</li>
                      <li><span>Ply under the worktop:</span> Yes</li>
                      <li><span>Detergent holder:</span> No</li>
                      <li><span>Rolling Shutter Pvc finish:</span> No</li>
                      <li><span>Wicker basket pair:</span> No</li>
                      <li><span>Pantry System:</span> No</li>
                      <li><span>Corner Accessories:</span> No</li>
                    </ul>
                    <h3 class="kps--price">From 5 Lacs - 7.99 Lacs</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-0 mb-md-0 mb-4">
                <div className="kps--boxes white-box">
                  <h3 className="kps--heading">Silver</h3>
                  <div className="kps--wrap">
                    <h5 className="kps--title">MATERIAL SPECIFICATIONS</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li>
                        <span>Carcasse Material:</span> MR Ply with both sides lamination with ABS Edge banding.
                      </li>
                      <li><span>Carcasse Color Choices:</span> 5</li>
                      <li><span>Sink Carcasse Material:</span> Stainless steel finish 304SS Grade</li>
                      <li><span>Drawer System:</span> 8 Nos.</li>
                      <li><span>Carcasse area considered:</span> 130 Sq.ft Upto.</li>
                    </ul>
                  </div>
                  <div className="kps--wrap">
                    <h5 className="kps--title">HARDWARE / GERMAN MADE / EQUIVALENT</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li><span>Hinges:</span> Soft closing Hettich</li>
                      <li><span>Handle:</span> External mounted handle for Base and Tall unit's shutter.</li>
                      <li><span>Plinth leveller:</span> Yes</li>
                      <li><span>Skirting Pvc Silver / Black:</span> Yes</li>
                      <li><span>Carcasse edge profile for wall units:</span> Yes</li>
                      <li><span>Hdyraulic Lift up dual door opening fititng:</span> Upto 2 nos.</li>
                    </ul>
                  </div>
                  <div className="kps--wrap">
                    <h5 className="kps--title">ACCESSORIES INCLUDED IN THE PACKAGES.</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li><span>Dishrack:</span> Yes</li>
                      <li><span>Autolid Wastebin:</span> No </li>
                      <li><span>Pull out Wastebin:</span> Yes</li>
                      <li><span>Pvc cutlery:</span> Yes</li>
                      <li><span>Sink; Double bowl with one side drain board, Stainless steel finish:</span> Upto 5 feet length</li>
                      <li><span>Faucet Sink Mounted:</span> Yes, Extendable option.</li>
                      <li><span>Light undet the wall units:</span> Yes</li>
                      <li><span>Ply under the worktop:</span> Yes</li>
                      <li><span>Detergent holder:</span> Yes</li>
                      <li><span>Rolling Shutter Pvc finish:</span> Yes</li>
                      <li><span>Wicker basket pair:</span> Yes</li>
                      <li><span>Pantry System:</span> Yes; Glass Pantry drawer system</li>
                      <li><span>Corner Accessories:</span> Yes</li>
                    </ul>
                    <h3 class="kps--price">From 8 Lacs - 11 Lacs</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-0 mb-md-0 mb-4">
                <div className="kps--boxes">
                  <h3 className="kps--heading">Gold</h3>
                  <div className="kps--wrap">
                    <h5 className="kps--title">MATERIAL SPECIFICATIONS</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li>
                        <span>Carcasse Material:</span> MR Ply with both sides lamination with ABS Edge banding.
                      </li>
                      <li><span>Carcasse Color Choices:</span> 5</li>
                      <li><span>Sink Carcasse Material:</span> Stainless steel finish 304SS Grade</li>
                      <li><span>Drawer System:</span> 14 Nos.</li>
                      <li><span>Carcasse area considered:</span> 160 Sq.ft Upto.</li>
                    </ul>
                  </div>
                  <div className="kps--wrap">
                    <h5 className="kps--title">HARDWARE / GERMAN MADE / EQUIVALENT</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li><span>Hinges:</span> Soft closing Hettich</li>
                      <li><span>Handle:</span> External mounted handle for Base and Tall unit's shutter.</li>
                      <li><span>Plinth leveller:</span> Yes</li>
                      <li><span>Skirting Pvc Silver / Black:</span> Yes</li>
                      <li><span>Carcasse edge profile for wall units:</span> Yes</li>
                      <li><span>Hdyraulic Lift up dual door opening fititng:</span> Upto 5 nos.</li>
                    </ul>
                  </div>
                  <div className="kps--wrap">
                    <h5 className="kps--title">ACCESSORIES INCLUDED IN THE PACKAGES.</h5>
                    <span className="kps--lines" />
                    <ul className="kps--package">
                      <li><span>Dishrack:</span> Yes</li>
                      <li><span>Autolid Wastebin:</span> No </li>
                      <li><span>Pull out Wastebin:</span> Yes</li>
                      <li><span>Pvc cutlery:</span> No</li>
                      <li><span>Sink; Double bowl with one side drain board, Stainless steel finish:</span> Upto 5 feet length</li>
                      <li><span>Faucet Sink Mounted:</span> Yes, Extendable option.</li>
                      <li><span>Light undet the wall units:</span> Yes</li>
                      <li><span>Ply under the worktop:</span> Yes</li>
                      <li><span>Detergent holder:</span> Yes</li>
                      <li><span>Rolling Shutter Pvc finish:</span> Yes</li>
                      <li><span>Wicker basket pair:</span> Yes</li>
                      <li><span>Pantry System:</span> Yes; High end pantry drawer system</li>
                      <li><span>Corner Accessories:</span> Yes</li>
                    </ul>
                    <h3 class="kps--price">Starting @ 11 Lacs</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonials-section">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-4">Testimonials</h3>
            <Swiper
                spaceBetween={0}
                slidesPerView={3}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                  },
                  "@1.00": {
                    slidesPerView: 2,
                  },
                  "@1.50": {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>All 3 kitchencs across 3 floors of my house were done by this team. Excellent job right from the design to implemention. Vikas and Raj were both helpful, creattive and suggested innovative designs to make the whole cooking experience smooth for anyone in the kitchen. GREATE JOB!!
                      </p>
                      <h5 className="name">Gaurav Sinha</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>The entire benchmark team has done an excellent job! The kitchen is the most beautiful place in the house. It is well designed and super convenient. From designing to installation, the team kept up to all deadlines. Anytime any little issue came up, it was addressed to immediately. The installation team is well trained and professional. They worked swiftly, efficiently and cleaned up after them each time. Thanks to the entire team for a wonderful job!
                      </p>
                      <h5 className="name">Amrita Malhotra</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Vikas is a connoisseur when it comes to design and his biggest strength is execution which he sets the benchmark with. The firm's name Benchmark is apt and has excelled for second time (first Vikas did my house on turnkey basis in 2009). Now again in 2022 it's been a wow experience working yet again. Thanks &amp; Best wishes to Benchmark team.</p>
                      <h5 className="name">Sunit Madan</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>We had to replace our 17 year old kitchen that had damaged. We wanted a traditional spanish mediterranean kitchen.. <br />We went to Benchmark Lifestyle for their reputation for quality and workmanship, despite being 2000KM away... And It turned out to be a great experience. <br />To begin with, they spent a lot of time understanding our requirements and inputs. Then they came up with a complete set of drawing and a 3D model that perfectly depicted the final layout. And they had actual samples, Fittings and Fixtures to show every element in the design, including colors and shades and equipments. Their suggestions in design and functionality were very valuable. Their patience and attitude to go that extra mile during design and selection process was commendable as we were very particular about lot of elements in the design. <br />The best part of our experience was a perfect execution as per the drawings. Their Installation team did a very professional job, They came with all the required tools and Parts. Didn't bother us a wee bit to arrange for anything. The quality of workmanship &amp; finishes were perfect. Our experience with other projects has never been this smooth or professional... At Benchmark, people at all levels of the organisation were highly competent and cultured.
                        <br />
                        I would highly recommend Lifestyle Benchmark for anyone looking for high quality kitchen..
                      </p>
                      <h5 className="name">Dr. Sathya Ranna</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        Getting our kitchen designed with Vikas and his team from Benchmark was a smooth and hassle free experience. All steps towards finishing the kitchen were planned and executed so well that we couldn't wait to use our new kitchen as we saw it coming into shape. <br />
                        Vikas and Benchmark took care of the tiniest details we had requested for, like ensuring our storage space was not compromised and how our appliances were neatly tucked behind the rolling shutter. Even the location of RO and the drainage of excess water was planned. Thank you Vikas and your team at Benchmark including Raj for delivering the kitchen with such finesse.
                      </p>
                      <h5 className="name">Stuti</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        I have worked with them multiple projects and vouch for their quality.
                        <br />
                        Their solutions are innovative and delivery finesse in unmatched. As a designer, I couldn't have asked for better collaboration.
                      </p>
                      <h5 className="name">Ritika Rakhiani</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        One of the best place for people who are looking for the "best". Qualita work is superbly good, work speaks quality. Seamless work which speaks about the passion and eye for detail of man behind all this "Vikas Juneja". <br />
                        Lots of option for kitchen with diff material possibilities, thousands of finishes and very practical designing of kitchen and wardrobes as per usage requirements.
                      </p>
                      <h5 className="name">Vishal Sharma</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        We were in the process of building our new home. Our architect had suggested benchmark lifestyle solutions. We were impressed looking at the studio , their range of kitchen palette is superb!! And meeting Mr. Vikas , knew we were at the right place for our kitchen.
                        <br />
                        The team at benchmark is very professional, courteous and thoughtful. The attention given to our project was fantastic. From initial design drawings, development, detail, functionality and even vastu of our kitchen was a dream come true. They were on top of every single detail. We were accompanied to every cabinet detail ensuring our requirement. In addition, helped us to get the preferred and great rates for kitchen appliances and fixtures. The Skill and advice , candid personal touch and working within our budget makes them the most efficient. Very pleased with our kitchen, looks beautiful and thrilled to start using this space.
                        <br />
                        Thank you so much for many hours you and your team spent discussing our needs , you guys did an outstanding Job !!!!l We highly recommend and endorse Benchmark lifestyle Solutions. Keep doing your incredible work .
                      </p>
                      <h5 className="name">Kanikey bharatwaj</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        We have been recommending our clients to Benchmark for the last 6 years and have found them to be always updated with the latest material, accessories and global design trends for wardrobes, vanities and kitchens. <br />
                        Our clients have given great feedback regarding their service, before time delivery and workmanship. <br />
                        We wish them all the best and look forward to doing many more projects together.
                      </p>
                      <h5 className="name">Alka Sood</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>A big thank you to Vikas and his team at Benchmark. They did a excellent job and on time. Design &amp; execution is par excellence. Design suggested by Vikas for Puja room is exceptional we love it, Its Divine and gives lot of positive energy. Would put in a word for Raj as well, he has the patience to listen to small details as well. <br />
                        Thank you team BENCHMARK for being part of our dream house.</p>
                      <h5 className="name">Sudhir Malik</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>It's now been a month using our new kitchen and we're very happy and satisfied with the way Qualita Kitchens managed our rennovations. They have a good display space and know their stuff well; they were well-organised, met the committed timelines, and were respectful about budgets. We liked the fact that they were very detail oriented with the designing, making 3Ds which helped us to visualise and finalise the layout. Raj and Vikas were always available, responsive and helpful with all our questions and queries, going the extra mile with even stone and tile and recommendations.</p>
                      <h5 className="name">Mansi Kochhar</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>We constructed our Villa in 2018 and our Kitchen and all Vanities were done by Vikas. It's been close to 2 years now and the quality of equipment &amp; products given by Vikas are immaculate. Our friends simply love the design and interiors done by Vikas. The more important thing is that for very small issues Vikas was only a pfone call away and service after sales has been excellent.</p>
                      <h5 className="name">Rashmi</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Thank you Mr. Vikas and team for doing our wardrobes so well and meticulously, excellent craftsmanship demonstrated by the boys doing the fitting to fill all gaps and crevices. And then most importantly no words to express my gratitude for renovating my clinic exactly as I wanted. Love your inputs, designs and recommendations. Receiving lots of compliments for my clinic decor. Best of luck.</p>
                      <h5 className="name">Mandeep Walia</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>It's a hassle free experience if you're getting the work done by Qualita. Their team of experienced professionals has so much to offer from various designs to unmatched support and on time delivery. <br />Highly recommended.</p>
                      <h5 className="name">Namit Kukreja</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>In designing and renovating our apartment at Ambience Island, Gurgaon, Vikas and his team stood out among all our vendors in terms of professionalism, quality, speed, patience with our changes and range of designs and material. Am already recommending them to other friends with my eyes closed. Really fantastic!</p>
                      <h5 className="name">Anurag Prashar</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Very professional in their work. Spend time and effort on working on the design. Stand by their commitments. <br />
                        It has been a pleasure to work them on our projects for over a decade now.</p>
                      <h5 className="name">Sujata Ranganathan</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Nicely presented end products of high quality designer kitchen &amp; wardrobes with latest accessories &amp; mind blowing finishes.</p>
                      <h5 className="name">Anand Bansal</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Excellent work done by Vikas and his team. It was a hassle free experience to get our wardrobes and vanity's done from Qualita. Highly recommended for their quality and timely delivery!</p>
                      <h5 className="name">Megha Madan</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        A Fabulous Job done !!! I am truly impressed by the work done by Benchmark lifestyle / Qualita. Just sharing my experience...... <br />
                        They had installed our kitchen 11 years ago and the quality till date is worth appreciating. Even today, it looks as good as new and is equally good functionally. (Pictures attached)<br />
                        We express our gratitude to Mr. Vikas Juneja and strongly recommend Qualita/ Benchmark Lifestyle for their genuineness and commitment for quality product and timely delivery.<br />
                        Even today we are experiencing the same commitment from them in the new assignment, which they are executing. Now apart from kitchen, they are doing wardrobes, furniture along with Beds.<br />
                        Professionalism , expertise, detailing and ethics are clearly visible in their work.<br />
                        If you are looking for quality products, I highly recommend this company.
                      </p>
                      <h5 className="name">Sandeep Kumar</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Vikas is a connoisseur when it comes to design and his biggest strength is execution which he sets the benchmark with. The firm's name Benchmark is apt and has excelled for second time (first Vikas did my house on turnkey basis in 2009). Now again in 2022 it's been a wow experience working yet again. Thanks &amp; Best wishes to Benchmark team.</p>
                      <h5 className="name">Sunit Madan</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        We purchased Weber gas frill from Mr. Vikas. <br />
                        Everything was well explained and it was delivered and installed with ease and as per commitment. Thanks Mr. Vikas...
                      </p>
                      <h5 className="name">Surinder Singh</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Can I give 6 stars? <br />
                        I wanted to send a weber grill to my brother in India (11m in the US) as a gift and was reluctant dealing with folks in India who would take your money and then give you 'reasons' to why things haven't been delivered as promised. That's when I found Vikas (on google). I shared my fear with him and he, very professionally, put my fears at calm. It's been over a month since my brother got his Weber... and this is what I messaged Vikas - <br />
                        "Hi Vikas... Just wanted to follow up on this order. You've made my brother a very happy man. Hels smoking stuff since the day he got the weber. THANK YOU for getting smiles to my family! Much appreciated!" <br />
                        No, the grill wasn't lit inside the house... it was assembled in the house and was lit outside!</p>
                      <h5 className="name">Rohit Vachher</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        This is a small story of our new House "Chandrakanta graham" at Godrej Icon Sec 88A Gurgaon. | had gone to meet Vikas along with my wife Amrita as we were exploring the various options, when we wanted to get the Kitchen Work Done. However, when we left his place we knew that our dream home is in safe hands with nothing less than the best. <br />
                        After completion when our family visited the house each and every single person was amazed to see the Kitchen &amp; was in praises for the work done. More than this when we see it as to what we have achieved, Marvel will be an understatement for the work done by Mr Juneja &amp; his team. Like an friend he helped me plan each and every single step, briefed us &amp; his team ensured that everything was done with precision.
                        <br />
                        Your work speaks music a master Sculptor
                        <br />
                        Thank you Vikas for the amazing work and just cant thank you enough for what you have done for us.
                      </p>
                      <h5 className="name">Manish Gambhir</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>I reached out to Vikas Juneja from Benchmark Lifestyle Solutions to help me purchase a new oven right in the middle of the hectic festival frenzy. I am a home baker and so it is very important for me to have a user-friendly oven, one which can churn out large numbers in a single bake cycle. It took only a single call and a couple of WhatsApp chats with Vikas to finalise the oven and its modular cabinet. From assembling of the cabinet to the installation of the oven, his team worked even on their off day and left no stones unturned to deliver the product way before the expected date of delivery, displaying extreme professionalism, especially given the challenges posed by the festive season and the pandemic. <br />
                        Needless to say, that the on time delivery and installation of the product improved my efficiency and helped me take on more orders for the festive season! <br />
                        Thank you Vikas for the professionalism and commitment with which the team and you made this festive-baking la piece of cake' for me! Wish you and the team the very best always, I have already referred Benchmark Lifestyle to friends and family and will continue doing so!</p>
                      <h5 className="name">Shikha Chugh</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        I was introduced to Benchmark by my Architect, Sujata Ranganathan. An architect par excellence. A meticlous person will only recommend another superb executor of her dreams! <br />
                        In Vikas Juneja &amp; Raj Sharma, my wife Nalini Kamal &amp; me, found two wonderful dedicated professionals. Who took pride in their work to deliver with patience our exacting standards. Mind you, myself being a product of institute's of excellence from my School to Engg.to Mgt. Education, have a penchant for excellence &amp; highest standards of Quality. <br />
                        "QUALITA" Team at Benchmark delivered on that. More than anything they were very wiling listners &amp; executed to the IT', what was discussed &amp; committed <br />
                        I would recommend that Vikas,Raj &amp; ur team to continue to maintain ur high standards. Never compromise on Quality at any cost, whatsoever.
                      </p>
                      <h5 className="name" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        We wanted to renovate kitchen but had just 20 days to complete. Vikas @Benchmark guided us and completed the kitchen including all appliances within 20 days. Now the kitchen is the most important place in the entire house.
                        <br />
                        Thank you Vikas
                      </p>
                      <h5 className="name">Sandeep Chaudhry</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        Benchmark lifestyle solution have great designs and product quality for kitchen as we requests and provide great service.
                      </p>
                      <h5 className="name">Aditi Malik</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Truly stylish truly emmaculate shows pure indulgence of Vikas and his wonderful team ,An experience zone worth experiencing and taking a walk , I wish I had known them few years before while doing up our gurgaon residence,may be it's a true wish , for our next holiday home somewhere on foothills of Aravalils ! Keep up the great work that Germans would admire to appreciate too !</p>
                      <h5 className="name">Deepaknker Parashar</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Benchmark Lifestyle is all about Form with Function. I strongly recommend working with them for their professionalism, quality of work and client centric approach. It's a team that works ‘with’ and ‘for’ the client.</p>
                      <h5 className="name">Nischae Suri</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>A big thank you to the entire Benchmark team for making our house so beautiful. The professionalism with which the team carried out the project is worth a mention. Timely delivery and great quality work.
                        Thank you !</p>
                      <h5 className="name">Sonia Mishra</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>I had my kitchen done from here and am very happy...kitchens are more than ever before going to be our lifelines as eating in is the new normal..this is the place to go for your new kitchen...all the best.</p>
                      <h5 className="name">Rekha</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>Quality and time commitment is very good. Excellent after sales/service which is very important in any product/services.</p>
                      <h5 className="name">Rohini Venkatesh</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="user-profile">
                      <img src={userprofile}  className="img-fluid" />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        An extremely beautiful kitchen made by team Qualita. Satisfaction is the ultimate peace when you are spending money and it was ensured by Mr. Vikas and his team. Last but not the least... positivity and professionalism by the team is worth appreciating as they focus on building relations along with work.
                      </p>
                      <h5 className="name">Anubhav Malik</h5>
                    </div>
                  </div>
                </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section className="how-it-works mb-4">
          <div className="container">
            <div className="row mx-0">
              <div className="col-12 bg-light px-4 py-5">
                <div className="row px-2">
                  <div className="col-md-6">
                    <h3 className="common-heading text-uppercase-2">How it works:</h3>
                    <p>Once you make your selection above, our qualified partners are notified of your choices and start working on the drawings. Once done, you will be notified via the platform and will be able to communicate with our partner to suggest any changes on what has been submitted.</p>
                    <strong>The process usually takes 5 business days.</strong>
                    <h5>Happy Designing!</h5>
                  </div>
                  <div className="col-md-6">
                    <h3 className="common-heading text-uppercase-2">Any Special requests</h3>
                    <Form className=""
                  onSubmit={handleSubmit(onSubmit)} >
                      <div className="form-group">
                        {/*<textarea className="form-control" placeholder="Any special requests" required defaultValue={""} />*/}
                        <div className="form-group">
                        <Form.Group controlId="formBasicFirstName">
                          <FormControl
                            type="text"
                            placeholder="Name"
                            className={errors.name ? "formError" : ""}
                            name="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 3,
                              maxLength: 20,
                            })}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span role="alert">Please enter your name.</span>
                          )}
                          {errors.name && errors.name.type === "minLength" && (
                            <span role="alert">
                              Your name should contain atleast 3 characters.
                            </span>
                          )}
                          {errors.name && errors.name.type === "maxLength" && (
                            <span role="alert">
                              Your name should should not 20 characters.
                            </span>
                          )}
                        </Form.Group>
                        </div>
                        <div className="form-group">
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="email"
                            placeholder="Email"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <span role="alert">
                              Please enter your email address.
                            </span>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <span role="alert">Invalid email address.</span>
                          )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should not exceed 50 characters.
                              </span>
                            )}
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group controlId="formBasicNumber">
                          <FormControl
                            type="tel"
                            placeholder="Phone Number"
                            name="number"
                            className={errors.number ? "formError" : ""}
                            aria-invalid={errors.number ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                              },
                            })}
                          />
                          {errors.number &&
                            errors.number.type === "required" && (
                              <span role="alert">
                                Please enter your mobile number.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "maxLength" && (
                              <span role="alert">
                                Your mobile number should not exceed 10 digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "minLength" && (
                              <span role="alert">
                                Your mobile number should contain atleast 10
                                digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "pattern" && (
                              <span role="alert">
                                Please enter correct mobile number. It should be
                                entered without country code.( Example
                                xxxxxxxxxx)
                              </span>
                            )}
                        </Form.Group>
                      </div>
                        <FormControl
                          as="textarea"
                          placeholder="Special Instructions"
                          className={errors.message ? "formError" : ""}
                          style={{ height: '100px' }}
                          name="message"
                          aria-invalid={errors.message ? "true" : "false"}
                          ref={register({
                            required: true,
                            
                            minLength: 5,
                          })}
                  />
                  {errors.message && errors.message.type === "required" && (
                    <span role="alert">Please enter your special requests.</span>
                  )}
                  {errors.message && errors.message.type === "minLength" && (
                    <span role="alert">
                      Your special request should minimum 5 characters.
                    </span>
                  )}
                      </div>
                      {/*<button className="btn custom-btn" >Submit</button>*/}
                      <Button
                    variant=""
                    className="btn custom-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        animation="grow"
                        aria-hidden="true"
                      />
                    ) : (
                      <>Submit</>
                    )}
                  </Button>
                      </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  </div>
  );
};

{/*Packages.propTypes = {
  homePageData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};*/}

function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KitchenPackages);
