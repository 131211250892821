import {
  Order,
  WhishList,
  Profile,
  ChangePassword,
  Payment,
  Address,
  Review,
  Notification,
} from "./Component";
const userRoutes = [
  {
    path: "/user/order",
    title: "My order",
    component: Order,
  },
  {
    path: "/user/wishlist",
    title: "My wishlist",
    component: WhishList,
  },
  {
    path: "/user/profile",
    title: "My Profile",
    component: Profile,
  },
  {
    path: "/user/changePassword",
    title: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/payment/successful/:id",
    title: "Payment Successfully",
    component: Payment,
  },
  {
    path: "/user/address",
    title: "My Address",
    component: Address,
  },
  {
    path: "/user/review",
    title: "My Review",
    component: Review,
  },
  {
    path: "/user/notification",
    title: "Notifications",
    component: Notification,
  },
];

export default userRoutes;
