import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as wishlistActions from "../../actions/wishlistActions";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import NotFound from "../../component/NotFound/NotFound";
import List from "./List";

const WhishList = (props) => {
  const { title, actions, wishlistItem, wishCount, isWishListData } = props;

  const [BrdName] = useState([{ title: "My Wishlist", url: "/user/wishlist" }]);
  const notFound = {
    title: "Your wishlist is empty!",
    description: "Add items that you like to your wishlist!",
    image: "/assets/img/blank-wishlist.svg",
    url: "/marketplace",
    buttion: "START SHOPPING",
  };

  useEffect(() => {
    document.title = title;
    function fetchData() {
      actions.myWishList();
    }
    fetchData();
    window.scrollTo(0, 0);
  }, [title, actions]);

  return (
    <>
      <Breadcrumbs name={BrdName} />

      <Container>
        <div className="title my_account_wrapper_title py-3">{title}</div>
        <Row>
          <Col md={4} lg={3}>
            <Sidebar />
          </Col>
          {isWishListData && (
            <Col md={8} lg={9}>
              {wishlistItem.item && wishlistItem.item.length > 0 ? (
                <Row className="order_main p-4">
                  {wishlistItem.item.map((product, key) => (
                    <Col xs="12" md="4" lg="4" key={key}>
                      <List product={product} wishCount={wishCount} />
                    </Col>
                  ))}
                </Row>
              ) : (
                <NotFound {...notFound} />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

WhishList.propTypes = {
  wishlistItem: PropTypes.any.isRequired,
  wishCount: PropTypes.any.isRequired,
  isWishListData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    wishlistItem: state.wishlistItem,
    wishCount: state.wishCount,
    isWishListData: state.isWishListData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(wishlistActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhishList);
