import React, { useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import { checkConform } from "../../utils/helpers";

const Item = (props) => {
  const [review] = useState(props.review);
  const removeCartItem = () => {
    const afterCallback = () => {
      props.removeItem(review.id);
    };
    checkConform(afterCallback, "Are you sure you want to remove this review?");
  };
  return (
    <li>
      <div className="uploaded_img mb-2">
        {review.image.map((image, key) => (
          <span key={key}>
            <img src={image.image} alt="upload" />
          </span>
        ))}
      </div>
      <div className="star mb-2 d-inline-flex align-items-center">
        <StarRatingComponent
          name="rate"
          starCount={5}
          value={review.rating}
          renderStarIcon={() => <i className="fas fa-star"></i>}
        />
        <span className="reviewed_text ml-1">Reviewed on {review.date}</span>
      </div>
      <div className="d-flex">
        <p className="mr-2">{review.description}</p>
        <div className="link helful_count ml-auto">
          <i className="fas fa-thumbs-up mr-2"></i>{" "}
          {review.like_count === null ? 0 : review.like_count}
        </div>
        <div className="link helful_count ml-auto" onClick={removeCartItem}>
          <i className="fas fa-trash-alt"></i>
        </div>
      </div>
    </li>
  );
};

export default Item;
