import React from "react";
import { Row, Col, Form, Modal, FormControl } from "react-bootstrap";
import config from "../../config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";

import SocialLogin from "./SocialLogin";

const SignUpModal = (props) => {
  const { actions, userRegister, deviceToken } = props;
  const isToken = deviceToken;
  const { register, errors, handleSubmit } = useForm();
  const userGuestRequestFromCartPage  = localStorage.getItem("userGuestRequestFromCartPage");
  const onSubmit = (data) => {
    let params = data;
    params.device_type = "web";
    params.device_token = isToken;
    actions.userRegisterData(params);
  };
  const handleclickLogin = (action) => {
    actions.userRegisterDataSuccess(false);
    actions.userLoginDataSuccess(action);
  };

  const handleclickRegister = (action) => {
    actions.userRegisterDataSuccess(action);
  };
  
  const handleclickGuest = (action) => {
    actions.userGuestDataSuccess(action);
    actions.userLoginDataSuccess(false);
  };
  
  const handleclickSeller = () => {
    window.open(config.SELLER_URL, "_blank");
  };
  return (
    <>
      <Modal
        show={userRegister}
        onHide={() => handleclickRegister(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec register-popup"
        centered
      >
        <Modal.Header className="p-0 mb-1 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Register</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="no-gutters">
              <Col xs={12} md={6}>
                <Form.Group controlId="formBasicFirstName">
                  <FormControl
                    type="text"
                    placeholder="First Name"
                    className={errors.first_name ? "formError" : ""}
                    name="first_name"
                    aria-invalid={errors.first_name ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  {errors.first_name &&
                    errors.first_name.type === "required" && (
                      <span role="alert">Please enter your first name.</span>
                    )}
                  {errors.first_name &&
                    errors.first_name.type === "minLength" && (
                      <span role="alert">
                        Your first name should contain atleast 3 characters.
                      </span>
                    )}
                  {errors.first_name &&
                    errors.first_name.type === "maxLength" && (
                      <span role="alert">
                        Your first name should not exceed 20 characters.
                      </span>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formBasicLastName">
                  <FormControl
                    type="text"
                    placeholder="Last Name"
                    className={errors.last_name ? "formError" : ""}
                    name="last_name"
                    aria-invalid={errors.last_name ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  {errors.last_name && errors.last_name.type === "required" && (
                    <span role="alert">Please enter your last name.</span>
                  )}
                  {errors.last_name &&
                    errors.last_name.type === "minLength" && (
                      <span role="alert">
                        Your last name should contain atleast 3 characters.
                      </span>
                    )}
                  {errors.last_name &&
                    errors.last_name.type === "maxLength" && (
                      <span role="alert">
                        Your last name should not exceed 20 characters.
                      </span>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                type="email"
                placeholder="Email Address"
                className={errors.email ? "formError" : ""}
                name="email"
                aria-invalid={errors.email ? "true" : "false"}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                  maxLength: 50,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span role="alert">Please enter your email address.</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span role="alert">Invalid email address.</span>
              )}
              {errors.email && errors.email.type === "maxLength" && (
                <span role="alert">
                  Your email should not exceed 50 characters.
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicNumber">
              <FormControl
                type="tel"
                placeholder="Mobile Number"
                name="number"
                className={errors.number ? "formError" : ""}
                aria-invalid={errors.number ? "true" : "false"}
                ref={register({
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                  },
                })}
              />
              {errors.number && errors.number.type === "required" && (
                <span role="alert">Please enter your mobile number.</span>
              )}
              {errors.number && errors.number.type === "maxLength" && (
                <span role="alert">
                  Your mobile number should be 10 digits long only.
                </span>
              )}
              {errors.number && errors.number.type === "minLength" && (
                <span role="alert">
                  Your mobile number should contain atleast 10 digits long.
                </span>
              )}
              {errors.number && errors.number.type === "pattern" && (
                <span role="alert">
                  Please enter correct mobile number. It should be entered
                  without country code.( Example xxxxxxxxxx)
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <FormControl
                type="password"
                className={errors.password ? "formError" : ""}
                placeholder="Password"
                name="password"
                aria-invalid={errors.password ? "true" : "false"}
                ref={register({
                  required: true,
                  minLength: 6,
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                  },
                })}
              />
              {errors.password && errors.password.type === "required" && (
                <span role="alert">Please enter your password</span>
              )}
              {errors.password && errors.password.type === "minLength" && (
                <span role="alert">
                  Your password should contain atleast 6 characters
                </span>
              )}
              {errors.password && errors.password.type === "pattern" && (
                <span role="alert">
                  Your password should contain at-least 1 Uppercase, 1
                  Lowercase, 1 Numeric and 1 special character
                </span>
              )}
            </Form.Group>
            <Form.Group
              controlId="formBasicCheckboxRegister"
              className="remember pt-2"
            >
              <div>
                <Form.Check
                  type="checkbox"
                  label="I Accept Terms of Use and Privacy Policy."
                  className={errors.terms_use ? "formError" : ""}
                  name="terms_use"
                  aria-invalid={errors.terms_use ? "true" : "false"}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.terms_use && errors.terms_use.type === "required" && (
                  <span role="alert">
                    Please Accept Terms of Use and Privacy Policy.
                  </span>
                )}
              </div>
            </Form.Group>
            <CustomButton title={"Sign Up"} />
            <div className="orLogin mb-3 mt-3">
              <span>Or Login with</span>
            </div>
			
			{userGuestRequestFromCartPage =='true' ? ( <button type="button" class="btn btn-block mb-2" onClick={() => handleclickGuest(true)}>Continue as Guest</button> ) : ( <span></span> )} 
			
            <div className="social_login">
              <SocialLogin isToken={isToken} />
            </div>
            <div className="no_accout text-center">
              <p className="mb-0">
                Already have an account?{" "}
                <span className="link" onClick={() => handleclickLogin(true)}>
                  Login
                </span>
              </p>
              <span className="link" onClick={() => handleclickSeller()}>
                Join as Seller
              </span>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userRegister: state.userRegister,
    deviceToken: state.deviceToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
