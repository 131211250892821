import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as productActions from "../../actions/productActions";
import * as brandActions from "../../actions/brandActions"
import InnerBanner from "../../component/innerbanner/InnerBanner";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import ListSidebar from "../../component/listsidebar/ListSidebar";
import { Container, Row, Col, Form , Tabs, Tab,Nav } from "react-bootstrap";
import { useLocation, useHistory,NavLink } from "react-router-dom";
import List from "./List";
import "./Product.css";
import Newsletter from "../../component/Newsletter/Newsletter";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import ReactPaginate from "react-paginate";
import "./TabPanel.css";
 

const Product = (props) => {
  const [BrdName] = useState([{ title: "Listing", url: "/marketplace" }]);
  const { title, actions, wishCount } = props;
  const location = useLocation();
  const [categoryTitle, setCategorytitle] = useState(false);
  const [accordionTitle, setAccordiontitle] = useState(false);
  const [viewpage, setViewPage] = useState(0);
  const queryString = require("query-string");
  const history = useHistory();
  const [sortable, setSortable] = useState("");
  const [loadFilter, setLoadFilter] = useState(true);
  const [brandTitle, setBrandtitle] = useState(false);
  const [brandLogo, setBrandlogo] = useState(false);
  const [urlSupplierId, setUrlSupplierId] = useState(""); 
  
  const [aboutSupplier, setAboutSupplier] = useState("");
  const [aboutSupplierDesigniche, setAboutSupplierDesigniche] = useState("");

  const handleClickCategory = (category) => {
    setCategorytitle(category.title);
    const params = new URLSearchParams(location.search);
    params.delete("page");
    params.set("categoryId", category.id);
    params.set("title", category.title);
    history.push({
      pathname: "/marketplace",
      search: "?" + params,
    });
  };

  const handleChangeSortable = (title) => {
    var value = title.split("-");
    setSortable(title);
    const params = new URLSearchParams(location.search);
    params.set("sort", value[0]);
    params.set("direction", value[1]);
    history.push({
      pathname: "/marketplace",
      search: "?" + params,
    });
  };

  const fetchProduct = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    setCategorytitle(params.get("title"));
	
 	if (params.get("logo") && params.get("logo") !='' ) {
		setBrandlogo(params.get("logo")); 
		
	} 
	
	if (params.get("brand_title")) {
		setBrandtitle(params.get("brand_title")); 
		
	}
     const request = {};
	
	if (params.get("supplierId")) {
      request.supplierId = params.get("supplierId");
      setUrlSupplierId(request.supplierId);	  
    }
	 
    if (params.get("categoryId")) {
      request.categoryId = params.get("categoryId");
      setAccordiontitle(params.get("accordion"));
    }
    if (params.get("discount")) {
      var discount = queryStringParsed["discount"];
      if (typeof queryStringParsed["discount"] === "string") {
        request.discount = discount.split(" ");
      } else {
        request.discount = discount;
      }
    }
    if (params.get("rating")) {
      var rating = queryStringParsed["rating"];
      if (typeof queryStringParsed["rating"] === "string") {
        request.rating = rating.split("");
      } else {
        request.rating = rating;
      }
    }
    if (params.get("length")) {
      var length = queryStringParsed["length"];
      if (typeof queryStringParsed["length"] === "string") {
        request.length = length.split(" ");
      } else {
        request.length = length;
      }
    }
    if (params.get("amount")) {
      var amount = queryStringParsed["amount"];
      request.amount = amount;
    }
    if (params.get("page")) {
      var pageNo = params.get("page") - 1;
      setViewPage(pageNo);
      var page = queryStringParsed["page"];
      request.page = page;
    } else {
      setViewPage(0);
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("zipcode")) {
      var zipcode = queryStringParsed["zipcode"];
      request.zipcode = zipcode;
    }
    if (params.get("type")) {
      var type = queryStringParsed["type"];
      request.type = type;
    }

    actions.loadProductData(request);
	
	
  }, [actions, location, queryString]);

  const fetchData = useCallback(() => {
    const params = new URLSearchParams(location.search);
	if(params.get("brand_title") != null && params.get("brand_title") != ''){
		document.title = params.get("brand_title")+" Collection in Designiche";
	}else{
		document.title = title;
	}
	 
	
	if (loadFilter) { 
	   let supplierId = '';
	   if (params.get("supplierId")) { 
			supplierId   =  params.get("supplierId");
 		} 
      actions.loadproductFilterData(supplierId);
    }
    setLoadFilter(false);
    fetchProduct();
	 
  }, [actions, title, fetchProduct, loadFilter]);

  const handlePageClick = (data) => {
    setViewPage(data.selected);
    let page = data.selected + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/marketplace",
      search: "?" + params,
    });
  };
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };
  useEffect(() => {
    fetchData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const params = new URLSearchParams(location.search);
    if (params.get("supplierId")) {
        const requestBrand = {'supplierId' : params.get("supplierId")}
        actions.loadBrandData(requestBrand);  
    }  
		 
  }, [fetchData]);
  
   
  
 
  return (
    <>
      <InnerBanner advertisingData={props.advertisingData}  urlSupplierId = {urlSupplierId}/>
      {(urlSupplierId =='' ) ? (<Breadcrumbs name={BrdName} />) : ( <span></span>)}
			
			
      <div className="main_wrap py-4">
	  {(urlSupplierId !='' ) 
        
		?  (		
		<section className="home_tab_panel">
		
				<Container>	
				<div className="row justify-content-center px-lg-3">
					<div className="col-md-6">
						<div className="contact-box d-flex justify-content-between align-items-center">
							<h4>Have a Custom Requirement?</h4> 
							<NavLink
								to={
								  "/contact-us"
								  } 
								exact
								className="btn" 
							  >
								Contact Us
							  </NavLink>
					  
						</div>
					</div> 
				</div>				
				<div className="tabbable boxed parentTabs"> 
						 
					<Tabs defaultActiveKey="products" className="supplier-tabs">
						<Tab eventKey="products" title="Products" className="lex-sm-fill"> 
						
							<Container>
								  <Row>
									<Col md={4} xl={3}>
									  <ListSidebar
										exact
										productFilterData={props.productFilterData}
										onClick={handleClickCategory}
										accordionTitle={accordionTitle}
										urlSupplierId = {urlSupplierId}
									  />
									</Col>
									{props.productData.length > 0 && (
									  <Col md={8} xl={9} className="border-left">
										<div className="product_listing">
										  <div className="search_title">
											<h3 className="mb-0">
											  Browse {props.pagination.total} results
											  {(categoryTitle && brandTitle) ? <span> of "{categoryTitle}" by "{brandTitle}" </span> : (categoryTitle ) ? <span> of "{categoryTitle}" </span> : brandTitle && <span> of "{brandTitle}" </span> } 
											   
											</h3>
											<Form
											  action=""
											  className="ml-auto d-flex align-items-center"
											>
											  <Form.Label className="mb-0 mr-2">Sort by</Form.Label>
											  <Form.Control
												as="select"
												value={sortable}
												onChange={(e) => handleChangeSortable(e.target.value)}
											  >
												<option value="title-asc">Title: A to Z</option>
												<option value="title-desc">Title: Z to A</option>
												<option value="discountPrice-asc">
												  Price: Low to High
												</option>
												<option value="discountPrice-desc">
												  Price: High to Low
												</option>
											  </Form.Control>
											</Form>
										  </div>
										  <Row>
											{props.productData.map((item, index) =>
											  item.type === "product" ? (
												<Col xl="4" lg="6" md="6" sm="6" key={index}>
												  <List product={item} wishCount={wishCount} />
												</Col>
											  ) : (
												<Col
												  xs={12}
												  className="advertise text-center mt-2 mb-5"
												  key={index}
												>
												  <LazyLoad
													images={item.image}
													key={1}
													imageClass={"advertiseProductBlur"}
												  />
												</Col>
											  )
											)}
										  </Row>
										</div>
									  </Col>
									)}
									{props.isProduct && (
									  <Col md={8} xl={9} className="border-left">
										<div className="no_record_found text-center pt-5">
										  <LazyLoad
											images={"/assets/img/logo.svg"}
											key={2}
											imageClass={"productBlur"}
										  />
										  <div className="pt-5">We're Sorry!, no item found</div>
										  {(categoryTitle && brandTitle) ? <span className="pt-5"> in "{categoryTitle}" by "{brandTitle}" </span> : (categoryTitle ) ? <span className="pt-5"> in "{categoryTitle}" </span> : brandTitle && <span className="pt-5"> in "{brandTitle}" </span> } 
										</div>
									  </Col>
									)}
								  </Row>
								  <div className="pagination_sec">
									{props.pagination.count !== 0 && (
									  <ReactPaginate
										previousLabel={"previous"}
										nextLabel={"next"}
										forcePage={viewpage}
										breakLabel={"..."}
										breakClassName={"break-me"}
										pageCount={props.pagination.total_pages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={props.pagination.per_page}
										onPageChange={(v) => handlePageClick(v)}
										containerClassName={"pagination"}
										subContainerClassName={"pages pagination"}
										activeClassName={"active"}
									  />
									)}
								  </div>
								</Container>
					    </Tab>
						
						
						<Tab eventKey="about_brand" title={"About "+brandTitle} className="lex-sm-fill" > 
							 {props.brandData.length >0 && ( <div dangerouslySetInnerHTML={prepareHtml(props.brandData[0]['about_supplier'])} />)}
					    </Tab>
					</Tabs>					   
				</div>	
			</Container>
		</section>
		) : ( <Container>
          <Row>
            <Col md={4} xl={3}>
              <ListSidebar
                exact
                productFilterData={props.productFilterData}
                onClick={handleClickCategory}
                accordionTitle={accordionTitle}
				urlSupplierId = {urlSupplierId}
              />
            </Col>
            {props.productData.length > 0 && (
              <Col md={8} xl={9} className="border-left">
                <div className="product_listing">
                  <div className="search_title">
                    <h3 className="mb-0">
                      Browse {props.pagination.total} results
                      {(categoryTitle && brandTitle) ? <span> of "{categoryTitle}" by "{brandTitle}" </span> : (categoryTitle ) ? <span> of "{categoryTitle}" </span> : brandTitle && <span> of "{brandTitle}" </span> } 
					   
                    </h3>
                    <Form
                      action=""
                      className="ml-auto d-flex align-items-center"
                    >
                      <Form.Label className="mb-0 mr-2">Sort by</Form.Label>
                      <Form.Control
                        as="select"
                        value={sortable}
                        onChange={(e) => handleChangeSortable(e.target.value)}
                      >
                        <option value="title-asc">Title: A to Z</option>
                        <option value="title-desc">Title: Z to A</option>
                        <option value="discountPrice-asc">
                          Price: Low to High
                        </option>
                        <option value="discountPrice-desc">
                          Price: High to Low
                        </option>
                      </Form.Control>
                    </Form>
                  </div>
                  <Row>
                    {props.productData.map((item, index) =>
                      item.type === "product" ? (
                        <Col xl="4" lg="6" md="6" sm="6" key={index}>
                          <List product={item} wishCount={wishCount} />
                        </Col>
                      ) : (
                        <Col
                          xs={12}
                          className="advertise text-center mt-2 mb-5"
                          key={index}
                        >
                          <LazyLoad
                            images={item.image}
                            key={1}
                            imageClass={"advertiseProductBlur"}
                          />
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              </Col>
            )}
            {props.isProduct && (
              <Col md={8} xl={9} className="border-left">
                <div className="no_record_found text-center pt-5">
                  <LazyLoad
                    images={"/assets/img/logo.svg"}
                    key={2}
                    imageClass={"productBlur"}
                  />
                  <div className="pt-5">We're Sorry!, no item found</div>
				  {(categoryTitle && brandTitle) ? <span className="pt-5"> in "{categoryTitle}" by "{brandTitle}" </span> : (categoryTitle ) ? <span className="pt-5"> in "{categoryTitle}" </span> : brandTitle && <span className="pt-5"> in "{brandTitle}" </span> } 
                </div>
              </Col>
            )}
          </Row>
          <div className="pagination_sec">
            {props.pagination.count !== 0 && (
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                forcePage={viewpage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={props.pagination.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={props.pagination.per_page}
                onPageChange={(v) => handlePageClick(v)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </Container> ) }
		
		
      </div>
      <Newsletter />
	  {localStorage.setItem("marketplace", true)}
    </>
  );
};

Product.propTypes = {
  productData: PropTypes.any.isRequired,
  pagination: PropTypes.any.isRequired,
  productFilterData: PropTypes.any.isRequired,
  advertisingData: PropTypes.any.isRequired,
  isProduct: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
  brandData: PropTypes.any.isRequired,
};

function mapStateToProps(state) { 

	/*console.log("========start STATE brandData ===========");
	console.log(state.brandData);
	console.log("========end STATE brandData ===========");*/
  return {
    productData: state.productData,
    pagination: state.pagination,
    productFilterData: state.productFilterData,
    advertisingData: state.advertisingData,
    isProduct: state.isProduct,
    wishCount: state.wishCount,
	brandData: state.brandData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
		productActions,
		brandActions
	), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
