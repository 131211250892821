import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormControl, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import * as cartActions from "../../actions/cartActions";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";
import * as CryptoJS from "crypto-js";
import PaymentForm from "../cart/paymentForm";

const MeetingModal = (props) => {
  const { actions, setting, userInfo, dummyOrder } = props;
  const { register, errors, handleSubmit } = useForm();
  const [showPaymentForm, setShowPayment] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [mySignature, setSignature] = useState(0);
  const [paymentUrl, setPaymentUrl] = useState(false);

  const onSubmit = (data) => {
    let params = data;
    params.order_id = setting.payment.orderId;
    params.user_id = userInfo.user_id;
    params.amount = setting.setting.request_virtual_amount;
    params.type = "MEETING";
   // actions.handlePlacePaymentOrder(params);
    actions.sendMeetingRequest(data);
  };

  useEffect(() => {
    if (setting.setting.request_virtual_amount) {
      let amount = parseFloat(
        setting.setting.request_virtual_amount.replace(",", "")
      );
      let appId = setting.payment.test_app_id;
      let secretKey = setting.payment.test_secret_key;
      let paymentUrl = setting.payment.test_url;
      if (setting.payment.prodMode) {
        appId = setting.payment.prod_app_id;
        secretKey = setting.payment.prod_secret_key;
        paymentUrl = setting.payment.prod_url;
      }
      var postData = {
        appId: appId,
        orderId: setting.payment.orderId,
        orderAmount: amount,
        orderCurrency: setting.payment.currency,
        orderNote: "Test",
        customerName: userInfo.first_name,
        customerEmail: userInfo.email,
        customerPhone: userInfo.number,
        returnUrl: setting.payment.returnUrlDesigner,
        notifyUrl: setting.payment.notifyUrlDesigner,
      };
      setPaymentData(postData);
      var orderedPostData = {};
      Object.keys(postData)
        .sort()
        .forEach(function (key) {
          orderedPostData[key] = postData[key];
        });
      var signatureData = "";
      for (const key in orderedPostData) {
        signatureData += key + orderedPostData[key];
      }
      var hash = CryptoJS.HmacSHA256(signatureData, secretKey);
      var signature = CryptoJS.enc.Base64.stringify(hash);
      setSignature(signature);
      setShowPayment(true);
      setPaymentUrl(paymentUrl);
    }
  }, [setting, userInfo]);

  useEffect(() => {
    if (dummyOrder) {
      document.getElementById("redirectForm").submit();
    }
  }, [dummyOrder]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-0 mb-3 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Request virtual meeting with designer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <section className="contact_sec pb-4 mb-2">
          <Container className="p-0">
            <Row>
              <Col md={12}>
                <div className="formOuter">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Group controlId="formBasicFirstName">
                            <FormControl
                              type="text"
                              placeholder="Name*"
                              className={errors.name ? "formError" : ""}
                              name="name"
                              aria-invalid={errors.name ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 3,
                                maxLength: 20,
                              })}
                            />
                            {errors.name && errors.name.type === "required" && (
                              <span role="alert">Please enter your name.</span>
                            )}
                            {errors.name &&
                              errors.name.type === "minLength" && (
                                <span role="alert">
                                  Your name should contain atleast 3 characters.
                                </span>
                              )}
                            {errors.name &&
                              errors.name.type === "maxLength" && (
                                <span role="alert">
                                  Your name should not exceed 20 characters.
                                </span>
                              )}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Form.Group controlId="formBasicEmail">
                            <FormControl
                              type="email"
                              placeholder="Email Address*"
                              className={errors.email ? "formError" : ""}
                              name="email"
                              aria-invalid={errors.email ? "true" : "false"}
                              ref={register({
                                required: true,
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                },
                                maxLength: 50,
                              })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <span role="alert">
                                  Please enter your email address.
                                </span>
                              )}
                            {errors.email &&
                              errors.email.type === "pattern" && (
                                <span role="alert">Invalid email address.</span>
                              )}
                            {errors.email &&
                              errors.email.type === "maxLength" && (
                                <span role="alert">
                                  Your email should not exceed 50 characters.
                                </span>
                              )}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Form.Group controlId="formBasicNumber">
                            <FormControl
                              type="tel"
                              placeholder="Mobile Number*"
                              name="number"
                              className={errors.number ? "formError" : ""}
                              aria-invalid={errors.number ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                pattern: {
                                  value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                                },
                              })}
                            />
                            {errors.number &&
                              errors.number.type === "required" && (
                                <span role="alert">
                                  Please enter your mobile number.
                                </span>
                              )}
                            {errors.number &&
                              errors.number.type === "maxLength" && (
                                <span role="alert">
                                  Your mobile number should not exceed 10
                                  digits.
                                </span>
                              )}
                            {errors.number &&
                              errors.number.type === "minLength" && (
                                <span role="alert">
                                  Your mobile number should contain atleast 10
                                  digits.
                                </span>
                              )}
                            {errors.number &&
                              errors.number.type === "pattern" && (
                                <span role="alert">
                                  Please enter correct mobile number. It should
                                  be entered without country code.( Example
                                  xxxxxxxxxx)
                                </span>
                              )}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Form.Group controlId="formBasicMessage">
                            <FormControl
                              as="textarea"
                              rows={3}
                              type="text"
                              placeholder="Message*"
                              className={errors.message ? "formError" : ""}
                              name="message"
                              aria-invalid={errors.message ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 5,
                              })}
                            />
                            {errors.message &&
                              errors.message.type === "required" && (
                                <span role="alert">
                                  Please enter your message.
                                </span>
                              )}
                            {errors.message &&
                              errors.message.type === "minLength" && (
                                <span role="alert">
                                  Your message should contain atleast 5
                                  characters.
                                </span>
                              )}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="btnOuter">
                          <CustomButton title={"SEND MESSAGE"} />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            {showPaymentForm && (
              <PaymentForm
                mySignature={mySignature}
                paymentData={paymentData}
                paymentUrl={paymentUrl}
              />
            )}
          </Container>
        </section>
      </Modal.Body>
    </Modal>
  );
};

MeetingModal.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
    userInfo: state.userInfo,
    dummyOrder: state.dummyOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(homePageActions, cartActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingModal);
