import curve from './images/curve.png';

import img1 from "../home/images/ds/01.jpg"; 
import img2 from "../home/images/ds/02.jpg"; 
import img3 from "../home/images/ds/03.jpg";
import img4 from "../home/images/ds/04.jpg";
import img5 from "../home/images/ds/05.jpg";
import img6 from "../home/images/ds/06.jpg";
import img7 from "../home/images/ds/07.jpg";
import img8 from "../home/images/ds/08.jpg";
import img9 from "../home/images/ds/09.jpg";
import img10 from "../home/images/ds/10.jpg";
import img11 from "../home/images/ds/11.jpg";
import img12 from "../home/images/ds/12.jpg";
import img13 from "../home/images/ds/13.jpg";
import img14 from "../home/images/ds/14.jpg";
import img15 from "../home/images/ds/15.jpg";


import banner_img from './images/design-services-banner.jpg';
// import '../App.scss';
import './stuvita.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom"; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Outlet, Link } from "react-router-dom";
//import "swiper/css";
//import "swiper/css/pagination";
import { Pagination } from "swiper";
 
const Stuvita = (props) => {

  const { actions, homePageData, isSubmitting } = props;
  const { slug } = useParams();

  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  const onSubmit = (data) => {
    console.log(JSON.stringify(data),'Prem');
 	data.request_for = 'Design Services';
     //return;
    actions.sendSpecialRequest(data);
  };
  
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" }
  };
  
   useEffect(() => {
    document.title = "Design Services";
  });
  
  const images = [
    {
      img: img1,
      description: "Modern",
    },
    {
      img: img2,
      description: "Contemporary",
    },
    {
      img: img3,
      description: "Industrial",
    },
    {
      img: img4,
      description: "Contemporary",
    },
    {
      img: img5,
      description: "Rustic",
    },
    {
      img: img6,
      description: "Rustic",
    },
    {
      img: img7,
      description: "Tropical",
    },
    {
      img: img8,
      description: "Electric",
    },
    {
      img: img9,
      description: "French",
    },
    {
      img: img10,
      description: "Minimalastic",
    },
    {
      img: img11,
      description: "Art Deco",
    },
    {
      img: img12,
      description: "Classical",
    },
    {
      img: img13,
      description: "Indian Traditional",
    },
    {
      img: img14,
      description: "Bohemian",
    },
    {
      img: img15,
      description: "Japanese",
    },
  ];
  const [selectedImage, setSelectedImage] = useState("");
  const openPopup = (image) => {
    setSelectedImage(image);
  };
  const closePopup = () => {
    setSelectedImage(null);
  };
  
  return (
    <div>
	{/* <img src={banner_img} className="img-fluid w-100" alt="" />  */}
        <section className="about-supplier">
          <div className="container"> 
            <h3 className="common-heading text-uppercase mb-1">Stuvita</h3>
            <h5 className="mb-2 pb-1">Our Design Partner</h5>
            <p className='mb-2 pb-1'>Exquisite. Transparent. Bespoke.</p>
            <p className='mb-2 pb-1'>Delve into the world of exclusivity and innovation with a range of iconic designs from architects and designers at the pinnacle of craftsmanship. Our iconic style never goes out of fashion as luxury and convenience mark every project. With a visionary allure and attention to detail, Stuvita offers technology-driven diverse designs blended with our customer's vision.</p>
            <p className='mb-2'>We are here to redefine luxury!</p>

            <p className='mb-2 pb-1'>With great vision comes great responsibility.</p>
            <p className='mb-1'>Vibhor Garg was always a visionary and he proved that at the age of 13 when he became the youngest Microsoft-certified software professional in India. Earning a spot in the Limca Book of records was one of his first achievements. </p>
            <p className='mb-0'>Born and brought up in Delhi, Vibhor Garg went on to clear JEE-2003 and studied at IIT Roorkee after completing his studies in Delhi. Armed with a bachelor's degree in Architecture, he worked with Deloitte in Business Technology. Stuvita brings exquisite service to everyone who dreams of special designs!</p>
          </div>
        </section>
        <img src={curve} alt="curve" className="img-fluid w-100" />
        
		    <div className="design-service-gallery container mb-5">
          <div className="ds-gallery">
            {images?.map((image, index) => (
              <div onClick={() => openPopup(image.img)} className="ds-gallery-item">
              <img key={index} src={image.img} alt={image.description} />
              <h4>{image.description}</h4>
              </div> 
            ))}
          </div>
          {selectedImage && (
            <div className="gallery_popup gallery_">
              <span className="close" onClick={closePopup}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-xlg"
                viewBox="0 0 25 25"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
              </span>
              <img src={selectedImage} alt="Selected Image" />
            </div>
          )}
			  </div>
        
        <section className='pb-5 stuvita-process no-1 pt-4'>
          <div className="container">
            <h3 className="common-heading text-uppercase mb-3">The Process</h3>
            <h5 className='font-weight-bold mb-4'>How we work?</h5>
            <div className="row">
              <div className="col-md-4">
                  <div className="process-box">
                    <span className='list-no'>1. Ideate your Expectation</span>
                    <p>Get in touch with our designers and express your desired design look and budget</p>
                  </div>
              </div>
              
              <div className="col-md-4">
                  <div className="process-box">
                    <span className='list-no'>2. Design Conceptualization</span>
                    <ul>
                      <li>Our team comes over to assess the space and area</li>
                      <li>The design team combines your idea with functionality to create a comfortable luxurious living</li>
                      <li>Witness the designs in 3D</li>
                    </ul>
                  </div>
              </div>
              
              <div className="col-md-4">
                  <div className="process-box">
                    <span className='list-no'>3. Documentation</span>
                    <ul>
                      <li>We take your review and approval before finalizing</li>
                      <li>3D Design is concluded with fine details</li>
                      <li>Drawing documentation and estimate </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <section className="scope-of-services no-2">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-1">SCOPE OF SERVICES</h3>
            <h6 className='mb-4'>The proposed services to be provided are as follows:</h6>

            <h5>INTERIORS</h5>
            <ol type='a'>
              <li>Finalizing interior furniture layout for the rooms and toilets in the discussion.</li>
              <li>Finalizing flooring for rooms and toilets - issuing flooring plans for the rooms considered</li>
              <li>Finalizing door and window finishes and design - issuing door and window details</li>
              <li>Finalizing the designs for wardrobe and storage designs - an issue of wardrobe details - plans, elevations and drawings accordingly</li>
              <li>Finalizing false ceiling design along with the location of electrical fittings and fixtures like fans, lights and decorative/feature luminaries - the issue of false ceiling drawings(RCP drawings)</li>
              <li>Selecting sanitary ware and bath fittings and finalizing the exact location of the same in all the toilets - issue toilet fixture layouts</li>
              <li>Selecting wall finishes for toilets - the issue of wall elevation drawings of toilets</li>
              <li>Finalizing the complete interior design scheme/look of each room with the help of PowerPoint presentations & mood boards and if required 3D views/artistic renderings</li>
              <li>Issue of internal wall elevation drawings and details only on finalization of the interior design scheme</li>
              <li>Selection and sourcing of loose furniture items like beds, sofas, armchairs, consoles, Study tables, Dining sets and coffee tables.</li>
              <li className='mb-0'>Selection and sourcing soft Furnishing & Decor items like rugs, Curtains/blinds, wall art, home decor accessories, exclusive light fittings etc.</li>
            </ol>
          </div>
        </section>

        <section className="cost-of-project mt-5 no-3">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-4">Our Final Deliverables</h3>
            <div className="row">
              <div className="col-md-4">
                <div className="cop-box h-100">
                  <span className='list-no'>01</span>
                  <p>Design Services Only: GFC (Good for Construction) Drawing & High-resolution 3D Images</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cop-box h-100">
                  <span className='list-no'>02</span>
                  <p>Design & supervision: GFC (Good for Construction) Drawing & High-resolution 3D Images + Periodic Site Supervision</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cop-box h-100">
                  <span className='list-no'>03</span>
                  <p>Design + PMC & Supervision: Drawing & High-resolution 3D Images + End to End hand holding.</p>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="cost-of-project no-4">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-1">COST OF PROJECT</h3>
            <h6 className='mb-4'>Minimum Project Size: <span className='font-weight-normal'>3000 sqft Carpet Area</span></h6>
            <div className="row">
              <div className="col-md-4">
                <div className="cop-box">
                  <span className='list-no'>01</span>
                  <p>Design Services Only: 300 INR/sqft</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cop-box">
                  <span className='list-no'>02</span>
                  <p>Design & supervision: 300 INR/sqft + 5000 INR onwards / visit **</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="cop-box">
                  <span className='list-no'>03</span>
                  <p>Design + PMC & Supervision: 450 INR/sqft + 5000 INR onwards / visit **</p>
                </div>
              </div>
              <div className="col-12">
                <span className='subject-location'>** Subject to location</span>
              </div>
            </div>

          </div>
        </section>
                
        <section className="pay-stages mb-5 bg-light py-5 no-5">
          <div className="container">
            <h3 className="common-heading text-uppercase">Pay Stages</h3>
            <div className='table-responsive'>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S L No.</th>
                  <th colSpan={2}>Stage of Payments</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td colSpan={2}>Stage 1 - On Appointment</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td colSpan={2}>Stage 2 - finalization of interior layouts</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td rowSpan={3}>3</td>
                  <td rowSpan={3} colSpan={1}>Stage 3 -3D Finalization</td>
                </tr>
                <tr>
                  <td>finalisation of <span className='font-weight-bold'>Bedroom areas</span> (bedroom, dressing, toilet, powder room)</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>finalisation of <span className='font-weight-bold'>Living areas</span> (living room, dining room, lounge, pooja room, tv room)</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td colSpan={2}>Stage 4 - issue of GFC Drawings (false ceiling & flooring)</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td colSpan={2}>Stage 5 - issue of GFC Drawings (wall elevations and final finishes)</td>
                  <td>15%</td>
                </tr>
              </tbody>
            </table>
            </div>
            <h6 className='font-weight-bold'>**Note: Visits to the Store & showrooms for the Selection of Materials are Chargeable @ INR 5000 Per Visit.</h6>
            <h6 className='font-weight-bold'>**Note:  Gst of 18% is applicable on all payments.</h6>
          </div>
        </section>

        <section className="sample-client-agg no-6">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-1">What we expect from our clients?</h3>
            <h6 className="mb-3">CLIENT'S ROLES & RESPONSIBILITIES</h6>
            <p className="mb-4">The Client shall discharge all his obligations connected with the project and engagement of the Architect as follows:</p>
            <div className="row">
              <div className="col-md-4">
                <div className="sca-box">
                  <span className="list-no">01</span>
                  <p>To provide detailed requirements of the project.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sca-box">
                  <span className="list-no">02</span>
                  <p>To furnish specific requirements, conditions, statutory stipulations, schedule of rates, etc. desired to be followed.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sca-box">
                  <span className="list-no">03</span>
                  <p>To pay all the fees, payments etc. at regular intervals, on presentation of on-account bills/requests for the same.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sca-box">
                  <span className="list-no">04</span>
                  <p>To give effect to the professional advice of the Interior Designer and cause no changes in the drawings and documents without the consent of the Interior Designer.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sca-box">
                  <span className="list-no">05</span>
                  <p>To honour Interior Designer's final bills within fifteen days of their submission. This shall not be related to the payment of bills to the Contractor / Vendors.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sca-box">
                  <span className="list-no">06</span>
                  <p>To appoint a Construction Manager (Clerk of works / Site supervisor) as per the Interior Designer's advice.</p>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="termination mb-0 no-7">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-4">TERMINATION</h3>
            <div className="row">
              <div className="col-md-4">
                <div className="termination-box">
                  <p>The obligation to provide services under this Appointment may be terminated by either party upon fourteen days' written notice in the event of substantial failure by the other party to perform in accordance with the terms hereof through no fault of the terminating party.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="termination-box">
                  <p>In the event of any termination, the Interior Designer shall be entitled to be paid for all submissions rendered till the date of termination, any reimbursable expenses and termination expenses.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="termination-box">
                  <p>If the project has been suspended for six months or more, the Interior Designer shall upon giving notice in writing, have the right to treat the Appointment as terminated. Termination of the Appointment shall be without prejudice to the accrued rights and remedies of either party.</p>
                </div>
              </div>
            </div>

            <div className='mt-4 pt-2'>
            <h5 className='font-weight-bold'>** VALIDITY</h5>
            <p className='mb-0'>This proposal shall be valid for a period of 90 days from the date of submission. The date of submission will be stated on the first page of this proposal. Any time extension to this proposal can only be made by the consent of both parties in writing.</p>
            </div>
          </div>
        </section>

        <section className="exclusions">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-1">EXCLUSIONS</h3>
            <h6 className='mb-4'>The scope of services does not include the following:</h6>
            <div className="row">
              <div className="col-md-3">
                <div className="exclusions-box">
                  <p>Topographical surveys, mechanical, irrigation schemes, budgeting and cost estimates, model making and any other additional works required and not listed in the scope of services.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="exclusions-box">
                  <p>A separated sub-consultant shall be hired to get the plan sanctioned & also obtain the completion certificate from the concerned authorities. The fees to the sub-consultant shall be given directly by the client.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="exclusions-box">
                  <p>The Kitchen shall be outsourced to a specialized kitchen vendor. The architect shall assist the client to finalise the scheme and give his input in the designs of the kitchen.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="exclusions-box">
                  <p>Waterproofing / Structural (if retrofit) / lift/ any other specialized consultancy, if required will be as per actual. The architect shall assist the client to finalise the scheme and give his input in the design.</p>
                </div>
              </div>
            </div>

          </div>
        </section>

        {/* <section className="pb-5 pt-3">
          <div className="container">
            <h3 className="common-heading text-uppercase mb-3">More About Us</h3>
            <p className="text-justify">Creating exquisite and bespoke designs requires innovation and style sense. Stuvita was born with a vision to create technology-driven,  luxurious spaces that are customised for every client and in sync with current design trends. Our creations have carved a niche in the luxury design segment with their creativity and aesthetic excellence.</p>
            <p className="text-justify">The founder had years of experience garnered through travels and working in the design industry. A multifarious range of experiences through the globe is amalgamated into the creation of Stuvita where these raw ideas are converted into opulent and alluring designs.</p>
            <p className="text-justify">The world thrives on exclusivity these days. The spaces need to be functional yet extraordinary and awe-inspiring. Elite innovations that are original in thought and execution are what Stuvita is known for. Imaginative and efficacious, Stuvita is inspired by indulgence, convenience and elements of grandeur as we blend with the vision of our clients.</p>
            <p className="text-justify">Attention to detail and personalised design language ensures that whatever we create reflects our commitment towards creating elegant and chic spaces.</p>
          </div>
        </section> */}

        {/* <section className='bg-light py-5'>
          <div className="container">
            <h3 className="common-heading text-uppercase mb-1">Vibhor Garg- Introduction</h3>
            <h6 className='mb-4'>With great vision comes great responsibility. </h6>

            <p className='text-justify'>Vibhor Garg was always a visionary and he proved that at the age of 13 when he became the youngest Microsoft-certified software professional in India. Earning a spot in the Limca Book of records was one of his first achievements.</p>
            <p className='text-justify'>Born and brought up in Delhi, Vibhor Garg went on to clear JEE-2003 and studied at IIT Roorkee after completing his studies in Delhi. Armed with a bachelor's degree in Architecture, he worked with Deloitte as a Business Technology Analyst for two years. Stepping further in his pursuit of knowledge, Vibhor completed an MBA in Finance & Operations from MDI Gurgaon. </p>
            <p className='text-justify mb-0'>His intuitive nature led to many adventures and the eventual birth of Stuvita. Vibhor aims to bridge the gap between design and execution during his experience. Established in 2021, Stuvita brings exquisite service to everyone who dreams of opulent designs!</p>
          </div>
        </section> */}

        {/* <section className='py-5'>
          <div className="container">
            <h3 className="common-heading text-uppercase mb-3">Why do we stand apart in design?</h3>
            <h5 className='font-weight-bold mb-4'>Our Assurance</h5>
            <div className="row">
              <div className="col-md-3">
                <div className="oa-box">
                  <h4>Imaginative</h4>
                  <p>Crafting experience through change & invention</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="oa-box">
                  <h4>Efficacious</h4>
                  <p>Leading with efficient and influential designs</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="oa-box">
                  <h4>Experience</h4>
                  <p>Bringing an extraordinary essence to every design</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="oa-box">
                  <h4>Amenities</h4>
                  <p>Offering luxury coupled with convenience</p>
                </div>
              </div>
            </div>


            <div className='mt-4'>
                <h3 className="common-heading text-uppercase mb-3">Tailored Luxurious Interior Designs</h3>
              <h5 className='font-weight-bold mb-3'>Thoughtful Designs</h5>
              <p>The secret of our comfortable sophisticated design lies in exquisite detail and expertise. We create elegant and elite spaces!</p>
            </div>
          </div>
        </section> */}

        {/* <section className='py-5 bg-light'>
          <div className="container">
            <h3 className="common-heading text-uppercase mb-3">What led us to where we are today?</h3>
            <h5 className='font-weight-bold mb-4'>Our Story</h5>
            <p className='text-justify'>Derived from thought, experience, and insight into the construction business, Stuvita was born to bridge the gap in the world of luxury designs. With a decade worth of experience as an architect, the founder encountered an unreasonable difference between design and execution during his experience. </p>
            <p className='text-justify'>He expanded his expertise through a thirst for culture and travel. His passion took him on many experiences, including the Maison and Objet in Paris, Salone del Mobile in Milan, to the Interzum Koelnmesse in Germany. He gathered his expertise from his extensive travel; from the French Riviera to Greece and from the modern world to the cultural one. With every experience, he carved a vision for Stuvita.</p>
            <p className='text-justify mb-0'>Stuvita was established in 2021 to bring exquisite service to everyone who dreams of opulent designs. The exposure to luxury projects everywhere in the world has shaped Stuvita as a progressive, influential, and innovative brand. We aim to make luxury interiors convenient and comfortable.</p>
          </div>
        </section> */}

        {/* <section className="sample-work-section bg-white pt-3 pb-0">
          <div className="container">
            <h3 className="common-heading mb-4">Sample Work</h3>
            <div className="row">
              <div className="col-md-7 sm-thumb">
                  <div className="row">
                  <div className="col-md-6">
                    <img src={gallery1} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-6">
                    <img src={gallery2} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-6">
                    <img src={gallery3} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-6">
                    <img src={gallery4} className="img-fluid" alt="" />
                  </div>
                  </div>
              </div>
              
              <div className="col-md-5 lg-thumb">
                <img src={gallery5} className="img-fluid" alt="" />
              </div>

              <div className="col-md-5 lg-thumb">
                <img src={gallery12} className="img-fluid" alt="" />
              </div>

              <div className="col-md-7 sm-thumb">
                  <div className="row">
                  <div className="col-md-6">
                    <img src={gallery6} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-6">
                    <img src={gallery7} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-6">
                    <img src={gallery8} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-6">
                    <img src={gallery9} className="img-fluid" alt="" />
                  </div>
                  </div>
              </div>

              <div className="col-md-7 sm-thumb">
                  <div className="row">
                    <div className="col-md-6">
                      <img src={gallery11} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6">
                      <img src={gallery10} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6">
                      <img src={gallery13} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6">
                      <img src={gallery14} className="img-fluid" alt="" />
                    </div>
                  </div>
              </div>
              
              <div className="col-md-5 lg-thumb">
                <img src={gallery15} className="img-fluid" alt="" />
              </div>

            </div>
          </div>
        </section> */}

        <section className="testimonials-section">
          <div className="container">
            <h3 className="common-heading mb-4 text-uppercase">Testimonials</h3>
            <Swiper
                spaceBetween={0}
                slidesPerView={3}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                  },
                  "@1.00": {
                    slidesPerView: 2,
                  },
                  "@1.50": {
                    slidesPerView: 3,
                  },
                }}
              >
              <SwiperSlide>
                <div className="testimonial-box">
                  <div className="testimonial-content">
                    <p>
                      Sincere thanks to Mr Vibhor Garg and his team for being a major help for our project in
                      Shalimar Bagh, New Delhi. I now believe that he can bring any design or vision to life in a unique fashion. The experience with him has been of great satisfaction. His expertise and wealth of knowledge left a great overall impression. I will be recommending Vibhor and his talented team to all my connections. I wish him and his team great success in the times to come.
                    </p>
                    <h5 className="name mb-0">Mr. Mangat Ram</h5>
                    <small className="location">Shalimar Bagh, New Delhi</small>
                  </div>
                </div>
              </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="testimonial-content">
                      <p>
                        It was a pleasure working with Mr. Vibhor Garg. We absolutely are happy with the positive attitude and in-depth design knowledge of Mr. Garg and his team. He completed our project in New Rajendra Nagar, New Delhi without leaving any corner undone. I was looking for someone who can create a contemporary space with a brush of Indian elements to it, thanks to him and his team for bringing my dream to life with cutting-edge technology and beautiful fittings. The home feels so fresh and lively, just the way it was visioned and designed. I am happy with the results and highly recommend working with them on Luxury Residential Projects.
                      </p>
                      <h5 className="name mb-0">Dr. Jagga</h5>
                      <small className="location">Rajinder Nagar, New Delhi</small>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="testimonial-content">
                      <p>
                        Dear Vibhor, thank you for making our impossible dream come true. Our experience working with him was illuminating, interesting, and one-of-a-kind; their attention to detail is amazing. We honestly did not think it was possible to have the Designer House in Kavi Nagar, Ghaziabad that we have in our vision due to a lack of creativity in the market but Mr. Vibhor and his team helped us. The project was done within the deadline and resulted in a unique and beautiful finish. Thanks to the team again the process from ideation to execution led us to believe that yes, we made the right choice.
                      </p>
                      <h5 className="name mb-0">Mr. Nitin Gupta</h5>
                      <small className="location">Kavi Nagar, Ghaziabad</small>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="testimonial-content">
                      <p>
                      We have been associated with Mr. Vibhor Garg for many years and consistently return to him and his team for various projects. I referred him to one of my business associates to design his home. Mr. Vibhor combined the client's ideas and vision with his expertise and experience to develop a beautifully designed space. Their impeccable taste and knowledge of designs were very reassuring. Throughout the design and construction process, Vibhor and his team's attention to detail were second to none and our client was extremely happy with the service provided by him. They were amazed by the way their home turned out and have been planning to work on different projects with him and his entire team. 
                      <br/>
                      I would highly recommend Vibhor and his team to any of my connections who are looking to renovate a personal or commercial space.
                      </p>
                      <h5 className="name mb-0">Dr. Nikhil Raheja</h5>
                      <small className="location">Rajinder Nagar, New Delhi</small>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="testimonial-content">
                      <p>
                      During our association with Mr. Vibhor Garg, we worked with him on a farmhouse project in New Delhi. We found Vibhor to be technically stronger than any other interior designer we have met or worked with. From the word go, the knowledge and problem-solving capability of Vibhor and his team have been extremely detail-oriented and objective. In areas where the civil team and technical advisors have faced issues, Vibhor and his team have demonstrated a sound understanding of matters and proposed cost-effective and timesaving solutions.<br/>
                      As a testament to that, we have already started discussions with Vibhor on various other projects<br/>
                      Sincerely thank Mr. Vibhor Garg and his team for being a great choice. I will continue to choose their services for all my interior work and recommend them to all my social connections.
                      </p>
                      <h5 className="name mb-0">Mr. Aman Jalota</h5>
                      <small className="location">Managing Partner of Solvabuild</small>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-box">
                    <div className="testimonial-content">
                      <p>
                      It was my pleasure to introduce Arch. Vibhor to one of our respected clients Mr Garg in Kurukshetra who was planning to expand his existing 2000 sq. yards villa. Vibhor took the opportunity and created a world-class design suiting all his needs and completed all the plans well in time. He was always available to answer all the queries as well as meetings at the client's location. Our client is extremely happy with the positive attitude and design sensibilities of Vibor Garg and its team. Vibhor's out-of-the-box thinking to provide viable solutions to the client's choice has even added to our credibility. We are looking forward to Vibhor's services for our other expansion and work at our site which speaks volumes about his professionalism and deliverables.<br/>
                      It's a great feeling to work with Vibhor and I'll recommend him to all my business associates and friends.
                      </p>
                      <h5 className="name mb-0">Mr. Vikas Juneja</h5>
                      <small className="location">MD, Benchmark Lifestyle Solutions Pvt. Ltd.</small>
                    </div>
                  </div>
                </SwiperSlide>
            </Swiper>
          </div>
        </section>
		
		<div className="container d-none">
			<div className="service-box mb-5 p-4">
				<h2>Shop Products Now</h2>
				<Link className="btn shop-btn" to="/marketplace">Click Here</Link>
			</div>
		</div>
		
		 <section className="how-it-works mb-5">
          <div className="container">
            <div className="row mx-0">
              <div className="col-12 bg-light px-4 py-5">
                <div className="row px-2">
                  <div className="col-md-6">
                    <h3 className="common-heading-2">How it works:</h3>
                    <p>Once you make your selection above, our qualified partners are notified of your choices and start working on the drawings. Once done, you will be notified via the platform and will be able to communicate with our partner to suggest any changes on what has been submitted.</p>
                    <strong>The process usually takes 5 business days.</strong>
                    <h5>Happy Designing!</h5>
                  </div>
                  <div className="col-md-6">
                    <h3 className="common-heading-2">Any Special requests</h3>
                    <Form className=""
                  onSubmit={handleSubmit(onSubmit)} >
                      <div className="form-group">
                        {/*<textarea className="form-control" placeholder="Any special requests" required defaultValue={""} />*/}
                        <div className="form-group">
 						<input type="hidden" name="request_for" value="Design Services" />
                        <Form.Group controlId="formBasicFirstName">
                          <FormControl
                            type="text"
                            placeholder="Name"
                            className={errors.name ? "formError" : ""}
                            name="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 3,
                              maxLength: 20,
                            })}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span role="alert">Please enter your name.</span>
                          )}
                          {errors.name && errors.name.type === "minLength" && (
                            <span role="alert">
                              Your name should contain atleast 3 characters.
                            </span>
                          )}
                          {errors.name && errors.name.type === "maxLength" && (
                            <span role="alert">
                              Your name should should not 20 characters.
                            </span>
                          )}
                        </Form.Group>
                        </div>
                        <div className="form-group">
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="email"
                            placeholder="Email"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <span role="alert">
                              Please enter your email address.
                            </span>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <span role="alert">Invalid email address.</span>
                          )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should not exceed 50 characters.
                              </span>
                            )}
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group controlId="formBasicNumber">
                          <FormControl
                            type="tel"
                            placeholder="Phone Number"
                            name="number"
                            className={errors.number ? "formError" : ""}
                            aria-invalid={errors.number ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                              },
                            })}
                          />
                          {errors.number &&
                            errors.number.type === "required" && (
                              <span role="alert">
                                Please enter your mobile number.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "maxLength" && (
                              <span role="alert">
                                Your mobile number should not exceed 10 digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "minLength" && (
                              <span role="alert">
                                Your mobile number should contain atleast 10
                                digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "pattern" && (
                              <span role="alert">
                                Please enter correct mobile number. It should be
                                entered without country code.( Example
                                xxxxxxxxxx)
                              </span>
                            )}
                        </Form.Group>
                      </div>
                        <FormControl
                          as="textarea"
                          placeholder="Special Instructions"
                          className={errors.message ? "formError" : ""}
                          style={{ height: '100px' }}
                          name="message"
                          aria-invalid={errors.message ? "true" : "false"}
                          ref={register({
                            required: true,
                            
                            minLength: 5,
                          })}
                  />
                  {errors.message && errors.message.type === "required" && (
                    <span role="alert">Please enter your special requests.</span>
                  )}
                  {errors.message && errors.message.type === "minLength" && (
                    <span role="alert">
                      Your special request should minimum 5 characters.
                    </span>
                  )}
                      </div>
                      {/*<button className="btn custom-btn" >Submit</button>*/}
                      <Button
                    variant=""
                    className="btn custom-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        animation="grow"
                        aria-hidden="true"
                      />
                    ) : (
                      <>Submit</>
                    )}
                  </Button>
                      </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    </div>
  );
}

//export default Stuvita;
function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stuvita);
