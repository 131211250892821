import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import HomeDesignTabPanel from "../home/HomeDesignTabPanel";
import Newsletter from "../../component/Newsletter/Newsletter";
import * as hotProductActions from "../../actions/hotProductActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import GuideModal from "../home/guide";
import MeetingModal from "../home/meeting";
const prepareHtml = (description) => {
  if (description) {
    return { __html: description || "" };
  }
  return { __html: "" };
};
const Work = (props) => {
  const {actionStaus, userInfo, pageList } = props;
  const [modalShow, setModalShow] = useState(false);
  const [meetingmodalShow, setMeetingModalShow] = useState(false);
  const [guide, setGuide] = useState(false);

  useEffect(() => {
    if (actionStaus) {
      setMeetingModalShow(false);
    }
  }, [actionStaus]);

  function handleClick(guide) {
    setGuide(guide);
    setModalShow(true);
  }
  function meetinghandleClick() {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      actions.loadActionStatus();
      setMeetingModalShow(true);
    }
  }
  const { title, actions, homeDesigen, setting } = props;
  const [video, setVideo] = useState(
    "https://www.youtube.com/watch?v=t6MxsTLtIPw"
  );

  useEffect(() => {
    const fetchData = () => {
      actions.loadHomeDesign();
    };
    fetchData();
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (setting.setting) {
      setVideo(setting.setting.video_link_en);
    }
  }, [title, actions, setting]);

  // const handleClick = (url) => {
  //   setVideo(url);
  // };


  return (
    <section className="about_sec">
      <ReactPlayer
        url={video}
        width="100%"
        height="640px"
        className="react-player"
      />
      {/* {setting.setting && (
        <ul className="language-type text-center">
          <li
            className={video === setting.setting.video_link_en ? "active" : ""}
            onClick={(e) => {
              handleClick(setting.setting.video_link_en);
            }}
          >
            <span>English - EN</span>
          </li>
          <li
            className={video === setting.setting.video_link_hi ? "active" : ""}
            onClick={(e) => {
              handleClick(setting.setting.video_link_hi);
            }}
          >
            <span>हिंदी - HI</span>
          </li>
        </ul>
      )} */}
      <Container className='hiw-container'>
        <HomeDesignTabPanel homeDesigen={homeDesigen} />
        <div className="space_budget_btns mb-5 text-center">
          <Link
            className="btn m-0"
            to="#!"
            title="Request virtual meeting"
            onClick={() => meetinghandleClick()}
          >
            Request virtual meeting with designer
          </Link>
        </div>
      </Container>
      <Newsletter />
      {modalShow && (
        <GuideModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          guide={guide}
        />
      )}
      {meetingmodalShow && (
        <MeetingModal
          show={meetingmodalShow}
          onHide={() => setMeetingModalShow(false)}
        />
      )}
    </section>
    
  );
};
function mapStateToProps(state) {
  return {
    homeDesigen: state.homeDesigen,
    setting: state.setting,
    actionStaus: state.actionStaus,
    userInfo: state.userInfo,
    pageList: state.pageList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(hotProductActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Work);
