import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const GuardedRoute = (props) => {
  const { component: Component, title, isAuth } = props;
  return (
    <Route
      render={(props) =>
        isAuth === true ? (
          <Component {...props} title={title} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    isAuth: state.isAuth,
  };
}

export default connect(mapStateToProps)(GuardedRoute);
