import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function trandingProductReducers(state = initialState.trandingProductData, action) {
  switch (action.type) {
    case types.LOAD_TRANDING_PRODUCT_DATA_SUCCESS:
      return action.trandingProductData
    default:
      return state
  }
}
