import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { setToaster, showToaster } from "../utils/helpers";
import * as userActions from "./userActions";

export function loadPackageDataSuccess(packageData) {
  return { type: types.LOAD_PACKAGE_DATA_SUCCESS, packageData };
}

export function loadPackageData(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.PACKAGE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadPackageDataSuccess(response.data.data));
            dispatch(toggleNetworkRequestStatus(false));
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadPackageRoomClassDataSuccess(packageRoomClassData) {
  return { type: types.LOAD_PACKAGE_ROOM_CLASS_DATA_SUCCESS, packageRoomClassData };
}

export function loadPackageRoomClassData() {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.GET_PACKAGE_ROOM_CLASS)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadPackageRoomClassDataSuccess(response.data.data));
            dispatch(toggleNetworkRequestStatus(false));
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

