import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { setToaster, showToaster } from "../utils/helpers";
import * as userActions from "./userActions";

export function loadproductDataSuccess(productData) {
  return { type: types.LOAD_PRODUCT_DATA_SUCCESS, productData };
}

export function loadproductDetailsSuccess(detailsData) {
  return { type: types.LOAD_PRODUCT_DETAILS_DATA_SUCCESS, detailsData };
}

export function loadpaginationDataSuccess(pagination) {
  return { type: types.LOAD_PAGINATION_DATA_SUCCESS, pagination };
}

export function loadadvertisingDataSuccess(advertisingData) {
  return { type: types.LOAD_ADVERTISING_DATA_SUCCESS, advertisingData };
}

export function loadProduct(isProduct) {
  return { type: types.PRODUCT_REQUEST_STATUS, isProduct };
}
export function loadDataWishListSuccess(isWishList) {
  return { type: types.LOAD_WISH_LIST_DATA_SUCCESS, isWishList };
}

export function loadRecentlyProductSuccess(recentlyView) {
  return { type: types.LOAD_RECENT_VIEW_DATA_SUCCESS, recentlyView };
}

export function loadProductStock(isStock) {
  return { type: types.STOCK_REQUEST_STATUS, isStock };
}

export function loadProductSilderImage(productSliderImage) {
  return {
    type: types.LOAD_PRODUCT_SILDER_IMAGE_DATA_SUCCESS,
    productSliderImage,
  };
}

export function loadProductData(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.PRODUCT, params)
      .then((response) => {
        dispatch(loadProduct(false));
        dispatch(toggleNetworkRequestStatus(false));
        if (response.data.status === 200) {
          dispatch(loadproductDataSuccess({}));
          dispatch(loadproductDataSuccess(response.data.data.product));
          dispatch(loadpaginationDataSuccess(response.data.data.pagination));
          dispatch(loadadvertisingDataSuccess(response.data.data.advertising));
          if (response.data.data.product.length === 0) {
            dispatch(loadProduct(true));
          }
        } else {
          dispatch(loadProduct(true));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadproductFilterDataSuccess(productFilterData) {
  return { type: types.LOAD_PRODUCT_FILTER_DATA_SUCCESS, productFilterData };
}

export function loadProductAttributeDataSuccess(productAttribute) {
  return { type: types.PRODUCT_ATTRIBUTE_DATA_SUCCESS, productAttribute };
}
export function loadProductNextAttributeDataSuccess(productNextAttribute) {
  return {
    type: types.PRODUCT_NEXT_ATTRIBUTE_DATA_SUCCESS,
    productNextAttribute,
  };
}
export function loadProductNextAttributeNullDataSuccess(productNextAttribute) {
  return {
    type: types.PRODUCT_NEXT_ATTRIBUTE_DATA_NULL_SUCCESS,
    productNextAttribute,
  };
}
export function loadProductReviewData(productReview) {
  return {
    type: types.LOAD_PRODUCT_REVIEW_DATA_SUCCESS,
    productReview,
  };
}

export function isCartDataSuccess(isCart) {
  return {
    type: types.PRODUCT_CART_DATA_SUCCESS,
    isCart,
  };
}

export function loadFooterProductData(footerProduct) {
  return {
    type: types.LOAD_FOOTER_PRODUCT,
    footerProduct,
  };
}

export function loadProductReviewMoreData(productReviewMore) {
  return {
    type: types.LOAD_PRODUCT_REVIEW_MORE_DATA_SUCCESS,
    productReviewMore,
  };
}

export function loadproductFilterData(supplier_id = '') {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(`${API.FILTERS}?supplierId=${supplier_id}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(loadproductFilterDataSuccess(response.data.data));
          dispatch(toggleNetworkRequestStatus(false));
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadproductDetailsData(params) {
  return async function (dispatch) {
    dispatch(loadProductSilderImage([]));
    dispatch(toggleNetworkRequestStatus(true));
    dispatch(isCartDataSuccess(false));
    await agent
      .post(API.PRODUCT_DETAILS, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          dispatch(loadProductNextAttributeNullDataSuccess(null));
          if (response.data.status === 200) {
            dispatch(loadDataWishListSuccess(response.data.data.isWishlist));
            dispatch(loadproductDetailsSuccess(response.data.data));
            dispatch(loadProductStock(response.data.data.stock));
            dispatch(loadProductSilderImage(response.data.data.image));
            dispatch(
              loadRecentlyProductSuccess(response.data.data.recentiyView)
            );
            dispatch(loadProductReviewData(response.data.data.review));
            if (response.data.data.type === 2) {
              dispatch(
                loadProductAttributeDataSuccess(response.data.data.attribute)
              );
            } else {
              dispatch(isCartDataSuccess(true));
            }
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadProductAttributeData(params) {
  return async function (dispatch) {
    await agent
      .post(API.PRODUCT_ATTRIBUTE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(isCartDataSuccess(false));
          if (response.data.status === 200) {
            if (params.action) {
              if (params.type === "1") {
                dispatch(loadProductNextAttributeNullDataSuccess(null));
              }
              dispatch(
                loadProductNextAttributeDataSuccess(
                  response.data.data.attribute
                )
              );
              if (!response.data.data.attribute.isChild) {
                dispatch(
                  loadProductStock(response.data.data.attribute.value[0].stock)
                );
              }
            } else {
              dispatch(
                loadProductNextAttributeDataSuccess(
                  response.data.data.attribute
                )
              );
            }
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(isCartDataSuccess(false));
        throw error;
      });
  };
}

export const loadRecentiyView = (params) => async (dispatch) => {
  try {
    const response = await agent.post(API.RECENTIY_VIEW_PRODUCT, params);
    if (response.status === 200 || response.status === 201) {
      dispatch(loadRecentlyProductSuccess(response.data.data));
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    throw error;
  }
};

export const loadReviewLikeClick = (params) => async (dispatch) => {
  try {
    await agent.post(API.PRODUCT_REVIEW_LIKE, params);
  } catch (error) {
    throw error;
  }
};

export const loadFooerProduct = () => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.LOAD_FOOTER_PRODUCT);
    if (response.status === 200 || response.status === 201) {
      dispatch(toggleNetworkRequestStatus(false));
      dispatch(loadFooterProductData(response.data.data));
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const addProductReview = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.ADD_PRODUCT_REVIEW, params);
    if (response.status === 200 || response.status === 201) {
      dispatch(toggleNetworkRequestStatus(false));
      setToaster(response.data.message);
    }
    dispatch(loadproductDetailsData(params));
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const loadProductReview = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.LOAD_PRODUCT_REVIEW, params);
    if (response.status === 200 || response.status === 201) {
      dispatch(toggleNetworkRequestStatus(false));
      dispatch(loadProductReviewMoreData(response.data.data));
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const addSellerReview = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.ADD_SELLER_REVIEW, params);
    if (response.status === 200 || response.status === 201) {
      dispatch(toggleNetworkRequestStatus(false));
      setToaster(response.data.message);
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};
