import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import Newsletter from "../../component/Newsletter/Newsletter";
import "./AppLanding.css";

var download_slide = {
  dots: true,
  arrows: false,
  infinite: true,
  fade: false,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  autoplay: true,
};

const App = (props) => {
  const { title } = props;

  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title]);

  const handleClick = (type) => {
    if (type === "APP_STORE") {
      window.open(
        "https://apps.apple.com/in/app/designiche/id1603056348",
        "_blank"
      );
    }
    if (type === "GOOGLE_STORE") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.octal.designiche",
        "_blank"
      );
    }
  };

  return (
    <>
      <section
        className="app_features_sec py-4 py-md-5"
        style={{
          backgroundImage: "url('/../../assets/img/app_landing_banner.jpg')",
        }}
      >
        <Container>
          <Row className="banner_content_block align-items-center">
            <Col xs={12} md={7} className="d-flex flex-column">
              <div className="w-100 banner_data py-3 py-lg-5">
                <h1 className="banner_title mb-3 mb-md-4">Designiche</h1>
                <p className="banner_info_text mb-4 mb-md-5">
                  Designiche sells ideas, concepts and aesthetics, not just
                  furniture. It is curating an offering from India, for India
                  and beyond, at global standards. An offering for a quietly
                  confident India, which is worthy of nothing but the best.
                </p>
                <ul className="download_app">
                  <li className="download_app_title mb-2">
                    <span className="pb-2">Download app now</span>
                  </li>
                  <li className="d-flex align-items-center pt-3">
                    <span
                      className="mr-3 download_button"
                      onClick={() => handleClick("APP_STORE")}
                    >
                      <img
                        src="/../../assets/img/app_store_img.png"
                        alt="app_store_img"
                      />
                    </span>
                    <span
                      className="download_button"
                      onClick={() => handleClick("GOOGLE_STORE")}
                    >
                      <img
                        src="/../../assets/img/google_play_img.png"
                        alt="google_play_img"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={5} className="text-center text-md-right">
              <div className="banner_mobile pt-4 pt-md-0">
                <img src="/../../assets/img/banner_mobile.png" alt="mobile" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="app_how_work_sec py-4 py-md-5">
        <h2 className="app_section_title mb-3 mb-md-4 text-center">
          How App Works
        </h2>
        <div
          className="app_how_work_block"
          style={{
            backgroundImage: "url('/../../assets/img/app_how_hork_bg.jpg')",
          }}
        >
          <Container className="py-4 py-md-4">
            <Row className="how_work_inner justify-content-center">
              <Col xs={12} md={6} lg={4}>
                <div className="app_features_content_panel text-left text-md-center p-0 p-md-4">
                  <div className="app_features_img">
                    <img
                      src="/../../assets/img/app_features1.png"
                      alt="Choose Role"
                    />
                  </div>
                  <div className="pl-4 pl-md-0">
                    <div className="app_features_panel_title">
                      Download Our App
                    </div>
                    <div className="app_features_panel_para">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="pt-5 pt-md-0">
                <div className="app_features_content_panel text-left text-md-center p-0 p-md-4">
                  <div className="app_features_img">
                    <img
                      src="/../../assets/img/app_features2.png"
                      alt="Choose Role"
                    />
                  </div>
                  <div className="pl-4 pl-md-0">
                    <div className="app_features_panel_title">
                      Create Your Account
                    </div>
                    <div className="app_features_panel_para">
                      It is a long established fact that a reader will be
                      distracted by the readatble content of a page when looking
                      at its layout
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="pt-5 pt-md-0">
                <div className="app_features_content_panel text-left text-md-center p-0 p-md-4">
                  <div className="app_features_img">
                    <img
                      src="/../../assets/img/app_features3.png"
                      alt="Choose Role"
                    />
                  </div>
                  <div className="pl-4 pl-md-0">
                    <div className="app_features_panel_title">
                      Enjoy Shopping
                    </div>
                    <div className="app_features_panel_para">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section
        className="features_overview_sec"
        style={{
          backgroundImage: "url('/../../assets/img/features_overview.jpg')",
        }}
      >
        <Container>
          <h2 className="app_section_title mb-3 mb-md-4 text-center">
            App Features
          </h2>
          <Row className="align-items-center">
            <Col xs={12} md={6} lg={7}>
              <div className="app_features_banner_img">
                <img src="/../../assets/img/features.jpg" alt="AppFeatures" />
              </div>
            </Col>
            <Col xs={12} md={6} lg={5}>
              <ul className="features_list mt-0 mt-md-5">
                <li className="py-3">
                  <div className="features_list_title">Attractive App Ui</div>
                  <div className="features_list_text pt-2">
                    It is a long established fact that a reader will be
                    distracted by the readatble content of a page when looking
                    at its layout.
                  </div>
                </li>
                <li className="second py-3">
                  <div className="features_list_title">
                    Listed New Products For Every Category
                  </div>
                  <div className="features_list_text pt-2">
                    It is a long established fact that a reader will be
                    distracted by the readatble content of a page when looking
                    at its layout.
                  </div>
                </li>
                <li className="third py-3">
                  <div className="features_list_title">Popular ratings</div>
                  <div className="features_list_text pt-2">
                    It is a long established fact that a reader will be
                    distracted by the readatble content of a page when looking
                    at its layout.
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="pt-5">
            <Container>
              <Row>
                <Col xs={12} md={6} lg={5}>
                  <h2 className="app_section_title mb-3 mb-md-4">
                    App Overview
                  </h2>
                  <p className="mb-5">
                    We believe that luxury is more than price and accessibility;
                    it’s about how the design resonates with you. Home design is
                    an art, and your home is a blank canvas. We help you
                    integrate your ideas with your home, helping you create the
                    ambience you want to enjoy. Our ethos is reflected in our
                    commitment to offering exceptional and distinct flavors of
                    design.
                  </p>

                  <p className="mb-0">
                    ed to visualise and discover your own aesthetic. The freedom
                    to realize your own perception of design. From avant-garde
                    furniture designers to well-established brands and furniture
                    stores, Designiche curates an offering of quality and
                    variety: We work hard to find and source reputable, trusted
                    and a variety of partners to suit our customers’ palette.
                    Our partners are brought on board by invitation or
                    recommendation only.
                  </p>
                </Col>
                <Col xs={12} md={6} lg={7}>
                  <div className="app_overview_img mt-5 mt-md-0">
                    <img
                      src="/../../assets/img/app_overview.png"
                      alt="AppOverview"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </section>
      <section
        className="app_about_sec py-4 py-md-5"
        style={{ backgroundImage: "url('/../../assets/img/app_about_bg.jpg')" }}
      >
        <Container className="py-3 py-md-4">
          <h2 className="app_section_title text-center mb-3 mb-md-4">
            About Us
          </h2>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <div className="app_about_screen">
                <img
                  src="/../../assets/img/app_about_screen.png"
                  alt="AboutUs"
                />
                <div className="download_app_slider_sec">
                  <Slider {...download_slide}>
                    <div>
                      <img
                        src="assets/img/download-app_slide1.jpg"
                        alt="download-app"
                      />
                    </div>
                    <div>
                      <img
                        src="assets/img/download-app_slide2.jpg"
                        alt="download-app"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="w-100 banner_data py-3 py-lg-5">
                <p className="banner_info_text mb-4 mb-md-5">
                  We believe that luxury is more than price and accessibility;
                  it’s about how the design resonates with you. Home design is
                  an art, and your home is a blank canvas. We help you integrate
                  your ideas with your home, helping you create the ambience you
                  want to enjoy. Our ethos is reflected in our commitment to
                  offering exceptional and distinct flavors of design.
                </p>
                <p className="banner_info_text mb-4 mb-md-5">
                  ed to visualise and discover your own aesthetic. The freedom
                  to realize your own perception of design. From avant-garde
                  furniture designers to well-established brands and furniture
                  stores, Designiche curates an offering of quality and variety:
                  We work hard to find and source reputable, trusted and a
                  variety of partners to suit our customers’ palette. Our
                  partners are brought on board by invitation or recommendation
                  only.
                </p>
                <p className="banner_info_text mb-4 mb-md-5">
                  Preneet Malhotra, our Founder and CEO, has led some of the
                  biggest brands in the world, and brings an exceptional ability
                  to maintain both quality and value for the discerning Indian
                  consumers. With a personal eye for design and quest for
                  quality, Designiche was born out of her experience and
                  frustration when she managed three personal building projects.
                </p>
                <ul className="download_app">
                  <li className="download_app_title mb-2">
                    <span className="pb-2">Download app now</span>
                  </li>
                  <li className="d-flex align-items-center pt-3">
                    <span
                      className="mr-3 download_button"
                      onClick={() => handleClick("APP_STORE")}
                    >
                      <img
                        src="/../../assets/img/app_store_img.png"
                        alt="app_store_img"
                      />
                    </span>
                    <span
                      className="download_button"
                      onClick={() => handleClick("GOOGLE_STORE")}
                    >
                      <img
                        src="/../../assets/img/google_play_img.png"
                        alt="google_play_img"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

export default App;
