import React from "react";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import "./LoginSign.css";
import { useForm } from "react-hook-form";

const CouponModal = (props) => {
  const {
    actions,
    couponModel,
    total,
    discount,
    couponCode,
    handleclickCouponCode,
    handleclickCouponDetails,
  } = props;
  const { register, errors, handleSubmit, setValue } = useForm();

  const handleclickModel = (action) => {
    actions.handleclickCouponModel(action);
  };

  const onSubmit = (data) => {
    data.amount = total;
    data.discount = discount;
    handleclickCouponDetails(data);
    actions.checkCouponCode(data, handleclickCouponCode);
  };

  const onClickApplyCoupon = (item) => {
    setValue("coupon_code", item.code);
    setValue("percentage", item.percentage);
  };

  return (
    <>
      <Modal
        show={couponModel}
        size="lg"
        onHide={() => handleclickModel(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Apply Coupon Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <FormControl type="hidden" name="percentage" ref={register()} />
              <FormControl
                type="text"
                className={errors.coupon_code ? "formError" : ""}
                name="coupon_code"
                placeholder="Have a Coupon Code ?"
                aria-invalid={errors.coupon_code ? "true" : "false"}
                ref={register({ required: true, minLength: 6 })}
              />
              {errors.coupon_code && errors.coupon_code.type === "required" && (
                <span role="alert" className="alertError">
                  Please enter coupon code.
                </span>
              )}
              {errors.coupon_code && errors.coupon_code.type === "minLength" && (
                <span role="alert" className="alertError">
                  Coupon code should contain atleast 6 characters.
                </span>
              )}
            </Form.Group>
            <div>
              <ul className="coupon_code_list">
                {couponCode &&
                  couponCode.length > 0 &&
                  couponCode.map((item, key) => (
                    <li key={key} onClick={() => onClickApplyCoupon(item)}>
                      <span className="coupon_code_percent">
                        {item.percentage}%
                      </span>
                      <div className="coupon_code_info">
                        <span className="coupon_code_title">
                          {item.code}
                          <small>{item.title}</small>
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <Button variant="" type="submit">
              Apply
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    couponModel: state.couponModel,
    couponCode: state.couponCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(cartActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponModal);
