import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function sellerOrderDataReducer(
  state = initialState.sellerOrderData,
  action
) {
  switch (action.type) {
    case types.LOAD_SELLER_ORDER_DATA_SUCCESS:
      return action.sellerOrderData;
    default:
      return state;
  }
}
