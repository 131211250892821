import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";

export function loadproductSuccess(headerProduct) {
  return {
    type: types.LOADED_HEADER_PRODUCT_SUCCESS,
    headerProduct,
  };
}

export const searchProduct = (params) => async (dispatch) => {
  try {
    const response = await agent.post(API.CATALOGUE_SEARCH, params);
    dispatch(loadproductSuccess(response.data.data));
  } catch (error) {
    throw error;
  }
};
