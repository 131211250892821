import React, { useEffect } from "react";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { checkConform, showToaster } from "../../utils/helpers";
import "./myroom.css";


const Index = (props) => {
 

  const { title, actions, setting } = props;
  const { register, errors, handleSubmit } = useForm();

  const [theme, setTheme] = React.useState("Modern");
  const [themeName, setThemeName] = React.useState("Modern");
  const [room, setRoom] = React.useState("Living Room");
  const [restoredImage, setRestoredImage] =  React.useState(null);
  const [error, setError] =  React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [originalPhoto, setOriginalPhoto] = React.useState(null);
  const [restoredLoaded, setRestoredLoaded] = React.useState(false);
  const [sideBySide, setSideBySide] = React.useState(false);
  const [photoName, setPhotoName] = React.useState(null);
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [actions, title]);
 // drag drop file component
 const MyUploader = () => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);
  
  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
      onChangePicture(e.dataTransfer.files[0]);
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      console.log(e.target.files);
      
      onChangePicture(e.target.files[0]);
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  const  onChangePicture = async (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();

      var size = Math.round(selectedFile.size / 1048576);
      if (size > 5) {
        showToaster("File size cannot exceed more than 5MB");
        return false;
      }
      if (!selectedFile.name.match(/\.(jpg|jpeg|png)$/)) {
        showToaster("Please select valid image.");
        return false;
      }
      formData.append("image", selectedFile, selectedFile.name);

      console.log(selectedFile);
      //setOriginalPhoto(selectedFile.fileUrl.replace("raw", "thumbnail"));

      //formData.append("image", selectedFile, selectedFile.name);
      //formData.append("image", selectedFile, selectedFile.name);
      //actions.updateProfileImage(formData);
      
      let fileUrl = await window.uploader(selectedFile);
      console.log(fileUrl);
      if (fileUrl.length !== 0) {
        //setPhotoName(file[0].originalFile.originalFileName);
        setOriginalPhoto(fileUrl);
        generatePhoto(fileUrl);
      }
     

    }
  };
  async function generatePhoto(fileUrl) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setLoading(true);
    const res = await fetch("https://room-gpt-ten-puce.vercel.app/api/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version,Authorization",
      },
      body: JSON.stringify({ imageUrl: fileUrl, theme, room }),
    });

    let response = await res.json();
    if (res.status !== 200) {
      setError(response);
    } else {
      setRestoredImage(response.generated);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }

  const onSubmit = (data) => {
    actions.sendContactUs(data);
  };  

  const onthemeChange = (elem) => {
    setTheme(elem.value)
    setThemeName(elem.options[elem.selectedIndex].text)
  };   
  return (
    <form id="form-fi
    le-upload" onDragEnter={handleDrag}  onSubmit={(e) => e.preventDefault()}>
{!originalPhoto && !restoredImage && (      
  <section className="uploader">
       <div>

<label>

Choose your room theme.
</label>
<br/>
<select className="form-control" onChange={(e) => onthemeChange(e.target)}>

<option value="Modern">Modern</option>
<option value="Professional">Professional</option>
<option value="Tropical">Tropical</option>
<option value="Vintage">Vintage</option>
<option value="Minimalist">Minimalistic</option>
<option value="Industrial">Industrial</option>
<option value="Neoclassic">Neoclassic</option>
</select>
</div>
<div>

<label>

Choose your room type.
</label>
<br/>
<select className="form-control" onChange={(e) => setRoom(e.target.value)}>

<option value="Living Room">Living Room</option>

<option value="Dining Room">Dining Room</option>

<option value="Bedroom">Bedroom</option>
<option value="Bathroom">Bathroom</option>
<option value="Office">Office</option>
<option value="Kitchen">Kitchen</option>
<option value="Basement">Basement</option>
<option value="Outdoor Patio">Outdoor Patio</option>
<option value="Gaming Room">Gaming Room</option>
</select>


</div>       
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
        <button className="upload-button form-control btn" onClick={onButtonClick} >Upload an Image</button>          
          <p><br></br>...or drag and drop an image.</p>

        </div> 
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </section>
      )} 


      {originalPhoto && !restoredImage &&  (
                      <Container>
                      <Row>
                      <Col md={6} className="text-center">

<h3 className="mb-1 font-medium  text-center">Original Room</h3>
              
      <img
          alt="original photo"
          src={originalPhoto}
          className="rounded-2xl relative w-full h-96  text-center"
          width={475}
          height={475}
        />
    </Col>
    <Col md={6} className="text-center">
        <h3 className="mb-1 font-medium  text-center">Generated Room</h3>
<button
                  disabled
                  className="btn rounded-2xl bg-blue-500 rounded-full text-white font-medium w-40"
                >
                  <span className="">
                  <span className="loading2">
      <span style={{ backgroundColor: "#000" }} />
      <span style={{ backgroundColor: "#000" }} />
      <span style={{ backgroundColor: "#000" }} />
    </span>
                  </span>
                </button>
    </Col>
    </Row>
    </Container>
  )}       
  {restoredImage && (
    <h2 className="text-center pb-5">
      Here's your remodeled <b>{room.toLowerCase()}</b> in the{" "}
      <b>{themeName.toLowerCase()}</b> theme!{" "}
    </h2>
  )}  
  {restoredImage && originalPhoto && (
        <Container>
        <Row>
          <Col md={6} className="text-center">

        <h3 className="mb-1 font-medium  text-center">Original Room</h3>
        <img
          alt="original photo"
          src={originalPhoto}
          className="rounded-2xl relative w-full h-96  text-center"
          width={475}
          height={475}
        />
       </Col>
        <Col md={6} className="text-center">
        <h3 className="mb-1 font-medium  text-center">Generated Room</h3>
        <a href={restoredImage} target="_blank" rel="noreferrer">
          <img
            alt="restored photo"
            src={restoredImage}
            className="rounded-2xl relative sm:mt-0 mt-2 cursor-zoom-in w-full h-96"
            width={475}
            height={475}
          />
        </a>
        </Col>
        <Col md={12} className="text-center">
        <div className="flex space-x-2 justify-center pt-5">
                {originalPhoto && !loading && !error && (
                  <button
                    onClick={() => {
                      setOriginalPhoto(null);
                      setRestoredImage(null);
                      setRestoredLoaded(false);
                      setError(null);
                    }}
                    className="rounded-2xl btn bg-blue-500 rounded-full text-white font-medium py-4 mt-8 hover:bg-blue-500/80 transition"
                  >
                    Generate New Room
                  </button>
                )}
              </div>
              </Col>
    </Row>
    </Container>
  )}  
    </form>

  );

};


  return (
    <section className="contact_sec  pt-5">

   
      <Container>
      <h1 className="text-center pb-5">
          Generate your <span className="text-blue-600">dream</span> room
        </h1>        
      <MyUploader/>      
      </Container>
    </section>
  );
};

export default Index;
