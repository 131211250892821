import React from "react";
import { Modal, Form, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import "./LoginSign.css";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";

const PasswordModal = (props) => {
  const { actions, forgotPassword } = props;
  const { register, errors, handleSubmit } = useForm();

  const handleclickOtp = (action) => {
    actions.passwordDataSuccess(action);
  };

  const handleclickLogin = (action) => {
    actions.userLoginDataSuccess(action);
    actions.passwordDataSuccess(false);
  };

  const onSubmit = (data) => {
    actions.forgotPasswordData(data);
  };

  return (
    <>
      <Modal
        show={forgotPassword}
        size="lg"
        onHide={() => handleclickOtp(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Trouble Logging In?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <p>
            Enter your email or mobile number and we'll send you a link to get
            back into your account.
          </p>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                type="text"
                className={errors.username ? "formError" : ""}
                name="username"
                placeholder="Email Address / Mobile Number"
                aria-invalid={errors.username ? "true" : "false"}
                ref={register({
                  required: true,
                })}
              />
              {errors.username && errors.username.type === "required" && (
                <span role="alert">
                  Please enter your email address / mobile number.
                </span>
              )}
            </Form.Group>
            <CustomButton title={"Submit"} />
            <div className="no_accout text-center">
              <p>
                Already have an account?{" "}
                <span className="link" onClick={() => handleclickLogin(true)}>
                  Login
                </span>
              </p>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    forgotPassword: state.forgotPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordModal);
