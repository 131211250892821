import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { NavLink,Link } from "react-router-dom"; 
import "./HomeTabPanel.css"; 
import {Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';


import Icon_accessories from './images/cate-icons/icon-accessories.png';
import Icon_sofa from './images/cate-icons/icon-sofa.png';
import Icon_chair from './images/cate-icons/icon-chair.png';
import Icon_ottomans from './images/cate-icons/icon-ottomans.png';
import Icon_table from './images/cate-icons/icon-table.png';
import Icon_bed from './images/cate-icons/icon-bed.png';
import Icon_cabinet from './images/cate-icons/icon-cabinet.png';
import Icon_lighting from './images/cate-icons/icon-lighting.png';
import Icon_carpet from './images/cate-icons/icon-carpet.png';
import Icon_art from './images/cate-icons/icon-art.png';
import Icon_kid from './images/cate-icons/icon-kid.png';
import Icon_outdoor from './images/cate-icons/icon-outdoor.png';

const ByCategoryStatic = (props) => {
    const [categoryArr, setCategoryArr] = useState([]);
	
	const [sofasTotalProducts, setSofasTotalProducts] = useState('');
	const [sofasTotalSuppliers, setSofasTotalSuppliers] = useState('');
	
	const [outdoorTotalProducts, setOutdoorTotalProducts] = useState('');
	const [outdoorTotalSuppliers, setOutdoorTotalSuppliers] = useState('');

	const [kidsTotalProducts, setKidsTotalProducts] = useState('');
	const [kidsTotalSuppliers, setKidsTotalSuppliers] = useState('');

	const [artTotalProducts, setArtTotalProducts] = useState('');
	const [artTotalSuppliers, setArtTotalSuppliers] = useState('');

	const [carpetsTotalProducts, setCarpetsTotalProducts] = useState('');
	const [carpetsTotalSuppliers, setCarpetsTotalSuppliers] = useState('');

	const [lightingTotalProducts, setLightingTotalProducts] = useState('');
	const [lightingTotalSuppliers, setLightingTotalSuppliers] = useState('');

	const [storageTotalProducts, setStorageTotalProducts] = useState('');
	const [storageTotalSuppliers, setStorageTotalSuppliers] = useState('');

	const [bedsTotalProducts, setBedsTotalProducts] = useState('');
	const [bedsTotalSuppliers, setBedsTotalSuppliers] = useState('');

	const [tablesTotalProducts, setTablesTotalProducts] = useState('');
	const [tablesTotalSuppliers, setTablesTotalSuppliers] = useState('');

	const [ottomansTotalProducts, setOttomansTotalProducts] = useState('');
	const [ottomansTotalSuppliers, setOttomansTotalSuppliers] = useState('');

	const [chairsTotalProducts, setChairsTotalProducts] = useState('');
	const [chairsTotalSuppliers, setChairsTotalSuppliers] = useState('');

	const [accessoriesTotalProducts, setAccessoriesTotalProducts] = useState('');
	const [accessoriesTotalSuppliers, setAccessoriesTotalSuppliers] = useState('');
    
	  useEffect(() => {
		  setTimeout(() => {  
			const localStorageFooterCategory = localStorage.getItem("localStorageFooterCategory");
			// Convert the string back to an array
			const storedCategoryArray = JSON.parse(localStorageFooterCategory);  
			console.log("=================By category :  start ====================");
			console.log(storedCategoryArray);
			console.log("=================By category :  end ===================="); 
			// setCategoryArr(storedCategoryArray); 
				storedCategoryArray.forEach(category => { 
				  if (category.title === 'Sofas') {
					  setSofasTotalProducts(category.total_products);
					  setSofasTotalSuppliers(category.total_suppliers);
				  }
				  
				   if (category.title === 'Outdoor') {
						setOutdoorTotalProducts(category.total_products);
						setOutdoorTotalSuppliers(category.total_suppliers);
						
				   }
					if (category.title === 'Kids') {
						setKidsTotalProducts(category.total_products);
						setKidsTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Art') {
						setArtTotalProducts(category.total_products);
						setArtTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Carpets and Rugs') {
						setCarpetsTotalProducts(category.total_products);
						setCarpetsTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Lighting') {
						setLightingTotalProducts(category.total_products);
						setLightingTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Storage and Modular') {
						setStorageTotalProducts(category.total_products);
						setStorageTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Beds') {
						setBedsTotalProducts(category.total_products);
						setBedsTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Tables') {
						setTablesTotalProducts(category.total_products);
						setTablesTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Ottomans and Pouffes') {
						setOttomansTotalProducts(category.total_products);
						setOttomansTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Chairs') {
						setChairsTotalProducts(category.total_products);
						setChairsTotalSuppliers(category.total_suppliers);
					}
					if (category.title === 'Accessories') {
						setAccessoriesTotalProducts(category.total_products);
						setAccessoriesTotalSuppliers(category.total_suppliers);
					 }  
				});
		
		   }, 2000);
	   }, []); 
  
  return (
    <> 		
      <div class="categories">
        <div class="container-">
          <div class="row px-md-5 mx-md-4">
            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=57b11a9a-e977-4ad3-84c7-f8b5acfcf309&title=Accessories">
                <div class="category-box"> 
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_accessories} alt="Accessories" class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                        <h3>Accessories</h3> 
                      <div className="cate-p">   
                          {accessoriesTotalProducts > 0 ? ( <> {accessoriesTotalProducts} Products<br /> </> ) : null} 
                          {accessoriesTotalSuppliers > 0 ? (  <> {accessoriesTotalSuppliers} Suppliers </> ) : null} 
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>
            
            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=613d8bcd-8f01-481b-8d69-30b69200379c&title=Sofas">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_sofa} alt="Sofa" class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                        <h3>Sofas</h3> 
                      <div className="cate-p"> 
                        {sofasTotalProducts > 0 ? ( <> {sofasTotalProducts} Products<br /> </> ) : null} 
                        {sofasTotalSuppliers > 0 ? (  <> {sofasTotalSuppliers} Suppliers </> ) : null} 
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=86564d80-9426-4fa1-a671-248580d9c808&title=Chairs">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_chair} alt="Chairs" class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Chairs</h3>
                      <div className="cate-p">  
                        {chairsTotalProducts > 0 ? ( <> {chairsTotalProducts} Products<br /> </> ) : null} 
                        {chairsTotalSuppliers > 0 ? (  <> {chairsTotalSuppliers} Suppliers </> ) : null} 
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=24c84cb4-99b1-40c1-aafb-d32243d13e8f&title=Ottomans%20and%20Pouffes">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_ottomans} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Ottomans and Pouffes</h3>
                      <div className="cate-p">  
                        {ottomansTotalProducts > 0 ? ( <> {ottomansTotalProducts} Products<br /> </> ) : null} 
                        {ottomansTotalSuppliers > 0 ? (  <> {ottomansTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>
            
            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=7de66daf-9d52-4251-9406-e518462b4c18&title=Tables">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_table} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Tables</h3>
                      <div className="cate-p">   
                        {tablesTotalProducts > 0 ? ( <> {tablesTotalProducts} Products<br /> </> ) : null} 
                        {tablesTotalSuppliers > 0 ? (  <> {tablesTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=a71c9d12-1446-4a13-9b5f-0de09776814c&title=Beds">
                <div class="category-box">
                  <div class="category-img">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={0}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                          <img src={Icon_bed} alt="Accessories" class="img-fluid" />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div class="category-content">
                    <h3>Beds</h3>
                    <div className="cate-p">  
                      {bedsTotalProducts > 0 ? ( <> {bedsTotalProducts} Products<br /> </> ) : null} 
                      {bedsTotalSuppliers > 0 ? (  <> {bedsTotalSuppliers} Suppliers </> ) : null}
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=b036127b-26a4-43f2-81fb-7ea47a6f77ff&title=Storage%20and%20Modular">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_cabinet} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Storage and Modular</h3>
                      <div className="cate-p">  
                        {storageTotalProducts > 0 ? ( <> {storageTotalProducts} Products<br /> </> ) : null} 
                        {storageTotalSuppliers > 0 ? (  <> {storageTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=cd82e3cc-e706-4695-bc11-6b533e239a8a&title=Lighting">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_lighting} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Lighting</h3>
                      <div className="cate-p"> 
                        {lightingTotalProducts > 0 ? ( <> {lightingTotalProducts} Products<br /> </> ) : null} 
                        {lightingTotalSuppliers > 0 ? (  <> {lightingTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=3f4013fe-d4fd-492c-bb1c-13f2eedf2c0d&title=Carpets%20and%20Rugs">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_carpet} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Carpets and Rugs</h3>
                      <div className="cate-p"> 
                        {carpetsTotalProducts > 0 ? ( <> {carpetsTotalProducts} Products<br /> </> ) : null} 
                        {carpetsTotalSuppliers > 0 ? (  <> {carpetsTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=8c2f0156-d595-4b5a-ab00-ac32632cc2d0&title=Art">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_art} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                        <h3>Art</h3>
                      <div className="cate-p"> 
                        {artTotalProducts > 0 ? ( <> {artTotalProducts} Products<br /> </> ) : null} 
                        {artTotalSuppliers > 0 ? (  <> {artTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>

            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=24a1e2ca-4814-4b84-be62-9c9ada50c253&title=Kids">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_kid} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Kids</h3>
                      <div className="cate-p"> 
                        {kidsTotalProducts > 0 ? ( <> {kidsTotalProducts} Products<br /> </> ) : null} 
                        {kidsTotalSuppliers > 0 ? (  <> {kidsTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>
            
            <div class="col-lg-2 col-md-4 col-4 cate-col">
              <NavLink className="w-100" to="/marketplace?categoryId=3ff9b469-527a-4d9e-8bc8-93c0286beb41&title=Outdoor">
                <div class="category-box">
                    <div class="category-img">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                            <img src={Icon_outdoor} class="img-fluid" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div class="category-content">
                      <h3>Outdoor</h3>
                      <div className="cate-p"> 
                        {outdoorTotalProducts > 0 ? ( <> {outdoorTotalProducts} Products<br /> </> ) : null} 
                        {outdoorTotalSuppliers > 0 ? (  <> {outdoorTotalSuppliers} Suppliers </> ) : null}
                      </div>
                    </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ByCategoryStatic;
