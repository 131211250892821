import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homePageActions from "../../actions/homePageActions";

const InstagramModal = (props) => {
  const { actions } = props;
  const [image, setImage] = useState("/assets/img/insta_image.svg");
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      const selectedFile = e.target.files[0];
      formData.append("image", selectedFile, selectedFile.name);
      setImage(URL.createObjectURL(e.target.files[0]));
      actions.updateInstaImage(formData);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Real Spaces Style, Submit your Photo(s)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <div className="text-center">
          <div className="insta_image">
            <img src={image} alt="Designiche" width="30%" />
            <input type="file" onChange={onChangePicture} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}
export default connect(null, mapDispatchToProps)(InstagramModal);
