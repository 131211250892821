let config = {
  APP_URL: "https://www.designiche.com/live/",
  API_BASE_URL_LOCAL:
    "http://192.168.1.119/project/designiche/code/admin/public/api/v1/", // mocked for dev purposes
  API_BASE_URL_LIVE: "https://www.designiche.com/live/public/api/v1/", // mocked for dev purposes
  API_BASE_URL: "https://www.designiche.com/live/public/api/v1/", // mocked for dev purposes
  FACEBOOK_CLIENT_ID: "644411293467691",
  GOOGLE_CLIENT_ID:
    "79441265766-jk7t5o5i1lp8remm4df3ondcnlkc0khm.apps.googleusercontent.com",
  Geocode_KEY: "AIzaSyAk8XubcqX5qaLo2e1KznSrlunnHpHnKqU",
  LANGUAGE: "en",
  REGION: "in",
  SELLER_URL: "https://www.designiche.com/live/supplier/login",
  ROOM_URL: "https://www.designiche.com/room/index.html",
  MODEL_VIEWR_URL: "https://www.designiche.com/live/modelViewer.php?image=",
  MODEL_URL: "https://www.designiche.com/live/modelViewer.php",
  PH_CODE: "+91-",
  FIREBASE_API_KEY:
    "AAAAlH6F6cY:APA91bGjNslb6r_I1jFIDpamUZ8tF3GvoiS07w0HizaNzD70yMguPwaXs_DLQ7x8RkDhPK7YX3pf1tArmKBQ-Gmtiskg8QMRsqYaI2Ln0tY1IXCIduLs-sH3TpoArgqU1LAeDtfOPFRl",
  FB_PIXEL_ID : "606962643940536",
};

export default config;