import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadcategoryProductDataSuccess(categoryProductData) {
  return {
    type: types.LOAD_CATEGORY_PRODUCT_DATA_SUCCESS,
    categoryProductData,
  };
}

export function loadtrandingProductDataSuccess(trandingProductData) {
  return {
    type: types.LOAD_TRANDING_PRODUCT_DATA_SUCCESS,
    trandingProductData,
  };
}

export function LoadCategoryDataSuccess(footerCategory) {
  return {
    type: types.LOAD_CATEGORY_DATA,
    footerCategory,
  };
}

export function LoadSupplierDataSuccess(footerSupplier) {
  return {
    type: types.LOAD_SUPPLIER_DATA,
    footerSupplier,
  };
}

export function loadcategoryProductData(param) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.HOME_CATEGORY, param)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(
              loadcategoryProductDataSuccess(response.data.data.categoryData)
            );
            dispatch(
              loadtrandingProductDataSuccess(response.data.data.trendingData)
            );
            dispatch(toggleNetworkRequestStatus(false));
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadFooterCategory() {
  return async function (dispatch) {
    await agent
      .get(API.LOAD_FOOTER_CATEGORY)
      .then((response) => {
        if (response.status === 200) {
          dispatch(LoadCategoryDataSuccess(response.data.data));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function LoadPagesDataSuccess(pageList) {
  return {
    type: types.PAGE_LIST_DATA_SUCCESS,
    pageList,
  };
}

export function loadFooterPages() {
  return async function (dispatch) {
    await agent
      .get(API.PAGE_LIST_DATA_SUCCESS)
      .then((response) => {
        if (response.status === 200) {
          dispatch(LoadPagesDataSuccess(response.data.data));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadFooterSupplier() {
  return async function (dispatch) {
    await agent 
      .get(API.LOAD_FOOTER_SUPPLIER)
      .then((response) => {
        if (response.status === 200) {
          dispatch(LoadSupplierDataSuccess(response.data.data));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
