import React, { useState } from "react";
import { Modal, Form, FormControl } from "react-bootstrap";
import CustomButton from "../../component/Button/Button";
import { useForm } from "react-hook-form";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../actions/productActions";

const AddSellerReviewModal = (props) => {
  const { details, actions } = props;
  const { register, errors, handleSubmit } = useForm();

  const [star, setStar] = useState(0);

  const onSubmit = (data) => {
    data.sellerId = details.id;
    data.rating = star;
    actions.addSellerReview(data);
  };

  const ratingChanged = (newRating) => {
    setStar(newRating);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="login_sign_popup_sec addReviewmodal"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rating and Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <div className="text-center px-4">
          <div className="seller_brand">
            <span className="verified">
              <img src={details.star} alt="sellerLogo" />
            </span>
            <img src={details.logo} alt="sellerImage" />
          </div>
          {/* <div className="seller_title py-3">{details.name}</div> */}
        </div>
        <div className="text-center px-4 d-inline-flex">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={50}
            activeColor="#ffd700"
            classNames={"ReactStars"}
          />
        </div>

        <div className="seller_descraption px-4 py-2"></div>
        <div className="text-center">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                as="textarea"
                rows={5}
                className={errors.description ? "formError" : ""}
                name="description"
                placeholder="Write your review"
                aria-invalid={errors.description ? "true" : "false"}
                ref={register({
                  required: true,
                })}
              />
              {errors.description && errors.description.type === "required" && (
                <span role="alert">Please enter your message</span>
              )}
            </Form.Group>
            <CustomButton title={"Submit"} />
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(productActions), dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AddSellerReviewModal);
