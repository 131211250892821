import React from "react";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import "./LoginSign.css";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";

const OtpModal = (props) => {
  const { actions, userOtp, otpData } = props;
  const { register, errors, handleSubmit } = useForm();

  const handleclickLogin = (action) => {
    actions.userotpDataSuccess(action);
    actions.userRegisterDataSuccess(true);
  };
  const handleclickResend = () => {
    actions.resendOtp(otpData);
  };

  const onSubmit = (data) => {
    let params = data;
    params.number = otpData.number;
    params.type = "sign";
    actions.checkOtpData(params);
  };

  return (
    <>
      <Modal
        show={userOtp}
        size="lg"
        onHide={() => handleclickLogin(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enter the verification code.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <p>
            We've just send a text message with fresh verification code to the
            mobile number.
          </p>
          <span>{otpData.otp}</span>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                type="number"
                className={errors.otp ? "formError" : ""}
                name="otp"
                placeholder="OTP"
                aria-invalid={errors.otp ? "true" : "false"}
                ref={register({
                  required: true,
                  minLength: 4,
                  maxLength: 4,
                })}
              />
              {errors.otp && errors.otp.type === "required" && (
                <span role="alert">Please enter OTP.</span>
              )}
              {errors.otp && errors.otp.type === "minLength" && (
                <span role="alert">
                  Your OTP should contain atleast 4 digits.
                </span>
              )}
              {errors.otp && errors.otp.type === "maxLength" && (
                <span role="alert">Your OTP should not exceed 4 digits.</span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox" className="remember pt-2">
              <span className="ml-auto">
                Not received your code?{" "}
                <span className="link" onClick={() => handleclickResend()}>
                  Resend code
                </span>
              </span>
            </Form.Group>
            <CustomButton title={"Submit"} />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userOtp: state.userOtp,
    otpData: state.otpData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpModal);
