import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import NewAddressModal from "./NewAddressModal";
import config from "../../config";
const AddressModal = (props) => {
  const { addressModel, addressData, addressId } = props;
  const [newAddressModel, setAddressModel] = useState(false);
  const [address] = useState({});

  const handleclickModel = (action) => {
    props.handleclickAddressModel(action);
  };

  const handleclickAddNewModel = (action) => {
    setAddressModel(action);
    props.handleclickAddressModel(false);
  };
  const handleclickShowAddress = () => {
    props.handleclickAddressModel(true);
    setAddressModel(false);
  };

  const handleClickAddressCloseModel = () => {
    setAddressModel(false);
  };

  const handleclickSetAddress = (item) => {
    props.handleclickSetAddress(item);
  };
  return (
    <>
      <Modal
        show={addressModel}
        size="lg"
        onHide={() => handleclickModel(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delivery/ Billing Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup delivery_address_list">
          <div>
            <ul className="delivery_address_list">
              {addressData &&
                addressData.map((address, key) => (
                  <li key={key}>
                    <input
                      name="delivery_address"
                      defaultChecked={addressId === address.show}
                      type="radio"
                      onClick={() => handleclickSetAddress(address)}
                    />

                    <span className="delivery_address_radio"></span>
                    <div className="delivery_address_list_view">
                      <div className="delivered_name">
                        {address.name.charAt(0).toUpperCase() +
                          address.name.slice(1)}
                      </div>
                      {address.address}, {address.city}, {address.state} -{" "}
                      {address.pincode}
                      <span className="d-block">
                        <i className="fas fa-mobile mr-1"></i> {config.PH_CODE}
                        {address.number}
                        {address.alternate_number && (
                          <>
                            , {config.PH_CODE}
                            {address.alternate_number}
                          </>
                        )}
                      </span>
                      {address.landmark && (
                        <span>
                          <i className="fas fa-map-marker mr-1"></i>
                          {address.landmark}
                        </span>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            <span
              className="newAddress w-100"
              onClick={() => handleclickAddNewModel(true)}
            >
              Add New
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <NewAddressModal
        newAddressModel={newAddressModel}
        handleclickAddNewModel={handleclickAddNewModel}
        handleclickShowAddress={handleclickShowAddress}
        handleClickAddressCloseModel={handleClickAddressCloseModel}
        details={address}
      />
    </>
  );
};

export default AddressModal;
