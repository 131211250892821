import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import ProductReview from "./review";
const LoadMoreReviewModal = (props) => {
  const { details, review } = props;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="login_sign_popup_sec LoadMoreReviewmodal"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {details.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <div className="border-top pt-4 mt-4">
          <Row className="rating_review">
            <Col xs={12} md={12} className="review pt-4 pt-md-0">
              <ul className="pt-2 pl-2">
                {review &&
                  review.length > 0 &&
                  review.map((review, key) => (
                    <ProductReview review={review} key={key} />
                  ))}
              </ul>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadMoreReviewModal;
