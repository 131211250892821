import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as userActions from "../../actions/userActions";
import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import NewAddressModal from "../../component/popups/NewAddressModal";
import { checkConform } from "../../utils/helpers";
import config from "../../config";

import { Row, Col, Container } from "react-bootstrap";

const Address = (props) => {
  const { title, addressData, actions } = props;
  const [BrdName] = useState([{ title: "My Address", url: "/user/address" }]);
  const [newAddressModel, setAddressModel] = useState(false);
  const [address, setAddress] = useState({});

  const handleclickAddNewModel = (action) => {
    setAddress({});
    setAddressModel(action);
  };
  const handleclickEditModel = (item, action) => {
    setAddress(item);
    setAddressModel(action);
  };
  const handleclickSetAddress = (item) => {
    item.is_default = true;
    actions.updateUserAddress(item);
  };

  const handleclickDelete = (id) => {
    const afterLogoutCallback = () => {
      actions.deleteUserAddress({ id: id });
    };
    checkConform(afterLogoutCallback, "Are you sure you want to delete?");
  };

  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title]);

  return (
    <>
      <Breadcrumbs name={BrdName} />

      <Container>
        <div className="title my_account_wrapper_title py-3">{title}</div>
        <Row>
          <Col md={4} lg={3}>
            <Sidebar />
          </Col>
          <Col md={12} lg={6}>
            <div className="order_main p-4">
              <span
                className="newAddress w-100"
                onClick={() => handleclickAddNewModel(true)}
              >
                Add New
              </span>
              <ul className="delivery_address_list">
                {addressData &&
                  addressData.map((address, key) => (
                    <li key={key}>
                      <input
                        defaultChecked={address.is_default ? true : false}
                        name="delivery_address"
                        type="radio"
                        onClick={() => handleclickSetAddress(address)}
                      />
                      <span className="delivery_address_radio"></span>
                      <div className="delivery_address_list_view">
                        <span
                          className="address_edit edit_action"
                          onClick={() => handleclickEditModel(address, true)}
                        >
                          <i className="fas fa-pencil-alt mr-1"></i>
                        </span>
                        <span
                          className="address_edit"
                          onClick={() => handleclickDelete(address.id)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </span>
                        <div className="delivered_name">
                          {address.name.charAt(0).toUpperCase() +
                            address.name.slice(1)}
                        </div>
                        {address.address}, {address.city}, {address.state} -{" "}
                        {address.pincode}
                        <span className="d-block">
                          <i className="fas fa-mobile mr-1"></i>{" "}
                          {config.PH_CODE}
                          {address.number}
                          {address.alternate_number && (
                            <>
                              , {config.PH_CODE}
                              {address.alternate_number}
                            </>
                          )}
                        </span>
                        <span>
                          <i className="fas fa-map-marker mr-1"></i>
                          {address.landmark}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </Col>
        </Row>
        <NewAddressModal
          newAddressModel={newAddressModel}
          handleclickAddNewModel={handleclickAddNewModel}
          details={address}
        />
      </Container>
    </>
  );
};

Address.propTypes = {
  actions: PropTypes.object.isRequired,
  addressData: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    addressData: state.addressData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Address);
