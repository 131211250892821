import React, { useState, useEffect } from "react";
import "./Header.css";
import {
  Container,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import config from "../../config";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import LoginModal from "../popups/Login";
import OtpModal from "../popups/Otp";
import PasswordModal from "../popups/Password";
import SignUpModal from "../popups/Signup";
import GuestModal from "../popups/Guest";
import LocationModal from "../popups/Location";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import * as headerAction from "../../actions/headerAction";
import { setToaster } from "../../utils/helpers";
import Menu from "../Header/Menu";
import OutsideClickHandler from "../OutsideClickHandler";
import { useForm } from "react-hook-form";
import $ from 'jquery';

document.getElementById("OnscrollBody").onscroll = function () {
  var element = document.getElementById("navbar_scroll_fixed");
  var scroll = window.scrollY;
  if (scroll <= 20) {
    element.classList.remove("fixed-top");
  } else {
    element.classList.add("fixed-top");
  }
};

const Header = (props) => {
  const { actions, userInfo, wishCount, cartCount, headerProduct, pageList } =
    props;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [keyword, setKeyword] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { handleSubmit, register, setValue } = useForm();
  
  
  if ($(window).width() < 992) {
	   $(".navbar-light .navbar-nav .nav-link").click(function(){
		  $(".navbar-light .collapse").hide('slow');
		  $(".navbar-toggler").addClass("collapsed");
		});
	   $(".logo").click(function(){
		  $(".navbar-light .collapse").hide('slow');
		  $(".navbar-toggler").addClass("collapsed");
		});
		$(".navbar-toggler").click(function(){ 
			if ($(this).hasClass("collapsed")) {
			  $(".navbar-light .collapse").show('slow');
			}else{
				$(".navbar-light .collapse").hide('slow');
			}
		});
	}
	else {
		$(".navbar-toggler").click(function(){
		  $(".navbar-light .collapse").show('slow');
		});
	}
  
  
  const onSubmit = (data) => {
    setSearch(false);
    history.push("/marketplace?keyword=" + data.keyword);
  };

  const handleclickRegister = (action) => {
    actions.userRegisterDataSuccess(action);
	localStorage.setItem("userGuestRequestFromCartPage", false);
	
  };

  const handleclickLogin = (action) => {
    actions.userLoginDataSuccess(action);
	localStorage.setItem("userGuestRequestFromCartPage", false);
  };
  
  const handleclickGuest = (action) => {
    actions.userGuestDataSuccess(action);
  };

  const handleKeyPress = (event) => {
    setKeyword(event.target.value);
  };

  const handleOutsideClick = (e) => {
    setOpen(false);
    setSearch(false);
  };

  const handleclickSeller = () => {
    setToaster("Use the floor planner on resolution of tab or higher.");
  };

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword) {
        actions.searchProduct({ keyword: keyword });
        setSearch(true);
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [keyword, actions]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("forMobile")) {
      setShowHeader(false);
    }
    setValue("keyword", params.get("keyword"));
  }, [location, setValue]);

  const handleclickSearchItem = (item) => {
    setSearch(false);
    setValue("keyword", item.title);
    history.push("/marketplace?keyword=" + item.title);
  };

  return (
    <>
      <LocationModal />
      <LoginModal />
      <OtpModal />
      <PasswordModal />
      <SignUpModal />
	  <GuestModal />
      {showHeader ? (
        <header id="navbar_scroll_fixed" className="navigation">
          <Container>
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
              <Navbar bg="white" expand="lg" className={  (userInfo.user_id ? 'logged-nav' : '')}>
                {/* <Navbar.Brand href="/"></Navbar.Brand> */}
                <Link className="logo" to="/" title="Designiche">
                  <img src="/../assets/img/logo.svg" alt="Designiche" />
                </Link>

                <Nav.Item className="d-none">
                  <Link
                    id="room_planner_seller"
                    to="#!"
                    title="room"
                    onClick={() => handleclickSeller(true)}
                  >
                   <img src="/../assets/img/room.svg" alt="Room Planner" />
                  </Link>
                </Nav.Item>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    inline
                    className="position-relative header_search d-none- d-lg-flex ml-0 ml-lg-4 pl-lg-2"
                  >
                    <FormControl
                      ref={register({
                        required: true,
                      })}
                      type="text"
                      name={"keyword"}
                      autoComplete="off"
                      placeholder="Search by product, category"
                      onChange={(e) => handleKeyPress(e)}
                    />
                    <Button>
                      <img
                        src="/../assets/img/search.png"
                        alt="search"
                        onClick={(e) => handleKeyPress(e)}
                      />
                    </Button>

                    {search && headerProduct && (
                      <ul className="searchproductlist">
                        {headerProduct.map((item, key) => (
                          <li
                            key={key}
                            onClick={() => handleclickSearchItem(item)}
                          >
                            <div>
                              <span className="searchimg">
                                <img src={item.image} alt="search" />
                              </span>
                              <span className="searchcontent">
                                {item.title} <small>{item.category}</small>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Form>
                <Navbar.Collapse id="basic-navbar-nav">
                 

                  <Nav className="ml-auto">
                    {pageList.includes(1) && (
                      <Nav.Item>
                        <NavLink
                          className="nav-link"
                          to="/about-us"
                          title="About Us"
                          exact
                          activeClassName="active"
                        >
                          About Us
                        </NavLink>
                      </Nav.Item>
                    )}
					 
					<Nav.Item className="gpt">
					  <NavLink
						className="nav-link"
						to="/designgpt"
						title="Design GPT"
						exact
						activeClassName="active"
					  >
						Design GPT<span>AI Powered</span>
					  </NavLink>
					</Nav.Item>
                    
                    {/*{pageList.includes(7) && (
                      <Nav.Item>
                        <NavLink
                          className="nav-link"
                          to="/help"
                          title="Help"
                          exact
                          activeClassName="active"
                        >
                          Help
                        </NavLink>
                      </Nav.Item>
                    )}*/}
                    {!userInfo.user_id ? (
                      <>
                      <Nav.Item>
                          <Link
                            id="room_planner"
                            to="#!"
                            title="room"
                            className="nav-link"
                            onClick={() => handleclickLogin(true)}
                          >
                            Room Planner
                          </Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            title="Login"
                            onClick={() => handleclickLogin(true)}
                          >
                            Login
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            title="Register"
                            onClick={() => handleclickRegister(true)}
                          >
                            Register
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : (
                      <>
                        <Nav.Item className="nav-item mb-md-0 mb-3">
                          <a
                              href={`${config.ROOM_URL}?userId=${userInfo.user_id}`}
                              className="m-0 nav-link"
                              target="_blank"
                              title="room" id="room_planner"
                            >
                              Room Planner
                            </a>
                        </Nav.Item>
                        <Nav.Item className="user_profile_link">
                          <span className="user_pic">
                            <img src={userInfo.viewImage} alt="Designiche" />
                          </span>

                          <div onClick={handleClick}>
                            <span className="user_name">
                              {userInfo.first_name.charAt(0).toUpperCase() +
                                userInfo.first_name.slice(1) +
                                " " +
                                userInfo.last_name.charAt(0).toUpperCase() +
                                userInfo.last_name.slice(1)}
                              {!open ? (
                                <i className="fas fa-angle-right ml-2"></i>
                              ) : (
                                <i className="fas fa-angle-down ml-2"></i>
                              )}
                            </span>
                          </div>

                          {open && <Menu />}
                        </Nav.Item>
                      </>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </OutsideClickHandler>
          </Container>
        </header>
      ) : (
        <header id="navbar_scroll_fixed" className="navigation"></header>
      )
      }
      {
        (location.pathname.indexOf('/product/details/')==-1 && location.pathname.indexOf('/marketplace')==-1) ? localStorage.removeItem("marketplace") :''
      }
      
    </>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    wishCount: state.wishCount,
    cartCount: state.cartCount,
    headerProduct: state.headerProduct,
    pageList: state.pageList,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userActions, headerAction),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
