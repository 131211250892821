import React from "react";
import { Container } from "react-bootstrap";
import "./InnerBanner.css";
import LazyLoad from "../../component/LazyLoad/LazyLoad";

import coverImg from './images/jaipur_rug_cover.jpg';

const InnerBanner = (props) => {
  return (
    <>
      <div className="inner_banner">
	  {props.urlSupplierId =='127--' ? (
								<img src={coverImg} className="img-fluid" alt="" />
							) :( <Container className="text-center">
								  <LazyLoad
									images={props.advertisingData.image}
									key={1}
									imageClass={"advertiseProductBlur"}
								  />
								</Container>)}
      </div>
    </>
  );
};

export default InnerBanner;
