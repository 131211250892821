import React from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { NavLink,Link } from "react-router-dom"; 
import "./HomeTabPanel.css"; 
import jaipurRug from './images/jaipurrug.jpg'; 
import anradGallery from './images/anrad-gallery.png'; 
 
 
const SupplierTabStatic = (props) => {
    
  
  return (
    <> 
   <div className="">
      <div className="suppliers">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=221&brand_title=A%202%20Interiors&logo=3465dda0-179b-43fd-9a2e-03a8319d4dfe.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/4ef58cf3-6fc3-40aa-a886-78e6404fed88.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=233&brand_title=Adventure&logo=e796add6-0d6e-4a39-9f0d-b554d6200518.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/74a6a5d2-8958-431c-9a34-16f1b8ba1c1f.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=135&brand_title=Alankaram&logo=9c9fd3f2-5f2d-4d01-9d01-d3c750fd0244.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/9c9fd3f2-5f2d-4d01-9d01-d3c750fd0244.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 d-none col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/product/details/eyJpdiI6IkFlYWZCSlV3TlhpS0F2NDhpT2gxR1E9PSIsInZhbHVlIjoienhSV244SFJPU3R6NTQyNk45ejhtUT09IiwibWFjIjoiMDM2N2QxMDVlZWRmNWQ4MjI4ZGU3OTdlYmQzMGM5YWU1Mjc3YTk5YWI5ZjQ1MjBkYmFkZTU1OWI4NDNmNTc1YSJ9">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/d2fb80de-816d-434b-85fe-2fe776508b38.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=229&brand_title=Carpet%20Cellar&logo=330165d9-7956-4834-9442-587e11ad2737.png&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/330165d9-7956-4834-9442-587e11ad2737.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=291&brand_title=Designiche&logo=72894d6e-b30a-4d75-94ff-9b8376713f48.png&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/72894d6e-b30a-4d75-94ff-9b8376713f48.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=230&brand_title=Encore%20Spaces&logo=999ea7d2-b7c6-4e97-b5ec-9e9f1d36947b.png&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/999ea7d2-b7c6-4e97-b5ec-9e9f1d36947b.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=167&brand_title=Gulmohar%20Lane&logo=9191b0c8-d2d1-4365-88d9-9a2f9799a328.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/cac6cc88-7396-4b35-923e-15443a3c96a5.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=242&brand_title=House%20of%20Mahe&logo=4035177d-977b-4360-832d-7a1daccc0370.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/4035177d-977b-4360-832d-7a1daccc0370.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=232&brand_title=Jahanavi%20Creations&logo=870586d2-2d7b-465d-b1a9-bcbd31c06b9c.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/870586d2-2d7b-465d-b1a9-bcbd31c06b9c.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=127&brand_title=Jaipur%20rugs&logo=null&image_1=null&image_2=null&image_3=null&image_4=null">
				          <img src={jaipurRug} alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=216&brand_title=KARARA%20MUJASSME%20(INDIA)&logo=932aaacb-6fc0-403e-8f91-fc451a84ff70.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/932aaacb-6fc0-403e-8f91-fc451a84ff70.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=368&brand_title=Knackcraft%20Works%20Private%20Limited&logo=c24d8aaf-0a9c-4de2-9d31-8293dfc751f5.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/c24d8aaf-0a9c-4de2-9d31-8293dfc751f5.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=228&brand_title=Latitude%2028&logo=90bf3e6b-d0fa-4882-aaf3-5cdc0d7c5326.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/90bf3e6b-d0fa-4882-aaf3-5cdc0d7c5326.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
			
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="marketplace?supplierId=359&brand_title=Nakshikathaa&logo=null&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/ceecbb57-3b26-4233-85a9-58c5d4796170.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
			
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=249&brand_title=Obeetee%20Carpets&logo=461fb989-a904-4701-8a28-b315434d1d09.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/461fb989-a904-4701-8a28-b315434d1d09.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=181&brand_title=Portside%20Cafe%20(Kish%20Handicrafts%20Pvt.%20Ltd.)&logo=1c9c3abd-c135-4667-844a-23fbbdd1dd2c.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/1c9c3abd-c135-4667-844a-23fbbdd1dd2c.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=204&brand_title=Repra%20Art%20Private%20Limited&logo=2a4045de-3981-48d9-84a9-9b3ea9933f72.png&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/2a4045de-3981-48d9-84a9-9b3ea9933f72.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
			
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=360&brand_title=Ridaya%20Designs&logo=48c726dd-8732-40ab-b705-bea3872cc986.jpeg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/48c726dd-8732-40ab-b705-bea3872cc986.jpeg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
			
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=203&brand_title=Sarthak%20Sahil%20Design%20Co.&logo=22621410-64f5-406c-a221-0f2dd8027eb3.jpg&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/22621410-64f5-406c-a221-0f2dd8027eb3.jpg"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=253&brand_title=Saswata%20Design%20Studio&logo=f0e19336-80af-4a9f-8b00-d617e0c5ab4c.png&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/f0e19336-80af-4a9f-8b00-d617e0c5ab4c.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/anrad-gallery">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/b1e86840-9ba1-4175-b98a-9c60f7af3c39.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
			
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/marketplace?supplierId=364&brand_title=Vintage%20India%20by%20Southex%20Books%20%26%20Prints%20Pvt.%20Ltd.&logo=f70f2ef2-ef9c-4edb-88d5-b0e0c4aaf90b.png&image_1=null&image_2=null&image_3=null&image_4=null">
                  <img src="https://www.designiche.com/live/public/storage/seller/thumb/f70f2ef2-ef9c-4edb-88d5-b0e0c4aaf90b.png"  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            
            <div className="col-lg-2 col-md-3 col-6 sup-col">
              <div className="supplier-logo">
                <NavLink to="/">
                  <img src={anradGallery}  alt className="img-fluid"/>
                </NavLink>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
			 
    </>
  );
};

export default SupplierTabStatic;
