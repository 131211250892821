import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Accordion,
  Card,
  ProgressBar,
  Spinner,
} from "react-bootstrap";

import { useParams, useHistory } from "react-router-dom";
import * as productActions from "../../actions/productActions";
import * as userActions from "../../actions/userActions";
import * as wishlistActions from "../../actions/wishlistActions";
import * as cartActions from "../../actions/cartActions";
import * as sellerActions from "../../actions/sellerActions";
import { showToaster } from "../../utils/helpers";
import StarRatingComponent from "react-star-rating-component";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import "react-image-gallery/styles/css/image-gallery.css";
import "./SellerDetail.css";
import "./ListingDetail.css";
import Iframe from "react-iframe";
import Newsletter from "../../component/Newsletter/Newsletter";
import RecentlyViewed from "../../component/RecentlyViewed/RecentlyViewed";
import config from "../../config";
import { useForm } from "react-hook-form";
import Location from "../../component/Location/Location";
import SellerDetailModal from "../../component/popups/SellerDetail";
import ProductReview from "./review";
import AddReviewModal from "./AddReview";
import AddSellerReviewModal from "./AddSellerReview";
import LoadMoreReview from "./LoadMoreReview";
import SliderImage from "react-zoom-slider";
import List from "../../page/marketplace/List";
import * as hotProductActions from "../../actions/hotProductActions";
import MeetingModal from "../../page/home/meeting";

import {
  FacebookIcon,
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
const Details = (props) => {
  const [meetingmodalShow, setMeetingModalShow] = useState(false);
  const [guide, setGuide] = useState(false);
  const {
    title,
    actions,
    detailsData,
    userInfo,
    isWishList,
    wishCount,
    productAttribute,
    productNextAttribute,
    isCart,
    recentlyView,
    productReview,
    isSubmitting,
    isAvailability,
    isStock,
    pinCode,
    productReviewMore,
    deviceToken,
    productSliderImage,
  } = props;
  
   
  const [modalShow, setModalShow] = useState(false);
  const [reviewmodalShow, setReviewModalShow] = useState(false);
  const [addSellerReviewShow, setSellerReviewShow] = useState(false);
  const [moreReview, setMoreReview] = useState(false);
  const [sellerModalShow, setSellerModalShow] = useState(false);
  const [modalViewer, setModalViewerShow] = useState(false);
  const [price, setPrice] = useState(true);
  const [discountedPrice, setdiscountedPrice] = useState(0);
  const [actualPrice, setactualPrice] = useState(0);
  const [firstAction, setFirstAction] = useState(false);
  const [check, setCheckAvailability] = useState(isAvailability);
  const [attributeId, setAttributeId] = useState(0);
  const [onCart, setAddCart] = useState(1);
  const isToken = deviceToken;
  const { id } = useParams();
  const location = window.location.href;
  const history = useHistory();
  
  const [BrdName] = useState([
    { title: "Back to Previous Page", url: "/marketplace" },
    { title: "/ Product details", url: "" },
  ]);
  const [activeId, setActiveId] = useState("Features");

  const fetchData = useCallback(() => {
    document.title = title;
    const request = {};
    request.productId = id;
    request.type = "web";
    request.deviceToken = deviceToken;
    actions.loadproductDetailsData(request);
  }, [title, id, actions, deviceToken]);

  useEffect(() => {
    detailsData.relatedProducts = null;
    detailsData.moreProducts = null;      
    fetchData();
    window.scrollTo(0, 0);
	
	 
    setMeetingModalShow(false);
     
	
  }, [fetchData, userInfo]);

  useEffect(() => {
    document.title = detailsData.title;
  });

  const renderImage = () => {
    if (productSliderImage.length > 0) {
      return <SliderImage data={productSliderImage} showDescription={true} />;
    }
    return null;
  };
  const handleclickWishlist = (id) => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      let params = {};
      params.count = wishCount;
      params.productId = id;
      actions.addWishList(params);
    }
  };

  const handleclickSellerProfile = () => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      setSellerModalShow(true);
    }
  };

  const handleChange = (event) => {
    if (event.target.value !== "0") {
      var index = event.target.selectedIndex;
      var optionElement = event.target.childNodes[index];
      var key = event.target.value;
      var id = optionElement.getAttribute("data-id");
      var price = optionElement.getAttribute("data-price");
      var discount = optionElement.getAttribute("data-discount");
      var child = optionElement.getAttribute("data-child");
      var next = optionElement.getAttribute("data-next");
      var pre = optionElement.getAttribute("data-pre");
      var type = optionElement.getAttribute("data-type");
      var isCart = optionElement.getAttribute("data-oncart");
      setAddCart(1);
      if (type === "1") {
        if (!firstAction) {
          setFirstAction(true);
        }
      }
      if (child === "true") {
        let params = {};
        params.productId = detailsData.id;
        params.key = key;
        params.nextKey = next;
        params.preKey = pre;
        params.type = type;
        params.deviceToken = isToken;
        params.action = firstAction;
        actions.loadProductAttributeData(params);
      } else {
        console.log(isCart);
        actions.isCartDataSuccess(true);
        setPrice(false);
        setactualPrice(price);
        setdiscountedPrice(discount);
        setAttributeId(id);
        setAddCart(isCart);
      }
    }
  };

  const handleLikeClick = (review) => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      let params = {};
      params.id = review.id;
      params.isLike = review.isLike;
      params.productId = detailsData.id;
      actions.loadReviewLikeClick(params);
    }
  };

  const handleLikeSellerReviewClick = (params) => {
    actions.likeSellerReview(params);
  };

  const handleAddSellerReviewClick = () => {
    setSellerModalShow(false);
    setSellerReviewShow(true);
  };

  const { register, errors, handleSubmit, setValue } = useForm();
  const onSubmit = (data) => {
    setCheckAvailability(true);
    setValue("pincode", data.pincode);
    data.sellerId = detailsData.seller.id;
    actions.checkAvailability(data);
  };

  useEffect(() => {
    setValue("pincode", pinCode);
  }, [pinCode]);

  const handleClickZipCode = (zipCode) => {
    if (!check) {
      setCheckAvailability(true);
      setValue("pincode", zipCode);
    }
  };

  const handleReviewClick = () => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      setReviewModalShow(true);
    }
  };

  const handleLoadMoreReview = () => {
    actions.loadProductReview({ productId: detailsData.id });
    setMoreReview(true);
  };

  function ShareModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="producrt_detail">
              <h3>{detailsData.title}</h3>
              <div className="rating mt-2">
                <div className="star prd_Detail_star">
                  <StarRatingComponent
                    name="rate"
                    starCount={5}
                    value={detailsData.rating}
                    renderStarIcon={() => <i className="fas fa-star"></i>}
                  />
                  <span className="ml-1">
                    {detailsData.rating} Rating & {detailsData.total_review}{" "}
                    Reviews
                  </span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FacebookShareButton url={location} className="p-2">
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <EmailShareButton url={location} className="p-2">
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
          <TwitterShareButton url={location} className="p-2">
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={location} className="p-2">
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </Modal.Body>
      </Modal>
    );
  }
  function ModleViewer(props) {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Iframe
          url={
            detailsData.ARImage && detailsData.ARImage.length
              ? config.MODEL_VIEWR_URL + detailsData.ARImage[0].original
              : config.MODEL_URL
          }
          width="100%"
          height="450px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
          frameBorder="0"
        />
      </Modal>
    );
  }

  const renderAttribute = (attributeList, type) => {
    if (attributeList && attributeList.length > 0) {
      return attributeList.map(
        (attribute, key) =>
          attribute.value &&
          attribute.value.length > 0 && (
            <Col xs={12} sm={6} key={key}>
              <Form.Group>
                <Form.Control as="select" onChange={handleChange}>
                  <option value="0">Select {attribute.key}</option>
                  {attribute.value.map((item, index) => (
                    <option
                      value={item.key}
                      key={index}
                      data-id={item.id}
                      data-price={item.price}
                      data-discount={item.discounted_price}
                      data-child={attribute.isChild}
                      data-next={attribute.nextKey}
                      data-pre={attribute.preKey}
                      data-oncart={item.isCart}
                      data-type={type}
                    >
                      {item.value}
                    </option>
                  ))}
                </Form.Control>
                {attribute.key === "Size" && (
                  <small className="pl-2 error-image">
                    Dimensions are in Inches and in Kg
                  </small>
                )}
              </Form.Group>
            </Col>
          )
      );
    }
    return null;
  };

  const handleclickAddToCart = () => {
    if (parseInt(onCart) === 0) {
      history.push("/cart");
      return;
    }
    if (!isCart) {
      showToaster(
        "Please select product attribute,if you have changed any attributes please choose again"
      );
    } else {
      if (!isAvailability) {
        showToaster("Please check your delivery Availability in your Area");
      } else {
        setCheckAvailability(false);
        let params = {};
        params.productId = detailsData.id;
        params.deviceToken = isToken;
        params.qty = 1;
        params.attributeId = attributeId;
        actions.addToCart(params);
      }
    }
  };

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };
  
  function meetinghandleClick() {
	 
    if (!userInfo.user_id) {
      //actions.userLoginDataSuccess(true);
	  actions.loadActionStatus();
      setMeetingModalShow(true);
    } else {
      actions.loadActionStatus();
      setMeetingModalShow(true);
    }
  }
  
  console.log("=============relatedProducts start=================");
	console.log(detailsData.title);
	console.log(detailsData.relatedProducts);
	console.log("=============relatedProducts end================="); 
  //window.location.reload(); 
  return (
    <>
      <Breadcrumbs name={BrdName} />
      <section className="listing_detail_Sec py-4">
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              {renderImage()}
            </Col>
            <Col xs={12} lg={6} className="pt-5 pt-lg-0 pl-lg-1">
              <div className="producrt_detail">
                <h3 style={{"marginBottom":"0"}}>{detailsData.title}</h3>
               
				  {detailsData.seller && (
					<div className="dealer_img">
					<span className="dealer_icon">
					<img src={detailsData.seller.logo} alt="" className="supplier_logo" />
					</span>
					</div>
                )}
				<div class="d-sm-flex d-block justify-content-between flex-row-reverse">
                <div className="rating mt-2">
                  { (detailsData.rating || detailsData.total_review) ? (<div className="star prd_Detail_star">
                    <StarRatingComponent
                      name="rate"
                      starCount={5}
                      value={detailsData.rating}
                      renderStarIcon={() => <i className="fas fa-star"></i>}
                    />
					
                    <span className="ml-1"> 
					  { detailsData.rating ? (detailsData.rating + ' Rating' ) : ('')} { (detailsData.rating && detailsData.total_review) ? ('&' ) : ('')} {detailsData.total_review ? (detailsData.total_review + ' Reviews' ) : ('')} 
                    </span>

                  </div>): ('')} 

                </div>
                { (detailsData.pwidth >0  || detailsData.pheight > 0 || detailsData.pheight_alt) ? ( <div className="pb-0 dimensions">Dimensions (inches): {detailsData.pwidth} x {detailsData.pheight} { detailsData.pheight_alt ? ('x ' + detailsData.pheight_alt ) : ('')} (Width x Length{detailsData.pheight_alt ? (' x Height')  :('')})</div>
				) : ( 
				<h4></h4>
				)}
				</div>

                
                {detailsData.type === 2 && (
                  <Form action="" className="product_form mb-3">
                    <Row>
                      {renderAttribute(productAttribute, 1)}
                      {renderAttribute(productNextAttribute, 2)}
                    </Row>
                  </Form>
                )}
			


				<div className="features d-none">	
			{detailsData.description &&
              detailsData.description.length > 0 &&
              detailsData.description.map((description, key) => ( 
			     (description.value && description.key =='Features' )  
					? (<div
							dangerouslySetInnerHTML={prepareHtml(description.value.substring(0, 250)) }
						  /> 
					 ) 
				: (<span></span>)
              ))}
			  
			  {detailsData.description &&
              detailsData.description.length > 0 &&
              detailsData.description.map((description, key) => ( 
			     (description.value && description.key =='Features' && description.value.length > 250 )  
					? (<a
						style={{ color: "#f26522", cursor: "pointer" }} 
						href="#feaureSection"
					  >
						Read more
					  </a> 
					 ) 
				: (<span></span>)
              ))} 
			  </div>
				
				<div className="service-box d-none">
					<p>Avail our Design Services to use the product aesthetically in your surrounding</p>
					<Link
						className="btn-small"
						to="#!"
						title="Request virtual meeting"
						onClick={() => meetinghandleClick()}
					  >Design Services</Link>
				</div> 
                 
		 <div id="feaureSection">
            {detailsData.description &&
              detailsData.description.length > 0 &&
              detailsData.description
              .filter(description => description.key !==  'Packaging and Delivery Information')
              .map((description, key) => ( 
			     (description.value)  
					? (<Accordion defaultActiveKey="Features" className="accordian_sec">
					<Card key={key}>
					  <Card.Header>
						<Accordion.Toggle
						  as={Button}
						  variant="link"
						  eventKey={description.key}
						  className="border-bottom"
						  onClick={() =>
							setActiveId(
							  activeId === description.key ? "" : description.key
							)
						  }
						>
						  {description.key}
						  {description.smallKey && (
							<small>({description.smallKey})</small>
						  )}
						  <i
							className={
							  description.key === activeId
								? "fas fa-angle-down"
								: "fas fa-angle-right"
							}
						  ></i>
						</Accordion.Toggle>
					  </Card.Header>
					  <Accordion.Collapse eventKey={description.key}>
						<Card.Body>
						  <div
							dangerouslySetInnerHTML={prepareHtml(description.value)}
						  /> 
						</Card.Body>
					  </Accordion.Collapse>
					</Card></Accordion>
					 )
				: (<span></span>)
				
              ))}
		 </div>
                <div className="made_location mb-3" style={{"display" : "none"}} >
                  <span>Made in {detailsData.made_by}</span>
                  {/* <div className="ml-auto">
                    <span className="btn-transparent">Ask a Designer?</span>
                    <span className="btn-transparent ml-1">
                      Request Customization
                    </span>
                  </div> */}
                </div>
                {detailsData.seller && (
                  <div className="dealer_info mb-3" style={{"display" : "none"}}>
                    <div className="d-flex">
                      <div className="dealer_img">
                        <span className="dealer_icon">
                          <img src={detailsData.seller.star} alt="" />
                        </span>
                        {/* <h6 className="mb-0">{detailsData.seller.name}</h6> */}
                        <h6 className="mb-0">
                          Supplier { (detailsData.seller.avgReviews || detailsData.seller.review.length) ? ('(' ) : ('')}{ detailsData.seller.avgReviews ? (detailsData.seller.avgReviews + ' Rating' ) : ('')} { (detailsData.seller.avgReviews && detailsData.seller.review.length) ? ('&' ) : ('')} {detailsData.seller.review.length ? (detailsData.seller.review.length + ' Reviews' ) : ('')} { (detailsData.seller.avgReviews || detailsData.seller.review.length) ? (')' ) : ('')} 
                        </h6>
                      </div>

                      <div className="d-inline-flex align-items-center ml-auto">
                        <span
                          className="btn-small ml-2"
                          onClick={() => handleclickSellerProfile()}
                        >
                          Ratings & Reviews
                        </span>
                      </div>
                    </div>
                    {/* <div className="made-outer">
                      <h6 className="mb-0">
                        Customer ({detailsData.rating} Rating &amp;
                        {detailsData.total_review} Reviews)
                      </h6>
                    </div> */}
                  </div>
                )}
                
              </div>
            </Col>
          </Row>


			{detailsData.relatedProducts && detailsData.relatedProducts.length > 0 && (
				 <div className="border-top-0 tab_heading d-flex align-items-center pt-3">
					<h2>Related Products</h2>
				  </div>)}
				<Row>		  
				{detailsData.relatedProducts &&
				detailsData.relatedProducts.length > 0 && detailsData.relatedProducts.map((productrelated, keyrelated) => (
					<Col xs="12" md="4" lg="3" key={keyrelated}>
					  <List product={productrelated}  />
					</Col>
				))}
				</Row>				
			  
			{detailsData.moreProducts && detailsData.moreProducts.length > 0 && (
			 <div className="border-top-0 tab_heading d-flex align-items-center pt-3">
				<h2>More Products {detailsData.seller.name ? (' from '+detailsData.seller.name ) : ('')} </h2>
			  </div>)}
			<Row>		  
			{detailsData.moreProducts &&
			detailsData.moreProducts.length > 0 && detailsData.moreProducts.map((productmore, keymore) => (
				<Col xs="12" md="4" lg="3" key={keymore}>
                  <List product={productmore}  />
                </Col>
			))}
			</Row>		   
						
			  
          
          <RecentlyViewed recentlyView={recentlyView} wishCount={wishCount} />
          <div className="border-top pt-4 mt-4 d-none">
            <Row className="rating_review">
              <Col xs={12} md={4} className="rating border-right">
                <h4>Customer Ratings &amp; Reviews</h4>
                <Row>
                  <Col xs={12} lg={4} className="mb-3 mb-lg-0">
                    <div className="rating-block">
                      <h2>
                        {detailsData.rating}
                        <small>
                          <i className="fas fa-star active"></i>
                        </small>
                      </h2>
                      <span>
                        {detailsData.rating} Rating &amp;&nbsp;
                        {detailsData.total_review} Reviews
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} lg={8}>
                    {detailsData.reviewStar &&
                      detailsData.reviewStar.length > 0 &&
                      detailsData.reviewStar.map((review, key) => (
                        <div
                          className="d-flex align-items-center star_block py-1"
                          key={key}
                        >
                          <div className="progress_star">
                            {review.star} <span className="fas fa-star"></span>
                          </div>
                          <ProgressBar
                            now={
                              (detailsData.total_review / review.value) * 100
                            }
                          />
                          <div className="progress_count text-right pl-2 ml-auto">
                            {review.value}
                          </div>
                        </div>
                      ))}
                  </Col>
                  {detailsData.isOrder && detailsData.isReview && (
                    <Col className="pt-md-2 mt-3 mt-md-4">
                      <span className="btn" onClick={handleReviewClick}>
                        Write a Review
                      </span>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={12} md={8} className="review pt-4 pt-md-0">
                <ul className="pt-2 pl-2">
                  {productReview &&
                    productReview.length > 0 &&
                    productReview.map((review, key) => (
                      <ProductReview
                        review={review}
                        key={key}
                        onClick={handleLikeClick}
                      />
                    ))}
                </ul>
                {productReview.length >= 5 && (
                  <div>
                    <Col className="pt-md-2 mt-3 mt-md-4 float-right">
                      <span
                        className="btn float-right"
                        onClick={handleLoadMoreReview}
                      >
                        See All
                      </span>
                    </Col>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {modalShow && (
        <ShareModal show={modalShow} onHide={() => setModalShow(false)} />
      )}
      {modalViewer && detailsData.ARImage && detailsData.ARImage.length && (
        <ModleViewer
          show={modalViewer}
          onHide={() => setModalViewerShow(false)}
        />
      )}
      <Newsletter />
      {detailsData.seller && (
        <SellerDetailModal
          show={sellerModalShow}
          onHide={() => setSellerModalShow(false)}
          order={detailsData.isOrder}
          details={detailsData.seller}
          onClick={handleLikeSellerReviewClick}
          onChange={handleAddSellerReviewClick}
        />
      )}
      {!check && <Location onClick={handleClickZipCode} />}
      {reviewmodalShow && (
        <AddReviewModal
          show={reviewmodalShow}
          onHide={() => setReviewModalShow(false)}
          details={detailsData}
        />
      )}
      {addSellerReviewShow && (
        <AddSellerReviewModal
          show={addSellerReviewShow}
          onHide={() => setSellerReviewShow(false)}
          details={detailsData.seller}
        />
      )}
      {moreReview && (
        <LoadMoreReview
          show={moreReview}
          onHide={() => setMoreReview(false)}
          details={detailsData}
          review={productReviewMore}
        />
      )}
	  {meetingmodalShow && (
        <MeetingModal
          show={meetingmodalShow}
          onHide={() => setMeetingModalShow(false)}
        />
      )}
    </>
  );
};

Details.propTypes = {
  detailsData: PropTypes.any.isRequired,
  userInfo: PropTypes.any.isRequired,
  isWishList: PropTypes.any.isRequired,
  wishCount: PropTypes.any.isRequired,
  productAttribute: PropTypes.any.isRequired,
  productNextAttribute: PropTypes.any.isRequired,
  isCart: PropTypes.any.isRequired,
  recentlyView: PropTypes.any.isRequired,
  productReview: PropTypes.any.isRequired,
  isSubmitting: PropTypes.any.isRequired,
  isAvailability: PropTypes.any.isRequired,
  isStock: PropTypes.any.isRequired,
  pinCode: PropTypes.any.isRequired,
  productReviewMore: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    detailsData: state.detailsData,
    userInfo: state.userInfo,
    userLogin: state.userLogin,
    isWishList: state.isWishList,
    isCart: state.isCart,
    wishCount: state.wishCount,
    productAttribute: state.productAttribute,
    productNextAttribute: state.productNextAttribute,
    productReview: state.productReview,
    productReviewMore: state.productReviewMore,
    recentlyView: state.recentlyView,
    isSubmitting: state.isSubmitting,
    isAvailability: state.isAvailability,
    isStock: state.isStock,
    pinCode: state.pinCode,
    deviceToken: state.deviceToken,
    productSliderImage: state.productSliderImage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        productActions,
        userActions,
        wishlistActions,
		hotProductActions,
        cartActions,
        sellerActions 
      ),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);

