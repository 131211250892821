export const LOAD_HOME_PAGE_DATA_SUCCESS = "LOAD_HOME_PAGE_DATA_SUCCESS";
export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOAD_BANNER_DATA_SUCCESS = "LOAD_BANNER_DATA_SUCCESS";
export const LOAD_PARENT_DATEGORY_DATA_SUCCESS =
  "LOAD_PARENT_DATEGORY_DATA_SUCCESS";
export const LOAD_CATEGORY_PRODUCT_DATA_SUCCESS =
  "LOAD_CATEGORY_PRODUCT_DATA_SUCCESS";
export const LOAD_TRANDING_PRODUCT_DATA_SUCCESS =
  "LOAD_TRANDING_PRODUCT_DATA_SUCCESS";
export const LOAD_HOT_PRODUCT_DATA_SUCCESS = "LOAD_HOT_PRODUCT_DATA_SUCCESS";
export const LOAD_PRODUCT_DATA_SUCCESS = "LOAD_PRODUCT_DATA_SUCCESS";
export const LOAD_PAGINATION_DATA_SUCCESS = "LOAD_PAGINATION_DATA_SUCCESS";
export const LOAD_PRODUCT_FILTER_DATA_SUCCESS =
  "LOAD_PRODUCT_FILTER_DATA_SUCCESS";
export const LOAD_ADVERTISING_DATA_SUCCESS = "LOAD_ADVERTISING_DATA_SUCCESS";
export const PRODUCT_REQUEST_STATUS = "PRODUCT_REQUEST_STATUS";
export const LOAD_PRODUCT_DETAILS_DATA_SUCCESS =
  "LOAD_PRODUCT_DETAILS_DATA_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_LOGIN_SUCCESS = "LOADED_USER_LOGIN_SUCCESS";
export const LOADED_USER_OTP_SUCCESS = "LOADED_USER_OTP_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOADED_OTP_DATA_SUCCESS = "LOADED_OTP_DATA_SUCCESS";
export const LOADED_USER_REGISTER_SUCCESS = "LOADED_USER_REGISTER_SUCCESS";
export const LOADED_USER_GUEST_SUCCESS = "LOADED_USER_GUEST_SUCCESS";
export const LOADED_FORGOT_PASSWORD_SUCCESS = "LOADED_FORGOT_PASSWORD_SUCCESS";
export const LOAD_WISH_LIST_DATA_SUCCESS = "LOAD_WISH_LIST_DATA_SUCCESS";
export const LOAD_WISH_LIST_COUNT_DATA_SUCCESS =
  "LOAD_WISH_LIST_COUNT_DATA_SUCCESS";
export const PRODUCT_ATTRIBUTE_DATA_SUCCESS = "PRODUCT_ATTRIBUTE_DATA_SUCCESS";
export const PRODUCT_NEXT_ATTRIBUTE_DATA_SUCCESS =
  "PRODUCT_NEXT_ATTRIBUTE_DATA_SUCCESS";
export const PRODUCT_NEXT_ATTRIBUTE_DATA_NULL_SUCCESS =
  "PRODUCT_NEXT_ATTRIBUTE_DATA_NULL_SUCCESS";
export const PRODUCT_CART_DATA_SUCCESS = "PRODUCT_CART_DATA_SUCCESS";
export const LOAD_CART_COUNT_DATA_SUCCESS = "LOAD_CART_COUNT_DATA_SUCCESS";
export const LOAD_RECENT_VIEW_DATA_SUCCESS = "LOAD_RECENT_VIEW_DATA_SUCCESS";
export const LOAD_PRODUCT_REVIEW_DATA_SUCCESS =
  "LOAD_PRODUCT_REVIEW_DATA_SUCCESS";
export const LOADED_CART_ITEM_SUCCESS = "LOADED_CART_ITEM_SUCCESS";
export const CART_ITEM_REQUEST_STATUS = "CART_ITEM_REQUEST_STATUS";
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART";
export const INCREMENT_CART_ITEM_QUANTITY = "INCREMENT_CART_ITEM_QUANTITY";
export const DECREMENT_CART_ITEM_QUANTITY = "DECREMENT_CART_ITEM_QUANTITY";
export const LOAD_CHECK_COUPON_DATA = "LOAD_CHECK_COUPON_DATA";
export const LOAD_COUPON_MODEL_DATA = "LOAD_COUPON_MODEL_DATA";
export const LOAD_COUPON_CODE_DATA = "LOAD_COUPON_CODE_DATA";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOAD_ORDER_ITEM_DATA = "LOAD_ORDER_ITEM_DATA";
export const LOAD_WISHLIST_ITEM_DATA = "LOAD_WISHLIST_ITEM_DATA";
export const UPDATE_WISHLIST_ITEM_DATA = "UPDATE_WISHLIST_ITEM_DATA";
export const LOAD_FOOTER_PRODUCT = "LOAD_FOOTER_PRODUCT";
export const CHECK_AVAILABILITY = "CHECK_AVAILABILITY";
export const LOAD_USER_ADDRESS_CODE_DATA = "LOAD_USER_ADDRESS_CODE_DATA";
export const LOADED_SETTING_INFO_SUCCESS = "LOADED_SETTING_INFO_SUCCESS";
export const ORDER_REQUEST_STATUS = "ORDER_REQUEST_STATUS";
export const STOCK_REQUEST_STATUS = "STOCK_REQUEST_STATUS";
export const LOADED_LOCATION_SUCCESS = "LOADED_LOCATION_SUCCESS";
export const LOADED_PINCODE_SUCCESS = "LOADED_PINCODE_SUCCESS";
export const LOADED_HEADER_PRODUCT_SUCCESS = "LOADED_HEADER_PRODUCT_SUCCESS";
export const LOAD_PRODUCT_REVIEW_MORE_DATA_SUCCESS =
  "LOAD_PRODUCT_REVIEW_MORE_DATA_SUCCESS";
export const LOAD_REVIEW_DATA = "LOAD_REVIEW_DATA";
export const LOAD_CATEGORY_DATA = "LOAD_CATEGORY_DATA";
export const LOAD_FAQ_DATA_SUCCESS = "LOAD_FAQ_DATA_SUCCESS";
export const LOAD_REALSPACE_DATA_SUCCESS = "LOAD_REALSPACE_DATA_SUCCESS";
export const LOADED_DEAIGEN_INFO_SUCCESS = "LOADED_DEAIGEN_INFO_SUCCESS";
export const LOADED_ACTION_STATUS_SUCCESS = "LOADED_ACTION_STATUS_SUCCESS";
export const LOADED_ORDER_CANCLE_MODAL = "LOADED_ORDER_CANCLE_MODAL";
export const LOAD_DEVICE_TOKEN_DATA_SUCCESS = "LOAD_DEVICE_TOKEN_DATA_SUCCESS";
export const LOAD_SELLER_ORDER_DATA_SUCCESS = "LOAD_SELLER_ORDER_DATA_SUCCESS";
export const LOAD_PRODUCT_SILDER_IMAGE_DATA_SUCCESS =
  "LOAD_PRODUCT_SILDER_IMAGE_DATA_SUCCESS";
export const LOAD_DUMMY_PRODUCT_MODEL_DATA = "LOAD_DUMMY_PRODUCT_MODEL_DATA";
export const LOAD_DUMMY_PRODUCT_DATA = "LOAD_DUMMY_PRODUCT_DATA";
export const PAGE_LIST_DATA_SUCCESS = "PAGE_LIST_DATA_SUCCESS";
export const LOAD_NOTIFICATION_DATA = "LOAD_NOTIFICATION_DATA";
export const LOAD_WISHLIST_DATA_SUCCESS = "LOAD_WISHLIST_DATA_SUCCESS";

export const LOAD_SUPPLIER_DATA = "LOAD_SUPPLIER_DATA";
export const LOAD_PACKAGE_DATA_SUCCESS = "LOAD_PACKAGE_DATA_SUCCESS";
export const LOAD_PACKAGE_ROOM_CLASS_DATA_SUCCESS = "LOAD_PACKAGE_ROOM_CLASS_DATA_SUCCESS";
export const LOAD_BRAND_DATA_SUCCESS = "LOAD_BRAND_DATA_SUCCESS";