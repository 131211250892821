import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import tcc1 from "./images/ttc1.jpg";
import tcc2 from "./images/ttc2.jpg";
import tcc3 from "./images/ttc3.jpg";
import tcc4 from "./images/ttc4.jpg";
import tcc5 from "./images/ttc5.jpg";
import curve from "./images/curve.png";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom"; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Outlet, Link } from "react-router-dom";
import ReactPlayer from "react-player";



const SarikaTotla = (props) => {
    const { actions, homePageData, isSubmitting } = props;
  const { slug } = useParams();

  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  const onSubmit = (data) => {
    console.log(JSON.stringify(data),'Prem');
 	data.request_for = 'Design Services';
     //return;
    actions.sendSpecialRequest(data);
  };
  
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" }
  };
  
   useEffect(() => {
    document.title = "Design Services";
  });
  const [video] = useState(
    "https://youtu.be/MjF7uMmYhv4"
  );
  const images = [
    {
      img: tcc1,
    },
    {
      img: tcc2,
    },
    {
      img: tcc3,
    },
    {
      img: tcc4,
    },
    {
      img: tcc5,
    },
  ];
  const [selectedImage, setSelectedImage] = useState("");
  const openPopup = (image) => {
    setSelectedImage(image);
  };
  const closePopup = () => {
    setSelectedImage(null);
  };
  return (
    <div>
      <section className="about-supplier">
        <div className="container">
          <h3 className="common-heading text-white text-uppercase">Sarika Totla</h3>
          <p className="text-white">
            She is an Interior Designer, graduated from LS Raheja, Mumbai. After
            working for 11years as an Interior Designer, wall textures inspired
            her and she shifted her focus to home styling, changing walls with
            texture and wall paper and added sheer curtains, “as this can
            dramatise any part of the house”.
          </p>

          <p className="text-white">
            With this vision, she started her company, Colour Co., 7 years back.
            Her idea was keep the furniture as is and only change wall colour
            and texture. This is only 10% of the renovation cost, but makes the
            rooms look as good as new.
          </p>

          <p className="text-white mb-0">
            Sarika says every 5 years it becomes a need to repaint the walls
            with fresh paint, as the colour starts to fade. She has done more
            than 140 projects, prominent projects in Camellias, Magnolias and
            Farmhouses at Rajokari.
          </p>
        </div>
      </section>
      <img src={curve} className="w-100" alt="" />
      <div className="design-service-gallery container pb-5 mb-2">
        <div className="ds-gallery tcc-section">
          {images?.map((image, index) => (
            <div
              onClick={() => openPopup(image.img)}
              className="ds-gallery-item"
            >
              <img key={index} src={image.img} alt={image} />
            </div>
          ))}
        </div>
        {selectedImage && (
          <div className="gallery_popup">
            <span className="close" onClick={closePopup}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 25 25"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
            <img src={selectedImage} alt="Selected Image" />
          </div>
        )}
      </div>
      <section className="pb-5 mb-3">
        <Container>
          <ReactPlayer
          url={video}
          height="355px"
          className="react-player walkthrough-video"
        />
        </Container>
      </section>
      <section className="how-it-works mb-5">
          <div className="container">
            <div className="row mx-0">
              <div className="col-12 bg-light px-4 py-5">
                <div className="row px-2">
                  <div className="col-md-6">
                    <h3 className="common-heading-2">How it works:</h3>
                    <p>Once you make your selection above, our qualified partners are notified of your choices and start working on the drawings. Once done, you will be notified via the platform and will be able to communicate with our partner to suggest any changes on what has been submitted.</p>
                    <strong>The process usually takes 5 business days.</strong>
                    <h5>Happy Designing!</h5>
                  </div>
                  <div className="col-md-6">
                    <h3 className="common-heading-2">Any Special requests</h3>
                    <Form className=""
                  onSubmit={handleSubmit(onSubmit)} >
                      <div className="form-group">
                        {/*<textarea className="form-control" placeholder="Any special requests" required defaultValue={""} />*/}
                        <div className="form-group">
 						            <input type="hidden" name="request_for" value="Design Services" />
                        <Form.Group controlId="formBasicFirstName">
                          <FormControl
                            type="text"
                            placeholder="Name"
                            className={errors.name ? "formError" : ""}
                            name="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 3,
                              maxLength: 20,
                            })}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span role="alert">Please enter your name.</span>
                          )}
                          {errors.name && errors.name.type === "minLength" && (
                            <span role="alert">
                              Your name should contain atleast 3 characters.
                            </span>
                          )}
                          {errors.name && errors.name.type === "maxLength" && (
                            <span role="alert">
                              Your name should should not 20 characters.
                            </span>
                          )}
                        </Form.Group>
                        </div>
                        <div className="form-group">
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="email"
                            placeholder="Email"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <span role="alert">
                              Please enter your email address.
                            </span>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <span role="alert">Invalid email address.</span>
                          )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should not exceed 50 characters.
                              </span>
                            )}
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group controlId="formBasicNumber">
                          <FormControl
                            type="tel"
                            placeholder="Phone Number"
                            name="number"
                            className={errors.number ? "formError" : ""}
                            aria-invalid={errors.number ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                              },
                            })}
                          />
                          {errors.number &&
                            errors.number.type === "required" && (
                              <span role="alert">
                                Please enter your mobile number.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "maxLength" && (
                              <span role="alert">
                                Your mobile number should not exceed 10 digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "minLength" && (
                              <span role="alert">
                                Your mobile number should contain atleast 10
                                digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "pattern" && (
                              <span role="alert">
                                Please enter correct mobile number. It should be
                                entered without country code.( Example
                                xxxxxxxxxx)
                              </span>
                            )}
                        </Form.Group>
                      </div>
                        <FormControl
                          as="textarea"
                          placeholder="Special Instructions"
                          className={errors.message ? "formError" : ""}
                          style={{ height: '100px' }}
                          name="message"
                          aria-invalid={errors.message ? "true" : "false"}
                          ref={register({
                            required: true,
                            
                            minLength: 5,
                          })}
                  />
                      {errors.message && errors.message.type === "required" && (
                        <span role="alert">Please enter your special requests.</span>
                      )}
                      {errors.message && errors.message.type === "minLength" && (
                        <span role="alert">
                          Your special request should minimum 5 characters.
                        </span>
                      )}
                      </div>
                      {/*<button className="btn custom-btn" >Submit</button>*/}
                        <Button
                          variant=""
                          className="btn custom-btn"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <Spinner
                              as="span"
                              size="sm"
                              role="status"
                              animation="grow"
                              aria-hidden="true"
                            />
                          ) : (
                            <>Submit</>
                          )}
                        </Button>
                      </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  );
};

//export default Stuvita;
function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SarikaTotla);
