import React from "react";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import { NavLink } from "react-router-dom";

const NotFound = ({ title, description, image, url, buttion }) => {
  return (
    <div className="no_record_found text-center pt-5">
      <LazyLoad images={image} key={1} imageClass={"productBlur"} />
      <div className="pt-5">
        {title}
        <small>{description}</small>
      </div>
      {buttion && (
        <div className="py-5">
          <NavLink to={url} title="Shop Now" exact className="btn py-3">
            {buttion}
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default NotFound;
