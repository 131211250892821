import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./HomeDesignTabPanel.css";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import StarRatingComponent from "react-star-rating-component";
import GuideModal from "./guide";
import MeetingModal from "./meeting";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import * as hotProductActions from "../../actions/hotProductActions";
import { NavLink, useHistory } from "react-router-dom";

import img1 from "./images/ds/01.jpg"; 
import img2 from "./images/ds/02.jpg"; 
import img3 from "./images/ds/03.jpg";
import img4 from "./images/ds/04.jpg";
import img5 from "./images/ds/05.jpg";
import img6 from "./images/ds/06.jpg";
import img7 from "./images/ds/07.jpg";
import img8 from "./images/ds/08.jpg";
import img9 from "./images/ds/09.jpg";
import img10 from "./images/ds/10.jpg";
import img11 from "./images/ds/11.jpg";
import img12 from "./images/ds/12.jpg";
import img13 from "./images/ds/13.jpg";
import img14 from "./images/ds/14.jpg";
import img15 from "./images/ds/15.jpg";

const prepareHtml = (description) => {
  if (description) {
    return { __html: description || "" };
  }
  return { __html: "" };
};

var TestimonialsSlider = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    { breakpoint: 1439, settings: { slidesToShow: 2 } },
    { breakpoint: 1365, settings: { slidesToShow: 2 } },
    { breakpoint: 1199, settings: { slidesToShow: 2 } },
    { breakpoint: 991, settings: { slidesToShow: 1 } },
    { breakpoint: 767, settings: { slidesToShow: 1 } },
    { breakpoint: 575, settings: { slidesToShow: 1 } },
    { breakpoint: 479, settings: { slidesToShow: 1 } },
  ],
};
const HomeDesignTabPanel = (props) => {
  const { homeDesigen, actions, actionStaus, userInfo, pageList } = props;
  const [modalShow, setModalShow] = useState(false);
  const [meetingmodalShow, setMeetingModalShow] = useState(false);
  const [guide, setGuide] = useState(false);
   
  useEffect(() => {
    if (actionStaus) {
      setMeetingModalShow(false);
    }
  }, [actionStaus]);

  function handleClick(guide) {
    setGuide(guide);
    setModalShow(true);
  }
  function meetinghandleClick() {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
    } else {
      actions.loadActionStatus();
      setMeetingModalShow(true);
    }
  }



  const images = [
    {
      img: img1,
      description: "Modern",
    },
    {
      img: img2,
      description: "Contemporary",
    },
    {
      img: img3,
      description: "Industrial",
    },
    {
      img: img4,
      description: "Contemporary",
    },
    {
      img: img5,
      description: "Rustic",
    },
    {
      img: img6,
      description: "Rustic",
    },
    {
      img: img7,
      description: "Tropical",
    },
    {
      img: img8,
      description: "Electric",
    },
    {
      img: img9,
      description: "French",
    },
    {
      img: img10,
      description: "Minimalastic",
    },
    {
      img: img11,
      description: "Art Deco",
    },
    {
      img: img12,
      description: "Classical",
    },
    {
      img: img13,
      description: "Indian Traditional",
    },
    {
      img: img14,
      description: "Bohemian",
    },
    {
      img: img15,
      description: "Japanese",
    },
  ];
  const [selectedImage, setSelectedImage] = useState("");
  const openPopup = (image) => {
    setSelectedImage(image);
  };
  const closePopup = () => {
    setSelectedImage(null);
  };
  return (
    <>
      {homeDesigen.roomPlanner && (
	    
        <div className="main_tab_content d-none mb-5"> 

          <div className="service-box p-3 mb-4 mt-5">
            <div>
              <h1 className="mb-0 text-white">Need help in curating the most unique look for your space?</h1>
              <h2 className="design_tab_heading text-white">Explore our expert design services!</h2>
            </div>
          </div>
          <div className={props.design_service ? "bg-light p-4" : "design_tab_sec bg-light p-4"}> 
            <div>  
              {props.design_service && pageList.includes(9) && (
                <>
                  <h2 className="design_tab_heading mb-2 d-none">
                    {homeDesigen.designServices.title}
                  </h2>
                  <p
                    className="tools_content mb-0"
                    dangerouslySetInnerHTML={prepareHtml(
                      homeDesigen.designServices.description
                    )}
                  ></p>
                  <div className="design-service-gallery mb-5">
                    <div className="ds-gallery">
                      {images?.map((image, index) => (
                        <div onClick={() => openPopup(image.img)} className="ds-gallery-item">
                        <img key={index} src={image.img} alt={image.description} />
                        <h4 className="bg-light">{image.description}</h4>
                        </div> 
                      ))}
                    </div>
                    {selectedImage && (
                      <div className="gallery_popup gallery_">
                        <span className="close" onClick={closePopup}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          className="bi bi-xlg"
                          viewBox="0 0 25 25"
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                        </span>
                        <img src={selectedImage} alt="Selected Image" />
                      </div>
                    )}
                  </div>
                  <div className="space_budget_btns my-2 text-center">  
                    <NavLink to="/designiche_design_services" title="View more about our Design Services" exact className="btn">
                      View more about our Design Services
                    </NavLink>
                  </div>
                </>
              )}
            </div>
            
            <div className="d-none">
              { props.activeSection &&  pageList.includes(8) && (	 
                <h2 className="design_tab_heading mt-5 mb-5">Tools</h2> 
              )}
              { props.activeSection &&  pageList.includes(8) && (
                <Row>
                  <Col xs={12} md={4}>
                    <div className="tools_img mb-4 mb-md-0">
                      <LazyLoad
                        images={homeDesigen.roomPlanner.image}
                        key={1}
                        imageClass={"designBlur"}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={8}>
                    <div className="tools_content_sec pt-0 pt-lg-5 pl-0 pl-lg-5">
                      <div className="tools_content_title mb-2">
                        {homeDesigen.roomPlanner.title}
                      </div>
                      <p
                        className="tools_content mb-0"
                        dangerouslySetInnerHTML={prepareHtml(
                          homeDesigen.roomPlanner.description
                        )}
                      ></p>
                    </div>
                  </Col>
                </Row>
              )}
              {props.activeSection && pageList.includes(9) && (
                <Row className="row_reverse pt-5">
                  <Col xs={12} md={8}>
                    <div className="tools_content_sec pt-0 pt-lg-5">
                      <div className="tools_content_title mb-2">
                        {homeDesigen.mobileAr.title}
                      </div>
                      <p
                        className="tools_content mb-0"
                        dangerouslySetInnerHTML={prepareHtml(
                          homeDesigen.mobileAr.description
                        )}
                      ></p>
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="tools_img mb-4 mb-md-0">
                      <LazyLoad
                        images={homeDesigen.mobileAr.image}
                        key={2}
                        imageClass={"designBlur"}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
         { props.activeSection && (	 
          <div className="design_tab_sec d-none design_service_tab_sec"> 
            <div className="testimonials_sec mt-5">
              <div className="tools_content_title mb-4">
                Customers Testimonials
              </div>
              <Slider {...TestimonialsSlider} className="testimonials_slider">
                {homeDesigen.testimonial.map((testimonial, index) => (
                  <div className="testimonials_block" key={index}>
                    <ul className="testi_rating_view d-flex mb-3">
                      <StarRatingComponent
                        name="rate"
                        starCount={5}
                        value={testimonial.rating}
                        renderStarIcon={() => <i className="fas fa-star"></i>}
                      />
                    </ul>
                    <div
                      className="testimonials_client_review mb-4"
                      dangerouslySetInnerHTML={prepareHtml(
                        testimonial.description
                      )}
                    ></div>
                    <div className="testimonials_client_info d-flex align-items-center">
                      <div className="testimonials_client_pic">
                        <LazyLoad
                          images={testimonial.image}
                          key={1}
                          imageClass={"clientBlur"}
                        />
                      </div>
                      <div className="testimonials_client_name pl-3">
                        {testimonial.name} <small>{testimonial.location}</small>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
		  )} 
		 	
				
		  { props.activeSection && (
          <div className="design_tab_sec d-none design_service_tab_sec">
            <h2 className="design_tab_heading mb-4">Resource &amp; Guide</h2>
            <ul className="resource_guide_links">
              {homeDesigen.guide.map((guide, index) => (
                <li key={index}>
                  <Link
                    to="#!"
                    title={guide.title}
                    onClick={() => handleClick(guide)}
                  >
                    {guide.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div> 
		  )}
        </div>
		
      )}
		<div className=""></div>
	  
      {modalShow && (
        <GuideModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          guide={guide}
        />
      )}
      {meetingmodalShow && (
        <MeetingModal
          show={meetingmodalShow}
          onHide={() => setMeetingModalShow(false)}
        />
      )}
    </>
  );
};

HomeDesignTabPanel.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    actionStaus: state.actionStaus,
    userInfo: state.userInfo,
    pageList: state.pageList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(hotProductActions, cartActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeDesignTabPanel);
