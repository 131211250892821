import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadBannerDataSuccess(bannerData) {
  return { type: types.LOAD_BANNER_DATA_SUCCESS, bannerData };
}

export function loadBannerData() {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.BANNER)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadBannerDataSuccess(response.data.data));
            dispatch(toggleNetworkRequestStatus(false));
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}
