import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as userActions from "./userActions";

export function loadDataSuccess(isWishList) {
  return { type: types.LOAD_WISH_LIST_DATA_SUCCESS, isWishList };
}

export function wishlistCountDataSuccess(wishCount) {
  return { type: types.LOAD_WISH_LIST_COUNT_DATA_SUCCESS, wishCount };
}

export function loadCartCountDataSuccess(cartCount) {
  return { type: types.LOAD_CART_COUNT_DATA_SUCCESS, cartCount };
}

export function LoadMyWishlistData(wishlistItem) {
  return { type: types.LOAD_WISHLIST_ITEM_DATA, wishlistItem };
}

export function UpdateWishlistData(wishlistItem) {
  return { type: types.UPDATE_WISHLIST_ITEM_DATA, wishlistItem };
}

export function userLoginSuccess(userLogin) {
  return { type: types.LOADED_USER_LOGIN_SUCCESS, userLogin };
}

export const addWishList = (params) => async (dispatch) => {
  try {
    const response = await agent.post(API.WISH_LIST_ADD, params);
    if (response.status === 200 || response.status === 201) {
      dispatch(myWishListReload(params));
      showToaster(response.data.message);
      dispatch(loadDataSuccess(response.data.data));
      if (response.data.data) {
        dispatch(wishlistCountDataSuccess(params.count + 1));
      } else {
        dispatch(wishlistCountDataSuccess(params.count - 1));
      }
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    throw error;
  }
};

export const myWishList = () => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.MY_WISH_LIST);
    dispatch(toggleNetworkRequestStatus(false));
    if (response.status === 200 || response.status === 201) {
      let params = {};
      params.item = response.data.data;
      dispatch(wishlistCountDataSuccess(response.data.data.length));
      console.log("response.data.data ==>", response.data.data);
      dispatch(LoadMyWishlistData(params));
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
    dispatch(LoadWishListData(true));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const myWishListReload = () => async (dispatch) => {
  try {
    dispatch(LoadMyWishlistData([]));
    dispatch(LoadWishListData(false));
    const response = await agent.get(API.MY_WISH_LIST);
    if (response.status === 200 || response.status === 201) {
      let params = {};
      params.item = response.data.data;
      dispatch(wishlistCountDataSuccess(response.data.data.length));
      dispatch(LoadMyWishlistData(params));
    }
    dispatch(LoadWishListData(true));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const userLoginDataSuccess = () => async (dispatch) => {
  dispatch(userLoginSuccess(true));
};

export function LoadWishListData(isWishListData) {
  return { type: types.LOAD_WISHLIST_DATA_SUCCESS, isWishListData };
}
