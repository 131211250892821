import React from "react";
import { Modal } from "react-bootstrap";

const GuideModal = (props) => {
  const { guide } = props;
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {guide.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <p
          className="tools_content mb-0"
          dangerouslySetInnerHTML={prepareHtml(guide.description)}
        ></p>
      </Modal.Body>
    </Modal>
  );
};

export default GuideModal;
