import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import { renderTitle } from "../../utils/helpers";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const DummyProductModal = (props) => {
  const { actions, dummyProductModal, dummyProduct } = props;

  const handleClickPop = (action) => {
    actions.dummyProductAction(action);
  };

  return (
    <>
      <Modal
        className="dummyProduct"
        show={dummyProductModal}
        size="lg"
        onHide={() => handleClickPop(false)}
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton></Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <h5>{dummyProduct.message}</h5>
          <div>
            <Row className="justify-content-center">
              {dummyProduct.product &&
                dummyProduct.product.map((item, index) => (
                  <Col xl="4" lg="6" md="6" sm="6" key={index}>
                    <div className="product">
                      <div className="pro_img">
                        <LazyLoad
                          images={item.image}
                          key={index}
                          imageClass={"productBlur"}
                        />
                        <div className="tab_overlay">
                          <NavLink
                            to={"/product/details/" + item.id}
                            title={item.name}
                            className="btn"
                          >
                            <i className="fas fa-eye"></i>
                          </NavLink>
                        </div>
                      </div>
                      <div className="title py-2">
                        <h5 className="p-0">
                          <NavLink
                            to={"/product/details/" + item.id}
                            title={item.name}
                          >
                            {renderTitle(item.name)}
                          </NavLink>
                        </h5>
                        {item.qty && (
                          <div>
                            <span>Available QTY:&nbsp;</span>
                            <b>{item.qty}</b>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    dummyProductModal: state.dummyProductModal,
    dummyProduct: state.dummyProduct,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(cartActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DummyProductModal);
