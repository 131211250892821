import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as orderActions from "../../actions/orderActions";
import { Container, Row, Col } from "react-bootstrap";
import Item from "./Item";
import { checkConform } from "../../utils/helpers";

import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import NotFound from "../../component/NotFound/NotFound";

const Order = (props) => {
  const { title, actions, notificationData } = props;
  const [BrdName] = useState([
    { title: "Notifications", url: "/user/notification" },
  ]);

  const notFound = {
    title: "You haven't received any notification.",
    description: "",
    image: "/assets/img/notification.png",
    buttion: "Shop Now",
    url: "/marketplace",
  };

  useEffect(() => {
    document.title = title;
    function fetchData() {
      actions.loadMyNotification();
    }
    fetchData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title, actions]);

  const removeItem = (id) => {
    let params = {};
    params.id = id;
    actions.removeNotification(params);
  };

  const handleRemoveAllClick = (data) => {
    const afterCallback = () => {
      let params = {};
      params.id = "all";
      actions.removeNotification(params);
    };
    checkConform(
      afterCallback,
      "Are you sure you want to clear all notifications?"
    );
  };

  return (
    <>
      <Breadcrumbs name={BrdName} />
      <div className="my_account_wrapper">
        <Container>
          <div className="title py-3">{title}</div>
          <Row>
            <Col md={4} lg={3}>
              <Sidebar />
            </Col>
            <Col md={8} lg={9}>
              <div className="order_main p-4">
                <div className="order_main p-4">
                  {notificationData && notificationData.length > 0 && (
                    <div className="notification_btn">
                      <button
                        onClick={() => handleRemoveAllClick({})}
                        className="btn"
                      >
                        Clear All
                      </button>
                    </div>
                  )}
                  <ul className="pt-2 pl-2">
                    {notificationData && notificationData.length > 0 ? (
                      notificationData.map((item, key) => (
                        <Item
                          notification={item}
                          key={key}
                          removeItem={removeItem}
                        />
                      ))
                    ) : (
                      <NotFound {...notFound} />
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Order.propTypes = {
  notificationData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    notificationData: state.notificationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(orderActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
