import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function reviewDataReducer(
  state = initialState.reviewData,
  action
) {
  switch (action.type) {
    case types.LOAD_REVIEW_DATA:
      return action.reviewData;
    default:
      return state;
  }
}
