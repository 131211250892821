import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";

export function likeSellerReview(params) {
  return async function (dispatch) {
    await agent
      .post(API.LIKE_SELLER_REVIEW, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
