import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function productNextAttributeReducers(
  state = initialState.productNextAttribute,
  action
) {
  switch (action.type) {
    case types.PRODUCT_NEXT_ATTRIBUTE_DATA_SUCCESS:
      const newState = state.filter(
        (val) => val.nextKey !== action.productNextAttribute.nextKey
      );
      if (newState.length > 0) {
        return newState.concat(action.productNextAttribute);
      } else {
        return [...state, action.productNextAttribute];
      }
    case types.PRODUCT_NEXT_ATTRIBUTE_DATA_NULL_SUCCESS:
      return [];
    default:
      return state;
  }
}
