import React from 'react';
import {Button,Spinner} from 'react-bootstrap';
import { connect } from "react-redux"; 

const CustomButton = (props) =>{
    const { title,isSubmitting } = props;
    return(
            <Button variant="" type="submit" disabled={isSubmitting} block>
              {isSubmitting ?
                <Spinner
                as="span"
                size="sm"
                role="status"
                animation="grow"
                aria-hidden="true"
                />:<>{title}</>}
            </Button>
    );
};

function mapStateToProps(state) {
    return {
      isSubmitting: state.isSubmitting,
    };
  }
  
export default connect(mapStateToProps)(CustomButton);

 