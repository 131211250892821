import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as userActions from "../../actions/userActions";
import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";
import { Row, Col, Form, FormControl, Container } from "react-bootstrap";
import CustomButton from "../../component/Button/Button";

const Profile = (props) => {
  const { title, actions, userInfo } = props;
  const [BrdName] = useState([{ title: "My Account", url: "/user/Profile" }]);
  const [isMobile, setMobile] = useState(true);
  const { register, errors, handleSubmit } = useForm({
    defaultValues: userInfo,
  });
  useEffect(() => {
    if (!userInfo.number) {
      setMobile(false);
    }
  }, [userInfo]);
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title]);

  const onSubmit = (data) => {
    actions.updateProfileData(data);
  };

  return (
    <>
      <Breadcrumbs name={BrdName} />

      <Container>
        <div className="title my_account_wrapper_title py-3">{title}</div>
        <Row>
          <Col md={4} lg={3}>
            <Sidebar />
          </Col>
          <Col md={6} lg={6}>
            <div className="order_main p-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="no-gutters">
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicFirstName">
                      <FormControl
                        type="text"
                        placeholder="First Name"
                        className={errors.first_name ? "formError" : ""}
                        name="first_name"
                        aria-invalid={errors.first_name ? "true" : "false"}
                        ref={register({
                          required: true,
                          minLength: 3,
                          maxLength: 20,
                        })}
                      />
                      {errors.first_name &&
                        errors.first_name.type === "required" && (
                          <span role="alert">Please enter your first name</span>
                        )}
                      {errors.first_name &&
                        errors.first_name.type === "minLength" && (
                          <span role="alert">
                            Your first name should contain atleast 3 characters
                          </span>
                        )}
                      {errors.first_name &&
                        errors.first_name.type === "maxLength" && (
                          <span role="alert">
                            Your first name should not exceed 20 characters.
                          </span>
                        )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicLastName">
                      <FormControl
                        type="text"
                        placeholder="Last Name"
                        className={errors.last_name ? "formError" : ""}
                        name="last_name"
                        aria-invalid={errors.last_name ? "true" : "false"}
                        ref={register({
                          required: true,
                          minLength: 3,
                          maxLength: 20,
                        })}
                      />
                      {errors.last_name &&
                        errors.last_name.type === "required" && (
                          <span role="alert">Please enter your last name.</span>
                        )}
                      {errors.last_name &&
                        errors.last_name.type === "minLength" && (
                          <span role="alert">
                            Your last name should contain atleast 3 characters.
                          </span>
                        )}
                      {errors.last_name &&
                        errors.last_name.type === "maxLength" && (
                          <span role="alert">
                            Your last name should not exceed 20 characters.
                          </span>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formBasicEmail">
                  <FormControl
                    type="email"
                    placeholder="Email Address"
                    className={errors.email ? "formError" : ""}
                    name="email"
                    readOnly={true}
                    aria-invalid={errors.email ? "true" : "false"}
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      maxLength: 50,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span role="alert">Please enter your email address.</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span role="alert">Invalid email address.</span>
                  )}
                  {errors.email && errors.email.type === "maxLength" && (
                    <span role="alert">
                      Your email should not exceed 50 characters.
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicNumber">
                  <FormControl
                    type="tel"
                    readOnly={isMobile}
                    placeholder="Mobile Number"
                    name="number"
                    className={errors.number ? "formError" : ""}
                    aria-invalid={errors.number ? "true" : "false"}
                    ref={register()}
                  />
                </Form.Group>
                <Row>
                  <Col xs={12} md={6}>
                    <CustomButton title={"Update"} />
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Profile.propTypes = {
  userInfo: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
