import React from "react";
import { Row, Col, Form, Modal, FormControl } from "react-bootstrap";
import config from "../../config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";

import SocialLogin from "./SocialLogin";

const GuestModal = (props) => {
  const { actions, userRegister, userGuest, deviceToken } = props;
  const isToken = deviceToken;
  const { register, errors, handleSubmit } = useForm();
  localStorage.setItem("userGuestRequestFromCartPage", false);

  const onSubmit = (data) => {
    let params = data;
    params.device_type = "web";
    params.device_token = isToken; 
    actions.userGuestData(params);
  };
  const handleclickLogin = (action) => { 
    actions.userRegisterDataSuccess(false);
	localStorage.setItem("userGuestRequestFromCartPage", true);
    actions.userLoginDataSuccess(action);
	actions.userGuestDataSuccess(false);
  };

  const handleclickRegister = (action) => {
    actions.userRegisterDataSuccess(action);
	actions.userLoginDataSuccess(false);
  };
  
  const handleclickGuest = (action) => {
    actions.userGuestDataSuccess(action); 
	actions.userLoginDataSuccess(false);
  };
  
   
  
  const handleclickSeller = () => {
    window.open(config.SELLER_URL, "_blank");
  };
  return (
    <>
      <Modal
        show={userGuest}
        onHide={() => handleclickGuest(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec- guest-login-modal"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Continue as Guest</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="no-gutters">
              <Col xs={12} md={6}>
                <Form.Group controlId="formBasicFirstName">
                  <FormControl
                    type="text"
                    placeholder="First Name"
                    className={errors.first_name ? "formError" : ""}
                    name="first_name"
                    aria-invalid={errors.first_name ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  {errors.first_name &&
                    errors.first_name.type === "required" && (
                      <span role="alert">Please enter your first name.</span>
                    )}
                  {errors.first_name &&
                    errors.first_name.type === "minLength" && (
                      <span role="alert">
                        Your first name should contain atleast 3 characters.
                      </span>
                    )}
                  {errors.first_name &&
                    errors.first_name.type === "maxLength" && (
                      <span role="alert">
                        Your first name should not exceed 20 characters.
                      </span>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formBasicLastName">
                  <FormControl
                    type="text"
                    placeholder="Last Name"
                    className={errors.last_name ? "formError" : ""}
                    name="last_name"
                    aria-invalid={errors.last_name ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  {errors.last_name && errors.last_name.type === "required" && (
                    <span role="alert">Please enter your last name.</span>
                  )}
                  {errors.last_name &&
                    errors.last_name.type === "minLength" && (
                      <span role="alert">
                        Your last name should contain atleast 3 characters.
                      </span>
                    )}
                  {errors.last_name &&
                    errors.last_name.type === "maxLength" && (
                      <span role="alert">
                        Your last name should not exceed 20 characters.
                      </span>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                type="email"
                placeholder="Email Address"
                className={errors.email ? "formError" : ""}
                name="email"
                aria-invalid={errors.email ? "true" : "false"}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                  maxLength: 50,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span role="alert">Please enter your email address.</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span role="alert">Invalid email address.</span>
              )}
              {errors.email && errors.email.type === "maxLength" && (
                <span role="alert">
                  Your email should not exceed 50 characters.
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicNumber">
              <FormControl
                type="tel"
                placeholder="Mobile Number"
                name="number"
                className={errors.number ? "formError" : ""}
                aria-invalid={errors.number ? "true" : "false"}
                ref={register({
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                  },
                })}
              />
              {errors.number && errors.number.type === "required" && (
                <span role="alert">Please enter your mobile number.</span>
              )}
              {errors.number && errors.number.type === "maxLength" && (
                <span role="alert">
                  Your mobile number should be 10 digits long only.
                </span>
              )}
              {errors.number && errors.number.type === "minLength" && (
                <span role="alert">
                  Your mobile number should contain atleast 10 digits long.
                </span>
              )}
              {errors.number && errors.number.type === "pattern" && (
                <span role="alert">
                  Please enter correct mobile number. It should be entered
                  without country code.( Example xxxxxxxxxx)
                </span>
              )}
            </Form.Group>
            
           
			
			 
			<div class="modal-title h4 mb-1">Shipping / Billing Information</div> 
			<Row> 
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Address*</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="address"
                    cols="30"
                    rows="5" 
                    className={errors.address ? "formError" : ""}
                    aria-invalid={errors.address ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 20,
                      maxLength: 200,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.address &&
                    (errors.address.type === "required" ||
                      errors.address.type === "validate") && (
                      <span role="alert">Please enter your address.</span>
                    )}
                  {errors.address && errors.address.type === "minLength" && (
                    <span role="alert">
                      Your address should contain atleast 20 characters.
                    </span>
                  )}
                  {errors.address && errors.address.type === "maxLength" && (
                    <span role="alert">
                      Your address should not exceed 200 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>City/District/Town*</Form.Label>
                  <FormControl
                    name="city"
                    type="text" 
                    className={errors.city ? "formError" : ""}
                    aria-invalid={errors.city ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.city &&
                    (errors.city.type === "required" ||
                      errors.city.type === "validate") && (
                      <span role="alert">Please enter your city.</span>
                    )}
                  {errors.city && errors.city.type === "minLength" && (
                    <span role="alert">
                      Your city should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.city && errors.city.type === "maxLength" && (
                    <span role="alert">
                      Your city should should not 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>State*</Form.Label>
                  <FormControl
                    name="state"
                    type="text" 
                    className={errors.state ? "formError" : ""}
                    aria-invalid={errors.state ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.state &&
                    (errors.state.type === "required" ||
                      errors.state.type === "validate") && (
                      <span role="alert">Please enter your state.</span>
                    )}
                  {errors.state && errors.state.type === "minLength" && (
                    <span role="alert">
                      Your state should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.state && errors.state.type === "maxLength" && (
                    <span role="alert">
                      Your state should not exceed 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>Landmark*</Form.Label>
                  <FormControl
                    name="landmark"
                    type="text" 
                    className={errors.landmark ? "formError" : ""}
                    aria-invalid={errors.landmark ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.landmark &&
                    (errors.landmark.type === "required" ||
                      errors.landmark.type === "validate") && (
                      <span role="alert">Please enter your landmark.</span>
                    )}
                  {errors.landmark && errors.landmark.type === "minLength" && (
                    <span role="alert">
                      Your landmark should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.landmark && errors.landmark.type === "maxLength" && (
                    <span role="alert">
                      Your landmark should should not 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>Alternate Contact Number(optional)</Form.Label>
                  <FormControl
                    type="tel" 
                    name="alternate_number"
                    className={errors.alternate_number ? "formError" : ""}
                    aria-invalid={errors.alternate_number ? "true" : "false"}
                    ref={register({
                      minLength: 10,
                      maxLength: 10,
                      pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                      },
                      validate: (value) => {
                        //return !!value.trim();
                      },
                    })}
                  />
                  {errors.alternate_number &&
                    (errors.alternate_number.type === "required" ||
                      errors.alternate_number.type === "validate") && (
                      <span role="alert">
                        Please enter your alternate contact number.
                      </span>
                    )}
                  {errors.alternate_number &&
                    errors.alternate_number.type === "maxLength" && (
                      <span role="alert">
                        Your alternate contact number should not exceed 10
                        digits.
                      </span>
                    )}
                  {errors.alternate_number &&
                    errors.alternate_number.type === "minLength" && (
                      <span role="alert">
                        Your alternate contact number should contain atleast 10
                        digits.
                      </span>
                    )}
                  {errors.alternate_number &&
                    errors.alternate_number.type === "pattern" && (
                      <span role="alert">
                        Please enter correct contact number. It should be
                        entered without country code.( Example xxxxxxxxxx)
                      </span>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Pincode*</Form.Label>
                  <FormControl
                    type="tel" 
                    className={errors.pincode ? "formError" : ""}
                    name="pincode"
                    aria-invalid={errors.pincode ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 6,
                      maxLength: 6,
                      pattern: {
                        value: /^[0-9\s]*$/i,
                      },
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.pincode &&
                    (errors.pincode.type === "required" ||
                      errors.pincode.type === "validate") && (
                      <span role="alert" className="alertError">
                        Please enter your pincode.
                      </span>
                    )}
                  {errors.pincode && errors.pincode.type === "minLength" && (
                    <span role="alert" className="alertError">
                      Pincode should contain atleast 6 digits.
                    </span>
                  )}
                  {errors.pincode && errors.pincode.type === "maxLength" && (
                    <span role="alert" className="alertError">
                      Pincode should not exceed 6 digits.
                    </span>
                  )}
                  {errors.pincode && errors.pincode.type === "pattern" && (
                    <span role="alert">Please enter digits only.</span>
                  )}
                </Form.Group>
              </Col>
              {/* <Col xs={12}>
                <div className="border-top pt-4 mt-3">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={details.is_billing}
                    label="Use same address as billing information"
                    className={errors.is_billing ? "formError" : ""}
                    name="is_billing"
                    aria-invalid={errors.is_billing ? "true" : "false"}
                    ref={register()}
                  />
                </div>
              </Col> */}
            </Row>
			
			
			 <Form.Group
              controlId="formBasicCheckboxRegister"
              className="remember pt-2"
            >
              <div>
                <Form.Check
                  type="checkbox"
                  label="I Accept Terms of Use and Privacy Policy."
                  className={errors.terms_use ? "formError" : ""}
                  name="terms_use"
                  aria-invalid={errors.terms_use ? "true" : "false"}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.terms_use && errors.terms_use.type === "required" && (
                  <span role="alert">
                    Please Accept Terms of Use and Privacy Policy.
                  </span>
                )}
              </div>
            </Form.Group>
			
			
			 
			
            <div className="text-center">
				<CustomButton title={"Continue"} />
			</div>
            <div className="orLogin d-none">
              <span>Or Login with</span>
            </div>
			 
            <div className="social_login d-none">
              <SocialLogin isToken={isToken} />
            </div>
            <div className="no_accout text-center d-none">
              <p className="mb-0">
                Already have an account?{" "}
                <span className="link" onClick={() => handleclickLogin(true)}>
                  Login
                </span>
              </p>
              <span className="link" onClick={() => handleclickSeller()}>
                Join as Seller
              </span>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userGuest: state.userGuest,
    deviceToken: state.deviceToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestModal);
