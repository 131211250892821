import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadhotProductDataSuccess(hotProductData) {
  return { type: types.LOAD_HOT_PRODUCT_DATA_SUCCESS, hotProductData };
}

export function loadRealSpacesDataSuccess(realSpaces) {
  return { type: types.LOAD_REALSPACE_DATA_SUCCESS, realSpaces };
}

export function loaddesignDataSuccess(homeDesigen) {
  return { type: types.LOADED_DEAIGEN_INFO_SUCCESS, homeDesigen };
}

export function loadActionStatusSuccess(actionStaus) {
  return { type: types.LOADED_ACTION_STATUS_SUCCESS, actionStaus };
}

export function loadhotProductData() {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.WHAT_HOT_PRODUCT)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadhotProductDataSuccess(response.data.data));
          }
          dispatch(toggleNetworkRequestStatus(false));
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadRealSpaces() {
  return async function (dispatch) {
    await agent
      .get(API.GET_REAL_SPACES)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadRealSpacesDataSuccess(response.data.data));
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadHomeDesign() {
  return async function (dispatch) {
    await agent
      .get(API.GET_HOME_PAGE_DESIGEN)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loaddesignDataSuccess(response.data.data));
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadActionStatus() {
  return async function (dispatch) {
    dispatch(loadActionStatusSuccess(false));
  };
}
