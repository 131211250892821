import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import "./LoginSign.css";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import config from "../../config";
import { showToaster } from "../../utils/helpers";

const SocialLogin = (props) => {
  const { actions, isToken } = props;

  const responseFacebook = (response) => {
    const data = response;
    if (response.name) {
      var name = data.name.split(" ");
      let params = {};
      params.device_type = "web";
      params.device_token = isToken;
      params.first_name = name[0];
      params.last_name = name[1];
      params.email = data.email;
      params.social_id = data.id;
      params.social_type = "Facebook";
      actions.userSocialLoginData(params);
    }
  };
  const responseGoogle = (response) => {
    console.log("response ==>", response);
    if (response.error !== "idpiframe_initialization_failed") {
      if (response.error) {
        showToaster(response.error);
      }
      const data = response.profileObj;
      var name = data.name.split(" ");
      let params = {};
      params.device_type = "web";
      params.device_token = isToken;
      params.first_name = name[0];
      params.last_name = name[1];
      params.email = data.email;
      params.social_id = data.googleId;
      params.social_type = "gmail";
      actions.userSocialLoginData(params);
    }
  };

  return (
    <>
      <FacebookLogin
        appId={config.FACEBOOK_CLIENT_ID}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="fb"
        icon="fab fa-facebook-f mr-3"
      />
      <GoogleLogin
        clientId={config.GOOGLE_CLIENT_ID}
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
        className="Gplus"
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SocialLogin);
