import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as userActions from "../../actions/userActions";
import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";
import { Row, Col, Form, FormControl, Container } from "react-bootstrap";
import CustomButton from "../../component/Button/Button";

const ChangePassword = (props) => {
  const { title, actions } = props;
  const [BrdName] = useState([
    { title: "Change Password", url: "/user/changePassword" },
  ]);
  const { register, errors, handleSubmit } = useForm();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfo,
  }));

  useEffect(() => {
    actions.getUserData();

    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title]);

  const onSubmit = (data) => {
    console.log(data);
    actions.updatePasswordData(data);
  };

  return (
    <>
      <Breadcrumbs name={BrdName} />

      <Container>
        <div className="title my_account_wrapper_title py-3">{title}</div>
        <Row>
          <Col md={4} lg={3}>
            <Sidebar />
          </Col>
          <Col md={6} lg={6}>
            <div className="order_main p-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                {userInfo.password == 1 && (
                  <Form.Group controlId="formBasicCurrentPassword">
                    <FormControl
                      type="password"
                      className={errors.current_password ? "formError" : ""}
                      placeholder="Current Password"
                      name="current_password"
                      aria-invalid={errors.current_password ? "true" : "false"}
                      ref={register({
                        required: true,
                        minLength: 6,
                        pattern: {
                          value:
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                        },
                      })}
                    />
                    {errors.current_password &&
                      errors.current_password.type === "required" && (
                        <span role="alert">Please enter your password</span>
                      )}
                    {errors.current_password &&
                      errors.current_password.type === "minLength" && (
                        <span role="alert">
                          Your password should contain atleast 6 characters
                        </span>
                      )}
                    {errors.current_password &&
                      errors.current_password.type === "pattern" && (
                        <span role="alert">
                          Your password should contain at-least 1 Uppercase, 1
                          Lowercase, 1 Numeric and 1 special character
                        </span>
                      )}
                  </Form.Group>
                )}
                <Form.Group controlId="formBasicPassword">
                  <FormControl
                    type="password"
                    className={errors.password ? "formError" : ""}
                    placeholder="Password"
                    name="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 6,
                      pattern: {
                        value:
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/i,
                      },
                    })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <span role="alert">Please enter your password</span>
                  )}
                  {errors.password && errors.password.type === "minLength" && (
                    <span role="alert">
                      Your password should contain atleast 6 characters
                    </span>
                  )}
                  {errors.password && errors.password.type === "pattern" && (
                    <span role="alert">
                      Your password should contain at-least 1 Uppercase, 1
                      Lowercase, 1 Numeric and 1 special character
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicpasswordConfirmation">
                  <FormControl
                    type="password"
                    className={errors.password_confirmation ? "formError" : ""}
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    aria-invalid={
                      errors.password_confirmation ? "true" : "false"
                    }
                    ref={register({
                      required: true,
                      minLength: 6,
                      pattern: {
                        value:
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/i,
                      },
                    })}
                  />
                  {errors.password_confirmation &&
                    errors.password_confirmation.type === "required" && (
                      <span role="alert">Please enter your password</span>
                    )}
                  {errors.password_confirmation &&
                    errors.password_confirmation.type === "minLength" && (
                      <span role="alert">
                        Your password should contain atleast 6 characters
                      </span>
                    )}
                  {errors.password_confirmation &&
                    errors.password_confirmation.type === "pattern" && (
                      <span role="alert">
                        Your password should contain at-least 1 Uppercase, 1
                        Lowercase, 1 Numeric and 1 special character
                      </span>
                    )}
                </Form.Group>

                <Row>
                  <Col xs={12} md={6}>
                    <CustomButton title={"Update"} />
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

ChangePassword.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ChangePassword);
