import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function bannerReducers(state = initialState.bannerData, action) {
  switch (action.type) {
    case types.LOAD_BANNER_DATA_SUCCESS:
      return action.bannerData
    default:
      return state
  }
}
