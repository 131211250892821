import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function productFilterReducers(state = initialState.productFilterData, action) {
  switch (action.type) {
    case types.LOAD_PRODUCT_FILTER_DATA_SUCCESS:
      return action.productFilterData
    default:
      return state
  }
}
