import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Newsletter from "../../component/Newsletter/Newsletter";

const Index = () => {
  useEffect(() => {
    document.title = "404";
  });

  return (
    <section className="about_sec">
      <div className="text-center not_found_error">
        <img src="../assets/img/error_page.svg" alt="not_found"></img>
      </div>
      <Container></Container>
      <Newsletter />
    </section>
  );
};

export default Index;
