import React from "react"
import { Modal, Row, Col, ProgressBar } from "react-bootstrap"
import Review from "../../component/SellerReview/review"

const SellerDetailModal = (props) => {
  const { details, order } = props

  const handleAddSellerReviewClick = () => {
    props.onChange()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="seller_info_popup"
      centered
    >
      <Modal.Header className=" mb-4 px-4 pt-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Supplier Ratings & Reviews
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 seller_info">
        <div className="text-center px-4">
          <div className="seller_brand">
            <img src={details.star} alt="sellerImage" />
          </div>
          {/* <div className="seller_title py-3">{details.name}</div> */}
        </div>
        {order && (
          <div className="seller_descraption px-4 py-2">
            <div className="text-right">
              <div className="pr-5">
                {/* <div className="heading">Seller Address</div> */}
                {/* <p className="m-0 ">
                {details.address_line_1},{details.address_line_2},{details.city}
                ,{details.state},{details.country} - {details.pin_code}
              </p> */}
                <p className="m-0 ">&nbsp;</p>
              </div>
              <div className="telegram" onClick={handleAddSellerReviewClick}>
                <i className="fab fa-telegram"></i>
              </div>
            </div>
          </div>
        )}
        {/* <div className="seller_descraption px-4 py-2">
          <div className="heading">Timing</div>

          <div className="d-flex align-items-center">
            <p className="m-0">Today:{details.time}</p>
            <div className="open">
              {" "}
              <span className="color_green px-1">open</span>{" "}
            </div>
          </div>
        </div> */}
        <div className="border-top px-4 py-4 mt-3">
          <Row className="align-items-center ">
            <Col xs={12} lg={4} className="mb-3 mb-lg-0">
              <div className="rating-block">
                <h2>
                  {details.avgReviews}{" "}
                  <small>
                    <i className="fas fa-star active"></i>
                  </small>
                </h2>
                <span>
                  {details.avgReviews} Rating &amp; {details.review.length}{" "}
                  Reviews
                </span>
              </div>
            </Col>
            <Col xs={12} lg={8}>
              {details.sellerStar &&
                details.sellerStar.length > 0 &&
                details.sellerStar.map((review, key) => (
                  <div
                    className="d-flex align-items-center star_block py-1"
                    key={key}
                  >
                    <div className="progress_star">
                      {review.star} <span className="fas fa-star"></span>
                    </div>
                    <ProgressBar now={review.value} />
                    <div className="progress_count text-right pl-2 ml-auto">
                      {review.value}
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        </div>
        <div className="seller_main">
          {details.review &&
            details.review.length > 0 &&
            details.review.map((review, key) => (
              <Review key={key} review={review} onClick={props.onClick} />
            ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SellerDetailModal
