// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userInfo: {},
  userParams: {},
  otpData: {},
  homePageData: {},
  isFetching: false,
  bannerData: [],
  packageData: [],
  brandData: [],
  packageRoomClassData: [],
  parentcategoryData: [],
  categoryProductData: [],
  trandingProductData: [],
  hotProductData: [],
  productData: [],
  pagination: {},
  productFilterData: {},
  advertisingData: {},
  isProduct: false,
  detailsData: {},
  isproductDetails: false,
  userLogin: false,
  userRegister: false,
  userGuest: false,
  userOtp: false,
  forgotPassword: false,
  isWishList: false,
  wishCount: 0,
  productAttribute: [],
  productNextAttribute: [],
  isCart: false,
  cartCount: 0,
  recentlyView: [],
  productReview: [],
  productReviewMore: [],
  cartItem: {},
  isCartItem: false,
  couponModel: false,
  couponCode: [],
  isAuth: false,
  orderItem: [],
  wishlistItem: {},
  isSubmitting: false,
  footerProduct: [],
  isAvailability: false,
  addressData: [],
  setting: {},
  dummyOrder: false,
  isStock: false,
  locationPop: true,
  pinCode: "",
  headerProduct: [],
  reviewData: [],
  footerCategory: [],
  faqData: [],
  realSpaces: [],
  homeDesigen: {},
  actionStaus: false,
  cancleModal: false,
  deviceToken: null,
  sellerOrderData: [],
  productSliderImage: [],
  dummyProductModal: false,
  dummyProduct: {},
  pageList: [],
  notificationData: [],
  isWishListData: false,
  footerSupplier: [],
};
