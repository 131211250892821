import curve from "./images/curve.png";
import img1 from "./images/krds1.jpg";
import img2 from "./images/krds2.jpg";
import img3 from "./images/krds3.jpg";
import img4 from "./images/krds4.jpg";
import img5 from "./images/krds5.jpg";
import img6 from "./images/krds6.jpg";
import img7 from "./images/krds7.jpg";
import img8 from "./images/krds8.jpg";
import img9 from "./images/krds9.jpg";
import img10 from "./images/krds10.jpg";
import img11 from "./images/krds11.jpg";
import img12 from "./images/krds12.jpg";

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useLocation, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper";

const KidsRoomDesignServices = (props) => {
  const { actions, homePageData, isSubmitting } = props;
  const { slug } = useParams();

  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  const onSubmit = (data) => {
    console.log(JSON.stringify(data), "Prem");
    data.request_for = "Design Services";
    //return;
    actions.sendSpecialRequest(data);
  };

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    document.title = "Kids Room Design Services";
  });

  const images = [
    {
      img: img1,
      img2: img2,
      img3: img4,
      img4: img5,
      img5: img6,
      img6: img8,
    },
    {
      img: img9,
      img2: img10,
      img3: img7,
      img4: img11,
      img5: img12,
    },
  ];
  const [selectedImage, setSelectedImage] = useState("");
  const openPopup = (image) => {
    setSelectedImage(image);
  };
  const closePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <section className="about-supplier">
        <div className="container">
          <h3 className="common-heading text-uppercase mb-1">
            Kids Room Design Services
          </h3>
          <p className="mb-0">
            Explore our specially curated Kids Room Designs to create a playful
            and functional area for your kid's growth and to allow them to
            explore their environment in a safe and healthy environment.
          </p>
        </div>
      </section>
      <img src={curve} alt="curve" className="img-fluid w-100" />

      <div className="design-service-gallery pt-2 pb-5 mb-md-3">
        <div className="container">
          <div className="new-project-gallery">
            <div className="text-center">
              <h3 className="common-heading text-uppercase mb-4 mt-3">
                Project Work <span style={{ fontFamily: 'system-ui' }}>-</span> Kids Room
              </h3>
            </div>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={15}
              slidesPerView={1}
              // loop={true}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              pagination={{
                clickable: true,
              }}
              navigation
              className="mySwiper"
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                },
                "@1.00": {
                  slidesPerView: 1,
                },
                "@1.50": {
                  slidesPerView: 1,
                },
              }}
            >
              {images?.map((image, index) => (
                <SwiperSlide className="sdsd">
                  <div className="new-project-gallery-box kids-room">
                    <img
                      key={index}
                      onClick={() => openPopup(image.img)}
                      src={image.img}
                    />
                    <img
                      key={index}
                      onClick={() => openPopup(image.img2)}
                      src={image.img2}
                    />
                    <img
                      key={index}
                      onClick={() => openPopup(image.img3)}
                      src={image.img3}
                    />
                    <img
                      key={index}
                      onClick={() => openPopup(image.img4)}
                      src={image.img4}
                    />
                    <img
                      key={index}
                      onClick={() => openPopup(image.img5)}
                      src={image.img5}
                    />
                    <img
                      key={index}
                      onClick={() => openPopup(image.img6)}
                      src={image.img6}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {selectedImage && (
              <div className="gallery_popup gallery_">
                <span className="close" onClick={closePopup}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-xlg"
                    viewBox="0 0 25 25"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </span>
                <img src={selectedImage} alt="Selected Image" />
              </div>
            )}
          </div>
        </div>
      </div>

      <section className="how-it-works mb-5">
        <div className="container">
          <div className="row mx-0">
            <div className="col-12 bg-light px-4 py-5">
              <div className="row px-2">
                <div className="col-md-6">
                  <h3 className="common-heading-2">How it works:</h3>
                  <p>
                    Once you make your selection above, our qualified partners
                    are notified of your choices and start working on the
                    drawings. Once done, you will be notified via the platform
                    and will be able to communicate with our partner to suggest
                    any changes on what has been submitted.
                  </p>
                  <strong>The process usually takes 5 business days.</strong>
                  <h5>Happy Designing!</h5>
                </div>
                <div className="col-md-6">
                  <h3 className="common-heading-2">Any Special requests</h3>
                  <Form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      {/*<textarea className="form-control" placeholder="Any special requests" required defaultValue={""} />*/}
                      <div className="form-group">
                        <input
                          type="hidden"
                          name="request_for"
                          value="Design Services"
                        />
                        <Form.Group controlId="formBasicFirstName">
                          <FormControl
                            type="text"
                            placeholder="Name"
                            className={errors.name ? "formError" : ""}
                            name="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 3,
                              maxLength: 20,
                            })}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span role="alert">Please enter your name.</span>
                          )}
                          {errors.name && errors.name.type === "minLength" && (
                            <span role="alert">
                              Your name should contain atleast 3 characters.
                            </span>
                          )}
                          {errors.name && errors.name.type === "maxLength" && (
                            <span role="alert">
                              Your name should should not 20 characters.
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group controlId="formBasicEmail">
                          <FormControl
                            type="email"
                            placeholder="Email"
                            className={errors.email ? "formError" : ""}
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            ref={register({
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              },
                              maxLength: 50,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <span role="alert">
                              Please enter your email address.
                            </span>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <span role="alert">Invalid email address.</span>
                          )}
                          {errors.email &&
                            errors.email.type === "maxLength" && (
                              <span role="alert">
                                Your email should not exceed 50 characters.
                              </span>
                            )}
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group controlId="formBasicNumber">
                          <FormControl
                            type="tel"
                            placeholder="Phone Number"
                            name="number"
                            className={errors.number ? "formError" : ""}
                            aria-invalid={errors.number ? "true" : "false"}
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                              },
                            })}
                          />
                          {errors.number &&
                            errors.number.type === "required" && (
                              <span role="alert">
                                Please enter your mobile number.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "maxLength" && (
                              <span role="alert">
                                Your mobile number should not exceed 10 digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "minLength" && (
                              <span role="alert">
                                Your mobile number should contain atleast 10
                                digits.
                              </span>
                            )}
                          {errors.number &&
                            errors.number.type === "pattern" && (
                              <span role="alert">
                                Please enter correct mobile number. It should be
                                entered without country code.( Example
                                xxxxxxxxxx)
                              </span>
                            )}
                        </Form.Group>
                      </div>
                      <FormControl
                        as="textarea"
                        placeholder="Special Instructions"
                        className={errors.message ? "formError" : ""}
                        style={{ height: "100px" }}
                        name="message"
                        aria-invalid={errors.message ? "true" : "false"}
                        ref={register({
                          required: true,

                          minLength: 5,
                        })}
                      />
                      {errors.message && errors.message.type === "required" && (
                        <span role="alert">
                          Please enter your special requests.
                        </span>
                      )}
                      {errors.message &&
                        errors.message.type === "minLength" && (
                          <span role="alert">
                            Your special request should minimum 5 characters.
                          </span>
                        )}
                    </div>
                    {/*<button className="btn custom-btn" >Submit</button>*/}
                    <Button
                      variant=""
                      className="btn custom-btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner
                          as="span"
                          size="sm"
                          role="status"
                          animation="grow"
                          aria-hidden="true"
                        />
                      ) : (
                        <>Submit</>
                      )}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

//export default Stuvita;
function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KidsRoomDesignServices);
