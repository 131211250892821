import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkConform } from "../../utils/helpers";
import * as userActions from "../../actions/userActions";
import { useHistory } from "react-router-dom";

const Menu = (props) => {
  const { actions, deviceToken } = props;
  const { push } = useHistory();
  const handleLogoutClick = () => {
    const afterLogoutCallback = () => {
      let params = {};
      const isToken = deviceToken;
      params.device_type = "web";
      params.device_token = isToken;
      actions.userLogout(params, push);
    };

    checkConform(
      afterLogoutCallback,
      "Are you sure you want to logout account?"
    );
  };

  return (
    <>
      <ul>
        <li>
          <NavLink to="/user/profile" title="My Profile" exact>
            <i className="fas fa-user-circle"></i> Account
          </NavLink>
        </li>


        <li>
          <NavLink to="/user/order" title="My Orders" exact>
            <i className="fas fa-shopping-bag"></i> Orders
          </NavLink>
        </li>
        <li>
          <NavLink to="#" title="Logout" onClick={handleLogoutClick}>
            <i className="fas fa-sign-out-alt"></i> Logout
          </NavLink>
        </li>
      </ul>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

function mapStateToProps(state) {
  return {
    deviceToken: state.deviceToken,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
