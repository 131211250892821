import React, { useEffect, useRef, useState,useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import * as productActions from "../../actions/productActions";
import * as packageActions from "../../actions/packageActions"
import { Container, Form, FormControl, Button, Spinner, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import banner from "./images/banner.jpg"; 

import curve from './curve.png';
import icon1 from './images/kitchen-packages.png';
import icon2 from './images/bathroom.png';
import icon3 from './images/wardrobes.png';
import icon4 from './images/kids-rooms.png';
import icon5 from './images/outdoor.png';
import icon6 from './images/interior-design.png';
import no_image from './images/product_no_image.png';
import kitchen_image from '../kitchenPackages/images/gallery-5.jpg';
import { Outlet, Link } from "react-router-dom";

import "./DetailsPage.css"; 
import "./packages.css"; 

const Packages = (props) => {
  const { actions, homePageData, isSubmitting } = props;
  
  const { category, discount, length, rating, maxPrice } = props.productFilterData; 
  //console.log("================productFilterData========");
	//console.log(props.productFilterData); 
  const { slug } = useParams();

  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const [loadFilter, setLoadFilter] = useState(true);
  const [loadPackage, setLoadPackage] = useState(true);
  const [loadPackageRoomClass, setLoadPackageRoomClass] = useState(true);
  const [showDefaultPackagePage, setShowDefaultPackagePage] = useState(true);
  const [showPackageDetails, setShowPackageDetails] = useState(false);
  const [roomClassName, setRoomClassName] = useState('');
  
  const location = useLocation();
  
  const onSubmit = (data) => {
    console.log(JSON.stringify(data),'Prem');
    //return;
    actions.sendSpecialRequest(data);
  };

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" }
  };

	const fetchPackage = useCallback(() => {
	  const params = new URLSearchParams(location.search);
	  
	   const request = {};
	   if (params.get("title")) {
			request.room_class = params.get("title");
			setShowDefaultPackagePage(false);
			setRoomClassName(params.get("title"));			
		}
		if (params.get("id")) {
			request.id = params.get("id");
			setShowPackageDetails(true);
		}
		
		actions.loadPackageData(request);
	}, [actions, location]); 

	
  
   const fetchData = useCallback(() => {
    if (loadFilter) {
      actions.loadproductFilterData();
    }
    setLoadFilter(false); 
	fetchPackage();
	if (loadPackageRoomClass) {	
		actions.loadPackageRoomClassData();
	}
	setLoadPackageRoomClass(false); 
	//console.log("================productFilterData========");
	//console.log(props.productFilterData); 
	console.log("================packageRoomClassData========");
	console.log(props.packageRoomClassData); 	
     
   }, [actions, loadFilter,fetchPackage,loadPackageRoomClass]);

  
  
  useEffect(() => {
	setShowDefaultPackagePage(true);
	setShowPackageDetails(false);  
	fetchData();	  
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [fetchData]);
  
   useEffect(() => {
    document.title = "Packages";
  });

const renderCategories = () => {
    if (category !='undefined' && category && category.length > 0) {
      return category.map((item, index) => ( (item.title && item.title =='By Rooms/Space' ) ? (
			<div>
			{item.childrenCategories.map((category) => (
              <li> 
			    <NavLink to={"/packages?title="+category.title}>
                        <img src={icon6} /> {category.title}
                      </NavLink></li>
			))}	
			</div>) : (<span></span>)
      ));
    } 
    return null;
  };  


const renderRoomClass = () => {
    if (props.packageRoomClassData !='undefined' && props.packageRoomClassData && props.packageRoomClassData.length > 0) {
      return props.packageRoomClassData.map((item, index) => ( (item.room_class && item.room_class !='' ) ? (
			<div> 
              <li> 
			    <NavLink to={"/packages?title="+item.room_class}>
                        <img src={icon6} /> {item.room_class}
                      </NavLink></li>
			 
			</div>) : (<span></span>)
      ));
    } 
    return null;
  }; 

const renderRoomClassList = () => {
    if (props.packageData !='undefined' && props.packageData && props.packageData.length > 0) {
      return props.packageData.map((item, index) => ( (item.name && item.name !='' ) ? (
			<div className="col-lg-12 row listRow"><div className="col-lg-6 col-md-6 col-sm-6"><NavLink to={"/packages?id="+item.encode_package_id}>
                       {item.name}
                      </NavLink></div><div className="col-lg-6 col-md-6 col-sm-6">{item.price_level}</div></div>) : (<span></span>)
      ));
    } 
    return null;
  }; 
  
 const renderAllList = () => {
    if (props.packageData !='undefined' && props.packageData && props.packageData.length > 0) {
      return props.packageData.map((item, index) => ( (item.name && item.name !='' ) ? (
			<div className="product col-md-3 col-sm-6 col-12 mb-4"> <div className="pro_img"> <div> <span className="productBlur lazy-load-image-background blur lazy-load-image-loaded" ><NavLink to={"/packages?id="+item.encode_package_id}>{(item.thumbnail_image_url!='') ? (<img src={item.thumbnail_image_url} alt={item.name}   />) : (<img src={item.image_url} alt={item.name}   />)}</NavLink></span> </div> </div> <div className="title"> <h5><NavLink to={"/packages?id="+item.encode_package_id}>{item.name}</NavLink><span>{item.room_class}</span></h5></div> </div>) : (<span></span>)
      ));
    } 
    return null;
  }; 

if (showPackageDetails) {
return (
    <div>
      <section className="detail-top-section mb-4">
        <div className="container">
          <div className="bg-light p-4">
            <div className="row">
			 <div className="col-md-12"><h3 class="common-heading text-uppercase mb-4">{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0) ? (props.packageData[0].name): ("")}</h3></div>
              
              <div className="col-md-5">
                <h5><strong>Package For:</strong> {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0) ? (props.packageData[0].room_class): ("")}</h5>
              </div>
              <div className="col-md-5">
                <h5><strong>Price:</strong> ₹{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0) ? (props.packageData[0].price_level): ("")}</h5>
              </div>
              
              <div className="col-md-12">
                <h5 className="mb-0">Curated by Designiche Styling Team</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner mb-4">
        <div className="container">
		{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].image_url!='') ? (<img src={props.packageData[0].image_url} alt="" className="img-fluid" />) : ("")}
          
        </div>
      </section>

      <section className="product-included">
        <div className="container">
		  {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && ( props.packageData[0].product_fullname_1!='' || props.packageData[0].product_fullname_2!='' || props.packageData[0].product_fullname_3!='' || props.packageData[0].product_fullname_4!='' || props.packageData[0].product_fullname_5!='' || props.packageData[0].product_fullname_6!='' || props.packageData[0].product_fullname_7!='' || props.packageData[0].product_fullname_8!='' || props.packageData[0].product_fullname_9!='' || props.packageData[0].product_fullname_10!='')) ? (<h3 className="common-heading mb-0 text-uppercase mt-5">Products Included in the Package</h3> ): ("")}
			
			 
          <ol className="d-none none">
		  {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_1!='') ? (<li>
              {props.packageData[0].product_fullname_1}  <a href={props.packageData[0].product_link_1}  className="p-l-20" target="_blank">{((props.packageData[0].product_link_1 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
            
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_2!='') ? (<li>
              {props.packageData[0].product_fullname_2} <a href={props.packageData[0].product_link_2} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_2 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_3!='') ? (<li>
              {props.packageData[0].product_fullname_3} <a href={props.packageData[0].product_link_3} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_3 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_4!='') ? (<li>
              {props.packageData[0].product_fullname_4} <a href={props.packageData[0].product_link_4} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_4 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_5!='') ? (<li>
              {props.packageData[0].product_fullname_5} <a href={props.packageData[0].product_link_5} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_5 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_6!='') ? (<li>
              {props.packageData[0].product_fullname_6} <a href={props.packageData[0].product_link_6} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_6 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_7!='') ? (<li>
              {props.packageData[0].product_fullname_7} <a href={props.packageData[0].product_link_7} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_7 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_8!='') ? (<li>
              {props.packageData[0].product_fullname_8} <a href={props.packageData[0].product_link_8} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_8 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_9!='') ? (<li>
              {props.packageData[0].product_fullname_9} <a href={props.packageData[0].product_link_9} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_9 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
			
			{(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_10!='') ? (<li>
              {props.packageData[0].product_fullname_10} <a href={props.packageData[0].product_link_10} className="p-l-20"  target="_blank">{((props.packageData[0].product_link_10 !=''  ) ? "CLICK TO VIEW": "")}</a>
            </li> ): ("")}
             
          </ol>
		  
		  
      
      <section className="product-included pb-5 pt-4">
        <Row> 
      
      {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_1!='') ? ( 
      <Col lg={3}>
            <a href={props.packageData[0].product_link_1} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_1}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_1 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_1}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_1}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_1}</span>
                </div> 
                <a href={props.packageData[0].product_link_1} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_2!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_2} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_2}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_2 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_2}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_2}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_2}</span>
                </div> 
                <a href={props.packageData[0].product_link_2} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_3!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_3} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_3}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_3 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_3}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_3}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_3}</span>
                </div> 
                <a href={props.packageData[0].product_link_3} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_4!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_4} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_4}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_4 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_4}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_4}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_4}</span>
                </div> 
                <a href={props.packageData[0].product_link_4} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_5!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_5} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_5}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_5 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_5}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_5}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_5}</span>
                </div> 
                <a href={props.packageData[0].product_link_5} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_6!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_6} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_6}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_6 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_6}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_6}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_6}</span>
                </div> 
                  <a href={props.packageData[0].product_link_6} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_7!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_7} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_7}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_7 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_7}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_7}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_7}</span>
                </div> 
                  <a href={props.packageData[0].product_link_7} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_8!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_8} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_8}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_8 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_8}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_8}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_8}</span>
                </div> 
                  <a href={props.packageData[0].product_link_8} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_9!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_9} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_9}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_9 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_9}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_9}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_9}</span>
                </div> 
                <a href={props.packageData[0].product_link_9} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
    
    {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].product_fullname_10!='') ? ( 
      <Col lg={3}>
              <a href={props.packageData[0].product_link_10} target="_blank">
              <div className="pi-product-wrap">
                <div className="pro_img">
                  <img
                    src={props.packageData[0].product_image_10}
                    className="img-fluid"
                    alt=""
                  />
        {(props.packageData[0].product_supplier_image_10 !='') ? (
                  <img
                    src={props.packageData[0].product_supplier_image_10}
                    className="pi-supplier-logo"
                    alt=""
                  />) :("")}
                </div>
                <h5>{props.packageData[0].product_fullname_10}</h5>
                <div className="discount mt-0">
                  <span>₹{props.packageData[0].product_price_10}</span>
                </div> 
                <a href={props.packageData[0].product_link_10} target="_blank" className="view-product btn"> 
                  Click to View
                </a>
              </div>
            </a>
          </Col>
    ): ("")}
          
      
        </Row>
      </section>
	
	
	
        </div>
      </section>
	  
	  {(props.packageData !='undefined' && props.packageData && props.packageData.length > 0 && props.packageData[0].description!='' && props.packageData[0].description!= null) ? (<section className="notes">
        <div className="container">
          <div className="bg-light p-4">
            <h3 className="common-heading text-uppercase">Notes by the Designer</h3> 
			 <div dangerouslySetInnerHTML={prepareHtml(props.packageData[0].description) }/> 
          </div>
		  
		<div className="service-box my-5 p-4">
			<h2 className="text-uppercase">Need help with a Package?</h2>
			<Link className="btn" to="/contact-us">Contact us now!</Link>
		</div>
        </div>
      </section> ): ("")}  
    </div>
  );
}	
   
if (!showPackageDetails) {
  return ( 
    <div>
      <section className="about-supplier">
    <div className="container">
      <h3 className="common-heading">Packages</h3>
      <p>Our unique package offerings across different services allows our customers to select customised packages from some of the most reputed suppliers.</p>
      <p className="mb-0">Our packages are hand-crafted to meet budgetary, aesthetic and functional requirements and our supplier partners are vetted based on their market repute, quality installations and positive customer reviews.</p>
    </div>
  </section>
  <img src={curve} alt="curve" className="img-fluid w-100" />
  <section className="package-section">
          <div className="container">
		    <div className='row mx-0'> <h3 className="common-heading mb-6">Designiche brings the following package options </h3> </div> <div className="mb-5 row"> <div className="product col-md-3 col-sm-6 col-12 mb-4"> <div className="pro_img"> <div> <span className="productBlur lazy-load-image-background blur lazy-load-image-loaded" ><NavLink to={"/kitchen-packages"}><img src={kitchen_image} alt="curve"  /></NavLink></span> </div> </div> <div className="title"> <h5><NavLink to={"/kitchen-packages"}>Kitchen Packages</NavLink><span>Kitchen</span></h5></div> </div> {renderAllList()}</div> 
            
          </div>
        </section>     
  </div> 
  );
  
}
   
};

Packages.propTypes = {
  packageRoomClassData: PropTypes.any.isRequired,	
  packageData: PropTypes.any.isRequired,
  productData: PropTypes.any.isRequired,
  pagination: PropTypes.any.isRequired,
  productFilterData: PropTypes.any.isRequired,
  advertisingData: PropTypes.any.isRequired,
  isProduct: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};  

function mapStateToProps(state) {
	console.log("================packageData========");
	console.log(state.packageData); 
	console.log("================inner packageRoomClassData========");
	console.log(state.packageRoomClassData);

  return {
	packageRoomClassData: state.packageRoomClassData,   
	packageData: state.packageData,  
    productData: state.productData,
    pagination: state.pagination,
    productFilterData: state.productFilterData,
    advertisingData: state.advertisingData,
    isProduct: state.isProduct,
    wishCount: state.wishCount,
  };
  
  
  
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
	Object.assign(
		productActions,
		packageActions
	), 
	dispatch
	),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
