import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/messaging"; // for cloud messaging
import "firebase/functions"; // for cloud functions

const config = {
  apiKey: "AIzaSyBV7fqOHs-l9YmZGejM8_P0e1vBUXkd0YA",
  authDomain: "furniche-c30f1.firebaseapp.com",
  databaseURL: "https://furniche-c30f1.firebaseio.com",
  projectId: "furniche-c30f1",
  storageBucket: "furniche-c30f1.appspot.com",
  messagingSenderId: "637777865158",
  appId: "1:637777865158:web:8753136704d78950eb2ac7",
  measurementId: "G-FP7KFC4X6D",
};
firebase.initializeApp(config);

export default firebase;
