import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as cartActions from "../../actions/cartActions";
import { Container, Table, Row, Col } from "react-bootstrap";
import Newsletter from "../../component/Newsletter/Newsletter";
import RecentlyViewed from "../../component/RecentlyViewed/RecentlyViewed";
import "./Cart.css";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import CartItem from "./CartItem";
import CouponModal from "../../component/popups/CouponCode";
import DummyProductModal from "../../component/popups/DummyProduct";
import AddressModal from "../../component/popups/Address";
import NotFound from "../../component/NotFound/NotFound";
import { showToaster } from "../../utils/helpers";
import * as CryptoJS from "crypto-js";
import PaymentForm from "./paymentForm";
import { NavLink } from "react-router-dom";
import config from "../../config";

const Cart = (props) => {
  const {
    title,
    actions,
    recentlyView,
    cartItem,
    isCartItem,
    userInfo,
    addressData,
    setting,
    dummyOrder,
    deviceToken,
    wishCount,
  } = props;
  const isToken = deviceToken;
  const [BrdName] = useState([{ title: "Shopping Cart", url: "/cart" }]);
  const [couponCode, setCouponCode] = useState(false);
  const [couponDetail, setCouponDetail] = useState(false);
  const [oneTime, setOneTime] = useState(true);
  const [addressModel, setAddressModel] = useState(false);
  const [address, setAddress] = useState({});
  const [orderType, setOrderType] = useState(0);
  const [mySignature, setSignature] = useState(0);
  const [showPaymentForm, setShowPayment] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [paymentUrl, setPaymentUrl] = useState(false);
  const notFound = {
    title: "Your cart is empty!",
    description: "There is nothing in your bag. Let's add some items.",
    image: "/assets/img/empty-cart.svg",
    url: "/marketplace",
    buttion: "START SHOPPING",
  };
  useEffect(() => {
    function fetchData() {
      if (oneTime) {
        let params = {};
        params.deviceToken = isToken;
        actions.loadRecentiyViewCart(params);
        actions.loadCartItem(params);
        actions.LoadCouponCodeData();
        setOneTime(false);
        window.scrollTo(0, 0);
      }
    }
    fetchData();
    if (dummyOrder) {
      document.getElementById("redirectForm").submit();
    }
	document.title = "Cart";
  }, [title, actions, isToken, oneTime, dummyOrder]);

  useEffect(() => {
    if (cartItem.shapingAddress) {
      setAddress(cartItem.shapingAddress);
    }
  }, [cartItem]);

  const removeItem = (id) => {
    let params = {};
    params.coupon_code = couponCode;
    params.cartId = id;
    actions.removeCartItem(params);
  };

  const incrementOrDecrement = (params) => {
    params.coupon_code = couponCode;
    actions.incrementOrDecrement(params);
  };

  const handleclickModel = (action) => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
	  localStorage.setItem("userGuestRequestFromCartPage", true);
    } else {
      actions.handleclickCouponModel(action);
    }
  };

  const handlCouponRemove = () => {
    const data = {};
    setCouponCode(false);
    data.amount = cartItem.total;
    data.discount = cartItem.discount;
    actions.checkCouponRemoveCode(data);
  };

  const handleclickAddressModel = (action) => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
	  localStorage.setItem("userGuestRequestFromCartPage", true);
    } else {
      setAddressModel(action);
    }
  };

  const handleclickCouponCode = (action) => {
    setCouponCode(action);
  };

  const handleclickCouponDetails = (action) => {
    setCouponDetail(action);
  };

  const handleclickSetAddress = (addressList) => {
    setAddressModel(false);
    setAddress(addressList);
  };

  const handleCheckOutClick = () => {
    if (!userInfo.user_id) {
      actions.userLoginDataSuccess(true);
	  localStorage.setItem("userGuestRequestFromCartPage", true);
    /*} else if (!userInfo.number) {
      showToaster(
        "Please update mobile number in your profile before checkout."
      );
      return false;*/
    } else {
      if (!address.hasOwnProperty("name")) {
        showToaster("Please choose delivery/ billing address");
        return false;
      }
	  
	  if( (userInfo.number =='' || userInfo.number == null ) && address.number !=''){
			userInfo.number = address.number; 
			let uparams = {};
			uparams.number = address.number; 
			uparams.user_id = userInfo.chat_user_id;
			actions.updateUserInfo(uparams);  
 	   }  
      setOrderType(2);
      setShowPayment(true);
      generateSignature();
	   
      let params = {};
      params.order_id = cartItem.orderId;
      params.coupon_code = couponCode;
      params.order_type = 2;
      params.shipping_id = address.id;
      params.billing_id = address.id;
      params.pinCode = address.pincode;
      params.user_id = userInfo.chat_user_id;
      actions.handlePlacePaymentOrder(params);
      // if (orderType === 1) {
      //   actions.handlePlaceOrder(params);
      // }
      // if (orderType === 2) {
      //   actions.handlePlacePaymentOrder(params);
      // }
    }
  };

  const setPaymentType = (type) => {
    if (!address.hasOwnProperty("name")) {
      showToaster("Please choose delivery/ billing address");
      return false;
    }
    setOrderType(type);
    if (type === 2) {
      generateSignature();
    } else {
      setShowPayment(false);
    }
  };

  const generateSignature = () => {
    //let amount = parseFloat(cartItem.viewTotal.replace(",", ""));
    let amount = cartItem.total;
    let appId = setting.payment.test_app_id;
    let secretKey = setting.payment.test_secret_key;
    let paymentUrl = setting.payment.test_url;
    if (setting.payment.prodMode) {
      appId = setting.payment.prod_app_id;
      secretKey = setting.payment.prod_secret_key;
      paymentUrl = setting.payment.prod_url;
    }
    var postData = {
      appId: appId,
      orderId: cartItem.orderId,
      orderAmount: amount,
      orderCurrency: setting.payment.currency,
      orderNote: "Test",
      customerName: userInfo.first_name,
      customerEmail: userInfo.email,
      customerPhone: userInfo.number,
      returnUrl: setting.payment.returnUrl,
      notifyUrl: setting.payment.notifyUrl,
    };
    setPaymentData(postData);
    var orderedPostData = {};
    Object.keys(postData)
      .sort()
      .forEach(function (key) {
        orderedPostData[key] = postData[key];
      });
    var signatureData = "";
    for (const key in orderedPostData) {
      signatureData += key + orderedPostData[key];
    }
    var hash = CryptoJS.HmacSHA256(signatureData, secretKey);
    var signature = CryptoJS.enc.Base64.stringify(hash);
    setSignature(signature);
    setShowPayment(true);
    setPaymentUrl(paymentUrl);
  };

  return (
    <>
      <Breadcrumbs name={BrdName} />
      <section className="shopping_cart_sec py-4">
        <Container>
          <div className="shopping_cart">
            <h2 className="mb-3">Shopping Cart</h2>
            <div className="p-3 bg-light">
              <div className="cart_table">
                {!isCartItem && cartItem && cartItem.item ? (
                  <Table responsive className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total Price</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItem &&
                        cartItem.item.map((cart, key) => (
                          <CartItem
                            {...cart}
                            key={key}
                            removeItem={removeItem}
                            incrementOrDecrement={incrementOrDecrement}
                          />
                        ))}
                      <tr>
                        <td colspan="6" className="text-sm-right">
                          Your Savings on this order&nbsp;
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.discountAmount}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <>{isCartItem && <NotFound {...notFound} />}</>
                )}
              </div>
              {!isCartItem && cartItem.item && (
                <Row className="no-gutters border-top">
                  <Col xs={12} md={6} lg={7}>
                    <div className="bg-white px-3 py-4">
                      <div className="update_cart d-block mb-3">
                        <div className="sidebar_filter position-relative row search_locate">
                          <span className="col-lg-6 col-6 d-flex">
                            <i className="fas fa-tag mt-1 mr-2"></i> Apply Coupons
                          </span>
						  <div className="col-lg-6 col-6 pl-0 d-md-block d-flex justify-content-end align-items-center">
                          {!couponCode && (
                            <span
                              className="coupons-base-button d-inline-block ml--5"
                              onClick={(e) => {
                                handleclickModel(true);
                              }}
                            >
                              Apply
                            </span>
                          )}
                          {couponCode && (
                            <span
                              className="coupons-base-button d-inline-block ml--5"
                              onClick={(e) => {
                                handlCouponRemove(false);
                              }}
                            >
                              Remove
                            </span>
                          )}
						  </div>
                        </div>
                      </div>
                      {couponCode && (
                        <div className="update_cart mb-3 d-block">
                          <div className="sidebar_filter position-relative row search_locate w-100">
                            <span className="col-lg-6 col-6 d-flex">
                              {couponDetail.percentage}% off your order "
                              {couponDetail.coupon_code}"
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="update_cart mb-3 d-block">
                        <div className="sidebar_filter position-relative row search_locate">
                          <span className="col-lg-6 col-6 d-flex">
                            <i className="fas fa-location-arrow mt-1 mr-2"></i> Select
                            Delivery/ Billing Address
                          </span>
						  <div className="col-lg-6 col-6 pl-0 d-md-block d-flex justify-content-end align-items-center">
                          {address.hasOwnProperty("name") ? (
                            <span
                              className="coupons-base-button d-inline-block ml--5"
                              onClick={(e) => {
                                handleclickAddressModel(true);
                              }}
                            >
                              Change address
                            </span>
                          ) : (
                            <span
                              className="coupons-base-button d-inline-block ml--5"
                              onClick={(e) => {
                                handleclickAddressModel(true);
                              }}
                            >
                              Choose Address
                            </span>
                          )}
                        </div>
                        </div>
                      </div>
                    </div>
                    {address.hasOwnProperty("name") && (
                      <div className="bg-white border-top px-3 py-4">
                        <div className="payment_method_title">
                          Delivery/ Billing Address
                        </div>
                        <ul className="payment_method_options">
                          <li>
                            <div className="delivery_address_list_view">
                              <div className="delivered_name">
                                {address.name.charAt(0).toUpperCase() +
                                  address.name.slice(1)}
                              </div>
                              {address.address}, {address.city}, {address.state}
                              - {address.pincode}
                              <span className="d-block">
                                <i className="fas fa-mobile mr-1"></i>{" "}
                                {config.PH_CODE}
                                {address.number}
                                {address.alternate_number && (
                                  <>
                                    , {config.PH_CODE}
                                    {address.alternate_number}
                                  </>
                                )}
                              </span>
                              {address.landmark && (
                                <span>
                                  <i className="fas fa-map-marker mr-1"></i>
                                  {address.landmark}
                                </span>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={5}>
                    <div className="bg-white px-3 py-4 cart_total h-100 border-left">
                      <ul>
                        <li>
                          Sub Total
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.subTotalAfterDiscount}
                          </span>
                        </li>

                        {couponCode && (
                          <li>
                            Coupon Discount
                            <span>
                              - {cartItem.currency}&nbsp;
                              {cartItem.couponDiscount}
                            </span>
                          </li>
                        )}

                        {couponCode && (
                          <li>
                            Sub Total After Discount{" "}
                            <span>
                              {cartItem.currency}&nbsp;
                              {cartItem.afterDiscountAmount}
                            </span>
                          </li>
                        )}

                        <li>
                          Shipping & Processing
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.shipping}
                          </span>
                        </li>
						
						{cartItem.user =='' && (
                        <li>
                          GST
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.tax}
                          </span>
                        </li>
						)}
						
						{cartItem.user && cartItem.igst_amount && (
                        <li> 
                          IGST
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.igst_amount}
                          </span>
                        </li>
						)}
						
						{cartItem.user && cartItem.sgst_amount && (
                        <li> 
                          SGST
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.sgst_amount}
                          </span>
                        </li>
						)}
						
						{cartItem.user && cartItem.cgst_amount && (
                        <li> 
                          CGST
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.cgst_amount}
                          </span>
                        </li>
						)}
						
                        <li className="total">
                          Grand Total
                          <span>
                            {cartItem.currency}&nbsp;
                            {cartItem.viewTotal}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {!isCartItem && (
              <div className="cart_last_btn text-center text-sm-right d-block d-sm-flex align-items-center justify-content-end mt-4">
                <NavLink
                  to="marketplace"
                  title="Shop Now"
                  exact
                  className="btn-dark"
                >
                  Continue Shopping
                </NavLink>
                <span
                  className="btn-dark btn mt-2 mt-sm-0 ml-0 ml-sm-1"
                  onClick={(e) => {
                    handleCheckOutClick(true);
                  }}
                >
                  Checkout
                </span>
              </div>
            )}
          </div>
          <div className="py-4 mt-4">
            <RecentlyViewed recentlyView={recentlyView} wishCount={wishCount} />
          </div>
        </Container>
      </section>
      <Newsletter />
      <CouponModal
        total={cartItem.total}
        discount={cartItem.discount}
        handleclickCouponCode={handleclickCouponCode}
        handleclickCouponDetails={handleclickCouponDetails}
      />
      <DummyProductModal />
      <AddressModal
        addressModel={addressModel}
        handleclickAddressModel={handleclickAddressModel}
        addressData={addressData}
        addressId={address.show}
        handleclickSetAddress={handleclickSetAddress}
      />
      {showPaymentForm && (
        <PaymentForm
          mySignature={mySignature}
          paymentData={paymentData}
          paymentUrl={paymentUrl}
        />
      )}
    </>
  );
};

Cart.propTypes = {
  homePageData: PropTypes.any.isRequired,
  recentlyView: PropTypes.any.isRequired,
  cartItem: PropTypes.any.isRequired,
  isCartItem: PropTypes.any.isRequired,
  userInfo: PropTypes.any.isRequired,
  wishCount: PropTypes.any.isRequired,
  addressData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    homePageData: state.homePageData,
    recentlyView: state.recentlyView,
    cartItem: state.cartItem,
    isCartItem: state.isCartItem,
    userInfo: state.userInfo,
    addressData: state.addressData,
    setting: state.setting,
    dummyOrder: state.dummyOrder,
    deviceToken: state.deviceToken,
    wishCount: state.wishCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(cartActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
