import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { setToaster } from "../utils/helpers";

export function loadHomePageDataSuccess(homePageData) {
  return { type: types.LOAD_HOME_PAGE_DATA_SUCCESS, homePageData };
}

export function loadFaqDataSuccess(faqData) {
  return { type: types.LOAD_FAQ_DATA_SUCCESS, faqData };
}

export function loadActionStatusSuccess(actionStaus) {
  return { type: types.LOADED_ACTION_STATUS_SUCCESS, actionStaus };
}

export function loadHomePageData(id) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.CMS_PAGE + id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadHomePageDataSuccess(response.data.data));
          }
          dispatch(toggleNetworkRequestStatus(false));
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function loadFaqData() {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.FAQ_PAGE)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadFaqDataSuccess(response.data.data));
          }
          dispatch(toggleNetworkRequestStatus(false));
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function sendContactUs(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.CONTACT_US_PAGE, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        setToaster(response.data.message);
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function sendSpecialRequest(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SPECIAL_REQUEST_PAGE, params)
      .then((response) => {
        console.log(JSON.stringify(response),'Prem special request');
        dispatch(submittingRequestStatus(false));
        setToaster(response.data.message);
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function sendMeetingRequest(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.MEETING_REQUEST, params)
      .then((response) => {
        dispatch(loadActionStatusSuccess(true));
        dispatch(submittingRequestStatus(false));
        setToaster(response.data.message);
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function updateInstaImage(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.UPLOAD_INSTA_IMAGE, params)
      .then((response) => {
        dispatch(loadActionStatusSuccess(true));
        dispatch(toggleNetworkRequestStatus(false));
        setToaster(response.data.message);
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function sendSwadesCollectionFormInfo(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SAVE_SWADESH_COLLECTION_DATA, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        setToaster(response.data.message);
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}
