import React, { useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import "./HomeTabPanel.css";
import HotProduct from "./HotProduct";
import HomeDesignTabPanel from "./HomeDesignTabPanel";
import ByCategoryStatic from "./ByCategoryStatic";
import SupplierTabStatic from "./SupplierTabStatic";
import { renderTitle } from "../../utils/helpers";
// import React,  from "react";
import ReactPlayer from "react-player";

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper";

import stuvita from '../services/images/stuvita.jpg';
import qualita from '../services/images/qualita.jpg';
import thecolorco1 from '../services/images/thecolorco_1.jpg';


// gallery images
import opg1 from '../services/images/works/opg1.JPG';
import opg2 from '../services/images/works/opg2.JPG';
import opg3 from '../services/images/works/opg3.JPG';
import opg4 from '../services/images/works/opg4.JPG';
import opg5 from '../services/images/works/opg5.JPG';
import opg6 from '../services/images/works/opg6.JPG';
import opg7 from '../services/images/works/opg7.JPG';
import opg8 from '../services/images/works/opg8.JPG';
import opg9 from '../services/images/works/opg9.JPG';
import opg10 from '../services/images/works/opg10.JPG';
import opg11 from '../services/images/works/opg11.JPG';
import opg12 from '../services/images/works/opg12.JPG';
import opg13 from '../services/images/works/opg13.JPG';
import opg14 from '../services/images/works/opg14.JPG';
import opg15 from '../services/images/works/opg15.JPG';
import opg16 from '../services/images/works/opg16.JPG';
import opg17 from '../services/images/works/opg17.JPG';
import opg18 from '../services/images/works/opg18.JPG';
import opg19 from '../services/images/works/opg19.JPG';
import opg20 from '../services/images/works/opg20.JPG';
import opg21 from '../services/images/works/opg21.JPG';
import opg22 from '../services/images/works/opg22.JPG';
import opg23 from '../services/images/works/opg23.JPG';
import opg24 from '../services/images/works/opg24.JPG';
import opg25 from '../services/images/works/opg25.JPG';
import opg26 from '../services/images/works/opg26.JPG';
import opg27 from '../services/images/works/opg27.JPG';
 


const HomeTabPanel = (props) => {
  const hideDesignService = false;
  const showDesignService = true;
  
  const hideTools = false;
  const showTools = true;
    var activeCategory = localStorage.getItem("ActiveCategoryTab");
    console.log(activeCategory);
    if(activeCategory == 'undefined' || activeCategory=='' || activeCategory==null)  {
        activeCategory = 'd3121f41-09d9-483e-9462-52845a3d3ce2';
    }
    const images = [
      {
        img: opg2,
        img2: opg3,
        img3: opg4,
        img4: opg5,
        img5: opg6,
        img6: opg7,
      },
      {
        img: opg8,
        img2: opg9,
        img3: opg10,
        img4: opg11,
        img5: opg12,
        img6: opg13,
      },
      {
        img: opg14,
        img2: opg15,
        img3: opg16,
        img4: opg17,
        img5: opg18,
        img6: opg19,
      },
      {
        img: opg20,
        img2: opg21,
        img3: opg22,
        img4: opg23,
        img5: opg24,
        img6: opg25,
      },
    ];
    const [selectedImage, setSelectedImage] = useState("");
    const openPopup = (image) => {
      setSelectedImage(image);
    };
    const closePopup = () => {
      setSelectedImage(null);
    };
    const [video] = useState(
      "https://youtu.be/9tF4m3Q7ct0"
    );
  return (
    <>
    <div id="ShopNow"></div>
      <section className="home_tab_panel">
        <Container>
          <div className="tabbable boxed parentTabs">
            <Tabs defaultActiveKey="design">
              <Tab eventKey="design" className="design_tab" title="Design">
                <div className="tabbable">
                    <div className="inner_tab_sec">
                      <Tabs
                        defaultActiveKey="design_services"
                        id="uncontrolled-tab-example"
                        className="mb-3 d-tabs"
                      >
                        <Tab eventKey="design_services" title="Design Services">
                          <section className="main_tab_content">
                            <div className="container-">
                              <h3 className="common-heading text-uppercase mb-3">Design Services</h3>
                              <p>Guided by our founder, Ms. Preneet Malhotra, our design services team emphasizes and thrives on personalizing and customizing spaces to create unique, aesthetically appealing homes.  We've embarked on projects ranging from modest home-styling assignments-tailoring avant-garde furniture to the homeowner's preferences—to extensive renovations that involve reimagining the entire house, room by room.</p>

                              <p>Our mission is not simply to furnish new spaces but to appreciate that every house has a narrative, each piece of old furniture carries a memory. We aim to preserve these cherished recollections while presenting you with a rejuvenated version of your home, maintaining its priceless aspects. We recognize that a home is a mirror of the homeowner's character, it's a testament to their life and their ancestral legacy, and our designs respect and honor this fact.</p>

                              <p>We've had clients with heirlooms like cherished chairs from a grandmother, which we incorporated into the house's new design. In another example worked to incorporate a collection of priceless CDs on a wall display in the family room. Our approach is to modernize the style without relinquishing the home's unique story and memories. We offer a co-creative personalization process with the homeowner, delivering a HOME, not merely a house. It's your story to tell, and no outsider can tell it better than you. We enable that process and make it a reality.</p>

                              <p className=" mb-0">Here at Designiche Design Services, our focus is on YOU—YOUR narrative, YOUR memories, YOUR budget, and what makes YOUR space feel like YOUR HOME.</p>
                            </div>
                          </section>
                          <div className="design-service-gallery p-4 bg-light mt-5">
                            <div className="new-project-gallery">
                              <div className="text-center">
                                <h3 className="common-heading text-uppercase mb-4 mt-3">
                                  Our Project Work
                                </h3>
                              </div>
                              <Swiper
                                modules={[Pagination, Navigation]}
                                spaceBetween={15}
                                slidesPerView={1}
                                loop={true}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                pagination={{
                                  clickable: true,
                                }}
                                navigation
                                className="mySwiper"
                                breakpoints={{
                                  "@0.00": {
                                    slidesPerView: 1,
                                  },
                                  "@1.00": {
                                    slidesPerView: 1,
                                  },
                                  "@1.50": {
                                    slidesPerView: 1,
                                  },
                                }}
                              >
                              {images?.map((image, index) => (
                                <SwiperSlide>
                                  <div className="new-project-gallery-box">
                                    <img key={index}  onClick={() => openPopup(image.img)} src={image.img} />
                                    <img key={index}  onClick={() => openPopup(image.img2)} src={image.img2} />
                                    <img key={index}  onClick={() => openPopup(image.img3)} src={image.img3} />
                                    <img key={index}  onClick={() => openPopup(image.img4)} src={image.img4} />
                                    <img key={index}  onClick={() => openPopup(image.img5)} src={image.img5} />
                                    <img key={index}  onClick={() => openPopup(image.img6)} src={image.img6} />
                                  </div> 
                                </SwiperSlide>
                              ))}
                              </Swiper>
                              
                          {selectedImage && (
                            <div className="gallery_popup gallery_">
                              <span className="close" onClick={closePopup}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-xlg"
                                viewBox="0 0 25 25"
                              >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                              </svg>
                              </span>
                              <img src={selectedImage} alt="Selected Image" />
                            </div>
                          )}
                              <div className="space_budget_btns mb-2 mt-5 text-center">  
                                <NavLink to="/designiche_design_services" title="Learn More about our Design Services" exact className="btn">
                                  Learn More about our Design Services
                                </NavLink>
                              </div>
                            </div>


                          {/* <div className="ds-gallery project-gallery d-none mt-5">
                            {images?.map((image, index) => (
                              <div onClick={() => openPopup(image.img)} className="ds-gallery-item">
                                <img key={index} src={image.img} alt={image.img} />
                              </div> 
                            ))}
                          </div> */}
                          {/* {selectedImage && (
                            <div className="gallery_popup gallery_">
                              <span className="close" onClick={closePopup}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-xlg"
                                viewBox="0 0 25 25"
                              >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                              </svg>
                              </span>
                              <img src={selectedImage} alt="Selected Image" />
                            </div>
                          )} */}
                        </div>

                          <section className="py-5 walkthrough">
                              <h3 className="common-heading text-uppercase mb-4">
                                Project Walkthrough Video
                              </h3>
                              <ReactPlayer
                              url={video}
                              height="355px"
                              className="react-player walkthrough-video"
                            />
                          </section>
                        </Tab>
                        <Tab eventKey="Partners" title="Our Service Partners">
                          <section className="main_tab_content">
                            <div className="container-">
                            <h3 className="common-heading text-uppercase mb-4 pb-2">Our Service Partners</h3>
                              <div className="row justify-content-center">
                                <div className="col-lg-2 col-md-3 col-6 sup-col">
                                  <div className="supplier-logo">
                                    <NavLink to="/design-services" className="mb-3">
                                      <img src={stuvita} alt="stuvita" className="img-fluid" />
                                    </NavLink>
                                  </div>
                                  <NavLink to="/design-services" className="text-center d-block" style={{ color: '#5c5b5b'}}>
                                  <p className="mb-md-3 mb-5 font-weight-bold">Luxury Interiors and Exteriors</p>
                                  </NavLink>
                                </div>
                                <div className="col-lg-2 col-md-3 col-6 sup-col">
                                  <div className="supplier-logo">
                                    <NavLink to="/kitchen-packages" className="mb-3">
                                      <img src={qualita} alt="qualita" className="img-fluid" />
                                    </NavLink>
                                  </div>
                                  <NavLink to="/kitchen-packages" className="text-center d-block" style={{ color: '#5c5b5b'}}>
                                    <p className="mb-md-3 mb-5 font-weight-bold">Kitchens and wardrobes</p>
                                  </NavLink>
                                </div>
                                <div className="col-lg-2 col-md-3 col-6 sup-col">
                                  <div className="supplier-logo">
                                    <NavLink to="/sarika_totla" className="mb-3">
                                      <img src={thecolorco1} alt="thecolorco" className="img-fluid" />
                                    </NavLink>
                                  </div>
                                  <NavLink to="/sarika_totla" className="text-center d-block" style={{ color: '#5c5b5b'}}>
                                    <p className="mb-md-3 mb-5 font-weight-bold">Designer walls</p>
                                  </NavLink>
                                </div>
                                <div className="col-lg-2 col-md-3 col-6 sup-col">
                                  <div className="supplier-logo">
                                    <NavLink to="/" className="cursor-default mb-3">
                                      <img src="https://www.designiche.com/live/public/storage/seller/thumb/4ef58cf3-6fc3-40aa-a886-78e6404fed88.png" alt="A 2 Interiors" className="img-fluid" />
                                    </NavLink>
                                  </div>
                                  <p className="mb-md-3 mb-5 font-weight-bold text-center">Turnkey project execution</p>
                                </div>
                                <div className="col-lg-2 col-md-3 col-6 sup-col">
                                  <div className="supplier-logo">
                                    <NavLink to="/" className="cursor-default mb-3">
                                      <img src="https://www.designiche.com/live/public/storage/seller/thumb/999ea7d2-b7c6-4e97-b5ec-9e9f1d36947b.png" alt="A2 Interiors" className="img-fluid" />
                                    </NavLink>
                                  </div>
                                  <p className="mb-md-3 mb-5 font-weight-bold text-center">Offices</p>
                                </div>
                              </div>
                            </div>
                          </section>
                        </Tab>
                      </Tabs>
                    </div>
                </div>
                {/* <HomeDesignTabPanel homeDesigen={props.homeDesigen}  design_service={showDesignService} activeSection={showTools} /> */}
              </Tab>
              <Tab eventKey="furniture" title="Interiors Catalogue">
                <div className="tabbable">
                  <div className="inner_tab_sec">
                    <Tabs className="ic-tabs"
                      id="controlled-tab-example"
                      onSelect={(k) => props.onClick(k)}
                      defaultActiveKey={activeCategory}
                    >
                      {props.parentCategoryData
                      .filter(item => item.title !== 'By Category' && item.title !== 'Collections')
                      .map((item) => (
                        <Tab
                          eventKey={item.id}
                          title={item.title === 'By Rooms/Space' ? "Partners" : ((item.title === 'By Category') ? "Categories" : item.title)}
                          key={item.id} 
                        >
                          <div className="main_tab_content">
                            <div className="bg_after position-relative">							
							{ (item.title =='By Rooms/Space' || item.title =='By Category') ? (
								<>
								{ item.title =='By Category' ? ( 
								<> 
									<ByCategoryStatic />  
								</> ) : ( <> 
									<SupplierTabStatic />  
								</>  ) }
								</> 
								 
							) : (
							<>	
							{ (item.title =='By Category' ) ? (
								<> 
									<ByCategoryStatic />  
								</>
							) : (
								<>
								</>
							)}							
                              {props.categoryProductData.map(
                                (category, index) => (
                                  <div key={index}>
                                    {category.type === "category" ? (
                                      <>
                                        <div
                                          className="tab_heading d-flex align-items-center"
                                          key={category.id}
                                        >
                                          <h2>{category.title}</h2>
                                          <NavLink
                                            to={
                                              "/marketplace?categoryId=" +
                                              category.id +
                                              "&title=" +
                                              category.title
                                            }
                                            title="Product"
                                            exact
                                            className="link ml-auto"
                                          >
                                            See All
                                          </NavLink>
                                        </div>
                                        <Row className="mb-5">
                                          {category.product.map((product) => (
                                            <Col
                                              xs={12}
                                              sm={6}
                                              md={3}
                                              className="product product-wrap"
                                              key={product.id}
                                            >
                                              <NavLink
                                                  to={
                                                    "/product/details/" +
                                                    product.id
                                                  }
                                                  title={product.title}
                                                  exact
                                                >
                                                <div className="pro_img">
                                                  <LazyLoad
                                                    images={product.image}
                                                    key={product.id}
                                                    imageClass={"productBlur"}
                                                  />
                                                  <div className="tab_overlay">
                                                    <NavLink
                                                      to={
                                                        "/product/details/" +
                                                        product.id
                                                      }
                                                      title={product.title}
                                                      exact
                                                      className="btn"
                                                    >
                                                      <i className="fas fa-eye"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <div className="title">
                                                  <h5>
                                                    {renderTitle(product.title)}{" "}
                                                    <span>
                                                      {product.currency}
                                                      {product.discounted_price}
                                                    </span>
                                                  </h5>
                                                  <div className="text-center">
                                                  <NavLink
                                                      to={
                                                        "/product/details/" +
                                                        product.id
                                                      }
                                                      title={product.title}
                                                      exact
                                                      className="btn view-product"
                                                    >View Product </NavLink>
                                                  </div>
                                                </div>
                                              </NavLink>
                                            </Col>
                                          ))}
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        className="advertise mb-5"
                                        key={category.id}
                                      >
                                        <div className="text-center">
                                          <LazyLoad
                                            images={category.image}
                                            key={category.id}
                                            imageClass={"advertiseBlur"}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
							  </>
							)}
                            </div>
							
							{item.title === "By Category" ? (
								<div></div>
							) :(<div></div>)}

                          </div>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="what_hot" title="What's Hot?">
                <HotProduct hotProductData={props.hotProductData} />
              </Tab>
            </Tabs>
			      <div><HomeDesignTabPanel homeDesigen={props.homeDesigen}  design_service={showDesignService} activeSection={hideTools}/></div>
          </div>
        </Container>
      </section>
    </>
  );

};
localStorage.removeItem("ActiveCategoryTab");
export default HomeTabPanel;
