import React from "react";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyLoad = ({ images, key, imageClass }) => (
  <div>
    <LazyLoadImage
      key={key}
      alt={"Product"}
      src={images}
      placeholderSrc={"/assets/img/product_no_image.png"}
      effect="blur"
      wrapperClassName={imageClass}
    />
  </div>
);

export default trackWindowScroll(LazyLoad);
