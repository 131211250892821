import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";

const Review = (props) => {
  const [onetime, setOnetime] = useState(true);
  const [review, setReview] = useState(props.review);

  const handleclickSellerReview = (review) => {
    const params = {};
    if (review.isLike) {
      setReview({
        ...props.review,
        ...{ like_count: review.like_count - 1, isLike: false },
      });
    } else {
      setReview({
        ...props.review,
        ...{ like_count: review.like_count + 1, isLike: true },
      });
    }
    params.id = review.id;
    params.count = review.count;
    props.onClick(params);
    setOnetime(true);
  };

  useEffect(() => {
    setReview(review);
  }, [review]);

  return (
    <>
      <div
        className="seller_review px-4 py-3"
        key={review.id}
        data-id={onetime}
      >
        <div className="d-flex">
          <StarRatingComponent
            name="rate"
            starCount={5}
            value={review.rating}
            renderStarIcon={() => <i className="fas fa-star"></i>}
          />
          <div className="rating_date ml-auto">Reviewed on {review.date}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="rating_content pr-5">
            <span className="rating_user heading">{review.user}</span>
            <p className="m-0">{review.description}</p>
          </div>
          <div
            className={
              review.isLike
                ? "link helful_count ml-auto"
                : "unlink helful_count ml-auto"
            }
            onClick={() => handleclickSellerReview(review)}
          >
            <i className="fas fa-thumbs-up mr-2"></i>
            {review.like_count}
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
