import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Jumbotron } from "reactstrap";
import firebase from "../../Firebase";
import Loader from "../../component/Loader";

function RoomList(props) {
  const { userInfo, roomKey } = props;
  const [room, setRoom] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const userKey = "users/" + userInfo.chat_user_id;
      firebase
        .database()
        .ref(userKey)
        .orderByChild("epoch")
        .on("value", (resp) => {
          setRoom([]);
          setRoom(snapshotToArray(resp));
          setShowLoading(false);
        });
    };
    fetchData();
  }, [userInfo]);

  const snapshotToArray = (snapshot) => {
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      returnArr.push(item);
    });
    return returnArr;
  };

  const enterChatRoom = (key) => {
    const roomname = key + "_" + userInfo.chat_user_id;
    history.push("/user/chat?roomKey=" + roomname);
  };

  const renderChatList = () => {
    return room.map((item, idx) => {
      const { logo, name, message, time, chat_id, key } = item;
      return (
        <li
          key={idx}
          onClick={() => {
            enterChatRoom(chat_id);
          }}
          className={key === roomKey ? "chatActive" : ""}
        >
          <div className="usr-msg-details">
            <div className="usr-ms-img">
              <img src={logo} alt="ListingImage" />
            </div>
            <div className="usr-mg-info">
              <h3>{name}</h3>
              <p>{message}</p>
            </div>
            <span className="posted_time">{time}</span>
          </div>
        </li>
      );
    });
  };

  return (
    <div className="jumbotron_leftsec">
      {showLoading && <Loader />}
      <Jumbotron>
        <ul>{renderChatList()}</ul>
      </Jumbotron>
    </div>
  );
}

export default RoomList;
