import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function parentCategoryReducers(state = initialState.parentcategoryData, action) {
  switch (action.type) {
    case types.LOAD_PARENT_DATEGORY_DATA_SUCCESS:
      return action.parentcategoryData
    default:
      return state
  }
}
