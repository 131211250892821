import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { sendPushNotification } from "../../utils/helpers";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";
import Moment from "moment";
import firebase from "../../Firebase";
import ScrollToBottom from "react-scroll-to-bottom";
import RoomList from "./RoomList";
import "./Chat.css";

function Chat(props) {
  const { userInfo } = props;
  const [chats, setChats] = useState([]);
  const [roomname, setRoomname] = useState("");
  const [isMessage, setMessage] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [newchat, setNewchat] = useState({
    roomname: "",
    senderId: "",
    message: "",
    date: "",
    type: "",
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const room = params.get("roomKey");

  useEffect(() => {
    const fetchData = async () => {
      setRoomname(room);
      firebase
        .database()
        .ref("chats/" + room)
        .on("value", (resp) => {
          setChats([]);
          setChats(snapshotToArray(resp));
        });
    };
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchData();
  }, [room, roomname]);

  const snapshotToArray = (snapshot) => {
    const returnArr = [];

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });

    return returnArr;
  };

  useEffect(() => {
    const fetchData = async () => {
      const userKey = "users/" + userInfo.chat_user_id;
      firebase
        .database()
        .ref(userKey)
        .on("value", (resp) => {
          setCurrentUser({});
          if (snapshotToArray(resp)) {
            snapshotToArray(resp).forEach((item) => {
              if (item.key === room) {
                setCurrentUser(item);
              }
            });
          }
        });
    };
    fetchData();
  }, [userInfo, room]);

  const submitMessage = (e) => {
    e.preventDefault();
    if (newchat) {
      const chat = newchat;
      chat.roomname = roomname;
      chat.senderId = userInfo.chat_user_id.toString();
      chat.date = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      chat.type = "message";
      const newMessage = firebase
        .database()
        .ref("chats/" + room)
        .push();
      newMessage.set(chat);
      setNewchat({
        roomname: "",
        senderId: "",
        message: "",
        date: "",
        type: "",
        image: "",
      });

      let databaseKey = "users/" + userInfo.chat_user_id + "/" + room;
      let time = Moment.utc().valueOf();
      const sellerInfo = {};
      sellerInfo.epoch = -1 * time;
      const newUser = firebase.database().ref(databaseKey);
      newUser.update(sellerInfo);
      setMessage(true);
      const notification = {
        title:
          userInfo.first_name.charAt(0).toUpperCase() +
          userInfo.first_name.slice(1) +
          " " +
          userInfo.last_name.charAt(0).toUpperCase() +
          userInfo.last_name.slice(1) +
          " was sent new message",
        body: chat.message.charAt(0).toUpperCase() + chat.message.slice(1),
        registration_ids:
          "fg2VSz82S1ydm0BLRZNy50:APA91bEmFp7lNRebfFz1JT6I7dFW2OzwkZ5zpgtM72bWQDRnU6JWeoDm9A0hpBjapjofUwFblc7zkH5svQLCDdqMGC9PEMvUjCwfe6caEsDLozqK9ujG-h8tG0OIf1gKSgST9418BJ8X",
      };
      sendPushNotification(notification);
    }
  };

  const onChange = (e) => {
    e.persist();
    setMessage(false);
    setNewchat({ ...newchat, [e.target.name]: e.target.value });
  };

  return (
    <div className="Container">
      <Container>
        <Row>
          <Col xs="12" sm="4" md="5" lg="4">
            <RoomList userInfo={userInfo} roomKey={room} />
          </Col>
          <Col xs="12" sm="8" md="7" lg="8">
            {currentUser.name ? (
              <div className="jumbotron_rightsec">
                <div className="message-bar-head">
                  <div className="usr-msg-details">
                    <div className="usr-ms-img">
                      <img src={currentUser.logo} alt="ListingImage" />
                    </div>
                    <div className="usr-mg-info">
                      <h3>{currentUser.name}</h3>
                      {currentUser.status ? <p>Online</p> : <p>Offline</p>}
                    </div>
                  </div>
                </div>
                <ScrollToBottom className="ChatContent">
                  {chats.map((item, idx) => (
                    <div key={idx} className="MessageBox">
                      {item.type === "join" || item.type === "exit" ? (
                        <div className="ChatStatus">
                          <span className="ChatDate">{item.date}</span>
                          <span className="ChatContentCenter">
                            {item.message}
                          </span>
                        </div>
                      ) : (
                        <div className="ChatMessage">
                          <div
                            className={`${
                              item.senderId === userInfo.chat_user_id.toString()
                                ? "RightBubble"
                                : "LeftBubble"
                            }`}
                          >
                            <div className="chat_message">{item.message}</div>
                            <span className="MsgDate">{item.date}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </ScrollToBottom>
                <footer className="StickyFooter">
                  <Form className="MessageForm" onSubmit={submitMessage}>
                    <InputGroup>
                      <Input
                        type="text"
                        name="message"
                        id="message"
                        placeholder="Enter message here"
                        value={newchat.message}
                        onChange={onChange}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isMessage}
                        >
                          <i className="fas fa-paper-plane"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Form>
                </footer>
              </div>
            ) : (
              <div className="jumbotron_rightsec noMessage">
                <img
                  src={"../../../assets/img/conversation.svg"}
                  alt={"user"}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

export default connect(mapStateToProps)(Chat);
