import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function packageReducers(state = initialState.packageData, action) {
  switch (action.type) {
    case types.LOAD_PACKAGE_DATA_SUCCESS:
      return action.packageData
    default:
      return state
  }
}
