import React from "react";
import { Modal, Row, Col, Form, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomButton from "../../component/Button/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
const NewAddressModal = (props) => {
  const { newAddressModel, actions, details } = props;

  const handleclickModel = (action) => {
    props.handleclickAddNewModel(action);
  };

  const modelClose = () => {
    props.handleClickAddressCloseModel();
  };

  const { register, errors, handleSubmit } = useForm();
  const onSubmit = (data) => {
    actions.addUserAddress(data, handleclickModel);
  };

  return (
    <>
      <Modal
        size="lg"
        show={newAddressModel}
        onHide={() => handleclickModel(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0 mb-4 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Shipping / Billing Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Control
              type="hidden"
              name="id"
              defaultValue={details.id}
              ref={register({})}
            />
            <Row>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>Name*</Form.Label>
                  <FormControl
                    name="name"
                    type="text"
                    defaultValue={details.name}
                    className={errors.name ? "formError" : ""}
                    aria-invalid={errors.name ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />

                  {errors.name &&
                    (errors.name.type === "validate" ||
                      errors.name.type === "required") && (
                      <span role="alert">Please enter your name.</span>
                    )}
                  {errors.name && errors.name.type === "minLength" && (
                    <span role="alert">
                      Your name should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.name && errors.name.type === "maxLength" && (
                    <span role="alert">
                      Your name should not exceed 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>Contact Number*</Form.Label>
                  <FormControl
                    type="tel"
                    defaultValue={details.number}
                    name="number"
                    className={errors.number ? "formError" : ""}
                    aria-invalid={errors.number ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                      },
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.number &&
                    (errors.number.type === "required" ||
                      errors.number.type === "validate") && (
                      <span role="alert">
                        Please enter your contact number.
                      </span>
                    )}
                  {errors.number && errors.number.type === "maxLength" && (
                    <span role="alert">
                      Your contact number should not exceed 10 digits.
                    </span>
                  )}
                  {errors.number && errors.number.type === "minLength" && (
                    <span role="alert">
                      Your contact number should contain atleast 10 digits.
                    </span>
                  )}
                  {errors.number && errors.number.type === "pattern" && (
                    <span role="alert">
                      Please enter correct contact number. It should be entered
                      without country code.( Example xxxxxxxxxx)
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Address*</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="address"
                    cols="30"
                    rows="5"
                    defaultValue={details.address}
                    className={errors.address ? "formError" : ""}
                    aria-invalid={errors.address ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 20,
                      maxLength: 200,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.address &&
                    (errors.address.type === "required" ||
                      errors.address.type === "validate") && (
                      <span role="alert">Please enter your address.</span>
                    )}
                  {errors.address && errors.address.type === "minLength" && (
                    <span role="alert">
                      Your address should contain atleast 20 characters.
                    </span>
                  )}
                  {errors.address && errors.address.type === "maxLength" && (
                    <span role="alert">
                      Your address should not exceed 200 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>City/District/Town*</Form.Label>
                  <FormControl
                    name="city"
                    type="text"
                    defaultValue={details.city}
                    className={errors.city ? "formError" : ""}
                    aria-invalid={errors.city ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.city &&
                    (errors.city.type === "required" ||
                      errors.city.type === "validate") && (
                      <span role="alert">Please enter your city.</span>
                    )}
                  {errors.city && errors.city.type === "minLength" && (
                    <span role="alert">
                      Your city should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.city && errors.city.type === "maxLength" && (
                    <span role="alert">
                      Your city should should not 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>State*</Form.Label>
                  <FormControl
                    name="state"
                    type="text"
                    defaultValue={details.state}
                    className={errors.state ? "formError" : ""}
                    aria-invalid={errors.state ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.state &&
                    (errors.state.type === "required" ||
                      errors.state.type === "validate") && (
                      <span role="alert">Please enter your state.</span>
                    )}
                  {errors.state && errors.state.type === "minLength" && (
                    <span role="alert">
                      Your state should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.state && errors.state.type === "maxLength" && (
                    <span role="alert">
                      Your state should not exceed 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>Landmark*</Form.Label>
                  <FormControl
                    name="landmark"
                    type="text"
                    defaultValue={details.landmark}
                    className={errors.landmark ? "formError" : ""}
                    aria-invalid={errors.landmark ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.landmark &&
                    (errors.landmark.type === "required" ||
                      errors.landmark.type === "validate") && (
                      <span role="alert">Please enter your landmark.</span>
                    )}
                  {errors.landmark && errors.landmark.type === "minLength" && (
                    <span role="alert">
                      Your landmark should contain atleast 3 characters.
                    </span>
                  )}
                  {errors.landmark && errors.landmark.type === "maxLength" && (
                    <span role="alert">
                      Your landmark should should not 20 characters.
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group>
                  <Form.Label>Alternate Contact Number(optional)</Form.Label>
                  <FormControl
                    type="tel"
                    defaultValue={details.alternate_number}
                    name="alternate_number"
                    className={errors.alternate_number ? "formError" : ""}
                    aria-invalid={errors.alternate_number ? "true" : "false"}
                    ref={register({
                      minLength: 10,
                      maxLength: 10,
                      pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                      },
                      validate: (value) => {
                        //return !!value.trim();
                      },
                    })}
                  />
                  {errors.alternate_number &&
                    (errors.alternate_number.type === "required" ||
                      errors.alternate_number.type === "validate") && (
                      <span role="alert">
                        Please enter your alternate contact number.
                      </span>
                    )}
                  {errors.alternate_number &&
                    errors.alternate_number.type === "maxLength" && (
                      <span role="alert">
                        Your alternate contact number should not exceed 10
                        digits.
                      </span>
                    )}
                  {errors.alternate_number &&
                    errors.alternate_number.type === "minLength" && (
                      <span role="alert">
                        Your alternate contact number should contain atleast 10
                        digits.
                      </span>
                    )}
                  {errors.alternate_number &&
                    errors.alternate_number.type === "pattern" && (
                      <span role="alert">
                        Please enter correct contact number. It should be
                        entered without country code.( Example xxxxxxxxxx)
                      </span>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} ld={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Pincode*</Form.Label>
                  <FormControl
                    type="tel"
                    defaultValue={details.pincode}
                    className={errors.pincode ? "formError" : ""}
                    name="pincode"
                    aria-invalid={errors.pincode ? "true" : "false"}
                    ref={register({
                      required: true,
                      minLength: 6,
                      maxLength: 6,
                      pattern: {
                        value: /^[0-9\s]*$/i,
                      },
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  {errors.pincode &&
                    (errors.pincode.type === "required" ||
                      errors.pincode.type === "validate") && (
                      <span role="alert" className="alertError">
                        Please enter your pincode.
                      </span>
                    )}
                  {errors.pincode && errors.pincode.type === "minLength" && (
                    <span role="alert" className="alertError">
                      Pincode should contain atleast 6 digits.
                    </span>
                  )}
                  {errors.pincode && errors.pincode.type === "maxLength" && (
                    <span role="alert" className="alertError">
                      Pincode should not exceed 6 digits.
                    </span>
                  )}
                  {errors.pincode && errors.pincode.type === "pattern" && (
                    <span role="alert">Please enter digits only.</span>
                  )}
                </Form.Group>
              </Col>
              {/* <Col xs={12}>
                <div className="border-top pt-4 mt-3">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={details.is_billing}
                    label="Use same address as billing information"
                    className={errors.is_billing ? "formError" : ""}
                    name="is_billing"
                    aria-invalid={errors.is_billing ? "true" : "false"}
                    ref={register()}
                  />
                </div>
              </Col> */}
            </Row>
            <div className="mt-5 w-50">
              {details.id ? (
                <CustomButton title={"Update"} />
              ) : (
                <CustomButton title={"Create New"} />
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userRegister: state.userRegister,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAddressModal);
