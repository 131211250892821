import { notice, check, toast } from "react-interaction";
import config from "../../config";

export const setToaster = (value) => {
  notice(value, {
    okStyle: { backgroundColor: "#f26522", color: "#fff" },
    okText: "OK",
    contentClassName: "account-logout",
    contentStyle: {
      width: 450,
    },
  });
};

export const checkConform = async (callback, message) => {
  try {
    const isConfirmed = await check(message, {
      okStyle: {
        backgroundColor: "#f26522",
        color: "#fff",
      },
      contentClassName: "account-logout",
      contentStyle: {
        width: 500,
      },
      okText: "Yes",
      cancelClassName: "my-check-cancel",
      cancelStyle: {
        backgroundColor: "#ccc",
        color: "#fff",
      },
      cancelText: "No",
    });

    if (isConfirmed) {
      callback();
    }
  } catch (error) {}
};

export const showToaster = (value) => {
  toast(value, { time: 2000 });
};

export const renderTitle = (title) => {
  if (!title) {
    return "";
  } else {
    var str = title;
    var n = str.length;
    if (n === 0) {
      return "";
    } else {
      var res = str.slice(0, 30);
      var dot = "";
      if (n > 30) {
        dot = "...";
      }
      return res + dot;
    }
  }
};

export const catchError = (response) => {
  if (response instanceof Error) {
    throw new Error(response.response.data.message);
  }
};

export const sendPushNotification = async (notification) => {
  const FIREBASE_API_KEY = config.FIREBASE_API_KEY;
  const message = {
    registration_ids: [notification.registration_ids],
    notification: {
      title: notification.title,
      body: notification.body,
      type: "CHAT",
      vibrate: 1,
      sound: 1,
      show_in_foreground: true,
      priority: "high",
      content_available: true,
    },
    data: {
      title: notification.title,
      body: notification.body,
      type: "CHAT",
      vibrate: 1,
      sound: 1,
      show_in_foreground: true,
      priority: "high",
      content_available: true,
    },
  };

  let headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "key=" + FIREBASE_API_KEY,
  });

  let response = await fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers,
    body: JSON.stringify(message),
  });
  response = await response.json();
  console.log(response);
};
