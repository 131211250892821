import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as categoryProductActions from "./categoryProductActions";
import * as API from "../utils/apiPath";

export function loadParentCategoryDataSuccess(parentcategoryData) {
  return { type: types.LOAD_PARENT_DATEGORY_DATA_SUCCESS, parentcategoryData };
}

export function loadParentCategoryData() {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .get(API.CATEGORY)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          var param = {};
          param.categoryId = 0;
          param.type = "web";
          if (response.data.status === 200) {
            if (response.data.data.length > 0) {
              param.categoryId = response.data.data[0].id;
              categoryProductActions.loadcategoryProductData(param)(dispatch);
            }
            dispatch(loadParentCategoryDataSuccess(response.data.data));
          }
          dispatch(toggleNetworkRequestStatus(false));
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}
