import React from "react";
import { Modal, Form, FormControl, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import "./LoginSign.css";
import { useForm } from "react-hook-form";
import config from "../../config";
import SocialLogin from "./SocialLogin";
import CustomButton from "../../component/Button/Button";

const LoginModal = (props) => {
  const { actions, userLogin, userParams, deviceToken } = props;
  const { register, errors, handleSubmit } = useForm({
    defaultValues: userParams,
  });
  const userGuestRequestFromCartPage  = localStorage.getItem("userGuestRequestFromCartPage");
  const isToken = deviceToken;
  const onSubmit = (data) => {
    let params = data;
    params.device_type = "web";
    params.device_token = isToken;
    actions.userLoginData(params);
  };
  const handleclickLogin = (action) => {
    actions.userLoginDataSuccess(action);
  };

  const handleclickSeller = () => {
    window.open(config.SELLER_URL, "_blank");
  };

  const handleclickSignShow = (action) => {
    actions.userRegisterDataSuccess(action);
    actions.userLoginDataSuccess(false);
  };

  const handleclickPassword = (action) => {
    actions.passwordDataSuccess(action);
    actions.userLoginDataSuccess(false);
  };
  
  const handleclickGuest = (action) => {
    actions.userGuestDataSuccess(action);
    actions.userLoginDataSuccess(false);
  };

  return (
    <>
      <Modal
        show={userLogin}
        size="lg"
        onHide={() => handleclickLogin(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="login_sign_popup_sec"
        centered
      >
	  {userGuestRequestFromCartPage =='true' ? (
			 <>
			<button type="button" class="btn mb-0 guest_btn" onClick={() => handleclickGuest(true)}>Continue as Guest</button> 
			<div className="orLogin mb-1 mt-3">
              <span>Or Login with</span>
            </div>
			 </>
	  ) : ( <span></span> )}
	  
        <Modal.Header className="p-0 mb-1 border-0" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 login_signup">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                type="text"
                className={errors.username ? "formError" : ""}
                name="username"
                placeholder="Email Address/ mobile number"
                aria-invalid={errors.username ? "true" : "false"}
                ref={register({
                  required: true,
                })}
              />
              {errors.username && errors.username.type === "required" && (
                <span role="alert">
                  Please enter your email address/ mobile number.
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <FormControl
                type="password"
                className={errors.password ? "formError" : ""}
                placeholder="Password"
                name="password"
                aria-invalid={errors.password ? "true" : "false"}
                ref={register({
                  required: true,
                })}
              />
              {errors.password && errors.password.type === "required" && (
                <span role="alert">Please enter your password.</span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox" className="remember pt-2 mb-2">
              <div>
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  name="remember_me"
                  ref={register}
                />
              </div>
              <span
                className="ml-auto link hide"
                onClick={() => handleclickPassword(true)}
              >
                Forgot password?
              </span>
            </Form.Group>
            <CustomButton title={"Submit"} />
            <div className="orLogin mt-3 mb-3">
              <span>Or Login with</span>
            </div>
			
			 
			
            <div className="social_login">
              <SocialLogin isToken={isToken} />
            </div>
            <div className="no_accout text-center">
              <p className="mb-0">
                Don’t have an account yet?{" "}
                <Nav.Link
                  className="link"
                  title="Register"
                  onClick={() => handleclickSignShow(true)}
                >
                  Register
                </Nav.Link>
              </p>
              <span className="link" onClick={() => handleclickSeller()}>
                Join as Seller
              </span>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userLogin: state.userLogin,
    userParams: state.userParams,
    deviceToken: state.deviceToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
