import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import * as productActions from "./productActions";
import * as cartActions from "./cartActions";

export function loginDataSuccess(userInfo) {
  return { type: types.LOADED_USER_INFO_SUCCESS, userInfo };
}

export function ParamsDataSuccess(userParams) {
  return { type: types.LOADED_USER_PARAM_SUCCESS, userParams };
}

export function userLoginDataSuccess(userLogin) {
  return { type: types.LOADED_USER_LOGIN_SUCCESS, userLogin };
}
export function userotpDataSuccess(userOtp) {
  return { type: types.LOADED_USER_OTP_SUCCESS, userOtp };
}
export function otpDataSuccess(otpData) {
  return { type: types.LOADED_OTP_DATA_SUCCESS, otpData };
}
export function userRegisterDataSuccess(userRegister) {
  return { type: types.LOADED_USER_REGISTER_SUCCESS, userRegister };
}

export function userGuestDataSuccess(userGuest) {
  return { type: types.LOADED_USER_GUEST_SUCCESS, userGuest };
}

export function passwordDataSuccess(forgotPassword) {
  return { type: types.LOADED_FORGOT_PASSWORD_SUCCESS, forgotPassword };
}

export function wishlistCountDataSuccess(wishCount) {
  return { type: types.LOAD_WISH_LIST_COUNT_DATA_SUCCESS, wishCount };
}
export function loadCartCountDataSuccess(cartCount) {
  return { type: types.LOAD_CART_COUNT_DATA_SUCCESS, cartCount };
}

export function loadDataSuccess(isCartItem) {
  return { type: types.CART_ITEM_REQUEST_STATUS, isCartItem };
}

export function loadCartItemDataSuccess(cartItem) {
  return { type: types.LOADED_CART_ITEM_SUCCESS, cartItem };
}

export function loadUserAuth(isAuth) {
  return { type: types.LOADED_USER_AUTH_SUCCESS, isAuth };
}

export function loadUserAddressDataSuccess(addressData) {
  return { type: types.LOAD_USER_ADDRESS_CODE_DATA, addressData };
}

export function userLoginData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.LOGIN, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            dispatch(userLoginDataSuccess(false));
            dispatch(loginDataSuccess(response.data.data));
            dispatch(loadUserAuth(true));
            dispatch(
              wishlistCountDataSuccess(response.data.data.wishListCount)
            );
            dispatch(loadCartCountDataSuccess(response.data.data.cartCount));
            localStorage.setItem("authToken", response.data.data.api_token);
            if (params.remember_me) {
              dispatch(ParamsDataSuccess(params));
            } else {
              dispatch(ParamsDataSuccess({}));
            }
            cartActions.loadCartItem(params)(dispatch);
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function userLogout(params, push) {
  return async function (dispatch) {
    let data = {};
    dispatch(loadUserAuth(false));
    dispatch(loginDataSuccess(data));
    dispatch(loadCartCountDataSuccess(0));
    dispatch(wishlistCountDataSuccess(0));
    await agent
      .get(API.LOGOUT)
      .then(() => {
        dispatch(userLoginDataSuccess(true));
        localStorage.removeItem("authToken");
        productActions.loadRecentiyView(params)(dispatch);
        dispatch(loadDataSuccess(true));
        dispatch(loadCartItemDataSuccess({}));
        push("/");
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function userLogoutAction() {
  return async function (dispatch) {
    let data = {};
    dispatch(loadUserAuth(false));
    dispatch(loginDataSuccess(data));
    dispatch(loadCartCountDataSuccess(0));
    dispatch(wishlistCountDataSuccess(0));
    dispatch(userLoginDataSuccess(true));
    localStorage.removeItem("authToken");
    dispatch(loadDataSuccess(true));
    dispatch(loadCartItemDataSuccess({}));
  };
}

export function userSocialLoginData(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.SOCIAL_SINGUP, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            dispatch(userLoginDataSuccess(false));
            dispatch(loginDataSuccess(response.data.data));
            dispatch(loadUserAuth(true));
            dispatch(
              wishlistCountDataSuccess(response.data.data.wishListCount)
            );
            dispatch(loadCartCountDataSuccess(response.data.data.cartCount));
            localStorage.setItem("authToken", response.data.data.api_token);
            cartActions.loadCartItem(params)(dispatch);
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function userRegisterData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SINGUP, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            // dispatch(userotpDataSuccess(true));
			dispatch(userLoginDataSuccess(true));
            dispatch(userRegisterDataSuccess(false));
            //dispatch(otpDataSuccess(response.data.data));
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function userGuestData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.SINGUP_AS_GUEST, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            //setToaster(response.data.message);
            dispatch(userotpDataSuccess(false));
			dispatch(userGuestDataSuccess(false));
            dispatch(loadUserAuth(true));
            dispatch(loginDataSuccess(response.data.data));
            localStorage.setItem("authToken", response.data.data.api_token);
			
			setTimeout(function () { 
				window.location.reload(false);
			}, 1000);
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function checkOtpData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.OTP_CHECK, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            setToaster(response.data.message);
            dispatch(userotpDataSuccess(false));
            dispatch(loadUserAuth(true));
            dispatch(loginDataSuccess(response.data.data));
            localStorage.setItem("authToken", response.data.data.api_token);
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function resendOtp(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.RESEND_OTP, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            dispatch(otpDataSuccess(response.data.data));
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function forgotPasswordData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.FORGOT_PASSWORD, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          if (response.data.status === 422 || response.data.status === 201) {
            setToaster(response.data.message);
          }
          if (response.data.status === 200) {
            dispatch(passwordDataSuccess(false));
            setToaster(response.data.message);
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function updateProfileData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.UPDATE_PROFILE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          showToaster(response.data.message);
          if (response.data.status === 200) {
            dispatch(loginDataSuccess(response.data.data));
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function updatePasswordData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.UPDATE_PASSWORD, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          showToaster(response.data.message);
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function updateProfileImage(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.UPDATE_PROFILE_IMAGE, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(toggleNetworkRequestStatus(false));
          showToaster(response.data.message);
          if (response.data.status === 200) {
            dispatch(loginDataSuccess(response.data.data));
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}

export function userSubscribe(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.CMS_PAGE_SUBSCRIBE, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        showToaster("Thank you for subscribing our newsletter!");
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function loadUserAddressData() {
  return async function (dispatch) {
    await agent
      .get(API.USER_ADDRESS_DATA)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadUserAddressDataSuccess(response.data.data));
          }
        }
        // if (response.status === 201) {
        //   if (response.data.status === 401) {
        //     userLogoutAction()(dispatch);
        //     showToaster("Your session has been expired please login again.");
        //   }
        // }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addUserAddress(params, handleclickModel) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.ADD_USER_ADDRESS_DATA, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(submittingRequestStatus(false));
          handleclickModel(false);
          showToaster(response.data.message);
          if (response.data.status === 200) {
            dispatch(loadUserAddressData());
          }
		  setTimeout(function(){ 
			window.location.reload(false);
		}, 1000);
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        throw error;
      });
  };
}

export function updateUserAddress(params) {
  return async function (dispatch) {
    await agent
      .post(API.UPDATE_USER_ADDRESS_DATA, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loadUserAddressData());
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteUserAddress(params) {
  return async function (dispatch) {
    await agent
      .post(API.REMOVE_USER_ADDRESS_DATA, params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          showToaster(response.data.message);
          if (response.data.status === 200) {
            dispatch(loadUserAddressData());
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getUserData() {
  return async function (dispatch) {
    await agent
      .get(API.GET_PROFILE)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 200) {
            dispatch(loginDataSuccess(response.data.data));
          }
        }
        if (response.status === 201) {
          if (response.data.status === 401) {
            userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
