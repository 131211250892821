import * as types from '../actions/actionsTypes'
import initialState from './initialState'

export default function categoryProductReducers(state = initialState.categoryProductData, action) {
  switch (action.type) {
    case types.LOAD_CATEGORY_PRODUCT_DATA_SUCCESS:
      return action.categoryProductData
    default:
      return state
  }
}
