import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as orderActions from "../../actions/orderActions";
import { Container, Row, Col } from "react-bootstrap";
import "./Order.css";
import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import NotFound from "../../component/NotFound/NotFound";
import DetailsModal from "./Details";
import CancelModal from "./Cancel";
import Item from "./item";
import ReactPaginate from "react-paginate";

const Order = (props) => {
  const { title, actions, orderItem, pagination, cancleModal } = props;
  const [modalShow, setModalShow] = useState(false);
  const [orderDetails, setOrderDetails] = useState(false);
  const [viewpage, setViewPage] = useState(0);
  const [BrdName] = useState([{ title: "My Order", url: "/user/order" }]);
  const location = useLocation();
  const history = useHistory();
  const notFound = {
    title: "You haven't placed any order yet!",
    description:
      "Order section is empty. After placing order, You can track them from here!",
    image: "/assets/img/order.png",
    url: "/marketplace",
    buttion: "START SHOPPING",
  };

  useEffect(() => {
    document.title = title;
    function fetchData() {
      actions.myOrderList();
    }
    fetchData();
    window.scrollTo(0, 0);
  }, [title, actions]);

  const handleViewClick = (item) => {
    setOrderDetails(item);
    setModalShow(true);
  };

  const handleCancleClick = (item) => {
    setOrderDetails(item);
    actions.cancelOrderModal(true);
  };

  const modalCancleClick = (item) => {
    actions.cancelOrderModal(item);
  };

  const handlePageClick = (data) => {
    setViewPage(data.selected);
    let page = data.selected + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/user/order",
      search: "?" + params,
    });
    const request = {};
    request.page = page;
    actions.myOrderList(request);
  };

  return (
    <>
      <Breadcrumbs name={BrdName} />
      <div className="my_account_wrapper">
        <Container>
          <div className="title py-3">{title}</div>
          <Row>
            <Col md={4} lg={3}>
              <Sidebar />
            </Col>
            <Col md={8} lg={9}>
              <div className="order_main p-4">
                {orderItem && orderItem.length > 0 ? (
                  orderItem.map((item, key) => (
                    <Item
                      details={item}
                      key={key}
                      onClick={handleViewClick}
                      onChange={handleCancleClick}
                    />
                  ))
                ) : (
                  <NotFound {...notFound} />
                )}
              </div>
              <div className="pagination_sec pt-4">
                {orderItem &&
                  orderItem.length > 0 &&
                  pagination.count !== 0 && (
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      forcePage={viewpage}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pagination.total_pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={pagination.per_page}
                      onPageChange={(v) => handlePageClick(v)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {modalShow && (
        <DetailsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          details={orderDetails}
        />
      )}
      {cancleModal && (
        <CancelModal
          show={cancleModal}
          onHide={() => modalCancleClick(false)}
          details={orderDetails}
        />
      )}
    </>
  );
};

Order.propTypes = {
  orderItem: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    orderItem: state.orderItem,
    pagination: state.pagination,
    cancleModal: state.cancleModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(orderActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
