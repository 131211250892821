import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";

export function loadDataSuccess(setting) {
  return { type: types.LOADED_SETTING_INFO_SUCCESS, setting };
}

export function loadTokenDataSuccess(deviceToken) {
  return { type: types.LOAD_DEVICE_TOKEN_DATA_SUCCESS, deviceToken };
}

export const getSettingData = () => async (dispatch) => {
  try {
    const response = await agent.get(API.SETTING);
    if (response.status === 200 || response.status === 201) {
      if (response.data.status === 200) {
        dispatch(loadDataSuccess(response.data.data));
      }
    }
  } catch (error) {
    throw error;
  }
};

export const setDeviceToken = (token) => async (dispatch) => {
  try {
    dispatch(loadTokenDataSuccess(token));
  } catch (error) {
    throw error;
  }
};
