import React from "react";
import {
  Home,
  About,
  Product,
  HowToWork,
  App,
  Details,
  Cart,
  Faq,
  KitchenPackages,
  Packages,
  Stuvita,
  Services,
  SarikaTotla,
  ContactUs,
  PaymentMeeting,
  PaymentFailed,
  Payment,
  NotFound,
  ForgotPassword,
  LinkExpired,
  MyRoom,
  KidsRoomDesignServices,
  AnradGallery,
} from "./Component";
import { Route, Switch, Redirect } from "react-router-dom";
import GuardedRoute from "./GuardedRoute";
import userRoutes from "./userRoutes";

const routes = (
  <Switch>
    <Route exact path="/" component={() => <Home title={`Home`} />} />
    <Route
      exact
      path="/forgotPassword"
      component={() => <ForgotPassword title={`Forgot Password`} />}
    />
    <Route
      exact
      path="/linkExpired"
      component={() => <LinkExpired title={`Link Expired`} />}
    />
    <Route exact path="/404" component={() => <NotFound title={`404`} />} />
    <Route exact path="/app" component={() => <App title={`App`} />} />
    <Route exact path="/faq" component={() => <Faq title={`FAQ's`} />} />
    <Route exact path="/packages" component={() => <Packages title={`Packages`} />} />
    <Route exact path="/design-services" component={() => <Stuvita title={`Design Services`} />} />
    <Route exact path="/designiche_design_services" component={() => <Services title={`Design Services`} />} />
    <Route exact path="/sarika_totla" component={() => <SarikaTotla title={`Sarika Totla`} />} />
    <Route exact path="/kitchen-packages" component={() => <KitchenPackages title={`Kitchen Packages`} />} />
    <Route exact path="/kids-room-design-services" component={() => <KidsRoomDesignServices title={`Kids Room Design Services`} />} />
    <Route exact path="/anrad-gallery" component={() => <AnradGallery title={`Anrad Gallery`} />} />
    <Route
      exact
      path="/contact-us"
      component={() => <ContactUs title={`ContactUs`} />}
    />
    <Route
      exact
      path="/marketplace"
      component={() => <Product title={`Product`} />}
    />
    <Route
      exact
      path="/product/details/:id"
      component={() => <Details title={`Product Details`} />}
    />
    <Route
      exact
      path="/how_it_work"
      component={() => <HowToWork title={`How It Works`} />}
    />
    <Route
      exact
      path="/meeting"
      component={() => <PaymentMeeting title={`Payment Was Successful`} />}
    />
    <Route exact path="/cart" component={() => <Cart title={`Cart`} />} />
    <Route
      exact
      path="/payment/successful/:id"
      component={() => <Payment title={`Payment Successful`} />}
    />
    <Route
      exact
      path="/payment/failed"
      component={() => <PaymentFailed title={`Payment failed`} />}
    />
    {userRoutes.map((item, key) => (
      <GuardedRoute
        path={item.path}
        component={item.component}
        title={item.title}
        key={key}
      />
    ))}
    <Route exact path="/designgpt" component={() => <MyRoom title={`Design GPT`} />} />
    <Route exact path="/:slug" component={() => <About title={`About Us`} />} />
    <Redirect to="/404" />
  </Switch>
);

export default routes;
