import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as orderActions from "../../actions/orderActions";
import { Container, Row, Col } from "react-bootstrap";
import Item from "./Item";
import Sidebar from "../../component/SiderBar/Sidebar";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import NotFound from "../../component/NotFound/NotFound";

const Order = (props) => {
  const { title, actions, reviewData } = props;
  const [BrdName] = useState([{ title: "My Review", url: "/user/review" }]);

  const notFound = {
    title: "You haven't submit any review yet!",
    description: "",
    image: "/assets/img/review.svg",
    url: "/marketplace",
    buttion: "START SHOPPING",
  };

  useEffect(() => {
    document.title = title;
    function fetchData() {
      actions.loadMyReview();
    }
    fetchData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title, actions]);

  const removeItem = (id) => {
    let params = {};
    params.id = id;
    actions.removeReview(params);
  };

  return (
    <>
      <Breadcrumbs name={BrdName} />
      <div className="my_account_wrapper">
        <Container>
          <div className="title py-3">{title}</div>
          <Row>
            <Col md={4} lg={3}>
              <Sidebar />
            </Col>
            <Col md={8} lg={9}>
              <div className="order_main p-4">
                <div className="order_main p-4">
                  <ul className="pt-2 pl-2">
                    {reviewData && reviewData.length > 0 ? (
                      reviewData.map((item, key) => (
                        <Item review={item} key={key} removeItem={removeItem} />
                      ))
                    ) : (
                      <NotFound {...notFound} />
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Order.propTypes = {
  reviewData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    reviewData: state.reviewData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(orderActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
