import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function productReviewMoreReducers(
  state = initialState.productReviewMore,
  action
) {
  switch (action.type) {
    case types.LOAD_PRODUCT_REVIEW_MORE_DATA_SUCCESS:
      return action.productReviewMore;
    default:
      return state;
  }
}
