import { combineReducers } from "redux";
import homePageData from "./homePageReducers";
import bannerData from "./bannerReducers";
import packageData from "./packageReducers";
import packageRoomClassData from "./packageRoomClassReducers";
import parentCategoryData from "./parentCategoryReducers";
import categoryProductData from "./categoryProductReducers";
import trandingProductData from "./trandingProductReducers";
import advertisingData from "./advertisingReducers";
import productData from "./productReducers";
import detailsData from "./productdetailsReducers";
import isProduct from "./isProductRequest";
import pagination from "./paginationReducers";
import hotProductData from "./hotProductReducers";
import productFilterData from "./productFilterReducers";
import networkRequest from "./networkRequest";
import initialState from "./initialState";
import userInfo from "./userInfoReducer";
import userParams from "./userParamsReducer";
import userLogin from "./userloginReducer";
import userRegister from "./userregisterReducer";
import userGuest from "./userguestReducer";
import userOtp from "./userotpReducer";
import otpData from "./otpDataReducer";
import forgotPassword from "./forgotPasswordReducer";
import isWishList from "./isWishlistRequest";
import wishCount from "./wishListCountRequest";
import productAttribute from "./productAttributeReducers";
import productNextAttribute from "./productNextAttributeReducers";
import isCart from "./isCartRequest";
import cartCount from "./cartCountRequest";
import recentlyView from "./recentlyVewProductReducers";
import productReview from "./productReviewReducers";
import cartItem from "./cartItemReducer";
import isCartItem from "./isCartItemRequest";
import couponModel from "./couponModelReducer";
import couponCode from "./couponCodeReducers";
import isAuth from "./isAuthRequest";
import orderItem from "./orderItemReducer";
import wishlistItem from "./wishlistItemReducer";
import isSubmitting from "./isSubmittingRequest";
import footerProduct from "./footerProductReducers";
import isAvailability from "./isAvailabilityRequest";
import addressData from "./userAddressReducers";
import setting from "./settingReducer";
import dummyOrder from "./isOrderRequest";
import isStock from "./isStockRequest";
import locationPop from "./isLocationRequest";
import pinCode from "./isPincodeRequest";
import headerProduct from "./headerProductReducers";
import productReviewMore from "./productReviewMoreReducers";
import reviewData from "./reviewDataReducer";
import footerCategory from "./footerDataReducer";
import faqData from "./faqDataReducer";
import realSpaces from "./realSpacesDataReducer";
import homeDesigen from "./homeDesignReducer";
import cancleModal from "./cancleModalReducer";
import actionStaus from "./actionStatusReducer";
import deviceToken from "./deviceTokenReducer";
import sellerOrderData from "./sellerOrderDataReducer";
import productSliderImage from "./productSilderImageReducers";
import dummyProductModal from "./dummyProductModelReducer";
import dummyProduct from "./dummyProductReducers";
import pageList from "./listPageReducers";
import notificationData from "./notificationReducers";
import isWishListData from "./isWishlistDataRequest";
import footerSupplier from "./footerSupplierDataReducer";
import brandData from "./brandReducers";

const rootReducer = combineReducers({
  homePageData,
  bannerData,
  packageData,
  brandData,
  packageRoomClassData,
  parentCategoryData,
  categoryProductData,
  trandingProductData,
  hotProductData,
  productData,
  pagination,
  advertisingData,
  productFilterData,
  isFetching: networkRequest,
  isProduct,
  detailsData,
  userInfo,
  userLogin,
  userRegister,
  userGuest,
  userParams,
  userOtp,
  otpData,
  forgotPassword,
  isWishList,
  wishCount,
  productAttribute,
  productNextAttribute,
  isCart,
  cartCount,
  recentlyView,
  productReview,
  cartItem,
  isCartItem,
  couponModel,
  couponCode,
  isAuth,
  orderItem,
  wishlistItem,
  isSubmitting,
  footerProduct,
  isAvailability,
  addressData,
  setting,
  dummyOrder,
  isStock,
  locationPop,
  pinCode,
  headerProduct,
  productReviewMore,
  reviewData,
  footerCategory,
  faqData,
  realSpaces,
  homeDesigen,
  actionStaus,
  cancleModal,
  deviceToken,
  sellerOrderData,
  productSliderImage,
  dummyProductModal,
  dummyProduct,
  pageList,
  notificationData,
  isWishListData,
  footerSupplier,
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
