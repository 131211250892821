import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Newsletter from "../../component/Newsletter/Newsletter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { Link } from "react-router-dom";

import LoadDetails from "./LoadDetails";

const Index = (props) => {
  const { title, actions, faqData } = props;
  const [modalShow, setModalShow] = useState(false);
  const [detail, setDetail] = useState(false);

  useEffect(() => {
    function fetchData() {
      actions.loadFaqData();
    }
    fetchData();
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [actions, title]);

  const handleLoadModal = (faq) => {
    setDetail(faq);
    setModalShow(true);
  };

  return (
    <>
      <section className="about_sec py-5">
        <Container className="pb-5">
          <h1 className="page_title mb-4">FAQ's</h1>
          <ul className="resource_guide_links">
            {faqData.map((faq, key) => (
              <li
                className="w-100"
                key={key}
                onClick={() => handleLoadModal(faq)}
              >
                <Link to="#!" title={faq.name}>
                  {key + 1}&nbsp;
                  {faq.name}
                </Link>
              </li>
            ))}
          </ul>
        </Container>
        <Newsletter />
        {modalShow && (
          <LoadDetails
            show={modalShow}
            onHide={() => setModalShow(false)}
            detail={detail}
          />
        )}
      </section>
    </>
  );
};

Index.propTypes = {
  faqData: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    faqData: state.faqData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
