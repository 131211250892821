import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import submittingRequestStatus from "./submittingRequestStatusAction";
import { showToaster } from "../utils/helpers";
import * as userActions from "./userActions";

export function loadDataSuccess(orderItem) {
  return { type: types.LOAD_ORDER_ITEM_DATA, orderItem };
}

export function loadReviewDataSuccess(reviewData) {
  return { type: types.LOAD_REVIEW_DATA, reviewData };
}

export function loadpaginationDataSuccess(pagination) {
  return { type: types.LOAD_PAGINATION_DATA_SUCCESS, pagination };
}

export function loadcancelModalDataSuccess(cancleModal) {
  return { type: types.LOADED_ORDER_CANCLE_MODAL, cancleModal };
}

export const myOrderList = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.MY_ORDER_LIST, params);
    dispatch(toggleNetworkRequestStatus(false));
    if (response.status === 200 || response.status === 201) {
      dispatch(loadDataSuccess(response.data.data.item));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const cancelOrder = (params) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.post(API.MY_ORDER_CANCEL, params);
    dispatch(submittingRequestStatus(false));
    dispatch(loadcancelModalDataSuccess(false));
    if (response.data.status === 200) {
      dispatch(loadDataSuccess({}));
      dispatch(submittingRequestStatus(false));
      dispatch(loadcancelModalDataSuccess(false));
      dispatch(myOrderList());
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
    if (response.data.status === 422) {
      showToaster(response.data.message);
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const loadMyReview = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.LOAD_MY_REVIEW, params);
    dispatch(toggleNetworkRequestStatus(false));
    if (response.status === 200 || response.status === 201) {
      if (response.data.status === 200) {
        dispatch(loadReviewDataSuccess(response.data.data.item));
      }
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const removeReview = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.DELETE_MY_REVIEW, params);
    dispatch(toggleNetworkRequestStatus(false));
    if (response.status === 200 || response.status === 201) {
      if (response.data.status === 200) {
      }
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const cancelOrderModal = (params) => async (dispatch) => {
  dispatch(loadcancelModalDataSuccess(params));
};

export function loadNotificationDataSuccess(notificationData) {
  return { type: types.LOAD_NOTIFICATION_DATA, notificationData };
}

export const loadMyNotification = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.LOAD_NOTIFICATION, params);
    dispatch(toggleNetworkRequestStatus(false));
    if (response.status === 200 || response.status === 201) {
      if (response.data.status === 200) {
        dispatch(loadNotificationDataSuccess(response.data.data.item));
      }
    }
    if (response.status === 201) {
      if (response.data.status === 401) {
        userActions.userLogoutAction()(dispatch);
        showToaster("Your session has been expired please login again.");
      }
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};

export const removeNotification = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.delete(API.LOAD_NOTIFICATION, {
      params: params,
    });
    dispatch(toggleNetworkRequestStatus(false));
    showToaster(response.data.message);
    dispatch(loadMyNotification());
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    throw error;
  }
};
