import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { checkConform, showToaster } from "../../utils/helpers";
import NumberFormat from "react-number-format";

const CartItem = ({
  id,
  productId,
  title,
  currency,
  qty,
  originalPrice,
  image,
  attributeName,
  key,
  incrementOrDecrement,
  removeItem,
  discount,
  price,
  maxQty,
  special_promotion_message,
}) => {
  const removeCartItem = () => {
    const afterCallback = () => {
      removeItem(id);
    };
    checkConform(
      afterCallback,
      "Are you sure, you want to remove this item from the cart?"
    );
  };
  const incrementOrDecrementClick = (e, type) => {
    let params = {};
    params.id = id;
    params.type = type;
    params.product_qty = qty;
    if (type === "inc") {
      params.qty = qty + 1;
    } else {
      params.qty = qty - 1;
    }
    if (params.qty < 11) {
      incrementOrDecrement(params);
    } else {
      showToaster("You've reached the maximum units allowed for the purchase of this item.");
    }
  };
  return (
    <>
      <tr key={key}>
        <td>
          <img src={image} alt={attributeName} width={55} />
        </td>
        <td>
          <h4>
            <NavLink to={"/product/details/" + productId} title={title}>
              {title} <span>{attributeName}</span>
			  <span>{special_promotion_message}</span>
            </NavLink>
          </h4>
        </td>
        <td>
          {currency}&nbsp;
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={originalPrice.toFixed(2)}
            prefix=""
            decimalSeparator="."
            displayType={"text"}
            thousandSeparator={true}
          />
          {discount !== 0 && (
            <del className="pl-2">
              {currency}&nbsp;
              {price}
            </del>
          )}
        </td>
        <td>
          <div className="number">
            <span
              className={qty <= 1 ? "minus disabled" : "minus"}
              onClick={(e) => {
                incrementOrDecrementClick(e, "desc");
              }}
            >
              -
            </span>
            <span className="minus">{qty}</span>
            <span
              className={qty >= maxQty ? "plus disabled" : "plus"}
              onClick={(e) => {
                incrementOrDecrementClick(e, "inc");
              }}
            >
              +
            </span>
          </div>
        </td>
        <td>
          {currency}&nbsp;
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={(originalPrice * qty).toFixed(2)}
            prefix=""
            decimalSeparator="."
            displayType={"text"}
            thousandSeparator={true}
          />
        </td>
        <td>
          <span className="action" onClick={removeCartItem}>
            <img src="/../../../assets/img/close-orange.png" alt="" />
          </span>
        </td>
      </tr>
    </>
  );
};

export default connect()(CartItem);
