import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function cartItemReducer(state = initialState.cartItem, action) {
  let updatedCart;
  let updatedItemIndex;
  switch (action.type) {
    case types.LOADED_CART_ITEM_SUCCESS:
      return action.cartItem;
    case types.REMOVE_PRODUCT_FROM_CART:
      updatedCart = state.item;
      updatedItemIndex = updatedCart.findIndex(
        (item) => item.id === action.cartItem
      );
      updatedCart.splice(updatedItemIndex, 1);
      return { ...state, cartItem: updatedCart };
    case types.INCREMENT_CART_ITEM_QUANTITY:
      updatedCart = state.item;
      updatedItemIndex = updatedCart.findIndex(
        (item) => item.id === action.cartItem
      );
      const incrementedItem = {
        ...state.item[updatedItemIndex],
      };
      incrementedItem.qty++;
      updatedCart[updatedItemIndex] = incrementedItem;
      return { ...state, cartItem: updatedCart };
    case types.DECREMENT_CART_ITEM_QUANTITY:
      updatedCart = state.item;
      updatedItemIndex = updatedCart.findIndex(
        (item) => item.id === action.cartItem
      );
      const decrementedItem = {
        ...state.item[updatedItemIndex],
      };
      if (decrementedItem.qty > 1) {
        decrementedItem.qty--;
      }
      updatedCart[updatedItemIndex] = decrementedItem;
      return { ...state, cartItem: updatedCart };
    case types.LOAD_CHECK_COUPON_DATA:
      updatedCart = state;
      updatedCart.viewTotal = action.cartItem.amount;
      updatedCart.total = action.cartItem.total;
      updatedCart.discountAmount = action.cartItem.discountAmount;
      updatedCart.couponDiscount = action.cartItem.couponDiscount;
      updatedCart.afterDiscountAmount = action.cartItem.afterDiscountAmount;
      return { ...state, cartItem: updatedCart };
    default:
      return state;
  }
}
