import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Sidebar.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Nav } from "react-bootstrap";
import { checkConform, showToaster } from "../../utils/helpers";
import * as userActions from "../../actions/userActions";

const Sidebar = (props) => {
  const { userInfo, actions, deviceToken } = props;

  function SidebarAddClass() {
    document
      .getElementsByClassName("sidebar")[0]
      .setAttribute("id", "sidebar_panel");
  }
  function SidebarRemoveClass() {
    document
      .getElementsByClassName("sidebar")[0]
      .removeAttribute("id", "sidebar_panel");
  }

  const handleLogoutClick = () => {
    const afterLogoutCallback = () => {
      let params = {};
      const isToken = deviceToken;
      params.device_type = "web";
      params.device_token = isToken;
      actions.userLogout(params);
    };

    checkConform(
      afterLogoutCallback,
      "Are you sure you want to logout account?"
    );
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      const selectedFile = e.target.files[0];
      var size = Math.round(selectedFile.size / 1048576);
      if (size > 5) {
        showToaster("File size cannot exceed more than 5MB");
        return false;
      }
      if (!selectedFile.name.match(/\.(jpg|jpeg|png)$/)) {
        showToaster("Please select valid image.");
        return false;
      }
      formData.append("image", selectedFile, selectedFile.name);
      actions.updateProfileImage(formData);
    }
  };

  return (
    <>
      <div className="sidebar_wrap">
        <span id="filter_toggle" onClick={SidebarAddClass}>
          My Account Menu
        </span>
        <div className="sidebar">
          <div className="sidebar_menu_block">
            <span className="filter_close" onClick={SidebarRemoveClass}>
              <img src="/../../../assets/img/close_white.png" alt="Close" />
            </span>
            <div className="my_account_sec">
              <div className="text-center">
                <div className="profile_pic">
                  <img src={userInfo.viewImage} alt="Designiche" />
                  <div className="change_profile_pic">
                    <input type="file" onChange={onChangePicture} />
                    <i className="fas fa-camera"></i>
                  </div>
                </div>
                <div className="error-image">You can upload max 5MB image</div>
                <div className="user_profile_name py-3">
                  {userInfo.first_name.charAt(0).toUpperCase() +
                    userInfo.first_name.slice(1) +
                    " " +
                    userInfo.last_name.charAt(0).toUpperCase() +
                    userInfo.last_name.slice(1)}
                </div>
              </div>
              <ul className="my_account_links">
                <li className="active">
                  <NavLink
                    to="/user/profile"
                    title="My Profile"
                    exact
                    activeClassName="active"
                  >
                    My Profile
                  </NavLink>
                </li>
                <li className="active">
                  <NavLink
                    to="/user/order"
                    title="My Orders"
                    exact
                    activeClassName="active"
                  >
                    My Orders
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/user/address"
                    title="Address List"
                    exact
                    activeClassName="active"
                  >
                    My Address
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/review"
                    title="My Reviews"
                    exact
                    activeClassName="active"
                  >
                    My Reviews
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/notification"
                    title="Notifications"
                    exact
                    activeClassName="active"
                  >
                    Notifications
                  </NavLink>
                </li>
                {!userInfo.social_type && (
                  <li>
                    <NavLink
                      to="/user/changePassword"
                      title="Change Password"
                      exact
                      activeClassName="active"
                    >
                      Change Password
                    </NavLink>
                  </li>
                )}
                <li>
                  <Nav.Link title="Logout" onClick={handleLogoutClick}>
                    Logout
                  </Nav.Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    deviceToken: state.deviceToken,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
