import React, { useEffect, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header, Footer, Loader, ScrollToTop } from "./Component";
import routes from "./routes";
import "./App.css";

import * as productActions from "./actions/productActions";
import * as settingActions from "./actions/settingActions";
import * as categoryProductActions from "./actions/categoryProductActions";
import uuid from "react-uuid";

function App(props) {
  const { isFetching, actions, deviceToken } = props;

  useEffect(() => {
    const fetchData = () => {
      actions.loadFooerProduct();
      actions.getSettingData();
      actions.loadFooterCategory();
      actions.loadFooterPages();
	  actions.loadFooterSupplier();
    };
    fetchData();
  }, [actions]);

  useEffect(() => {
    if (deviceToken === null) {
      const token = uuid();
      actions.setDeviceToken(token);
    }
  }, [deviceToken, actions]);

  return (
    <Fragment>
      <Router>
        <ScrollToTop />
        <Header />
        {routes}
        {isFetching && <Loader />}
        <Footer />
      </Router>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: state.isFetching,
    deviceToken: state.deviceToken,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(productActions, settingActions, categoryProductActions),
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
