import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";

const Review = (props) => {
  const [onetime, setOnetime] = useState(true);
  const [review, setReview] = useState(props.review);

  const handleLikeClick = (review) => {
    const params = {};
    console.log("review.isLike ==>", review.isLike);
    if (review.isLike) {
      setReview({
        ...props.review,
        ...{ like_count: review.like_count - 1, isLike: false },
      });
    } else {
      setReview({
        ...props.review,
        ...{ like_count: review.like_count + 1, isLike: true },
      });
    }
    params.id = review.id;
    params.isLike = review.isLike;
    props.onClick(params);
    setOnetime(true);
  };

  useEffect(() => {
    setReview(review);
  }, [review]);

  return (
    <>
      <li data-onetime={onetime}>
        <div className="uploaded_img mb-2">
          {review.image.map((image, key) => (
            <span key={key}>
              <img src={image.image} alt="upload" />
            </span>
          ))}
        </div>
        <div className="star mb-2 d-inline-flex align-items-center">
          <StarRatingComponent
            name="rate"
            starCount={5}
            value={review.rating}
            renderStarIcon={() => <i className="fas fa-star"></i>}
          />
          <span className="reviewed_text ml-1">Reviewed on {review.date}</span>
        </div>
        <h4>{review.user}</h4>
        <div className="d-flex">
          <p className="mr-2">{review.description}</p>
          <div
            className={
              review.isLike
                ? "link helful_count ml-auto"
                : "unlink helful_count ml-auto"
            }
            onClick={() => handleLikeClick(review)}
          >
            <i className="fas fa-thumbs-up mr-2"></i>Helpful |{" "}
            {review.like_count}
          </div>
        </div>
      </li>
    </>
  );
};

export default Review;
