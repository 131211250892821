import React, { useState, Fragment, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
  Spinner,
} from "react-bootstrap";
import "./Newsletter.css";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";

const Newsletter = (props) => {
  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();
  const { actions, isSubmitting } = props;
  const onSubmit = (data) => {
    actions.userSubscribe(data);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("forMobile")) {
      setShowHeader(false);
    }
  }, [location]);

  return (
    <Fragment>
      {showHeader && (
        <section className="newsletter">
          <Container>
            <Row className="align-items-center">
              <Col
                xs={12}
                lg={5}
                className="mb-3 mb-lg-0 text-center text-md-left"
              >
                <h3>Be the first to know...</h3>
                <h6 className="m-0">
                  Sign up for emails packed with finds and inspiration
                </h6>
              </Col>
              <Col xs={12} lg={7}>
                <Form
                  inline
                  className="newsletter_form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormControl
                    type="email"
                    placeholder="Email Address"
                    className={errors.email ? "formError" : ""}
                    name="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      maxLength: 50,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span role="alert">Please enter your email address.</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span role="alert">Invalid email address.</span>
                  )}
                  {errors.email && errors.email.type === "maxLength" && (
                    <span role="alert">
                      Your email should not exceed 50 characters.
                    </span>
                  )}
                  <Button
                    variant=""
                    className="btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        animation="grow"
                        aria-hidden="true"
                      />
                    ) : (
                      <>SUBSCRIBE</>
                    )}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
