import React from "react";
import { Modal } from "react-bootstrap";
const LoadDetailsModal = (props) => {
  const { detail } = props;

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-0 mb-4 border-0" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {detail.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 login_signup">
        <div className="border-top pt-4 mt-4">
          <div
            className="page_content"
            dangerouslySetInnerHTML={prepareHtml(detail.description)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadDetailsModal;
