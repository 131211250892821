  
// import '../App.scss';
import './services.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { NavLink } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom"; 
import { Outlet, Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper";

import ReactPlayer from "react-player";

import p_img_1 from './images/project-img-sm-1.jpg';
import p_img_1_2 from './images/project-img-sm-1.2.jpg';
import p_img_1_3 from './images/project-img-sm-1.3.jpg';
import p_img_2 from './images/project-img-sm-2.jpg';
import p_img_2_2 from './images/project-img-sm-2.2.jpg';
import p_img_2_3 from './images/project-img-sm-2.3.jpg';
import p_img_3 from './images/project-img-sm-3.jpg';
import p_img_3_2 from './images/project-img-sm-3.2.jpg';
import p_img_3_3 from './images/project-img-sm-3.3.jpg';

import carousel1 from './images/project-img-1.jpeg';
import carousel2 from './images/project-img-1.2.jpeg';
import carousel3 from './images/project-img-1.3.jpg';
import carousel4 from './images/project-img-2.jpeg';
import carousel5 from './images/project-img-2.2.jpeg';
import carousel6 from './images/project-img-2.3.jpeg';
import carousel7 from './images/project-img-3.jpeg';
import carousel8 from './images/project-img-3.2.jpeg';
import carousel9 from './images/project-img-3.3.jpeg'; 
import curve from './images/curve.png';
import stuvita from './images/stuvita.jpg';
import qualita from './images/qualita.jpg';
// import thecolorco from './images/thecolorco.jpg';
import thecolorco1 from './images/thecolorco_1.jpg';

// gallery images
import opg1 from './images/works/opg1.JPG';
import opg2 from './images/works/opg2.JPG';
import opg3 from './images/works/opg3.JPG';
import opg4 from './images/works/opg4.JPG';
import opg5 from './images/works/opg5.JPG';
import opg6 from './images/works/opg6.JPG';
import opg7 from './images/works/opg7.JPG';
import opg8 from './images/works/opg8.JPG';
import opg9 from './images/works/opg9.JPG';
import opg10 from './images/works/opg10.JPG';
import opg11 from './images/works/opg11.JPG';
import opg12 from './images/works/opg12.JPG';
import opg13 from './images/works/opg13.JPG';
import opg14 from './images/works/opg14.JPG';
import opg15 from './images/works/opg15.JPG';
import opg16 from './images/works/opg16.JPG';
import opg17 from './images/works/opg17.JPG';
import opg18 from './images/works/opg18.JPG';
import opg19 from './images/works/opg19.JPG';
import opg20 from './images/works/opg20.JPG';
import opg21 from './images/works/opg21.JPG';
import opg22 from './images/works/opg22.JPG';
import opg23 from './images/works/opg23.JPG';
import opg24 from './images/works/opg24.JPG';
import opg25 from './images/works/opg25.JPG';
import opg26 from './images/works/opg26.JPG';
import opg27 from './images/works/opg27.JPG';
 
const Services = (props) => {
 
  const { actions, homePageData, isSubmitting } = props;
  const { slug } = useParams();

  const { register, errors, handleSubmit } = useForm();
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  const onSubmit = (data) => {
    console.log(JSON.stringify(data),'Prem');
 	data.request_for = 'Design Services';
     //return;
    actions.sendSpecialRequest(data);
  };
  
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" }
  };
  
   useEffect(() => {
    document.title = "Designiche Design Services";
  });
  const [video] = useState(
    "https://youtu.be/9tF4m3Q7ct0"
  );
    
  const images = [
    {
      img: opg2,
      img2: opg3,
      img3: opg4,
      img4: opg5,
      img5: opg6,
      img6: opg7,
    },
    {
      img: opg8,
      img2: opg9,
      img3: opg10,
      img4: opg11,
      img5: opg12,
      img6: opg13,
    },
    {
      img: opg14,
      img2: opg15,
      img3: opg16,
      img4: opg17,
      img5: opg18,
      img6: opg19,
    },
    {
      img: opg20,
      img2: opg21,
      img3: opg22,
      img4: opg23,
      img5: opg24,
      img6: opg25,
    },
  ];
  const [selectedImage, setSelectedImage] = useState("");
  const openPopup = (image) => {
    setSelectedImage(image);
  };
  const closePopup = () => {
    setSelectedImage(null);
  };
  
  return (
        <div>  
      	<section className="pt-5 dds-top-section">
          <div className="container">
          <h3 class="common-heading text-white text-uppercase mb-2">Designiche Design Services</h3>
            <p className="text-white">Guided by our founder, Ms. Preneet Malhotra, our design services team emphasizes and thrives on personalizing and customizing spaces to create unique, aesthetically appealing homes.  We've embarked on projects ranging from modest home-styling assignments—tailoring avant-garde furniture to the homeowner's preferences—to extensive renovations that involve reimagining the entire house, room by room.</p>

            <p className="text-white">Our mission is not simply to furnish new spaces but to appreciate that every house has a narrative, each piece of old furniture carries a memory. We aim to preserve these cherished recollections while presenting you with a rejuvenated version of your home, maintaining its priceless aspects. We recognize that a home is a mirror of the homeowner's character, it's a testament to their life and their ancestral legacy, and our designs respect and honor this fact.</p>

            <p className="text-white">We've had clients with heirlooms like cherished chairs from a grandmother, which we incorporated into the house's new design. In another example worked to incorporate a collection of priceless CDs on a wall display in the family room. Our approach is to modernize the style without relinquishing the home's unique story and memories. We offer a co-creative personalization process with the homeowner, delivering a HOME, not merely a house. It's your story to tell, and no outsider can tell it better than you. We enable that process and make it a reality.</p>

            <p className="text-white mb-0">Here at Designiche Design Services, our focus is on YOU—YOUR narrative, YOUR memories, YOUR budget, and what makes YOUR space feel like YOUR HOME.</p>
          </div>
        </section>
        <img src={curve} className="w-100" alt="" />
        
        <section className="projects bg-light d-none pt-5 pb-2">
          <div className="container pt-2">
            <h3 className="common-heading text-uppercase mb-4 pb-2">Our Projects</h3>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4 pb-4">
                <div className="project-box" data-toggle="modal" data-target="#pro1">
                  <img src={p_img_1} alt="project image" />
                  <img src={p_img_1_2} alt="project image" />
                  <img src={p_img_1_3} alt="project image" />
                  <h3 className="project--heading">Project 1</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pb-4">
                <div className="project-box" data-toggle="modal" data-target="#pro2">
                  <img src={p_img_2} alt="project image" />
                  <img src={p_img_2_2} alt="project image" />
                  <img src={p_img_2_3} alt="project image" />
                  <h3 className="project--heading">Project 2</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 pb-4">
                <div className="project-box" data-toggle="modal" data-target="#pro3">
                  <img src={p_img_3} alt="project image" />
                  <img src={p_img_3_3} alt="project image" />
                  <img src={p_img_3_2} alt="project image" />
                  <h3 className="project--heading">Project 3</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="pro1" tabindex="-1" aria-labelledby="pro1Label" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div id="carouselP1" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item text-center active">
                    <img src={carousel1} alt="project image" className="img-fluid" />
                    </div>
                    <div className="carousel-item text-center">
                    <img src={carousel2} alt="project image" className="img-fluid" />
                    </div>
                    <div className="carousel-item text-center">
                    <img src={carousel3} alt="project image" className="img-fluid" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselP1" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselP1" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="pro2" tabindex="-1" aria-labelledby="pro2Label" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div id="carouselP2" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item text-center active">
                    <img src={carousel4} alt="project image" className="img-fluid" />
                    </div>
                    <div className="carousel-item text-center">
                    <img src={carousel5} alt="project image" className="img-fluid" />
                    </div>
                    <div className="carousel-item text-center">
                    <img src={carousel6} alt="project image" className="img-fluid" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselP2" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselP2" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="pro3" tabindex="-1" aria-labelledby="pro3Label" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div id="carouselP3" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item text-center active">
                    <img src={carousel7} alt="project image" className="img-fluid" />
                    </div>
                    <div className="carousel-item text-center">
                    <img src={carousel8} alt="project image" className="img-fluid" />
                    </div>
                    <div className="carousel-item text-center">
                    <img src={carousel9} alt="project image" className="img-fluid" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselP3" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselP3" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
        
		    <div className="design-service-gallery container pb-5">
            <div className="new-project-gallery">
              <div className="text-center">
                <h3 className="common-heading text-uppercase mb-4">
                  Our Project Work
                </h3>
              </div>
              <Swiper
                modules={[Pagination, Navigation]}
                spaceBetween={15}
                slidesPerView={1}
                loop={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                pagination={{
                  clickable: true,
                }}
                navigation
                className="mySwiper"
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                  },
                  "@1.00": {
                    slidesPerView: 1,
                  },
                  "@1.50": {
                    slidesPerView: 1,
                  },
                }}
              >
              {images?.map((image, index) => (
                <SwiperSlide>
                  <div className="new-project-gallery-box">
                    <img key={index}  onClick={() => openPopup(image.img)} src={image.img} />
                    <img key={index}  onClick={() => openPopup(image.img2)} src={image.img2} />
                    <img key={index}  onClick={() => openPopup(image.img3)} src={image.img3} />
                    <img key={index}  onClick={() => openPopup(image.img4)} src={image.img4} />
                    <img key={index}  onClick={() => openPopup(image.img5)} src={image.img5} />
                    <img key={index}  onClick={() => openPopup(image.img6)} src={image.img6} />
                  </div> 
                </SwiperSlide>
              ))}
              </Swiper>               
              {selectedImage && (
                <div className="gallery_popup gallery_">
                  <span className="close" onClick={closePopup}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-xlg"
                    viewBox="0 0 25 25"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                  </span>
                  <img src={selectedImage} alt="Selected Image" />
                </div>
              )}
            </div>
			  </div>

            <section className="pb-5">
              <Container>
                <h3 className="common-heading text-uppercase mb-4">
                  Project Walkthrough Video
                </h3>
                <ReactPlayer
                url={video}
                height="355px"
                className="react-player walkthrough-video"
              />
              </Container>
            </section>


        <Container>
          <section className="p--partners bg-light p-4 mb-5">
            <div className="">
              <h3 className="common-heading text-uppercase mb-4">
                Our Partners
              </h3>
              <div className="row justify-content-center">
                <div className="col-lg-2 col-md-3 col-6 sup-col">
                  <div className="supplier-logo">
                    <NavLink to="/design-services" className="mb-3">
                      <img src={stuvita} alt="stuvita" className="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/design-services" className="text-center d-block" style={{ color: '#5c5b5b'}}>
                  <p className="mb-md-3 mb-5 font-weight-bold">Luxury Interiors and Exteriors</p>
                  </NavLink>
                </div>
                <div className="col-lg-2 col-md-3 col-6 sup-col">
                  <div className="supplier-logo">
                    <NavLink to="/kitchen-packages" className="mb-3">
                      <img src={qualita} alt="qualita" className="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/kitchen-packages" className="text-center d-block" style={{ color: '#5c5b5b'}}>
                    <p className="mb-md-3 mb-5 font-weight-bold">Kitchens and wardrobes</p>
                  </NavLink>
                </div>
                <div className="col-lg-2 col-md-3 col-6 sup-col">
                  <div className="supplier-logo">
                    <NavLink to="/sarika_totla" className="mb-3">
                      <img src={thecolorco1} alt="thecolorco" className="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/sarika_totla" className="text-center d-block" style={{ color: '#5c5b5b'}}>
                    <p className="mb-md-3 mb-5 font-weight-bold">Designer walls</p>
                  </NavLink>
                </div>
                <div className="col-lg-2 col-md-3 col-6 sup-col">
                  <div className="supplier-logo">
                    <NavLink to="/designiche_design_services" className="cursor-default mb-3">
                      <img src="https://www.designiche.com/live/public/storage/seller/thumb/4ef58cf3-6fc3-40aa-a886-78e6404fed88.png" alt="A 2 Interiors" className="img-fluid" />
                    </NavLink>
                  </div>
                  <p className="mb-md-3 mb-5 font-weight-bold text-center">Turnkey project execution</p>
                </div>
                <div className="col-lg-2 col-md-3 col-6 sup-col">
                  <div className="supplier-logo">
                    <NavLink to="/designiche_design_services" className="cursor-default mb-3">
                      <img src="https://www.designiche.com/live/public/storage/seller/thumb/999ea7d2-b7c6-4e97-b5ec-9e9f1d36947b.png" alt="A2 Interiors" className="img-fluid" />
                    </NavLink>
                  </div>
                  <p className="mb-md-3 mb-5 font-weight-bold text-center">Offices</p>
                </div>
              </div>
            </div>
          </section>
        </Container>
    </div>
  );
}

//export default Services;
function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(homePageActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
