import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Newsletter from "../../component/Newsletter/Newsletter";
import RecentlyViewed from "../../component/RecentlyViewed/RecentlyViewed";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import { NavLink } from "react-router-dom";
import * as cartActions from "../../actions/cartActions";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";

const Payment = (props) => {
  const { recentlyView, title, actions, deviceToken, sellerOrderData } = props;
  const isToken = deviceToken;
  const [oneTime, setOneTime] = useState(true);

  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (oneTime) {
      let params = {};
      params.deviceToken = isToken;
      actions.loadCartItem(params);
      setOneTime(false);
    }
  }, [title, actions, isToken, oneTime]);
  const { id } = useParams();

  useEffect(() => {
    function fetchData() {
      actions.loadSellerData({ id: id });
    }
    fetchData();
  }, [actions, id]);

  const notFound = {
    title: "Your payment was successful.",
    description: "Thank you for shopping at",
    image: "/assets/img/credit-card.svg",
    logo: "/assets/img/logo.svg",
    url: "/user/order",
    buttion: "View Orders",
    description_mes: "",
    description_mes_se: "our trusted partner brought on board by invitation.",
  };

  const [BrdName] = useState([{ title: "Shopping Cart", url: "/cart" }]);
  return (
    <>
      <Breadcrumbs name={BrdName} />
      <section className="shopping_cart_sec py-4">
        <Container>
          <div className="shopping_cart">
            <div className="no_record_found text-center pt-5">
              <div className="pt-5">
                {title}
                <small>
                  {notFound.description}
                  <img src={notFound.logo} alt="log" className="pl-2" />
                </small>
                <span>{notFound.description_mes_se}</span>
                <div className="payment_div">
                  <span>{notFound.description_mes}</span>
                  {sellerOrderData && (
                    <div>
                      {sellerOrderData.map((item, index) => (
                        <div className="sliderproduct" key={index}>
                          <img src={item.logo} alt="log" className="pl-2" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="py-5">
                <NavLink
                  to={notFound.url}
                  title="Shop Now"
                  exact
                  className="btn py-2"
                >
                  {notFound.buttion}
                </NavLink>
              </div>
            </div>
            <div className="py-4 mt-4">
              <div className="p-3 bg-light">
                <div className="cart_table">
                  <RecentlyViewed recentlyView={recentlyView} />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

Payment.propTypes = {
  recentlyView: PropTypes.any.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    recentlyView: state.recentlyView,
    deviceToken: state.deviceToken,
    sellerOrderData: state.sellerOrderData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(cartActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
