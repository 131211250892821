import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function userguestReducer(
  state = initialState.userGuest,
  action
) {
  switch (action.type) {
    case types.LOADED_USER_GUEST_SUCCESS:
      return action.userGuest;
    default:
      return state;
  }
}
