import React from "react";
import { Row, Col } from "react-bootstrap";
import LazyLoad from "../../component/LazyLoad/LazyLoad";
import "./HomeBanner.css";
import { NavLink } from "react-router-dom";
import "./HomeTabPanel.css";
import { renderTitle } from "../../utils/helpers";

const HotProduct = (props) => {
  return (
    <>
      <div className="main_tab_content">
        <div className="bg_after position-relative">
          {props.hotProductData.map((category, index) => (
            <div key={index} className="hot_products">
              {category.type === "category" ? (
                <>
				          <h3 className="mb-4 pb-2 font-weight-bold">Browse our featured bestsellers, trending collections & active deals!</h3>
                  <div
                    className="tab_heading d-flex align-items-center"
                    key={category.id}
                  >
                    <h2>{category.title}</h2>
                    <NavLink
                      to={"/marketplace?type=" + category.id}
                      title="Product"
                      exact
                      className="link ml-auto"
                    >
                      See All
                    </NavLink>
                  </div>
                  <Row className="mb-5">
                    {category.product.map((product) => (
                      <Col
                        xs={12}
                        sm={6}
                        md={3}
                        className="product product-wrap"
                        key={product.id}
                      >
                      <NavLink
                            to={
                              "/product/details/" +
                              product.id
                            }
                            title={product.title}
                            exact
                          >
                        <div className="pro_img">
                          <LazyLoad
                            images={product.image}
                            key={product.id}
                            imageClass={"productBlur"}
                          />
                          <div className="tab_overlay">
                            <NavLink
                              to={"/product/details/" + product.id}
                              title={product.title}
                              exact
                              className="btn"
                            >
                              <i className="fas fa-eye"></i>
                            </NavLink>
                          </div>
                        </div>
                        <div className="title">

                          <div className="text-center">
                          <NavLink
                            to={
                              "/product/details/" +
                              product.id
                            }
                            title={product.title}
                            exact
                            className="btn view-product"
                            >View Product </NavLink>
                          </div>
                        </div>
                        </NavLink>
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <div className="advertise mb-5" key={category.id}>
                  <div className="text-center">
                    <LazyLoad
                      images={category.image}
                      key={category.id}
                      imageClass={"advertiseBlur"}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HotProduct;
