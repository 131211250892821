import React, { useState, useCallback, useEffect } from "react";
import { Form, FormGroup, Button, Accordion, Card } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import "./ListSidebar.css";
import Slider from "react-input-slider";
const queryString = require("query-string");

const ListSidebar = (props) => {
  const { productFilterData } = props;
  const { category, discount, length, rating, maxPrice } = productFilterData;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [amount, setAmount] = useState({ min: 0, max: maxPrice });
  const [search, setSearch] = useState("");
  const [zipcode, setZipCode] = useState("");  

  localStorage.setItem("maxPrice", amount.max);

  const setFilter = useCallback(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("keyword")) {
      setSearch(params.get("keyword"));
    }
    if (params.get("zipcode")) {
      setZipCode(params.get("zipcode"));
    }
  }, [location]);

  useEffect(() => {
    setFilter();
  }, [setFilter]);
  function SidebarAddClass() {
    document
      .getElementsByClassName("sidebar")[0]
      .setAttribute("id", "sidebar_panel");
  }
  function SidebarRemoveClass() {
    document
      .getElementsByClassName("sidebar")[0]
      .removeAttribute("id", "sidebar_panel");
  }
  const [active, setActive] = useState(params.get("categoryId"));

  const handleSetFilter = (type, id) => {
    let queryStringParsed = queryString.parse(location.search);
    if (
      queryStringParsed[type] &&
      queryStringParsed[type].includes(id.toString())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleAmountFilter = () => {
    var amount = localStorage.getItem("maxPrice");
    const params = new URLSearchParams(location.search);
    params.delete("page");
    params.set("amount", amount);
    history.push({
      pathname: "/marketplace",
      search: "?" + params,
    });
  };

  const resetSetFilter = () => {
    history.push(`/marketplace`);
    window.location.reload(false);
  };

  const handleSearchClick = () => {
    params.set("keyword", search);
    params.delete("page");
    history.push({
      pathname: "/marketplace",
      search: "?" + params,
    });
  };

  const handleLocationClick = () => {
    params.set("zipcode", zipcode);
    params.delete("page");
    history.push({
      pathname: "/marketplace",
      search: "?" + params,
    });
  };

  const handleClickFilter = (type, id) => {
    let queryStringParsed = queryString.parse(location.search);
    if (queryStringParsed.page) {
      delete queryStringParsed.page;
    }

    if (Object.keys(queryStringParsed).length === 0)
      history.push(`/marketplace?${type}=${id}`);
    else {
      if (
        queryStringParsed[type] &&
        !queryStringParsed[type].includes(id.toString())
      ) {
        history.push(`/marketplace${location.search}&${type}=${id}`);
      } else {
        if (queryStringParsed[type]) {
          if (typeof queryStringParsed[type] === "string")
            delete queryStringParsed[type];
          else {
            var index = queryStringParsed[type].indexOf(id.toString());
            queryStringParsed[type].splice(index, 1);
          }
          history.push(
            `/marketplace?${queryString.stringify(queryStringParsed)}`
          );
        } else {
          history.push(`/marketplace${location.search}&${type}=${id}`);
        }
      }
    }
  };

  const renderCategories = () => {
    if (category && category.length > 0) {
      return category.map((item, index) => (
        <Card key={index}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={item.id}>
              {item.title}{" "}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={item.id} className="">
            <Card.Body className="p-0">
              <ul className="sidebar_menu pl-3">
                {item.childrenCategories.map((category) => (
                  <li
                    className={active === category.id ? "active" : ""}
                    onClick={() => setActive(category.id)}
                    key={category.id}
                  >
                    <span
                      className="categoryData"
                      onClick={() => props.onClick(category)}
                    > 
                      {category.title} {category.supplier_total_products >0 ? `(${category.supplier_total_products})` : ``}
                      <i className="fas fa-check pl-2 ml-auto"></i>
                    </span>
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ));
    }

    return null;
  };

  const renderDiscount = () => {
    if (discount && discount.length > 0) {
      return (
        <Card className="d-none">
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="3">
              Discount <i className="fa fa-angle-down" aria-hidden="true"></i>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3" className="">
            <Card.Body className="p-0">
              <ul className="multiple_select sidebar_menu pl-3">
                {discount.map((discountItem, index) => (
                  <li key={index}>
                    <span className="custom_check">
                      {discountItem.value} &nbsp;
                      <input
                        type="checkbox"
                        onClick={(v) =>
                          handleClickFilter("discount", discountItem.key)
                        }
                        defaultChecked={handleSetFilter(
                          "discount",
                          discountItem.key
                        )}
                      />
                      <span className="check_indicator">&nbsp;</span>
                    </span>
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    }
    return null;
  };
  const renderLength = () => {
    if (length && length.length > 0) {
      return (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="4">
              Length <i className="fa fa-angle-down" aria-hidden="true"></i>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body className="p-0">
              <ul className="multiple_select sidebar_menu pl-3">
                {length.map((lengthItem) => (
                  <li key={lengthItem.key}>
                    <span className="custom_check">
                      {lengthItem.value} &nbsp;
                      <input
                        type="checkbox"
                        onClick={(v) =>
                          handleClickFilter("length", lengthItem.key)
                        }
                        defaultChecked={handleSetFilter(
                          "length",
                          lengthItem.key
                        )}
                      />
                      <span className="check_indicator">&nbsp;</span>
                    </span>
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    }
    return null;
  };
  const renderRating = () => {
    if (rating && rating.length > 0) {
      return (
        <Card className="d-none">
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="5">
              Customer Rating{" "}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body className="p-0">
              <ul className="multiple_select sidebar_menu pl-3">
                {rating.map((ratingItem) => (
                  <li key={ratingItem.key}>
                    <span className="custom_check">
                      {ratingItem.value} &nbsp;
                      <input
                        type="checkbox"
                        onClick={(v) =>
                          handleClickFilter("rating", ratingItem.key)
                        }
                        defaultChecked={handleSetFilter(
                          "rating",
                          ratingItem.key
                        )}
                      />
                      <span className="check_indicator">&nbsp;</span>
                    </span>
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    }
    return null;
  };
  const renderAmount = () => {
    if (maxPrice) {
      return (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="6">
              Price Range<i className="fa fa-angle-down" aria-hidden="true"></i>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              {amount.max ? (
                <div className="text-right">{amount.max}</div>
              ) : (
                <div className="text-right">0</div>
              )}
              <Slider
                className="w-100"
                axis="x"
                xstep={100}
                xmin={0}
                xmax={maxPrice}
                x={amount.max}
                onChange={({ x }) => {
                  setAmount({ max: x });
                }}
                onDragEnd={() => {
                  handleAmountFilter();
                }}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    }
    return null;
  };
  return (
    <>
      <div className="sidebar_wrap">
        <span id="filter_toggle" onClick={SidebarAddClass}>
          <img
            src="/../../../assets/img/filter.png"
            className="mr-2"
            alt="Filter"
          />
          Filter
        </span>
        <div className="sidebar">
          <div className="sidebar_menu_block">
            <span className="filter_close" onClick={SidebarRemoveClass}>
              <img src="/../../../assets/img/close_white.png" alt="Close" />
            </span>
            <Form>
              <FormGroup className="reset_filter d-flex align-items-center">
                <p className="m-0">Refine Your Search</p>
                <span className="link ml-auto" onClick={() => resetSetFilter()}>
                  Reset
                </span>
              </FormGroup>
              <FormGroup className="search_locate sidebar_filter position-relative">
                <span className="pre">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <Form.Control
                  className="px-5"
                  type="text"
                  placeholder="Enter your pincode"
                  value={zipcode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <Button type="button">
                  <img
                    src="/../../../assets/img/search.png"
                    alt="Search"
                    onClick={(e) => handleLocationClick()}
                  />
                </Button>
              </FormGroup>
              <FormGroup className="sidebar_filter position-relative">
                <Form.Control
                  className="pr-5"
                  type="text"
                  placeholder="Search by product name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button type="button" onClick={(e) => handleSearchClick()}>
                  <img src="../assets/img/search.png" alt="" />
                </Button>
              </FormGroup>
            </Form>
            <div className="accordion_link_sec">
              <Accordion defaultActiveKey="338bdc9b-6a39-4404-b03c-d2482e359732">
                {renderCategories()}
                { /*{renderAmount()} */ }
                { /* {renderDiscount()} */ }
                { /*  {renderLength()} */ }
				{ /* {renderRating()} */ }
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListSidebar;
