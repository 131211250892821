import React, { useState } from "react";
import { connect } from "react-redux";

const PaymentForm = (props) => {
  const { mySignature, paymentUrl } = props;
  const [paymentForm] = useState(props.paymentData);
  return (
    <>
      <form id="redirectForm" method="post" action={paymentUrl}>
        <input
          type="hidden"
          className="form-pay"
          name="appId"
          defaultValue={paymentForm.appId}
        />
        <input
          type="hidden"
          className="form-pay"
          name="orderId"
          defaultValue={paymentForm.orderId}
        />
        <input
          className="form-pay"
          type="hidden"
          name="orderAmount"
          defaultValue={paymentForm.orderAmount}
        />
        <input
          className="form-pay"
          type="hidden"
          name="orderCurrency"
          defaultValue={paymentForm.orderCurrency}
        />
        <input
          className="form-pay"
          type="hidden"
          name="orderNote"
          defaultValue={paymentForm.orderNote}
        />
        <input
          className="form-pay"
          type="hidden"
          name="customerName"
          defaultValue={paymentForm.customerName}
        />
        <input
          className="form-pay"
          type="hidden"
          name="customerEmail"
          defaultValue={paymentForm.customerEmail}
        />
        <input
          className="form-pay"
          type="hidden"
          name="customerPhone"
          defaultValue={paymentForm.customerPhone}
        />
        <input
          className="form-pay"
          type="hidden"
          name="returnUrl"
          defaultValue={paymentForm.returnUrl}
        />
        <input
          className="form-pay"
          type="hidden"
          name="notifyUrl"
          defaultValue={paymentForm.notifyUrl}
        />
        <input
          className="form-pay"
          type="hidden"
          name="signature"
          defaultValue={mySignature}
        />
      </form>
    </>
  );
};

export default connect()(PaymentForm);
