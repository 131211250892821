import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { setToaster, showToaster } from "../utils/helpers";
import * as userActions from "./userActions";


export function loadbrandDataSuccess(brandData) {
  return { type: types.LOAD_BRAND_DATA_SUCCESS, brandData };
}

export function loadBrandData(params) {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.GET_SUPPLIER_DETAILS, params)
      .then((response) => {
        
        dispatch(toggleNetworkRequestStatus(false));
        if (response.data.status === 200) {
         // dispatch(loadbrandDataSuccess({}));
          dispatch(loadbrandDataSuccess(response.data.data)); 
		    console.log("========start INNER brandData ===========");
			console.log(response.data.data);
			console.log("========end INNER brandData ===========");
         }  
        if (response.status === 201) {
          if (response.data.status === 401) {
            userActions.userLogoutAction()(dispatch);
            showToaster("Your session has been expired please login again.");
          }
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      });
  };
}
 

