import React from 'react';
import {Container} from 'react-bootstrap';
import {Link,useHistory } from 'react-router-dom';
import './Breadcrumbs.css';

const Breadcrumbs = (props) =>{
    const history = useHistory();
    const marketplace = localStorage.getItem("marketplace");
    let product_details = false;
    if(window.location.pathname.indexOf('/product/details/')!=-1) product_details = true;
    return(
        
        <>
        <section className="breadcrumb_sec">
            <Container>
                <ol className="breadcrumb">
                    <li key={0}><Link to="/">Home / </Link></li>
                    {props.name && props.name.map
                        (
                            (value,key)=>
                            ( value.url ) ? (
                            <li className="active" key={key}>
                                {
                                 ( marketplace && product_details ) ? (
                                <a href="#" onClick={history.goBack}>&nbsp;{value.title}</a>
                                 ) :(
                                    <Link to={value.url}>&nbsp;{value.title}</Link>
                                 )
                                }
                                </li>
                            ) :  (  <li className="active" key={key}>&nbsp;{value.title}</li>)
                        )
                    }
                </ol>
            </Container>
        </section>
        </>
    );
};

export default Breadcrumbs;